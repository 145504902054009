import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { forkJoin, from, Observable, of } from 'rxjs';
import { concatMap, first, map, switchMap, take } from 'rxjs/operators';
import { selectElementContents } from 'src/app/core/functions';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';

import { ContactPerspective } from '../../model/contactPerspective';
import { SearchDefinition } from '../../model/search-definition';
import { ContactService } from '../../services/contact.service';
import { ContactsSelectionService } from '../../services/contacts-selection.service';
import { ContextNodePaneActionsService } from '../context-node/context-node-pane-actions.service';
import { PaneReferenceService } from '../dashboard/services/pane-reference.service';

declare var $: any;
const DEFAULT_ACTIONS: SelectionAction = {
    generateReport: false,
    createProject: false,
    createBucket: false,
    assignToProject: false,
};

@Component({
    selector: "diversite-contact-perspective-selection",
    templateUrl: "./contact-perspective-selection.component.html",
    styleUrls: ["./contact-perspective-selection.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPerspectiveSelectionComponent implements OnChanges, OnInit, OnDestroy {
    @Input() allowedActions: SelectionAction = DEFAULT_ACTIONS;
    @Input() contactIds: string[];

    @Output() generateReport = new EventEmitter<string[]>();

    toCopyCPs: ContactPerspective[] = [];
    copyLoading = false;
    isAdd = true;

    contactObs: { id: string; contactPerspectiveObs: Observable<ContactPerspective> }[] = [];

    parentBucketId: string;
    toAssignBucketId: string;
    isDownloading = false;
    minimized = false;
    addBucketPrompt = false;
    bucketName = "";
    idfig1: string;
    idfig2: string;

    private disposeBag = new DisposeBag();

    constructor(
        private sanitizer: DomSanitizer,
        private chRef: ChangeDetectorRef,
        private contactService: ContactService,
        private hostElement: ElementRef,
        private paneRefService: PaneReferenceService,
        private notificationService: NotificationService,
        private contactsSelectionService: ContactsSelectionService,
        private actionsService: ContextNodePaneActionsService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.contactIds && changes.contactIds.currentValue) {
            this.contactObs = this.setContactData(changes.contactIds.currentValue);
        }
    }

    ngOnInit(): void { }

    openMassEditPane(): void {
        this.actionsService
            .openActionPaneAtIndex("mass-edit", this.paneRefService.currentLayout.length + 1, {
                contactIds: this.contactObs.map((cObs) => cObs.id),
            })
            .subscribe()
            .disposedBy(this.disposeBag);
    }

    openMergeMembersPane(): void {
        this.actionsService
            .openActionPaneAtIndex("merge-contacts", this.paneRefService.currentLayout.length + 1, {
                contactIdfigs: [this.idfig1, this.idfig2],
            })
            .subscribe()
            .disposedBy(this.disposeBag);
    }

    openDownloadPane(): void {

        this.actionsService
            .openActionPaneAtIndex("export", this.paneRefService.currentLayout.length + 1, {
                searchDefinition: SearchDefinition.DEFAULT_SEARCH_DEFINITION_CONTACT_IDS(this.contactIds),
            })
            .subscribe()
            .disposedBy(this.disposeBag);
    }

    openSelectionPane(): void {
        this.actionsService.openSearchPane(
            SearchDefinition.DEFAULT_SEARCH_DEFINITION_CONTACT_IDS(this.contactIds)
        ).subscribe(_ => {
            this.minimized = true;
            this.chRef.detectChanges();
        }).disposedBy(this.disposeBag);
    }


    openCreateFolder(): void {
        this.contactService.contactsByIds(this.contactIds, { listen: false }).pipe(switchMap(contacts => {
            return this.actionsService.openActionPaneAtIndex("add-idfig-list", 1, {
                contactIdfigs: contacts.map(c => c.idfig).filter(idfig => idfig && idfig !== "")
            })
        })).subscribe().disposedBy(this.disposeBag);

    }

    private setContactData(
        contactIds: string[]
    ): { id: string; contactPerspectiveObs: Observable<ContactPerspective> }[] {
        return contactIds
            .filter((id, index) => index < 10)
            .map((contactId, index) => {
                const alreadyExists = this.contactObs.find((cd) => cd.id === contactId);
                if (alreadyExists) {
                    return alreadyExists;
                } else {
                    return {
                        id: contactId,
                        contactPerspectiveObs: this.contactService.contactById(contactId).pipe(
                            take(1),
                            map((c) => {
                                if (index === 0) {
                                    this.idfig1 = c.idfig;
                                }
                                if (index === 1) {
                                    this.idfig2 = c.idfig;
                                }
                                return c.defaultPerspective;
                            })
                        ),
                    };
                }
            })
            .filter((c) => c);
    }

    safeImage(contactPerspective: ContactPerspective): SafeStyle {
        const image = contactPerspective.contact.images[0];
        if (image) {
            return this.sanitizer.bypassSecurityTrustStyle(`url(${image.url})`);
        }
    }

    trackById(_: any, item: any): string {
        return item.id;
    }

    clearSelection(): void {
        this.contactsSelectionService.clearSelection();
    }

    remove(cp: ContactPerspective): void {
        this.contactsSelectionService.removeSelection(cp.id);
    }

    get direction(): "up" | "down" {
        return this.minimized ? "up" : "down";
    }

    spaceString(i: number): string {
        return `${"--".repeat(i)} `;
    }

    createReport(): void {
        this.generateReport.emit(this.contactsSelectionService.currentSelection);
    }

    folderName(contactPerspectives: ContactPerspective[]): string {
        return `selection_${contactPerspectives.length}_contacts`;
    }

    trackByFn(_: number, contactId: string): string {
        return `${contactId}`;
    }

    get selectionLength(): number {
        return this.contactsSelectionService.currentSelection.length;
    }

    get currentSelection(): string[] {
        return this.contactsSelectionService.currentSelection;
    }

    copySelectionInfo(): void {
        const arrayBunch = [];
        let tempArray = [];
        const numByBatch = 50;
        this.copyLoading = true;
        this.contactsSelectionService.currentSelection.forEach((id, index) => {
            tempArray.push(id);

            if ((index + 1) % numByBatch === 0) {
                arrayBunch.push([...tempArray]);
                tempArray = [];
            }
        });
        if (tempArray.length > 0) {
            arrayBunch.push([...tempArray]);
        }

        from(arrayBunch)
            .pipe(
                concatMap((idsArray: string[]) => {
                    return forkJoin(
                        idsArray.map((cId) =>
                            this.contactService.contactById(cId).pipe(
                                first(),
                                switchMap((c) => {
                                    if (c) {
                                        return of(c.defaultPerspective);
                                    } else {
                                        return of(undefined);
                                    }
                                })
                            )
                        )
                    );
                }),
                map((cps) => cps.filter((cp) => cp))
            )
            .pipe(take(Math.ceil(this.contactsSelectionService.currentSelection.length / numByBatch)))
            .subscribe(
                (cps: ContactPerspective[]) => {
                    this.toCopyCPs = [...this.toCopyCPs, ...cps];
                    this.chRef.detectChanges();
                },
                () => { },
                () => {
                    this.copy();
                    this.toCopyCPs = [];
                    this.copyLoading = false;
                    this.chRef.detectChanges();
                }
            );
    }

    private copy(): void {
        const table = $(".table-for-copy", this.hostElement.nativeElement);
        table.removeClass("hidden");
        selectElementContents(table.get(0));
        navigator.clipboard.writeText(window.getSelection().toString());
        table.addClass("hidden");
        this.notificationService.show(
            "Les informations de l'utilisateur ont été copiés dans le presse-papier.",
            "info"
        );
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}

export interface SelectionAction {
    generateReport: boolean;
    createProject: boolean;
    createBucket: boolean;
    assignToProject: boolean;
}
