<div *ngIf="formElement">
    <clr-radio-container [class.no-label]="!options.showLabel">
        <label *ngIf="options.showLabel"
            >{{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
            ><ng-content select="[tooltip-content]"></ng-content
        ></label>
        <clr-radio-wrapper *ngFor="let option of formElement.options">
            <input
                [disabled]="formElement.readonly"
                (change)="onInputChange()"
                [name]="formElement.name"
                [(ngModel)]="value"
                type="radio"
                clrRadio
                [value]="option.id"
            />
            <label>{{ option.label[lang] }}</label>
        </clr-radio-wrapper>
    </clr-radio-container>
</div>
