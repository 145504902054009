<div class="attribute-node" *ngIf="!attributeNode.attributeId">
    <div class="toggle-open">
        <cds-icon (click)="toggleOpen()" shape="angle" [direction]="open ? 'down' : 'right'"></cds-icon>
        <cds-icon (click)="onRemoveNode()" *ngIf="editPerspectiveMode" shape="times"></cds-icon>
    </div>
    <div class="attribute-node-content">
        <div class="node-title">
            <cds-icon *ngIf="editPerspectiveMode" class="handle-option" shape="drag-handle"></cds-icon>
            <input
                *ngIf="editPerspectiveMode"
                class="node-name-input"
                [value]="attributeNode.name"
                (change)="onNodeNameChange($event.target.value)"
            />

            <input
                *ngIf="!editPerspectiveMode"
                readonly
                class="node-name-input readonly"
                [value]="attributeNode.name"
            />
        </div>
        <div class="attribute-nodes" [hidden]="!(attributeNode.children && open)">
            <ng-container *ngFor="let node of attributeNode.children">
                <diversite-attribute-node
                    *ngIf="showEmptyAttributes || (!showEmptyAttributes && hasValue(node))"
                    [attr.data-nodeid]="node.id"
                    [showEmptyAttributes]="showEmptyAttributes"
                    [class.attribute-child]="node.attributeId ? true : false"
                    [attributeNode]="node"
                    [editPerspectiveMode]="editPerspectiveMode"
                    [contact]="contact"
                    (attributeNodeChange)="onAttributeNodeChildChange($event)"
                    (sortTree)="onSortTree()"
                    (removeNode)="onRemoveNode($event)"
                ></diversite-attribute-node>
            </ng-container>
        </div>
        <a class="add-sub-folder" *ngIf="open && editPerspectiveMode" (click)="addSubfolder()" class="action-link"
            >Ajouter un sous-dossier</a
        >
    </div>
</div>

<div class="attribute-value node-title" *ngIf="attributeNode.attributeId">
    <cds-icon *ngIf="editPerspectiveMode" class="handle-option" shape="drag-handle"></cds-icon>
    <cds-icon *ngIf="editPerspectiveMode" shape="times"></cds-icon>
    <diversite-attribute-label [id]="attributeNode.attributeId"></diversite-attribute-label>

    <div class="content-value">
        <fillout-form-element
            *ngIf="editContactMode"
            [value]="tempAttributeValue"
            [lang]="lang"
            [options]="optionsForFormElements"
            [formElement]="tempFormElement"
            (responseChange)="tempAttributeValue = $event.value"
        ></fillout-form-element>
        <diversite-attribute-value
            *ngIf="!editContactMode"
            [attributeName]="attributeNode.attributeId"
            [value]="value"
        ></diversite-attribute-value>
    </div>
    <div class="actions">
        <button *ngIf="editContactMode" [disabled]="loading" class="btn btn-sm btn-primary" (click)="save()">
            <span *ngIf="!loading">Sauvegarder</span>
            <span *ngIf="loading" class="spinner spinner-sm"> Loading... </span>
        </button>
        <a *ngIf="!editContactMode && isLogged" class="action-link" (click)="setToEditMode()">Éditer</a>
        <a *ngIf="editContactMode" class="action-link" (click)="cancelEdit()">Annuler</a>
    </div>
</div>
