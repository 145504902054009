import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, isDevMode, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, concatMap, filter, map, mergeMap, Observable, pairwise, pluck, scan, skip, startWith, switchMap, tap } from 'rxjs';
import { Activity, ActivityService } from 'src/modules/diversite/services/activity.service';
import { ContextNodePaneActionsService } from '../../../context-node/context-node-pane-actions.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { escape } from 'querystring';

@Component({
    selector: 'diversite-activities-pane-content-container',
    templateUrl: './activities-pane-content-container.component.html',
    styleUrl: './activities-pane-content-container.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivitiesPaneContentContainerComponent {

    @Output() close = new EventEmitter<void>();
    @ViewChild("loadingContainer") loadingContainer: ElementRef;


    activities$: Observable<Activity[]>;

    private disposeBag = new DisposeBag();

    token: any;
    readyToLoadMore = true;

    triggerPagination$ = new BehaviorSubject<Date>(undefined);
    private _activities = new Map<string, Activity>();

    loadingObs: IntersectionObserver;

    constructor(private activityService: ActivityService, private actionService: ContextNodePaneActionsService, private chRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.activities$ = this.triggerPagination$.pipe(
            filter(_ => this.readyToLoadMore),
            tap(_ => {
                this.readyToLoadMore = false;
            }),
            mergeMap((token) => this.activityService.activities({ afterDate: token, limit: 150 })),
            map(activities => {
                activities.forEach(a => {
                    this._activities.set(a.id, a);
                });
                const orderedActivities = Array.from(this._activities.values()).sort((a, b) => {
                    return a.created < b.created ? 1 : -1;
                });
                this.token = orderedActivities[orderedActivities.length - 1].created;
                setTimeout(() => {
                    this.readyToLoadMore = true;
                }, 500);

                return orderedActivities;
            })
        );
    }

    ngAfterViewInit(): void {
        //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        //Add 'implements AfterViewInit' to the class.

        let options = {
            // root: document.querySelector('#scrollArea'),
            rootMargin: '0px',
            threshold: 1.0
        };

        this.loadingObs = new IntersectionObserver((entries) => {
            const isVisible = entries[0].isIntersecting;
            if (isVisible && this.readyToLoadMore) {
                this.chRef.detectChanges();
                this.triggerPagination$.next(this.token)
            }

        }, options);


        this.loadingObs.observe(this.loadingContainer.nativeElement)
    }

    isProd(): boolean {
        return !isDevMode();
    }

    trackById(_, entity: any): string {
        return entity.id;
    }

    onOpenProfile(contactId: string): void {
        this.actionService.openContactProfilePane(contactId).subscribe().disposedBy(this.disposeBag);
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
        this.loadingObs.unobserve(this.loadingContainer.nativeElement);
    }

}   
