import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { TextElement } from '../contacts-report/contacts-report.component';

@Component({
    selector: "diversite-editable-text",
    templateUrl: "./editable-text.component.html",
    styleUrls: ["./editable-text.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableTextComponent implements OnInit, AfterViewInit {
    @Input() text: TextElement;
    @Output() loaded = new EventEmitter<EditableTextLoadedEvent>();
    @Output() remove = new EventEmitter<void>();

    @ViewChild("dragger") dragger: ElementRef;

    constructor(private hostElement: ElementRef) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.loaded.emit({ host: this.hostElement, dragger: this.dragger });
    }

    close(): void {
        this.remove.emit();
    }
}

export interface EditableTextLoadedEvent {
    host: ElementRef;
    dragger: ElementRef;
}
