import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    SimpleChanges,
} from '@angular/core';

import { Availability } from '../../services/availability.service';

declare var $: any;
@Component({
    selector: "diversite-calendar-availabilities",
    templateUrl: "./calendar-availabilities.component.html",
    styleUrls: ["./calendar-availabilities.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarAvailabilitiesComponent implements OnInit, AfterViewInit {
    @Input() availabilities: Availability[];

    datepickerInstance: any;
    dateShow = {
        year: null,
        month: null,
    };
    constructor(private chRef: ChangeDetectorRef, private host: ElementRef) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.availabilities && changes.availabilities.currentValue) {
            this.setAvailabities();
        }
    }

    ngAfterViewInit(): void {
        const defaultDate = new Date();
        this.dateShow = { year: defaultDate.getFullYear(), month: defaultDate.getMonth() + 1 };
        this.datepickerInstance = $(".availability-profile", this.host.nativeElement).datepicker({
            nextText: ">",
            prevText: "<",
            showMonthAfterYear: true,
            defaultDate,
            monthNames: [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Août",
                "Septembre",
                "Octobre",
                "Novembre",
                "Décembre",
            ],
            dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
            onChangeMonthYear: (year: number, month: number) => {
                setTimeout(() => {
                    this.dateShow = { year, month };
                    this.setAvailabities();
                });
            },
        });

        this.setAvailabities();
    }

    private setAvailabities(): void {
        if (this.dateShow.year && this.dateShow.month && this.availabilities && this.availabilities.length > 0) {
            const days = $("table.ui-datepicker-calendar tbody td[data-handler=selectDay]", this.host.nativeElement);

            this.availabilities
                .filter((availability) => {
                    // console.log(availability.date.getFullYear(), this.dateShow.year);
                    // console.log(availability.date.getMonth() + 1, this.dateShow.month);
                    return (
                        availability.date.getFullYear() === this.dateShow.year &&
                        availability.date.getMonth() + 1 === this.dateShow.month
                    );
                })
                .forEach((availability) => {
                    const htmlElementForDay = days[availability.date.getDate() - 1];
                    if (availability.available) {
                        $(htmlElementForDay).addClass("available");
                    } else {
                        $(htmlElementForDay).addClass("not-available");
                    }
                });
        }
    }
}
