import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { debounceTime, skip } from "rxjs/operators";
import { DisposeBag } from "src/modules/core/utilities/dispose-bag";
import { FormElement } from "src/modules/diversite/model/form/form-element/form-element";
import { FormWeight } from "src/modules/diversite/model/form/form-element/form-weight";
import { TranslatableLabel } from "src/modules/diversite/services/data-catalog.service";

@Component({
    selector: "diversite-form-weight-edition",
    templateUrl: "./form-weight-edition.component.html",
    styleUrls: ["./form-weight-edition.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormWeightEditionComponent implements OnInit, OnChanges, OnChanges, OnDestroy {
    @Input() lang: string;
    @Input() formElement: FormWeight;
    @Input() readonly readonlyElement = false;
    @Output() formElementChange = new EventEmitter<FormElement>();
    @ViewChild("labelElement") labelElement: ElementRef;

    delayedChanges$ = new BehaviorSubject<void>(undefined);

    tempFormElement: { label: TranslatableLabel; readonly: boolean; required: boolean } = {
        label: {},
        readonly: false,
        required: false,
    };

    private _disposeBag = new DisposeBag();
    constructor(private chRef: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.lang && changes.lang.currentValue) {
            this.tempFormElement.label = {};
            this.chRef.detectChanges();
            this.tempFormElement.label = { ...this.initLabel() };
            this.chRef.detectChanges();
        }
    }

    private initLabel(): TranslatableLabel {
        const initLabel: TranslatableLabel = {};
        initLabel[this.lang] =
            this.formElement.label && this.formElement.label[this.lang] ? this.formElement.label[this.lang] : "";
        return initLabel;
    }

    ngOnInit(): void {
        this.tempFormElement = {
            label: this.initLabel(),
            readonly: this.formElement ? this.formElement.readonly : false,
            required: this.formElement ? this.formElement.required : false,
        };
        this.chRef.detectChanges();
        this.delayedChanges$
            .pipe(debounceTime(500), skip(1))
            .subscribe((_) => {
                const label = { ...this.formElement.label };
                label[this.lang] = this.labelElement.nativeElement.textContent;
                this.formElement = this.formElement.change({
                    label,
                    readonly: this.tempFormElement.readonly,
                    required: this.tempFormElement.required,
                });
                this.formElementChange.emit(this.formElement);
            })
            .disposedBy(this._disposeBag);
    }

    ngOnDestroy(): void {
        this._disposeBag.dispose();
    }

    onLabelChange(): void {
        this.delayedChanges$.next();
    }
}
