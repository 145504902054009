import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DisposeBag } from "src/modules/core/utilities/dispose-bag";
import { Contact } from "src/modules/diversite/model/contact";
import { ReportService } from "src/modules/diversite/services/report.service";

@Component({
    selector: "app-contact-container",
    templateUrl: "./contact-container.component.html",
    styleUrls: ["./contact-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactContainerComponent {}
