import { Injectable } from '@angular/core';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { SystemRequestService } from 'src/app/services/system-request.service';

import { FileUploadConnector } from './file-upload-connector';


@Injectable({
    providedIn: "root",
})
export class FileUploadGenericService {
    constructor(private systemRequestService: SystemRequestService) { }

    upload(file: File): Observable<UploadFileResponse> {
        // return this.systemRequestService.add({
        //     requestType: "generateaccesstoken"
        // }).pipe(
        //     switchMap(systemRequest => this.systemRequestService.get(systemRequest.id)),
        //     filter(systemRequest => systemRequest.result === "success"),
        //     switchMap(systemRequest => {
        //         const fileUploadConnector = new FileUploadConnector((systemRequest as GenerateAccessTokenRequest).token);
        //         return fileUploadConnector.uploadFile(file);
        //     })
        // );
        const fileUploadConnector = new FileUploadConnector();
        return fileUploadConnector.uploadFile(file);
    }

    uploadFiles(files: File[]): Observable<UploadFilesResponse> {
        // return this.systemRequestService.add({
        //     requestType: "generateaccesstoken"
        // }).pipe(
        //     switchMap(systemRequest => this.systemRequestService.get(systemRequest.id)),
        //     filter(systemRequest => systemRequest.result === "success"),
        //     switchMap(systemRequest => {
        //         const fileUploadConnector = new FileUploadConnector((systemRequest as GenerateAccessTokenRequest).token);
        //         return fileUploadConnector.uploadFile(file);
        //     })
        // );
        // const fileUploadConnector = new FileUploadConnector();
        // return fileUploadConnector.uploadFiles(files);


        const obs$ = new Observable<UploadFilesResponse>((subscriber) => {
            const uploadSubs$ = files.map((file, i: number) => {
                const fileUploadConnector = new FileUploadConnector();
                return fileUploadConnector.uploadFile(file);
            });

            const totalSize: number = files.map((file) => file.size).reduce((accumulator, curr) => accumulator + curr);
            return combineLatest(uploadSubs$)
                .pipe(
                    map((responses: UploadFileResponse[]) => {
                        const uploadBytes = responses
                            .map((response) =>
                                response.loadedBytes || response.loadedBytes === 0
                                    ? response.loadedBytes
                                    : response.asset.file.size
                            )
                            .reduce((accumulator, curr) => accumulator + curr);
                        return {
                            percentage: Math.round((uploadBytes / totalSize) * 100),
                            urls: responses.map((response) => {
                                return response.asset.finalUrl;
                            }),
                        };
                    }),
                    tap((response) => {
                        subscriber.next(response);
                        if (response.percentage === 100) {
                            subscriber.complete();
                        }
                    })
                )
                .subscribe();
        });

        return obs$;
    }
}


export interface UploadFileResponse {
    asset: Asset;
    loadedBytes?: number;
}

export interface UploadFilesResponse {
    urls: string[];
    percentage: number;
}

export interface Asset {
    assetName: string;
    file: File;
    finalUrl?: string;
}
