import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { ContactPublicMapperService } from 'src/modules/fillout/mapper/contact-public-mapper.service';
import { ContactPublic } from 'src/modules/fillout/services/recruit.service';

import { FormMapperService } from '../mappers/form-mapper.service';
import { Form } from '../model/form/form';

@Injectable({
    providedIn: "root",
})
export class ContactChangeHistoryService {
    constructor(
        private db: AngularFirestore,
        private formMapper: FormMapperService,
        private contactPublicMapper: ContactPublicMapperService,
        private userService: UserService
    ) { }

    historyForContact(id: string): Observable<ChangeHistory[]> {
        return this.db
            .collection("changesHistory")
            .doc(id)
            .collection("changes", (ref) => ref.orderBy("created", "desc"))
            .snapshotChanges()
            .pipe(
                switchMap((snapChanges: DocumentChangeAction<firebase.firestore.DocumentData>[]) => {
                    return forkJoin(
                        snapChanges.map((snapshot) => {
                            const data = snapshot.payload.doc.data();
                            const type: ChangeHistoryType = this.mapType(data);
                            return this.userService.user(data?.user?.id).pipe(
                                take(1),
                                map((user) => {
                                    const changeHistory: ChangeHistory = {
                                        id: snapshot.payload.doc.id,
                                        type,
                                        context: data.context || null,
                                        deltaId: data.deltaId && data.deltaId !== "" ? data.deltaId : null,
                                        data: this.mapDataForType(type, data),
                                        date: new Date(data.created.seconds * 1000),
                                        user,
                                    } as ChangeHistory;

                                    return changeHistory;
                                })
                            );
                        })
                    ).pipe(map(changeHistories => {
                        return changeHistories.filter(h => h.type !== "classification")
                    }))
                })
            );
    }

    delta(contactId: string, deltaId: string): Observable<ChangeHistoryDelta> {
        return this.db
            .collection("deltas")
            .doc(contactId)
            .collection("deltas")
            .doc(deltaId)
            .get()
            .pipe(
                map((doc) => {
                    const data = doc.data();

                    Object.keys(data).forEach((k) => {
                        if (this.valueIsTimestamp(data[k])) {
                            data[k] = data[k].toDate();
                        }
                    });

                    return {
                        ...data,
                        id: deltaId,
                        deltaDate: doc.data().deltaDate?.toDate(),
                    };
                })
            );
    }

    private mapDataForType(type: ChangeHistoryType, data: any): any {

        const d = {};

        if (type === "formSubmission" && data.formDefinition) {
            d["form"] = this.formMapper.formDtoToForm(data.formDefinition);
        }
        if (data.contact) {
            d["contact"] = this.buildContactFromAttributeList(data.contact);
        }


        return Object.keys(d).length > 0 ? d : undefined;
    }

    private buildContactFromAttributeList(attributes: any): ContactPublic {
        if (attributes && Object.keys(attributes).length > 0) {
            return this.contactPublicMapper.mapDataToContactPublic(attributes);
        }
    }

    valueOf(value: any): any {
        if (this.valueIsTimestamp(value)) {
            return new Date(value.seconds * 1000);
        }
        return value;
    }

    private valueIsTimestamp(value: any): boolean {
        return (
            value && typeof value === "object" && value.hasOwnProperty("seconds") && value.hasOwnProperty("nanoseconds")
        );
    }

    private mapType(data: any): ChangeHistoryType {
        if (data.contact && Object.keys(data.contact).length === 1 && Object.keys(data.contact).find(k => k.startsWith("contextNode_"))) {
            return "classification"
        }
        if (data.context?.type === 'upload-photo' || data.contact?.photo_photos_upload_form) {
            return "uploadPhoto";
        }
        if (data.requestType === "createandrecruitcontact" || data.requestType === "recruitContact") {
            return "formSubmission";
        }
        if (data.requestType === "legacySync") {
            return "legacySync";
        }
        if (data.requestType === "contactUpdate" && data.context?.type === "reactivation") {
            return "reactivation";
        }
        if (data.requestType === "contactUpdate" && data.context?.type === "deactivation") {
            return "deactivation";
        }
        if (data.requestType === "contactUpdate" && !data.context) {
            return "updateProfile";
        }

        return "unmapped";
    }
}

export interface ChangeHistory {
    id: string;
    date: Date;
    data: ChangeHistoryData;
    context: any;
    deltaId?: string;
    type: ChangeHistoryType;
    user?: User;
}

export type ChangeHistoryType =
    | "updateProfile"
    | "deactivation"
    | "reactivation"
    | "legacySync"
    | "formSubmission"
    | "uploadPhoto"
    | "classification"
    | "unmapped";

export interface ChangeHistoryData {
    form?: Form;
    contact?: ContactPublic;
}

export interface ChangeHistoryDelta {
    deltaDate: Date;
}
