import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, map, share, switchMap } from 'rxjs/operators';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { ContactService } from 'src/modules/diversite/services/contact.service';

@Component({
    selector: "diversite-merge-contacts-action",
    templateUrl: "./merge-contacts-action.component.html",
    styleUrls: ["./merge-contacts-action.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MergeContactsActionComponent implements OnInit, OnChanges {
    @Input() idfig1: string;
    @Input() idfig2: string;

    @Output() close = new EventEmitter<void>();

    private idfig1$ = new BehaviorSubject<string>(undefined);
    private idfig2$ = new BehaviorSubject<string>(undefined);
    private disposeBag = new DisposeBag();

    private contact1$: Observable<ContactPerspective> = this.idfig1$.pipe(
        debounceTime(1000),
        switchMap((idfig1) => this.contactService.contactByIdfig(idfig1)),
        map((c) => c?.defaultPerspective),
        share()
    );

    private contact2$: Observable<ContactPerspective> = this.idfig2$.pipe(
        debounceTime(1000),
        switchMap((idfig2) => this.contactService.contactByIdfig(idfig2)),
        map((c) => c?.defaultPerspective),
        share()
    );

    contact1: ContactPerspective;
    contact2: ContactPerspective;

    profileToKeep: string;
    loading = false;
    constructor(
        private contactService: ContactService,
        private notificationService: NotificationService,
        private chRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.contact1$
            .subscribe((c) => {
                if (!c) {
                    this.profileToKeep = undefined;
                }
                this.contact1 = c;
                this.chRef.markForCheck();
            })
            .disposedBy(this.disposeBag);
        this.contact2$
            .subscribe((c) => {
                if (!c) {
                    this.profileToKeep = undefined;
                }
                this.contact2 = c;
                this.chRef.markForCheck();
            })
            .disposedBy(this.disposeBag);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.idfig1?.currentValue) {
            this.onIdfig1Change(changes.idfig1?.currentValue);
        }
        if (changes.idfig2?.currentValue) {
            this.onIdfig2Change(changes.idfig2?.currentValue);
        }
    }

    onOpenChange(): void {
        this.close.emit();
    }

    onIdfig1Change(idfig: string): void {
        this.idfig1$.next(idfig);
    }
    onIdfig2Change(idfig: string): void {
        this.idfig2$.next(idfig);
    }

    cancel(): void {
        this.onOpenChange();
    }

    confirmContactMerge(): void {
        this.loading = true;
        if (this.contact1 && this.contact2 && this.profileToKeep) {
            console.log("contact1", this.contact1);
            console.log("contact2", this.contact2);
            console.log("target", this.profileToKeep);
            this.contactService
                .mergeProfiles([this.contact1, this.contact2], this.profileToKeep)
                .subscribe((_) => {
                    this.notificationService.show("Les profils ont été fusionné avec succès.", "success");
                    this.onOpenChange();
                    this.chRef.markForCheck();
                })
                .disposedBy(this.disposeBag);
        }
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
