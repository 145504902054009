import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, of } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Contact } from 'src/modules/diversite/model/contact';
import { ContactImage } from 'src/modules/diversite/model/contact-image';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { ContextNode } from 'src/modules/diversite/model/context-node';
import { ClassificationService } from 'src/modules/diversite/services/classification.service';
import { ContactContextNodeAssignService } from 'src/modules/diversite/services/contact-context-node-assign.service';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { ContactHighlight } from 'src/modules/diversite/services/elasticsearch.service';
import {
    FacePositionService,
    ImageDispositionOption,
    ImageLayout,
} from 'src/modules/diversite/services/face-position.service';
import {
    AttributeShown,
    SearchProfileVisibleAttributesService,
} from 'src/modules/diversite/services/search-profile-visible-attributes.service';

import { AddPhotosForContactEvent } from '../contact-view/contact-view.component';
import { AttributeChangeEvent } from './search-profile-attribute/search-profile-attribute.component';

const CARD_FACE_OPTIONS: ImageDispositionOption = {
    containerWidth: 240,
    containerHeight: 300,
    finalFaceHeight: 110,
};

declare let $: any;
@Component({
    selector: "diversite-contact-search-profile",
    templateUrl: "./contact-search-profile.component.html",
    styleUrls: ["./contact-search-profile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactSearchProfileComponent implements OnChanges, OnDestroy {
    @Input() contactId: string;
    @Input() contact: Contact;
    @Input() infoSize = 110;
    @Input() height = 300;
    @Input() width = 200;
    @Input() highlights: ContactHighlight[] = [];
    @Input() cardFaceDispositionOptions = CARD_FACE_OPTIONS;
    @Input() selected = false;
    @Input() selectedFieldToShow: string[] = [];
    @Input() removable = true;

    @Output() viewProfile = new EventEmitter<ContactPerspective>();
    @Output() selectContact = new EventEmitter<Contact>();
    @Output() loaded = new EventEmitter<ContactLoadedEvent>();
    @Output() contactDefaultImage = new EventEmitter<ChangeContactDefaultImageEvent>();
    @Output() addPhotos = new EventEmitter<AddPhotosForContactEvent>();
    @Output() editContact = new EventEmitter<Contact>();
    @Output() openFormPane = new EventEmitter<string>();

    contactPerspective$ = new BehaviorSubject<ContactPerspective>(undefined);
    isInViewport = false;
    defaultImage: ImageLayout;
    viewProfileWindow: ContactPerspective;
    thumbnails: ImageLayout[] = [];
    private _disposeBag = new DisposeBag();
    htmlFocusInfo = new Map<string, string>();
    shownAttributes$: Observable<AttributeShown[]>;
    highlights$ = new BehaviorSubject<ContactHighlight[]>([]);

    contextNodesToShow$: Observable<ContextNode[]>;

    zoom = 1;
    isCompact: boolean = false;

    private clickEvents$: Observable<MouseEvent>;

    constructor(
        private contactService: ContactService,
        private facePosition: FacePositionService,
        private hostElement: ElementRef,
        private visibleAttrService: SearchProfileVisibleAttributesService,
        private classificationService: ClassificationService,
        private assignContextNodeService: ContactContextNodeAssignService,
        private chRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.contextNodesToShow$ = combineLatest([this.contactPerspective$, this.classificationService.nodesToShowOnProfiles]).pipe(map(data => {
            const cp: ContactPerspective = data[0];
            const projectRootFolders: ContextNode[] = data[1] || [];
            if (cp && projectRootFolders.length > 0) {
                return projectRootFolders.filter(cn => {
                    return cp.contact?.getAttributeValue(`contextNode_${cn.id}`) ? true : false
                })
            }
            return projectRootFolders;
        }));
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (!this.contactPerspective$.value && this.contactId) {
            this.setContactPerspectiveObs(this.contactPerspectiveObs(this.contactId));
        }

        if (changes.contact?.currentValue && !this.contactId) {
            const contact: Contact = changes.contact?.currentValue;
            this.setContactPerspectiveObs(of(this.contactPerspective(contact)));
            this.loaded.emit({ contact, element: this.hostElement });
            this.onLoaded(this.hostElement.nativeElement, contact.defaultPerspective);
        }

        if (changes.highlights) {
            if (changes.highlights.currentValue) {
                this.highlights$.next(changes.highlights.currentValue)
            } else {
                this.highlights$.next([])
            }
        }

        if (changes.width?.currentValue && changes.width.currentValue !== changes.width.previousValue) {
            this.zoom = this._zoom(changes.width?.currentValue);
            this.isCompact = this._isCompact(changes.width?.currentValue);
        }
    }


    private setContactPerspectiveObs(cpObs: Observable<ContactPerspective>): void {
        cpObs.subscribe(cp => this.contactPerspective$.next(cp)).disposedBy(this._disposeBag);
        this.setVisibleAttributesObs();
    }

    private setVisibleAttributesObs(): void {
        this.shownAttributes$ = this.contactPerspective$.pipe(switchMap(cp => {
            return combineLatest([
                this.visibleAttrService.attributes().pipe(map(attrs => {
                    return attrs.filter(attr => {
                        const attributeHighlighted = this.highlights$.value.find(attributeHightlited => attributeHightlited.attributeName === attr.attributeId) ? true : false;
                        return cp.contact.hasAttribute(attr.attributeId) && !attributeHighlighted
                    })
                })),
                this.highlights$.pipe(
                    map(chs => {
                        return chs.map(ch => { return { attributeId: ch.attributeName, highlight: ch.highlight } });
                    })
                )]).pipe(map(x => x[0].concat(x[1])));
        }))
    }

    onLoaded(element: HTMLElement, cp: ContactPerspective): void {
        this.handleClickAndDoubleClick(element, cp)
    }

    valueForIndex(cp: ContactPerspective, attributeName: string): string {
        const value = cp.contact.getAttributeValue(attributeName);
        return value;
    }



    onOpenFormPane(formId: string): void {
        this.openFormPane.emit(formId);
    }

    private setImages(contactPerspective): void {
        this.thumbnails = this.imagesThumbnailSelector(contactPerspective);
        this.defaultImage = this.generateDefaultImage(contactPerspective);
    }

    private handleClickAndDoubleClick(element: HTMLElement, cp: ContactPerspective): void {
        if (!this.clickEvents$) {

            const photoCover = element;
            const clickEvent = fromEvent<MouseEvent>(photoCover, "mousedown");
            const dblClickEvent = fromEvent<MouseEvent>(photoCover, "dblclick");
            this.clickEvents$ = merge(clickEvent, dblClickEvent).pipe(debounceTime(250));
            this.clickEvents$.subscribe((event) => {

                if (event.type === "mousedown") {
                    this.select(cp.contact);
                } else {
                    this.openProfile(cp);
                }
            })
                .disposedBy(this._disposeBag);
        }

    }

    private _zoom(width: number): number {
        const value = width / 2 / 100;
        if (value > 1) {
            return 1;
        } else if (value < 0.6) {
            return 0.6;
        } else {
            return value;
        }
    }

    private contactPerspectiveObs(contactId: string): Observable<ContactPerspective> {
        return this.contactService.contactById(contactId, { listen: true }).pipe(
            map((c) => {
                return this.contactPerspective(c);
            }), tap(cp => {
                if (cp) {
                    this.loaded.emit({ contact: cp.contact, element: this.hostElement });
                    this.onLoaded(this.hostElement.nativeElement, cp);
                }
            })
        );
    }

    private contactPerspective(c: Contact): ContactPerspective {
        if (c) {
            const cp = c.defaultPerspective;

            this.setImages(cp);
            return cp;
        }
    }


    private _isCompact(width: number): boolean {
        return width < 130;
    }

    private generateDefaultImage(contactPerspective: ContactPerspective): ImageLayout {
        const defaultImage = contactPerspective.defaultImage;

        if (defaultImage) {
            return this.facePosition.mapContactImageToImageLayout(
                defaultImage,
                this.cardFaceDispositionOptions,
                contactPerspective.imageDispositionSpecs.find((imgSpec) => imgSpec.imageId === defaultImage.id)
            );
        }

        return null;
    }

    onImageSelected(cp: ContactPerspective, index: number): void {
        if (index || index === 0) {
            this.defaultImage = this.generateDefaultImage(cp);
            this.contactDefaultImage.emit({
                contactId: this.contactId ? this.contactId : this.contact?.id,
                index,
            });
            this.chRef.markForCheck();
        }
    }

    private imagesThumbnailSelector(contactPerspective: ContactPerspective): ImageLayout[] {
        return contactPerspective?.contact?.images.map((image) => this.thumbnail(image));
    }

    private openProfile(cp: ContactPerspective): void {
        this.viewProfile.emit(cp);
    }

    private select(contact: Contact): void {
        // if (!this.classificationService.disabled) {
        //     if (this.classificationService.selectedNodeId && this.classificationService.selectedNodeId !== "") {
        //         const updatedContact: Contact = this.contactPerspective$.value.contact.toggleContextNode(this.classificationService.selectedNodeId);
        //         this.contactPerspective$.next(updatedContact.defaultPerspective);
        //         // TO IMPLEMENT

        //         // this.editContact.emit(updatedContact);
        //         // this.assignContextNodeService.toggleClassificationForContact(updatedContact, this.classificationService.selectedNodeId);
        //     }
        // } else {
        this.selectContact.emit(contact);
        // }
    }

    onProfileClose(event: any): void {
        try {
            $(this.hostElement.nativeElement).draggable("enable");
        } catch { }
    }

    private thumbnail(contactImage: ContactImage): ImageLayout {
        if (contactImage) {
            return { url: contactImage.url, rotate: contactImage.rotate, id: contactImage.id };
        }
    }

    trackByAttrData(i: number, searchedAttribute: AttributeShown): string {
        return `${searchedAttribute.attributeId}`;
    }

    onAddPhotos(event: AddPhotosForContactEvent): void {
        this.addPhotos.emit(event);
    }

    onAttributeValueChange(response: AttributeChangeEvent, contact: Contact): void {
        const editedContact = contact.changeAttribute(response.attributeId, response.value);
        this.contactPerspective$.next(editedContact.defaultPerspective);
        this.contactService
            .editContact(editedContact)
            .subscribe()
            .disposedBy(this._disposeBag);
    }

    ngOnDestroy(): void {
        this._disposeBag.dispose();
    }
}

export interface ChangeContactDefaultImageEvent {
    contactId: string;
    index: number;
}

export interface ContactLoadedEvent {
    contact: Contact;
    element: ElementRef;
}


