<div [class.no-label]="!options.showLabel" *ngIf="formElement" class="imageupload-container clr-form-control">
    <label *ngIf="options.showLabel" class="clr-control-label"
        >{{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>

    <div class="clr-file-wrapper">
        <label class="clr-control-label">
            <span class="btn btn-primary btn-sm" [class.disabled]="uploading || formElement.readonly">{{
                label("addPhotoButtonText")
            }}</span>
            <input
                [disabled]="uploading || formElement.readonly"
                [id]="formElement.id"
                (change)="onInputChange($event)"
                class="clr-file"
                type="file"
                accept="image/*"
                multiple
                [name]="formElement.name"
                [required]="formElement.required"
            />
        </label>
    </div>

    <div [hidden]="!uploading" class="loading">
        <small class="text"
            >{{ label("uploadingText") }}<span class="spinner spinner-sm"> {{ label("uploadingText") }} </span></small
        >

        <div class="progress-static labeled">
            <div class="progress-meter" [attr.data-value]="percentage" [attr.data-displayval]="percentage"></div>
            <span>{{ percentage }}%</span>
        </div>
    </div>

    <div class="images" *ngIf="!uploading">
        <div *ngFor="let img of this.imageUrls; let i = index" class="image">
            <img [src]="img" />
            <cds-icon *ngIf="!formElement.readonly" (click)="deleteImage(i)" shape="times"></cds-icon>
        </div>
    </div>
</div>
