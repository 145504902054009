import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { Pane } from 'src/modules/diversite/model/pane';

import { PaneService } from './pane.service';

export const SPLIT_SETTINGS = {
    paneMinSize: 400,
    paneDefaultSize: 600,
    // should always be under 100
    paneCollapsedSize: 26,
};
@Injectable({
    providedIn: "root",
})
export class PaneReferenceService {
    constructor(private db: AngularFirestore, private authService: AuthService, private paneService: PaneService) { }

    private _paneContent = new BehaviorSubject<PaneContent[]>([]);

    private _paneAdded$ = new BehaviorSubject<Pane>(undefined);
    readonly paneAdded$ = this._paneAdded$.pipe(filter((p) => (p ? true : false)));

    init(): Observable<void> {


        return this.db
            .collection("paneLayouts")
            .doc(this.authService.loggedInUser.id)
            .get()
            .pipe(

                switchMap((doc) => {

                    const data = doc.data() as any;
                    if (doc.exists && data.columns && data.columns.length > 0) {
                        return of(data.columns);
                    } else {
                        return this.paneService.addPane(new Pane({ type: "treeview" })).pipe(
                            map((p) => {
                                return [this.newPaneReference(p.id)];
                            })
                        );
                    }
                }),
                switchMap((paneRefs: PaneReference[]) => {
                    return forkJoin(paneRefs.map(paneRef => this.paneService.pane(paneRef.id, { listen: false }))).pipe(map(panes => {
                        return paneRefs.map(paneRef => {
                            return {
                                paneRef,
                                pane: panes.find(pane => pane.id === paneRef.id)
                            } as PaneContent
                        })
                    }))
                }),
                tap((paneContents: PaneContent[]) => {
                    this._paneContent.next(paneContents)
                }),
                map(_ => undefined)
            );

    }


    editPane(pane: Pane): Observable<PaneContent> {
        return this.paneService.editPane(pane).pipe(map(p => {
            let newPaneContent: PaneContent;
            this._paneContent.next(this.currentLayout.map(pc => {
                if (pc.paneRef.id === p.id) {
                    newPaneContent = { ...pc, pane: p }
                    return newPaneContent
                }
                return pc;
            }));
            return newPaneContent;
        }))
    }


    saveLayout(): void {
        this.db.collection("paneLayouts").doc(this.authService.loggedInUser.id).set({
            columns: this.currentLayout.map(paneContent => {
                return { id: paneContent.paneRef.id, size: paneContent.paneRef.size };
            })
        });
    }


    layout(): Observable<PaneContent[]> {
        return this._paneContent;
    }

    get currentLayout(): PaneContent[] {
        return this._paneContent.value;
    }


    addPane(pane: Pane, insertAtIndex?: number): Observable<Pane> {

        const toReplaceContent = this._paneContent.value.find((paneContent) => pane.type === paneContent.pane.type && !paneContent.pane.locked);
        return this.paneService.addPane(pane).pipe(
            tap((pane) => {
                let currentLayout = this._paneContent.value;
                if (toReplaceContent) {
                    this._paneContent.next(
                        currentLayout.map((paneContent) => {
                            if (paneContent.paneRef.id === toReplaceContent.paneRef.id) {
                                return {
                                    paneRef: { ...paneContent.paneRef, id: pane.id },
                                    pane: pane.change({})
                                };
                            }
                            return { ...paneContent };
                        })
                    );
                    this._paneAdded$.next(pane);
                } else {
                    // add a column
                    if (insertAtIndex && insertAtIndex > 0) {
                        currentLayout.splice(insertAtIndex, 0, { paneRef: this.newPaneReference(pane.id), pane });
                    } else {
                        currentLayout = [...currentLayout, { paneRef: this.newPaneReference(pane.id), pane }];
                    }
                    this._paneContent.next(currentLayout);
                    this._paneAdded$.next(pane);
                }
            }),
            take(1)
        );

    }

    private newPaneReference(id: string): PaneReference {
        return { id, size: SPLIT_SETTINGS.paneDefaultSize };
    }

    editPaneRefSize(paneRefId: string, width: number): void {
        return this._paneContent.next(
            this._paneContent.value.map((paneContent) => {
                if (paneContent.paneRef.id === paneRefId) {
                    return {
                        ...paneContent, paneRef: { ...paneContent.paneRef, size: width }
                    }
                }
                return paneContent
            })
        );
    }

    toggleCollapsePane(paneId: string): void {
        this._paneContent.next(
            this._paneContent.value.map((paneContent) => {
                if (paneContent.paneRef.id === paneId) {
                    return {
                        ...paneContent, paneRef: { ...paneContent.paneRef, size: paneContent.paneRef.size === SPLIT_SETTINGS.paneCollapsedSize ? this.paneService.defaultPaneMinSizeForType(paneContent.pane.type) : SPLIT_SETTINGS.paneCollapsedSize }
                    };

                };
                return paneContent;
            }));
    }

    removePane(paneId: string): Observable<void> {
        let newPaneLayout = this._paneContent.value.filter((paneContent) => paneContent.paneRef.id !== paneId);
        this._paneContent.next(newPaneLayout);
        return this.paneService.removePane(paneId);
    }
}

export interface PaneReference {
    id: string;
    size?: number;
}

export interface PaneContent {
    paneRef: PaneReference;
    pane: Pane;
}
