import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { ContextNodePaneActionsService } from 'src/modules/diversite/components/context-node/context-node-pane-actions.service';
import { Project, ProjectStatus } from 'src/modules/diversite/model/project';
import { ContactContextNodeService } from 'src/modules/diversite/services/contact-context-node.service';
import { ContextNodeService } from 'src/modules/diversite/services/context-node.service';
import { ProjectService } from 'src/modules/diversite/services/projects.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';
import { PaneReferenceService } from '../../../services/pane-reference.service';
import { Pane } from 'src/modules/diversite/model/pane';

@Component({
    selector: "diversite-projects-pane-content",
    templateUrl: "./projects-pane-content.component.html",
    styleUrls: ["./projects-pane-content.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsPaneContentComponent implements OnInit, OnDestroy {
    @Input() pane: Pane;
    @Input() projects: Project[];

    private disposeBag = new DisposeBag();

    projectToDelete: Project;

    constructor(
        private contextNodeService: ContextNodeService,
        private projectService: ProjectService,
        private actionService: ContextNodePaneActionsService,
        private paneRefService: PaneReferenceService,
        private translateService: TranslateService
    ) { }

    ngOnInit(): void { }

    trackById(_: number, obj: any): string {
        return obj.id;
    }

    get lang(): string {
        return this.translateService.lang;
    }

    status(status: ProjectStatus): string {
        switch (status) {
            case "deleted":
                return "Supprimé";
            case "archived":
                return "Archivé";
            case "active":
                return "Actif";
            default:
                break;
        }
    }

    onRestore(project: Project): void {
        this.projectService.restoreProject(project).subscribe().disposedBy(this.disposeBag);
    }

    onDeletePrompt(project: Project): void {
        this.projectToDelete = project;
    }

    onCloseProjectDeletion(): void {
        this.projectToDelete = undefined;
    }

    onConfirmProjectDeletion(project: Project): void {
        this.contextNodeService
            .nodeView()
            .pipe(
                switchMap((nodes) => {
                    const projectNode = nodes.find((n) =>
                        n.contextData?.projectId ? n.contextData?.projectId === project.id : false
                    );
                    if (projectNode) {
                        return this.contextNodeService.removeNodeFromNodeView(projectNode.id).pipe(
                            switchMap((_) => {
                                return this.projectService.deleteProject(project);
                            })
                        );
                    } else {
                        return this.projectService.deleteProject(project);
                    }
                })
            )
            .subscribe((_) => {
                this.onCloseProjectDeletion();
            })
            .disposedBy(this.disposeBag);
    }

    onShare(project: Project): void {
        this.actionService.openActionPaneAtIndex(
            "share-project",
            this.paneRefService.currentLayout.findIndex((paneContent) => paneContent.paneRef.id === this.pane.id) + 1,
            { projectId: project.id }
        ).subscribe().disposedBy(this.disposeBag)
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
