<div class="package" *ngIf="package">
    <clr-input-container>
        <label>Nom <small>fr</small></label>
        <input
            (change)="onNameChanged($event.target.value, 'fr')"
            clrInput
            placeholder="fr"
            name="name"
            [value]="package.name.fr"
        />
    </clr-input-container>

    <clr-input-container>
        <label>Nom <small>en</small></label>
        <input
            (change)="onNameChanged($event.target.value, 'en')"
            clrInput
            placeholder="en"
            name="name"
            [value]="package.name.en"
        />
    </clr-input-container>

    <div class="text-editor">
        <label class="clr-control-label">Description <small> fr (affiché sur la page de téléchargement)</small></label>
        <diversite-text-html-editor
            [contentHtml]="package.description.fr"
            (contentChange)="onDescriptionChanged($event, 'fr')"
        ></diversite-text-html-editor>
    </div>

    <div class="text-editor">
        <label class="clr-control-label">Description <small> en (affiché sur la page de téléchargement)</small></label>
        <diversite-text-html-editor
            [contentHtml]="package.description.en"
            (contentChange)="onDescriptionChanged($event, 'en')"
        ></diversite-text-html-editor>
    </div>

    <h3>Fichier(s)</h3>
    <div class="clr-file-wrapper">
        <label class="clr-control-label">
            <a class="btn btn-sm btn-primary">Ajouter un fichier</a>
            <input
                (change)="onInputChange(package, $event)"
                class="clr-file"
                type="file"
                accept="application/pdf"
                multiple
            />
        </label>
    </div>

    <table class="table table-sm files">
        <tr *ngFor="let file of package.files">
            <td class="file">
                <div class="filename">{{ file.name }}</div>
                <input
                    type="text"
                    placeholder="Description fr"
                    (change)="onFileDescirptionChange($event.target.value, file.id, 'fr')"
                    [value]="file.description.fr"
                />
                <input
                    type="text"
                    placeholder="Description en"
                    (change)="onFileDescirptionChange($event.target.value, file.id, 'en')"
                    [value]="file.description.en"
                />
            </td>
            <td>
                <cds-icon shape="trash" (click)="removeFile(file.id, package)"></cds-icon>
            </td>
        </tr>
    </table>

    <div class="bottom">
        <button type="button" class="btn btn-sm" (click)="onCancel()">Annuler</button>
        <button (click)="editPackage()" type="button" class="btn btn-sm btn-primary">
            Modifier le dossier de partage
        </button>
    </div>
</div>
