import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { FormElementType } from '../model/form/form-element/form-element';

@Injectable({
    providedIn: "root",
})
export class SystemDataApiClientService {
    constructor(private db: AngularFirestore) {}

    getDefinitions(): Observable<ContactProfileDefinitionDTO> {
        return this.db
            .collection("systemData")
            .doc("definitions")
            .get()
            .pipe(
                take(1),
                map((doc: any) => {
                    const data = doc.data();
                    return { ...data } as ContactProfileDefinitionDTO;
                })
            );
    }
}

export interface ContactProfileDefinitionDTO {
    profile: { attributes: AttributeIndexDTO[] };
    modules: AttributeModuleDTO[];
    formElementLibrary: any[];
}

export interface AttributeIndexDTO {
    index: string;
    label: any;
    displayed: boolean;
    module: string;
    order: number;
    formField: FormDefinition;
}

export interface FormDefinition {
    type: FormElementType;
    // object index:label
    options: any;
    required: boolean;
}

export interface AttributeModuleDTO {
    order: number;
    name: string;
    id: string;
}
