<div class="activity" *ngIf="contact$ | async as contact">
    <div class="top">
        <p>
            <a (click)="openProfilePane(contact.id)"
                ><strong>{{ contact.fullname }}</strong
                >&nbsp;<small>({{ contact.idfig }})</small></a
            >&nbsp;à ajouté de nouvelle(s) photo(s)
            <span *ngIf="hasProjectInfo() as projectName">
                pour le projet <strong>{{ projectName }}</strong></span
            >
        </p>
        <div class="sr-id">{{ activity.id }}</div>
    </div>
    <div class="activity-content">
        <!-- <pre style="font-size: 0.45rem; line-height: 0.5rem">{{ activity | json }}</pre> -->
        <diversite-photo-cover
            (dblclick)="openPhoto(photoUrl)"
            [url]="photoUrl"
            *ngFor="let photoUrl of activity.contact.photo_photos_upload_form"
        ></diversite-photo-cover>
    </div>
    <div class="bottom">
        <div class="date">
            <small>{{ activity.created | date : "dd-MM-yyyy HH:mm" }}</small>
        </div>
    </div>
</div>
