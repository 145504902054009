import { Component, Input, ViewChild } from '@angular/core';
import { BehaviorSubject, interval, never, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

@Component({
    selector: 'audio-player',
    templateUrl: "./audio-player.component.html",
    styleUrls: [`./audio-player.component.scss`]
})
export class AudioPlayerComponent {
    @Input() audioUrl: string;
    @Input() duration: number;
    @ViewChild('audioElement') audioElement;
    public isPlaying$ = new BehaviorSubject<boolean>(false);
    public currentTime$ = new Observable<number>();

    onClick(): void {
        if (this.audioUrl) {
            if (this.isPlaying$.value) {
                this.audioElement.nativeElement.pause();
            } else {
                this.audioElement.nativeElement.play();
            }
        }
    }

    onPlay(): void {
        this.isPlaying$.next(true);
    }

    onPause(): void {
        this.isPlaying$.next(false);
    }

    onEnd(): void {
        this.isPlaying$.next(false);
        this._restartCurrentTime();
    }

    onLoaded(): void {
        this._restartCurrentTime();
    }

    private _restartCurrentTime(): void {
        let x = 1;
        this.currentTime$ = this.isPlaying$.pipe(
            switchMap(is => !is ? never() : interval(1000).pipe(
                map(() => x++),
                startWith(0)
            ))
        );
    }
}
