import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormElement } from 'src/modules/diversite/model/form/form-element/form-element';
import { SearchOperation } from 'src/modules/diversite/model/search-operation';
import {
    SearchProfileVisibleAttributesService,
} from 'src/modules/diversite/services/search-profile-visible-attributes.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';

@Component({
    selector: "diversite-search-form-element-standalone",
    templateUrl: "./search-form-element-standalone.component.html",
    styleUrls: ["./search-form-element-standalone.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFormElementStandaloneComponent {
    @Input() formElement: FormElement;
    @Input() searchOperation: SearchOperation;
    @Input() display: "vertical" | "horizontal" = "horizontal";
    @Output() searchOperationAdd = new EventEmitter<SearchOperation>();

    constructor(private translateService: TranslateService, private visibleAttrService: SearchProfileVisibleAttributesService) { }
    get lang(): string {
        return this.translateService.lang;
    }

    onEnterPressed(): void {
        this.addParameter();
    }

    get disabled(): boolean {
        return !this.searchOperation || !this.searchOperation?.hasValue();
    }

    onSearchOperationValueChange(sop: SearchOperation): void {
        this.searchOperation = sop;
    }

    addParameter(): void {
        if (!this.disabled) {
            this.searchOperationAdd.emit(this.searchOperation);
            this.searchOperation = null;
        }
    }

    onToggleViewField(attributeId: string): void {
        this.visibleAttrService.toggleAttribute(attributeId);
    }


    isViewed(attributeId: string): boolean {
        return this.visibleAttrService.isViewedAttr(attributeId);
    }
}
