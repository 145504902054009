import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { from, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";

import { FormElementDTO, ModuleMapperService } from "../mappers/module-mapper.service";
import { FormElement } from "../model/form/form-element/form-element";
import { ModuleService } from "./module.service";

@Injectable({
    providedIn: "root",
})
export class SearchFavoriteNodeService {
    constructor(
        private moduleMapperService: ModuleMapperService,
        private authService: AuthService,
        private modulesService: ModuleService,
        private db: AngularFirestore
    ) {}

    searchFavorites(options?: { listen: boolean }): Observable<SearchFavoriteNode[]> {
        const obs = this.db.collection("searchFavoritesViews").doc(this.authService.loggedInUser.id);
        if (options && options.listen) {
            return obs.snapshotChanges().pipe(
                map((doc) => {
                    if (doc.payload.exists) {
                        const data = doc.payload.data() as any;
                        if (data.nodes) {
                            return data.nodes.map((n) => this.searchFavoriteNodeDTOToSearchFavoriteNode(n));
                        } else {
                            return [];
                        }
                    } else {
                        return this.defaultFavoriteNodes();
                    }
                })
            );
        } else {
            return obs.get().pipe(
                map((doc) => {
                    if (doc.exists) {
                        const data = doc.data() as any;
                        if (data.nodes) {
                            return data.nodes.map((n) => this.searchFavoriteNodeDTOToSearchFavoriteNode(n));
                        } else {
                            return [];
                        }
                    } else {
                        return [];
                    }
                })
            );
        }
    }

    private defaultFavoriteNodes(): SearchFavoriteNode[] {
        return [
            new SearchFavoriteNode({
                id: this.db.createId(),
                type: "folder",
                name: "Age sexe",
                open: true,
                children: [
                    new SearchFavoriteNode({
                        id: this.db.createId(),
                        formElement: this.modulesService.formElementForIndex("idfig"),
                        type: "search-field",
                    }),
                    new SearchFavoriteNode({
                        id: this.db.createId(),
                        formElement: this.modulesService.formElementForIndex("identity_firstname"),
                        type: "search-field",
                    }),
                    new SearchFavoriteNode({
                        id: this.db.createId(),
                        formElement: this.modulesService.formElementForIndex("identity_lastname"),
                        type: "search-field",
                    }),
                    new SearchFavoriteNode({
                        id: this.db.createId(),
                        formElement: this.modulesService.formElementForIndex("identity_gender"),
                        type: "search-field",
                    }),
                    new SearchFavoriteNode({
                        id: this.db.createId(),
                        formElement: this.modulesService.formElementForIndex("identity_dateOfBirth"),
                        type: "search-field",
                    }),
                    new SearchFavoriteNode({
                        id: this.db.createId(),
                        formElement: this.modulesService.formElementForIndex("affiliation_uda"),
                        type: "search-field",
                    }),
                    new SearchFavoriteNode({
                        id: this.db.createId(),
                        formElement: this.modulesService.formElementForIndex("affiliation_actra"),
                        type: "search-field",
                    }),
                    new SearchFavoriteNode({
                        id: this.db.createId(),
                        formElement: this.modulesService.formElementForIndex("groupe_ethnique"),
                        type: "search-field",
                    }),
                    new SearchFavoriteNode({
                        id: this.db.createId(),
                        formElement: this.modulesService.formElementForIndex("measurements_height_metric"),
                        type: "search-field",
                    }),
                    new SearchFavoriteNode({
                        id: this.db.createId(),
                        formElement: this.modulesService.formElementForIndex("measurements_weight_metric"),
                        type: "search-field",
                    }),
                ],
            }),
            new SearchFavoriteNode({
                id: this.db.createId(),
                type: "folder",
                name: "Groupe 2",
            }),
            new SearchFavoriteNode({
                id: this.db.createId(),
                type: "folder",
                name: "Groupe 3",
            }),
        ];
    }

    addFavoriteGroup(): Observable<SearchFavoriteNode> {
        return this.searchFavorites({ listen: false }).pipe(
            switchMap((nodes) => {
                const newFolder = new SearchFavoriteNode({
                    id: this.db.createId(),
                    type: "folder",
                    name: "Nouveau Groupe",
                    open: false,
                    children: [],
                });

                return this.saveFavorites([...nodes, newFolder]).pipe(map((_) => newFolder));
            })
        );
    }

    private searchFavoriteNodeDTOToSearchFavoriteNode(node: SearchFavoriteNodeDTO): SearchFavoriteNode {
        return new SearchFavoriteNode({
            ...node,
            type: node.type,
            formElement: node.formElement
                ? this.moduleMapperService.formElementDtoToFormElement(node.formElement)
                : null,
            children: node.children
                ? node.children.map((n) => this.searchFavoriteNodeDTOToSearchFavoriteNode(n))
                : null,
        });
    }

    private searchFavoriteNodeToSearchFavoriteNodeDTO(node: SearchFavoriteNode): SearchFavoriteNodeDTO {
        return {
            id: node.id || null,
            name: node.name || null,
            type: node.type || null,
            formElement: node.formElement ? node.formElement.toDto() : null,
            children: node.children
                ? node.children.map((n) => this.searchFavoriteNodeToSearchFavoriteNodeDTO(n))
                : null,
            open: node.open === true ? true : false,
        };
    }

    saveFavorites(nodes: SearchFavoriteNode[]): Observable<SearchFavoriteNode[]> {
        return from(
            this.db
                .collection("searchFavoritesViews")
                .doc(this.authService.loggedInUser.id)
                .set({ nodes: nodes.map((n) => this.searchFavoriteNodeToSearchFavoriteNodeDTO(n)) })
        ).pipe(map((_) => nodes));
    }

    addToFavorite(searchFavoriteNode: SearchFavoriteNode): Observable<SearchFavoriteNode> {
        if (!searchFavoriteNode.id) {
            searchFavoriteNode = searchFavoriteNode.change({ id: this.db.createId() });
        }
        return this.searchFavorites({ listen: false }).pipe(
            switchMap((nodes) => {
                return this.saveFavorites([...nodes, searchFavoriteNode]);
            }),
            map((_) => searchFavoriteNode)
        );
    }
}

export type SearchFavoriteType = "folder" | "search-field";

export interface SearchFavoriteNodeBuilder {
    id?: string;
    name?: string;
    type?: SearchFavoriteType;
    formElement?: FormElement;
    children?: SearchFavoriteNode[];
    open?: boolean;
}
export class SearchFavoriteNode {
    private _id: string;
    private _name: string;
    private _type: SearchFavoriteType;
    private _formElement: FormElement;
    private _children: SearchFavoriteNode[];
    private _open: boolean;

    constructor(builder: SearchFavoriteNodeBuilder) {
        this._id = builder.id;
        this._name = builder.name || null;
        this._type = builder.type || null;
        this._formElement = builder.formElement || null;
        this._children = builder.children || null;
        this._open = builder.open || null;
    }

    get id(): string {
        return this._id;
    }
    get name(): string {
        return this._name;
    }
    get type(): SearchFavoriteType {
        return this._type;
    }
    get formElement(): FormElement {
        return this._formElement;
    }
    get children(): SearchFavoriteNode[] {
        return this._children;
    }
    get open(): boolean {
        return this._open;
    }

    change(builder: SearchFavoriteNodeBuilder): SearchFavoriteNode {
        return new SearchFavoriteNode({
            id: this.id,
            name: this.name,
            type: this.type,
            formElement: this.formElement,
            children: this.children,
            open: this.open,
            ...builder,
        });
    }
}

export interface SearchFavoriteNodeDTO {
    id?: string;
    name?: string;
    type?: SearchFavoriteType;
    formElement?: FormElementDTO;
    children?: SearchFavoriteNodeDTO[];
    open?: boolean;
}
