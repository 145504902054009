import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AccessRequest, AccessRoleService } from '../services/access-role.service';

@Directive({
    selector: '[hasAccess]'
})
export class HasAccessDirective {
    @Input() set hasAccess(accessRequest: AccessRequest) {
        if (!accessRequest.permissions || accessRequest.permissions.length === 0) {
            throw new Error("Access Request need to specify which permissions to test");
        }
        if (accessRequest.type === 'project' && !accessRequest.projectId) {
            throw new Error("Access Request need to specify projectId when type is project.");
        }
        if (accessRequest.type === 'segment' && !accessRequest.segment) {
            throw new Error("Access Request need to specify which segment when type is segment.");
        }

        this.accessRoleService.hasAccess(accessRequest) ? this.container.createEmbeddedView(this.templateRef) : this.container.clear()
    }
    constructor(
        private templateRef: TemplateRef<any>,
        private container: ViewContainerRef,
        private accessRoleService: AccessRoleService,
    ) { }

}
