<div class="mass-edit-container">
    <div class="loading" *ngIf="isLoading"><clr-spinner [clrMedium]="true">Loading ...</clr-spinner></div>

    <div class="top">
        <div class="contacts" *ngIf="contacts$ | async as contacts">
            <div class="contact" *ngFor="let contact of contacts; trackBy: trackById">
                <diversite-photo-cover
                    [mode]="'cover'"
                    [url]="contact.images[0]?.url"
                    [rotate]="contact.images[0]?.rotate"
                >
                </diversite-photo-cover>
            </div>
            <div class="more" *ngIf="contacts.length > 10">
                <small>... plus {{ contacts.length - 10 }}</small>
            </div>
        </div>

        <div class="datasource" *ngIf="datasource$ | async as datasource">
            <cds-icon size="lg" shape="storage"></cds-icon>{{ datasource.name }}
        </div>

        <div class="search-definition" *ngIf="searchDefinition$ | async as searchDef">
            <cds-icon size="lg" shape="search"></cds-icon>Éditer les membres pour ces
            paramêtres<diversite-search-parameters-compact
                [options]="{ removable: false, editable: false }"
                [searchDefinition]="searchDef"
            ></diversite-search-parameters-compact>
        </div>
    </div>
    <div class="middle">
        <div *ngIf="isStepOne">
            <div class="alert alert-info" role="alert">
                <div class="alert-items">
                    <div class="alert-item static">
                        <div class="alert-icon-wrapper">
                            <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                        </div>
                        <span class="alert-text">Veuillez choisir les attributs à modifier.</span>
                    </div>
                </div>
            </div>
            <clr-combobox-container>
                <label>Tout les attributs</label>
                <clr-combobox
                    #attributeCombobox
                    [(ngModel)]="selection"
                    name="multiSelect"
                    clrMulti="true"
                    required
                    (clrSelectionChange)="onComboboxSelectionChange($event)"
                    (clrOpenChange)="onComboboxOpenChange($event)"
                    (clrInputChange)="onComboboxInputChange($event)"
                >
                    <ng-container *clrOptionSelected="let selected">
                        {{ labelForIndex(selected) }}
                    </ng-container>
                    <clr-options>
                        <clr-option *clrOptionItems="let attr of attrs$ | async; field: 'label'" [clrValue]="attr.id">
                            {{ attr.label }}
                        </clr-option>
                    </clr-options>
                </clr-combobox>
            </clr-combobox-container>
            <br />
            <clr-accordion>
                <clr-accordion-panel *ngFor="let module of attributeModules$ | async">
                    <clr-accordion-title>{{ module.name[this.lang] }}</clr-accordion-title>
                    <clr-accordion-content *clrIfExpanded>
                        <clr-checkbox-container clrInline>
                            <clr-checkbox-wrapper *ngFor="let formElement of module.formElements; trackBy: trackById">
                                <input
                                    type="checkbox"
                                    [checked]="isAttributeChecked(formElement.name)"
                                    (change)="onAttributeCheckboxChange(formElement.name)"
                                    clrCheckbox
                                    [name]="formElement.name"
                                    required
                                    [value]="formElement.name"
                                />
                                <label>{{ formElement.label[this.lang] }}</label>
                            </clr-checkbox-wrapper>
                        </clr-checkbox-container>
                    </clr-accordion-content>
                </clr-accordion-panel>
            </clr-accordion>
        </div>

        <div class="edit-attributes" *ngIf="isStepTwo">
            <ng-container *ngFor="let formElement of toEditFormElements; trackBy: trackById">
                <fillout-form-element
                    [lang]="lang"
                    [formElement]="formElement"
                    (responseChange)="onAttributeChange($event)"
                ></fillout-form-element>

                <clr-checkbox-wrapper *ngIf="formElement.type === 'textarea' || formElement.type === 'textbox'">
                    <input
                        type="checkbox"
                        clrCheckbox
                        (change)="onConcatChange($event.target.checked, formElement.name)"
                    />
                    <label>Concaténer l'information avec l'information existante,si possible</label>
                </clr-checkbox-wrapper>
            </ng-container>
        </div>
    </div>

    <div class="bottom">
        <button type="button" class="btn btn-sm" (click)="onCancel()">Annuler</button>
        <button [disabled]="isStepOne" (click)="goToStep('select-attributes')" type="button" class="btn btn-sm">
            Précèdent
        </button>

        <button
            *ngIf="isStepOne"
            (click)="goToStep('edit-attributes')"
            [disabled]="isNextDisabled"
            type="button"
            class="btn btn-primary btn-sm"
        >
            Suivant
        </button>
        <button *ngIf="isStepTwo" (click)="editContacts()" type="button" class="btn btn-primary btn-sm">
            Modifier les membres
        </button>
    </div>
</div>
