import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { from, Observable } from "rxjs";
import { map, take } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class ContactsDatabasesService {
    constructor(private db: AngularFirestore) {}

    database(id: string, options?: { listen: boolean }): Observable<ContactsDatabase> {
        if (options && options.listen) {
            return this.db
                .collection("contactsDatabases")
                .doc(id)
                .snapshotChanges()
                .pipe(
                    map((d) => {
                        const doc = d.payload;
                        return { ...(doc.data() as any), id: doc.id } as ContactsDatabase;
                    })
                );
        } else {
            return this.db
                .collection("contactsDatabases")
                .doc(id)
                .get()
                .pipe(
                    map((d) => {
                        const data = d.data() as any;
                        return { ...(data as any), id: d.id } as ContactsDatabase;
                    })
                );
        }
    }

    databaseByDatasourceIndex(datasourceIndex: string): Observable<ContactsDatabase> {
        return this.db
            .collection("contactsDatabases", (ref) => ref.where("datasourceIndex", "==", datasourceIndex))
            .snapshotChanges()
            .pipe(
                map((d) => {
                    if (d.length === 1) {
                        const doc = d[0].payload;
                        return { ...(doc.doc.data() as any), id: doc.doc.id } as ContactsDatabase;
                    } else {
                        if (d.length > 1) {
                            throw new Error(`Cannot have more than 1 database with "${datasourceIndex}" index`);
                        } else {
                            return undefined;
                        }
                    }
                })
            );
    }

    databases(options?: { listen: boolean }): Observable<ContactsDatabase[]> {
        if (options && options.listen) {
            return this.db
                .collection("contactsDatabases")
                .snapshotChanges()
                .pipe(
                    map((docs) => {
                        return docs.map((d) => {
                            const doc = d.payload.doc;
                            return { ...(doc.data() as any), id: doc.id } as ContactsDatabase;
                        });
                    })
                );
        } else {
            return this.db
                .collection("contactsDatabases")
                .get()
                .pipe(
                    map((docs) => {
                        return docs.docs.map((d) => {
                            return { ...(d.data() as any), id: d.id } as ContactsDatabase;
                        });
                    })
                );
        }
    }

    addDatabase(name = ""): Observable<ContactsDatabase> {
        const id = this.db.createId();
        const newDB: ContactsDatabase = { name, id, datasourceIndex: null };
        return from(this.db.collection("contactsDatabases").doc(id).set(newDB)).pipe(
            map((_) => {
                return newDB;
            }),
            take(1)
        );
    }

    editDatabase(db: ContactsDatabase): Observable<ContactsDatabase> {
        return from(this.db.collection("contactsDatabases").doc(db.id).set(db)).pipe(
            map((_) => db),
            take(1)
        );
    }
}

export interface ContactsDatabase {
    id: string;
    name: string;
    datasourceIndex: DatasourceIndex;
}

export type DatasourceIndex = "FJBPremium" | "FJBPremiumLegacy" | "FJB" | "FJBLegacyProject";
