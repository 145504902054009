<!-- <pre>{{formElement|json}}</pre> -->
<div *ngIf="formElement">
    <fillout-text
        [options]="options"
        [lang]="lang"
        *ngIf="formElement.type === 'text'"
        [formElement]="formElement"
    ></fillout-text>

    <fillout-textarea
        [lang]="lang"
        [value]="value"
        [options]="options"
        *ngIf="formElement.type === 'textarea'"
        (responseChange)="onResponseChange($event)"
        [formElement]="formElement"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}</span>
        </span>
    </fillout-textarea>
    <fillout-textbox
        [lang]="lang"
        [value]="value"
        [options]="options"
        *ngIf="formElement.type === 'textbox'"
        (responseChange)="onResponseChange($event)"
        [formElement]="formElement"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}</span>
        </span>
    </fillout-textbox>

    <fillout-number
        [lang]="lang"
        [value]="value"
        [options]="options"
        *ngIf="formElement.type === 'number'"
        (responseChange)="onResponseChange($event)"
        [formElement]="formElement"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}</span>
        </span>
    </fillout-number>

    <fillout-imageupload
        [lang]="lang"
        *ngIf="formElement.type === 'imageupload'"
        (responseChange)="onResponseChange($event)"
        [value]="value"
        [formElement]="formElement"
        [options]="options"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}</span>
        </span>
    </fillout-imageupload>

    <fillout-videoupload
        [lang]="lang"
        *ngIf="formElement.type === 'videoupload'"
        (responseChange)="onResponseChange($event)"
        [value]="value"
        [formElement]="formElement"
        [options]="options"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}</span>
        </span>
    </fillout-videoupload>

    <fillout-fileupload
        [lang]="lang"
        *ngIf="formElement.type === 'fileupload'"
        (responseChange)="onResponseChange($event)"
        [formElement]="formElement"
        [options]="options"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}</span>
        </span>
    </fillout-fileupload>
    <fillout-voiceupload
        [lang]="lang"
        *ngIf="formElement.type === 'voiceupload'"
        (responseChange)="onResponseChange($event)"
        [formElement]="formElement"
        [options]="options"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}&#125;</span>
        </span>
    </fillout-voiceupload>
    <fillout-dropdown
        [lang]="lang"
        [value]="value"
        *ngIf="formElement.type === 'dropdown'"
        (responseChange)="onResponseChange($event)"
        [formElement]="formElement"
        [options]="options"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}</span>
        </span>
    </fillout-dropdown>

    <fillout-union
        [lang]="lang"
        [value]="value"
        *ngIf="formElement.type === 'union'"
        (responseChange)="onResponseChange($event)"
        [formElement]="formElement"
        [options]="options"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}</span>
        </span>
    </fillout-union>

    <fillout-datetime
        [lang]="lang"
        [value]="value"
        *ngIf="formElement.type === 'datetime'"
        (responseChange)="onResponseChange($event)"
        [formElement]="formElement"
        [options]="options"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}</span>
        </span>
    </fillout-datetime>
    <fillout-radio
        [lang]="lang"
        [value]="value"
        *ngIf="formElement.type === 'radio'"
        (responseChange)="onResponseChange($event)"
        [formElement]="formElement"
        [options]="options"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}</span>
        </span>
    </fillout-radio>
    <fillout-checkbox
        [lang]="lang"
        [value]="value"
        *ngIf="formElement.type === 'checkbox'"
        (responseChange)="onResponseChange($event)"
        [formElement]="formElement"
        [options]="options"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}</span>
        </span>
    </fillout-checkbox>

    <fillout-height
        [lang]="lang"
        [value]="value"
        *ngIf="formElement.type === 'height'"
        (responseChange)="onResponseChange($event)"
        [formElement]="formElement"
        [options]="options"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}</span>
        </span>
    </fillout-height>

    <fillout-weight
        [lang]="lang"
        [value]="value"
        *ngIf="formElement.type === 'weight'"
        (responseChange)="onResponseChange($event)"
        [formElement]="formElement"
        [options]="options"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}</span>
        </span>
    </fillout-weight>

    <fillout-phone
        [lang]="lang"
        [value]="value"
        *ngIf="formElement.type === 'phone'"
        (responseChange)="onResponseChange($event)"
        [formElement]="formElement"
        [options]="options"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}</span>
        </span>
    </fillout-phone>

    <fillout-availability
        *ngIf="formElement.type === 'availability'"
        [lang]="lang"
        [value]="value"
        (responseChange)="onResponseChange($event)"
        [formElement]="formElement"
        [options]="options"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}</span>
        </span>
    </fillout-availability>
    <!-- FILLOUT TABLE DEPRECATED -->
    <fillout-table
        [lang]="lang"
        [value]="value"
        *ngIf="formElement.type === 'table'"
        (responseChange)="onResponseChange($event)"
        [formElement]="formElement"
    >
        <span
            *ngIf="tooltipShow(formElement)"
            tooltip-content
            role="tooltip"
            aria-haspopup="true"
            class="tooltip tooltip-right"
        >
            <clr-icon shape="info-circle" size="20"></clr-icon>
            <span class="tooltip-content">{{ formElement.description[lang] }}</span>
        </span>
    </fillout-table>
</div>
