<div class="compact-tags" *ngIf="searchDefinition">
    <ng-container *ngIf="searchDefinition?.searchGroup">
        <div
            *ngFor="
                let searchGroup of searchDefinition.searchGroup?.searchGroups;
                let first = first;
                trackBy: trackById
            "
            class="search-group"
        >
            <div class="search-operations">
                <div class="or-text" *ngIf="!first && searchGroup.searchOperations?.length > 0">OU</div>
                <diversite-search-definition-tag
                    *ngIf="searchGroup.hasFullTextSearch()"
                    [maxCharacters]="40"
                    [editable]="isEditable(searchGroup.fullTextSearch) && options.editable"
                    [removable]="options.removable"
                    [searchOperation]="searchGroup.fullTextSearch"
                    (searchOperationChange)="onSearchOperationChange($event)"
                    (removeSearchOperation)="onRemoveSearchOperation($event)"
                ></diversite-search-definition-tag>
                <ng-container *ngFor="let sop of searchGroup.searchOperations; trackBy: trackById">
                    <diversite-search-definition-tag
                        [maxCharacters]="40"
                        [editable]="isEditable(searchGroup.fullTextSearch) && options.editable"
                        [removable]="options.removable"
                        [searchOperation]="sop"
                        (searchOperationChange)="onSearchOperationChange($event)"
                        (removeSearchOperation)="onRemoveSearchOperation($event)"
                    ></diversite-search-definition-tag>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <em *ngIf="!hasOperation()">(aucune opération dans ce groupe)</em>
</div>
