import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { Contact } from 'src/modules/diversite/model/contact';
import { Project } from 'src/modules/diversite/model/project';
import { ContactService } from 'src/modules/diversite/services/contact.service';

import { Form } from '../../../../../model/form/form';
import { FormService, VariablesDefinition } from '../../../../../services/form.service';
import { ProjectService } from '../../../../../services/projects.service';
import { TranslateService } from '../../../../../services/translate.service';

@Component({
    selector: "diversite-send-form-action",
    templateUrl: "./send-form-action.component.html",
    styleUrls: ["./send-form-action.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendFormActionComponent implements OnInit, AfterViewInit {
    constructor(
        private formService: FormService,
        private translateService: TranslateService,
        private notificationService: NotificationService,
        private projectService: ProjectService,
        private contactService: ContactService
    ) { }

    @Input() projectId: string = "";
    @Input() contactIds: string[];
    @Output() close = new EventEmitter<void>();
    @ViewChild("publishForm", { read: NgForm }) public publishForm: NgForm;

    variablesDefinition: VariablesDefinition = {};
    urlSlug: string = "";

    formType: "existing" | "template" = "existing";
    contacts$: Observable<Contact[]>;
    projects$: Observable<Project[]>;
    readonly selectedProjectId$ = new BehaviorSubject<string>("");
    readonly selectedForm$ = new BehaviorSubject<Form>(undefined);
    forms$: Observable<Form[]>;

    step: "step1" | "step2" = "step1";
    emailText = "";

    templates$: Observable<Form[]>;
    unpublishedForms: Form[];

    ngOnInit(): void {
        this.projects$ = this.projectService.projects({ deleted: false, listen: false });
        this.forms$ = this.selectedProjectId$.pipe(
            switchMap((projectId) =>
                projectId && projectId !== "" ? this.formService.formForProject(projectId) : of([])
            )
        );
        this.templates$ = this.formService.templates();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.contacts$ = forkJoin(
            changes.contactIds.currentValue.map((id) => {
                return this.contactService.contactById(id).pipe(first());
            })
        ) as Observable<Contact[]>;
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.selectedProjectId$.next(this.projectId);
        }, 500);
    }

    onProjectChange(projectId: string): void {
        this.resetFormSelection();
        this.selectedProjectId$.next(projectId);
    }

    onFormChange(formId: string, forms: Form[]): void {
        this.selectedForm$.next(forms.find((f) => f.id === formId));
        if (this.formType === "template") {
            this.initVariablesForTemplate();
        }
    }

    get lang(): string {
        return this.translateService.lang;
    }

    get urlFirstPart(): string {
        return `${window.location.origin}/#/c/`;
    }

    get isSendDisabled(): boolean {
        if (this.formNeedToBePublished(this.selectedForm) && this.publishForm && this.publishForm.controls.urlSlug) {
            return this.publishForm?.controls.urlSlug.invalid || !this.hasFormSelected();
        }
        return !this.hasFormSelected();
    }

    private hasFormSelected(): boolean {
        return this.selectedForm ? true : false;
    }

    resetFormSelection(): void {
        this.selectedForm$.next(undefined);
        this.selectedProjectId$.next("");
    }

    trackById(_: number, item: any): string {
        return item.id;
    }

    get selectedForm(): Form {
        return this.selectedForm$.value;
    }

    sendForm(form: Form): void {
        let obs;

        if (form.isTemplate) {
            console.log("sendTemplateToContactsForProject");
            obs = this.formService.sendTemplateToContactsForProject(
                form,
                this.projectId,
                { firstPart: this.urlFirstPart, slug: this.urlSlug },
                this.variablesDefinition,
                this.contactIds,
                this.emailText
            );
        } else {
            if (this.formSelectedIsFromSameProject()) {
                if (form.isPublished) {
                    obs = this.formService.sendExistingFormToContacts(
                        form,
                        this.contactIds,
                        this.urlFirstPart,
                        this.emailText
                    );
                } else {

                    obs = this.formService.publishFormAndSendIt(
                        form,
                        this.contactIds,
                        this.projectId,
                        {
                            firstPart: this.urlFirstPart,
                            slug: this.urlSlug,
                        },
                        this.emailText
                    );
                }
            } else {
                obs = this.formService.copyFormToAnotherProjectAndSendIt(
                    form,
                    this.contactIds,
                    this.projectId,
                    {
                        firstPart: this.urlFirstPart,
                        slug: this.urlSlug,
                    },
                    this.emailText
                );
            }
        }

        obs.subscribe((_) => {
            this.close.emit();
            this.notificationService.show("Votre formulaire à été envoyé aux destinataires.", "success");
        });
    }

    onCancel(): void {
        this.close.emit();
    }

    formSelectedIsFromSameProject(): boolean {
        return this.projectId === this.selectedProjectId$.value;
    }

    formNeedToBePublished(form: Form): boolean {
        if (form) {
            // publish if template is selected
            if (form.isTemplate || this.formType === "template") {
                return true;
            }
            // publish if form is from another project
            if (!this.formSelectedIsFromSameProject()) {
                return true;
            }
            //if form is not published yet
            if (!form.isPublished) {
                return true;
            }
        }

        return false;
    }

    private initVariablesForTemplate(): void {
        if (this.selectedForm && this.selectedForm.variables) {
            this.variablesDefinition = {};
            this.selectedForm.variables.forEach((v) => {
                const translatableLabel = {};
                this.selectedForm.languages.forEach((l) => {
                    translatableLabel[l] = "";
                });
                this.variablesDefinition[v.slug] = translatableLabel;
            });
        }
    }
}
