import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { heightLiteral } from "src/app/core/functions";

import { SearchValue } from "../../search-value-selector.component";

@Component({
    selector: "search-value-height",
    templateUrl: "./search-value-height.component.html",
    styleUrls: ["./search-value-height.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchValueHeightComponent implements OnInit {
    @Input() searchValue: SearchValue;
    @Output() searchValueChange = new EventEmitter<SearchValue>();

    constructor() {}

    ngOnInit(): void {}

    onHeightChange(): void {
        this.searchValueChange.emit(this.searchValue);
    }

    erase(mm: "min" | "max"): void {
        if (mm === "min") {
            this.searchValue.valueMin = undefined;
        }
        if (mm === "max") {
            this.searchValue.valueMax = undefined;
        }
        this.searchValueChange.emit(this.searchValue);
    }

    height(index: "min" | "max"): string {
        if (index === "min" && this.searchValue.valueMin) {
            return heightLiteral(this.searchValue.valueMin);
        } else if (index === "max" && this.searchValue.valueMax) {
            return heightLiteral(this.searchValue.valueMax);
        }
        return " ";
    }
}
