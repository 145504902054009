<div class="node-content node-fixed">
    <div class="node-title" (contextmenu)="onRightClickContact($event, node)">
        <cds-icon [shape]="'user'"></cds-icon>
        <input class="node-name-input" disabled [value]="'Contact(s)'" />
        <span class="actions">
            <ng-content></ng-content>
            <strong>&nbsp;&nbsp;{{ total }}&nbsp;&nbsp;</strong>
            <cds-icon (click)="onRightClickContact($event, node)" shape="cog"></cds-icon>
            <cds-icon (click)="openContactsTab(node)" shape="view-columns"></cds-icon>
            <cds-icon (click)="openSearchTab(node)" shape="search"></cds-icon>
        </span>
    </div>

    <div class="contacts-container" *ngIf="isOpen">
        <div class="actions-contacts">
            <clr-select-container>
                <select (change)="onOrderChange($event.target.value)" clrSelect name="options">
                    <option value="lastUpdated">Dernier modifié</option>
                    <option value="created">Date d'inscription</option>
                </select>
            </clr-select-container>

            <a class="action-link" (click)="openContactsTab(this.node)">Ouvrir dans un panneau</a>
            <a class="action-link" (click)="onEditContacts()">Modifier les membres</a>
            <a class="action-link" (click)="onGenerateReport()">Créer un rapport</a>
            <a class="action-link" (click)="onExport()">Exporter</a>
            <input type="range" name="face-height" [(ngModel)]="faceSize" min="50" max="200" />
        </div>

        <div class="contacts" *ngIf="orderedContacts$ | async as contacts; else loading">
            <diversite-contact-simple-profile
                *ngFor="let contact of contacts; trackBy: trackById"
                [style.height.px]="faceSize"
                [style.width.px]="faceSize"
                [contact]="contact"
                (removeContact)="onRemoveContact($event, node)"
                (viewProfile)="onViewProfile($event)"
                (selectContact)="onSelectContact($event)"
            ></diversite-contact-simple-profile>
        </div>
        <ng-template #loading>
            <clr-spinner clrMedium="true"></clr-spinner>
        </ng-template>
    </div>
</div>
