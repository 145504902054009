import { ThumbnailType } from "../api-clients/contact-api-client.service";
import { FaceRectangle } from "./contact-image";

export interface Builder {
    thumbnailUrl?: string;
    faceRectangle?: FaceRectangle;
    type?: ThumbnailType;
    width?: number;
    height?: number;
    metaData?: any;
}

export class Thumbnail {
    private _thumbnailUrl?: string;
    private _faceRectangle?: FaceRectangle;
    private _type: ThumbnailType;
    private _width?: number;
    private _height?: number;
    private _metaData?: any;

    constructor(builder: Builder) {
        this._width = builder.width || null;
        this._height = builder.height || null;
        this._type = builder.type || "thumbnail";
        this._thumbnailUrl = builder.thumbnailUrl;
        this._faceRectangle = builder.faceRectangle || null;
        this._metaData = builder.metaData || null;
    }

    get width(): number {
        return this._width;
    }
    get height(): number {
        return this._height;
    }
    get thumbnailUrl(): string {
        return this._thumbnailUrl;
    }
    get type(): ThumbnailType {
        return this._type;
    }
    get faceRectangle(): FaceRectangle {
        return this._faceRectangle;
    }
    get metaData(): any {
        return this._metaData;
    }
}
