import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormElementInput } from 'src/modules/diversite/model/form/form-element/form-element';
import { FormattedField, ModulesAttributesService } from "src/modules/diversite/services/modules-attributes.service";

@Component({
    selector: "diversite-quick-edit-input",
    templateUrl: "./quick-edit-input.component.html",
    styleUrls: ["./quick-edit-input.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickEditInputComponent implements OnInit {
    @Input() formElementInput: FormElementInput;
    @Input() lang: string;
    @Output() formElementInputChange = new EventEmitter<FormElementInput>();
    delayedChanges$ = new BehaviorSubject<void>(undefined);

    baseField: FormattedField;

    constructor(private modulesAttributesService: ModulesAttributesService) { }

    ngOnInit(): void {
        this.baseField = this.modulesAttributesService.getBaseFieldByIndex(this.formElementInput.name);
    }

    onRequiredChanged(event: any): void {
        const input = event.target as HTMLInputElement;
        this.formElementInputChange.emit(
            this.formElementInput.change({
                required: input.checked,
            }) as FormElementInput
        );
    }

    onReadonlyChanged(event: any): void {
        const input = event.target as HTMLInputElement;
        if (input.checked) {
            this.formElementInputChange.emit(
                this.formElementInput.change({
                    readonly: input.checked,
                    required: false,
                }) as FormElementInput
            );
        } else {
            this.formElementInputChange.emit(
                this.formElementInput.change({
                    readonly: input.checked,
                }) as FormElementInput
            );
        }
    }

    onScoreChange(event: any): void {
        const input = event.target as HTMLInputElement;
        this.formElementInputChange.emit(
            this.formElementInput.change({
                score: parseInt(input.value, 10),
            }) as FormElementInput
        );
    }

    onNameChange(event: any): void {
        const input = event.target as HTMLInputElement;
        this.formElementInputChange.emit(
            this.formElementInput.change({
                name: input.value,
            }) as FormElementInput
        );
    }
}
