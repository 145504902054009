import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { debounceTime, skip } from "rxjs/operators";
import { DeepCopy } from "src/app/core/deep-copy";
import { guid } from "src/app/core/functions";
import { DisposeBag } from "src/modules/core/utilities/dispose-bag";
import { FormCheckbox } from "src/modules/diversite/model/form/form-element/form-checkbox";
import { FormElement } from "src/modules/diversite/model/form/form-element/form-element";
import { FormOption } from "src/modules/diversite/model/form/form-element/form-option";
import { TranslatableLabel } from "src/modules/diversite/services/data-catalog.service";

import { FormElementEditable } from "../form-element-editable";

declare var $: any;
@Component({
    selector: "diversite-form-checkbox-edition",
    templateUrl: "./form-checkbox-edition.component.html",
    styleUrls: ["./form-checkbox-edition.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCheckboxEditionComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit, FormElementEditable {
    @Input() lang: string;
    @Input() formElement: FormCheckbox;
    @Input() readonly readonlyElement = false;
    @Output() formElementChange = new EventEmitter<FormElement>();
    @ViewChild("labelElement") labelElement: ElementRef;

    delayedChanges$ = new BehaviorSubject<void>(undefined);

    tempFormElement: { label: TranslatableLabel; options: FormOption[] } = {
        label: {},
        options: [],
    };

    private _disposeBag = new DisposeBag();
    constructor(private chRef: ChangeDetectorRef, private hostElement: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.lang && changes.lang.currentValue) {
            this.tempFormElement.label = {};
            this.tempFormElement.options = [];
            this.chRef.detectChanges();
            this.tempFormElement.label = { ...this.initLabel() };
            this.tempFormElement.options = this.formElement.options;
            this.chRef.detectChanges();
        }
    }

    private initLabel(): TranslatableLabel {
        const initLabel: TranslatableLabel = {};
        initLabel[this.lang] =
            this.formElement.label && this.formElement.label[this.lang] ? this.formElement.label[this.lang] : "";
        return initLabel;
    }

    ngOnInit(): void {
        this.tempFormElement = {
            label: this.initLabel(),
            options: DeepCopy.copy(this.formElement.options),
        };
        this.chRef.detectChanges();
        this.delayedChanges$
            .pipe(debounceTime(500), skip(1))
            .subscribe((_) => {
                const label = { ...this.formElement.label };
                label[this.lang] = this.labelElement.nativeElement.textContent;
                this.formElement = this.formElement.change({
                    label,
                    options: DeepCopy.copy(this.tempFormElement.options),
                });
                this.formElementChange.emit(this.formElement);
                $(".options", this.hostElement.nativeElement).sortable("enable");
            })
            .disposedBy(this._disposeBag);
    }

    ngAfterViewInit(): void {
        $(".options", this.hostElement.nativeElement).sortable({
            handle: ".drag-field",
            update: (event, ui) => {
                const indexes = $("[data-index]", event.target)
                    .toArray()
                    .map((e) => Number(e.getAttribute("data-index")));

                this.tempFormElement.options = indexes.map((index) => this.tempFormElement.options[index]);
                this.delayedChanges$.next();
                $(".options", this.hostElement.nativeElement).sortable("disable");
            },
        });
    }

    trackByFn(index: number, option: any): string {
        return `${option.id}`;
    }

    onPropertyChange(): void {
        this.delayedChanges$.next();
    }

    ngOnDestroy(): void {
        this._disposeBag.dispose();
    }

    onLabelChange(): void {
        this.delayedChanges$.next();
    }

    onKeyup(event: KeyboardEvent): void {
        this.delayedChanges$.next();
    }

    onChange(event: Event): void {
        this.delayedChanges$.next();
    }

    addOptionToField(): void {
        this.tempFormElement.options.push({ id: guid(), label: {} });
        this.delayedChanges$.next();
    }

    deleteOption(option: FormOption): void {
        this.tempFormElement.options = this.tempFormElement.options.filter((opt) => option.id !== opt.id);
        this.delayedChanges$.next();
    }
}
