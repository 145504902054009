import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ClrCombobox, ClrDropdown } from '@clr/angular';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip, take } from 'rxjs/operators';
import { isArrayOfImageUrl, isImageUrl } from 'src/app/core/functions';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Contact } from 'src/modules/diversite/model/contact';
import { AttributeIndex, ContactProfileDefinition } from 'src/modules/diversite/model/contact-profile-definition';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { Orientation, Report, ReportType } from 'src/modules/diversite/model/report';
import { ContactsSelectionService } from 'src/modules/diversite/services/contacts-selection.service';
import { DragDropActionService } from 'src/modules/diversite/services/drag-drop-action.service';
import {
    FacePositionService,
    ImageDispositionOption,
    ImageLayout,
} from 'src/modules/diversite/services/face-position.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';
import * as xlsx from 'xlsx';

import { CardAvailableOptions } from '../../contacts/contact-card/contact-card.component';
import { RotateImageEvent } from '../../photo-profile/photo-profile.component';
import { EditableTextLoadedEvent } from '../editable-text/editable-text.component';

declare let $: any;

@Component({
    selector: "diversite-contacts-report",
    templateUrl: "./contacts-report.component.html",
    styleUrls: ["./contacts-report.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsReportComponent implements OnInit, OnChanges, OnDestroy {
    @Input() report: Report;
    @Input() print: boolean = false;
    @Output() editReport = new EventEmitter<Report>();
    @Output() rotatePicture = new EventEmitter<RotateImageEvent>();

    @ViewChild("fieldsDropdownComponent") fieldsDropdownComponent: ClrDropdown;
    @ViewChild("epltable") epltable: ElementRef;
    @ViewChild("fileLogo") fileLogo: ElementRef;

    @ViewChild("pagePictureGrid") pagePictureGrid: ElementRef;
    @ViewChild("fieldSelection") fieldSelection: ClrCombobox<any>;

    private readonly _reportChange$ = new BehaviorSubject<Report>(undefined);

    sizeThumbnail = 1;
    proportionFace = 0.7;
    pagesPadding = 0.2;
    contactsMargin = 0.2;
    column: number;
    row: number;
    breakPageOn: number;
    exportExcel = false;
    fileLogoData: string;
    logoSize = 100;
    cardFaceDispositionOptions: ImageDispositionOption;

    pagesPictureGrid: PageContent[];
    pagesPictureInfo: PageContent[];

    editorConfig = {
        theme: "bubble",
    };

    selectionFields: any;

    introPageContent = "";

    readonly contactCardOptions: CardAvailableOptions = {
        zoom: true,
        move: true,
        rotate: true,
    };

    private _availableFields: FieldUI[];
    private _disposeBag = new DisposeBag();

    constructor(
        private facePositionService: FacePositionService,
        private sanitizer: DomSanitizer,
        private dragDropActionService: DragDropActionService,
        private contactSelectionService: ContactsSelectionService,
        private translateService: TranslateService,
        private chRef: ChangeDetectorRef,
        private host: ElementRef
    ) { }

    ngOnInit() {
        if (!this.print) {
            this._reportChange$
                .pipe(skip(1), debounceTime(500))
                .subscribe((r) => {
                    this.editReport.emit(r.change({ introPageContent: this.introPageContent }));
                })
                .disposedBy(this._disposeBag);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.report && changes.report.currentValue) {
            if (this.introPageContent !== changes.report.currentValue.introPageContent) {
                this.introPageContent = changes.report.currentValue.introPageContent;
            }
            this.setOptions();
            this._availableFields = this.getAllAvailableFields(changes.report.currentValue);
            this.pagesPictureInfo = this.setPagesForPictureInfo();

            this.recalculateColumnRowSetup();
            this.thumbnailSizeChange(false);
            this.setVisibleFieldComponent();

            this.chRef.detectChanges();
            this.setContactDroppableInReport();
        }
    }

    ngOnDestroy(): void {
        this._disposeBag.dispose();
    }

    onReportNameChange(name: string): void {
        this.report = this.report.change({ name });
        this.editReport.emit(this.report);
    }

    private setContactDroppableInReport(): void {
        const node = $(".right-section", this.host.nativeElement);
        node.droppable({
            greedy: true,
            tolerance: "pointer",
            drop: (e: any) => {
                this.dragDropActionService
                    .dropDragAction({ reportId: this.report.id })
                    .pipe(take(1))
                    .subscribe((response) => {
                        this.contactSelectionService.clearSelection();
                    })
                    .disposedBy(this._disposeBag);
            },
        });
    }

    addTextToPictureGrid(index: number): void {
        this.pagesPictureGrid[index].texts.push({
            text: "Editer le texte",
            top: (this.pagesPictureGrid[index].texts.length + 1) * 10,
            left: (this.pagesPictureGrid[index].texts.length + 1) * 10,
        });
    }

    addTextToPictureInfo(index: number): void {
        this.pagesPictureInfo[index].texts.push({
            text: "Editer le texte",
            top: (this.pagesPictureInfo[index].texts.length + 1) * 10,
            left: (this.pagesPictureInfo[index].texts.length + 1) * 10,
        });
    }

    onEditableTextLoaded(event: EditableTextLoadedEvent): void {
        $(event.host.nativeElement).draggable({
            containment: "parent",
            handle: event.dragger.nativeElement,
        });
    }

    onTextRemovePictureGrid(pageIndex: number, textIndex: number): void {
        this.pagesPictureGrid[pageIndex].texts = this.pagesPictureGrid[pageIndex].texts.filter(
            (_, index) => index !== textIndex
        );
    }

    onTextRemovePictureInfo(pageIndex: number, textIndex: number): void {
        this.pagesPictureInfo[pageIndex].texts = this.pagesPictureInfo[pageIndex].texts.filter(
            (_, index) => index !== textIndex
        );
    }

    private setOptions(): void {
        this.contactsMargin = this.report.photoMargin;
        this.pagesPadding = this.report.pageMargin;
        this.sizeThumbnail = this.report.photoSize;
    }

    private setVisibleFieldComponent(): void {
        // if (this.isPictureInfo()) {
        //     this.selectionFields = this.report.contactProfileDefinition.attributes
        //         .filter((attr) => attr.displayed)
        //         .map((attr) => {
        //             return { index: attr.index, label: attr.label[this.lang] };
        //         });
        //     setTimeout(() => {
        //         this.chRef.detectChanges();
        //         $("input[role='combobox']", `#${this.fieldSelection.id}`).click();
        //     });
        // }
    }

    toComboboxData(attributes: AttributeIndex[]): any[] {
        return attributes.map((attr) => {
            return { ...attr, label: attr.label[this.lang] };
        });
    }

    onFieldVisibilityChange(event: { model: { index: string; label: string }[] }): void {
        // this.report = this.report.change({
        //     contactProfileDefinition: new ContactProfileDefinition({
        //         attributes: this.report.contactProfileDefinition.attributes.map((attr) => {
        //             const isSelected = event.model?.find((selectedAttr) => attr.index === selectedAttr.index)
        //                 ? true
        //                 : false;
        //             return {
        //                 label: attr.label,
        //                 index: attr.index,
        //                 displayed: isSelected,
        //                 module: attr.module,
        //                 order: attr.order,
        //             };
        //         }),
        //     }),
        // });
        this._reportChange$.next(this.report);
    }

    onProfileClose(): void { }

    get lang(): string {
        return this.translateService.lang;
    }

    get fieldToSearch(): string {
        return `label.${this.lang}`;
    }

    images(contact: Contact): ImageLayout[] {
        return contact.attributes
            .filter((attr) => isImageUrl(attr.value) || isArrayOfImageUrl(attr.value))
            .map((attr) => {
                if (isImageUrl(attr.value)) {
                    return {
                        id: attr.value,
                        url: attr.value,
                    };
                } else if (isArrayOfImageUrl(attr.value)) {
                    return attr.value.map((url) => {
                        return {
                            id: url,
                            url,
                        };
                    });
                }
            })
            .reduce((acc, val) => acc.concat(val), []);
    }

    thumbnailSizeChange(emit: boolean): void {
        this.resetCardDispositionOptions();
        this.centerAllSelectedFaces();
        this.recalculateColumnRowSetup();
        this.report = this.report.change({ photoSize: this.sizeThumbnail });
        if (emit) {
            this._reportChange$.next(this.report);
        }
    }

    thumbnailMarginChange(): void {
        this.recalculateColumnRowSetup();
        this.report = this.report.change({ photoMargin: this.contactsMargin });
        this._reportChange$.next(this.report);
    }

    pagesPaddingChange(): void {
        this.recalculateColumnRowSetup();
        this.report = this.report.change({ pageMargin: this.pagesPadding });
        this._reportChange$.next(this.report);
    }

    proportionFaceChange(): void {
        this.resetCardDispositionOptions();
        this.centerAllSelectedFaces();
        this.recalculateColumnRowSetup();
        this._reportChange$.next(this.report);
    }

    onIntroPageContentChange(html: string): void {
        this.introPageContent = html;
        this._reportChange$.next(this.report);
    }

    get inchToPx(): number {
        return this.sizeThumbnail * 96;
    }

    centerAllSelectedFaces(): void {
        this.report = this.report.change({
            contactPerspectives: this.report.contactPerspectives.map((cp) => {
                const selectedImage = cp.contact.images[cp.defaultImageIndex];
                if (selectedImage && selectedImage.faceRectangle && selectedImage.url) {
                    const centeredImageDisposition = this.facePositionService.positionForFaceRectangle(
                        selectedImage.faceRectangle,
                        selectedImage.width,
                        selectedImage.height,
                        selectedImage.rotate,
                        this.cardFaceDispositionOptions
                    );
                    return cp.changeImageDisposition({
                        imageId: selectedImage.id,
                        x: centeredImageDisposition.backgroundx,
                        y: centeredImageDisposition.backgroundy,
                        zoom: centeredImageDisposition.zoom,
                    });
                }
                return cp;
            }),
        });
    }

    onRotatePicture(event: RotateImageEvent): void {
        this.rotatePicture.emit(event);
    }

    private resetCardDispositionOptions(): void {
        this.chRef.detectChanges();
        const size = Math.round($("diversite-contact-card").width());
        this.cardFaceDispositionOptions = {
            containerWidth: size,
            containerHeight: size,
            finalFaceHeight: Math.round(size * this.proportionFace),
        };
    }

    private recalculateColumnRowSetup(): void {
        if (this.report) {
            this.column = this.columnCount();
            this.row = this.rowCount();
            this.breakPageOn = this.column * this.row;

            this.pagesPictureGrid = [];
            let page = 0;
            this.report.contactPerspectives.forEach((value, index) => {
                if (!this.pagesPictureGrid[page]) {
                    this.pagesPictureGrid[page] = {
                        title: this.report.name,
                        contactPerspectives: [],
                        texts: [],
                    };
                }
                this.pagesPictureGrid[page].contactPerspectives.push(value);
                if (index % this.breakPageOn === this.breakPageOn - 1) {
                    page = page + 1;
                }
            });

            this.chRef.detectChanges();
            this.initiateDragDrop();
        }
    }

    showedFields(): FieldUI[] {
        return this.fields.filter((field) => field.selected);
    }

    private columnCount(): number {
        const pageWidthForThumbnail = this.pagesWidth - 2 * this.pagesPadding;
        const columnCount = pageWidthForThumbnail / (this.sizeThumbnail + 2 * this.contactsMargin);
        return Math.floor(columnCount - 0.0000001);
    }

    private rowCount(): number {
        const pageHeightForThumbnail = this.pagesHeight - 2 * this.pagesPadding;
        const rowCount = pageHeightForThumbnail / (this.sizeThumbnail + 2 * this.contactsMargin);
        return Math.floor(rowCount + 0.0000001) ? Math.floor(rowCount + 0.0000001) : 1;
    }

    totalPageHeight(): number {
        if (!this.pagesPictureGrid) {
            return 0;
        }
        return this.pagesPictureGrid.length * (this.pagesHeight + 0.4);
    }

    get pagesWidth(): number {
        return this.report && this.report.orientation === "landscape" ? 11 : 8.5;
    }

    get pagesHeight(): number {
        return this.report && this.report.orientation === "landscape" ? 8.5 : 11;
    }

    changeOrientation(orientation: Orientation): void {
        this.report = this.report.change({ orientation });
        this._reportChange$.next(this.report);
    }

    doPrint(): void {
        if (this.print) {
            window.print();
        } else {
            const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=800,top=10`;
            window.open(
                `${window.location.origin}/#/report/${this.report.id}`,
                `${window.location.origin}/#/report/${this.report.id}`,
                params
            );
        }
    }

    initiateDragDrop(): void {
        // $(".page .contacts").sortable({
        //     appendTo: $(".pages-container"),
        //     placeholder: "ui-state-highlight",
        //     connectWith: ".page",
        //     delay: 100,
        //     tolerance: "pointer",
        //     containment: $(".pages-container"),
        //     stop: () => {
        //         this.dropContact();
        //     },
        // });
    }

    isPictureGrid(): boolean {
        return this.report && this.report.type === "picture-grid";
    }

    isPictureInfo(): boolean {
        return this.report && this.report.type === "picture-info";
    }

    isList(): boolean {
        return this.report && this.report.type === "list";
    }

    isLandscape(): boolean {
        return this.report && this.report.orientation === "landscape";
    }

    isPortrait(): boolean {
        return this.report && this.report.orientation === "portrait";
    }

    changeType(reportType: ReportType): void {
        this.report = this.report.change({ type: reportType });

        this._reportChange$.next(this.report);

        this.setVisibleFieldComponent();
    }

    private setPagesForPictureInfo(): PageContent[] {
        const pages: PageContent[] = [];
        let pageNum = 0;
        pages.push({
            title: this.report.name,
            contactPerspectives: [],
            texts: [],
        });
        this.report.contactPerspectives.forEach((element, i) => {
            pages[pageNum].contactPerspectives.push(element);
            if ((i + 1) % this.report.profilePerPage === 0) {
                pages.push({
                    title: this.report.name,
                    contactPerspectives: [],
                    texts: [],
                });
                pageNum = pageNum + 1;
            }
        });

        if (pages[pages.length - 1].contactPerspectives.length === 0) {
            pages.splice(-1, 1);
        }

        return pages;
    }

    private getAllAvailableFields(report: Report): any {
        const attributeDef = new Map<string, string>();
        report.contactPerspectives.forEach((contactPerspective: ContactPerspective) => {
            contactPerspective.contact.attributes.forEach((attribute) => {
                if (!attributeDef.has(attribute.def.id)) {
                    attributeDef.set(attribute.def.id, attribute.def.name);
                }
            });
        });

        return Array.from(attributeDef.keys()).map((key) => {
            return {
                id: key,
                label: attributeDef.get(key),
                // selected: !this.report.hiddenFieldDefitionIds.includes(key),
            };
        });
    }

    private dropContact(): void {
        // TODO needs to rearrange original images
    }

    get fields(): FieldUI[] {
        return this._availableFields;
    }

    getValueOfField(contactPerspective: ContactPerspective, field: FieldUI): string {
        const attr = contactPerspective.contact.attributes.find((a) => field.id === a.def.id);
        return attr ? attr.value : "";
    }

    safeImage(contactPerspective: ContactPerspective): SafeStyle {
        const image = contactPerspective.contact.images[0];
        if (image) {
            return this.sanitizer.bypassSecurityTrustStyle(`url(${image.url})`);
        }
    }

    onContactPerspectiveChange(contactPerspective: ContactPerspective): void {

        this.report = this.report.change({
            contactPerspectives: this.report.contactPerspectives.map((cp) => {
                return cp.id === contactPerspective.id ? contactPerspective : cp;
            }),
        });
        this._reportChange$.next(this.report);
    }

    onIntroPageChange(event: Event): void {
        const checkbox = event.target as HTMLInputElement;
        this.report = this.report.change({
            introPage: checkbox.checked,
        });
        this._reportChange$.next(this.report);
    }

    exportToExcel(): void {
        this.exportExcel = true;
        this.chRef.detectChanges();

        const ws: xlsx.WorkSheet = xlsx.utils.table_to_sheet(this.epltable.nativeElement);
        const wb: xlsx.WorkBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
        xlsx.writeFile(wb, `excel.xlsx`);
        this.exportExcel = false;
        this.chRef.detectChanges();
    }

    fileLogoChange(): void {
        const input = this.fileLogo.nativeElement as HTMLInputElement;
        if (input.files && input.files[0]) {
            const reader = new FileReader();

            reader.onload = (e) => {
                // $('#blah').attr('src', e.target.result);
                this.fileLogoData = e.target.result as string;

                this.chRef.detectChanges();
            };

            reader.readAsDataURL(input.files[0]);
        }
    }

    onProfilePerPageChange(event: Event): void {
        const select = event.target as HTMLSelectElement;
        this.report = this.report.change({
            profilePerPage: parseInt(select.value, 10),
        });
        this.pagesPictureInfo = this.setPagesForPictureInfo();
        this.chRef.detectChanges();
        this._reportChange$.next(this.report);
    }

    clearLogo(): void {
        this.fileLogoData = null;
        this.chRef.detectChanges();
    }

    logoBackgroundImage(): SafeStyle {
        const image = this.fileLogoData;
        if (image) {
            return this.sanitizer.bypassSecurityTrustStyle(`url(${image})`);
        }
    }

    contactAttributeValue(contact: Contact, attributeIndex: string): string {
        return contact.getAttributeValueLiteral(attributeIndex);
    }

    trackByCPFn(index: number, item: Contact): string {
        return item.id;
    }

    trackAtributes(index: number, item: AttributeIndex): string {
        return item.index;
    }

    trackByIndex(index: number): string {
        return `${index}`;
    }
}

export interface FieldUI {
    id: string;
    label: string;
    selected: boolean;
}

export interface PageContent {
    title: string;
    contactPerspectives: ContactPerspective[];
    texts: TextElement[];
}

export interface TextElement {
    text: string;
    top: number;
    left: number;
}
