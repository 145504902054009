<div class="form-request-container" *ngIf="attributesMissing$ | async as formElements">
    <div class="branding">
        <img class="main-logo-castlug" [src]="logo()" />
    </div>

    <div class="lang" *ngIf="!isSubmitted || !isSubmitting">
        <span (click)="selectLang(lang)" [class.current]="lang === selectedLang" *ngFor="let lang of languages">{{
            lang
        }}</span>
    </div>

    <div *ngIf="isSubmitting && !isSubmitted" class="is-submitting">
        <span class="spinner spinner-lg"></span>
    </div>

    <div *ngIf="!isSubmitting">
        <div *ngIf="mode === 'fillout'">
            <fillout-form-element
                *ngIf="email"
                [formElement]="disabledEmailFormElement"
                [lang]="lang"
                [value]="email"
            ></fillout-form-element>
            <div [id]="fe.id" *ngFor="let fe of formElements" class="form-element-container">
                <fillout-form-element
                    [formElement]="fe"
                    [lang]="lang"
                    (responseChange)="onResponseChange($event)"
                ></fillout-form-element>
            </div>
        </div>
        <div *ngIf="mode === 'preview'">
            <div [id]="fe.id" *ngFor="let fe of formElements" class="form-element-container">
                <fillout-form-element
                    [formElement]="fe"
                    [lang]="lang"
                    [value]="valueForIndex(fe.name)"
                ></fillout-form-element>
            </div>
        </div>

        <div *ngIf="showErrorsBoard && !isValid(formElements)" class="errors alert alert-danger">
            <div class="alert-items">
                <strong>{{ label("fixFollowingErrorsText") }}</strong>
                <div class="alert-item static" *ngFor="let errorsInput of errors; trackBy: trackByError">
                    <ng-container *ngIf="errorsInput.length === 1 && !errorsInput[0].isNewMemberEmailError">
                        <div class="alert-icon-wrapper">
                            <clr-icon class="alert-icon" shape="exclamation-circle"> </clr-icon>
                        </div>
                        <span class="alert-text">
                            <span *ngIf="errorsInput.length <= 1"
                                >{{ label("errorRequiredText") }} :
                                <em>{{ errorsInput[0].formElement.label[selectedLang] }}</em>
                            </span>

                            <ng-container *ngIf="errorsInput[0].type === 'tableFieldsRequired'">
                                {{ label("errorTableHasInvalidFieldsText") }}
                            </ng-container>
                        </span>
                        <div class="alert-actions">
                            <a (click)="seeError(errorsInput[0])" class="error-focus-link">{{
                                label("seeErrorText")
                            }}</a>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <button (click)="submit(formElements)" class="submit-btn btn btn-primary">
            {{ label("submitButtonLabel") }}
        </button>
    </div>
    <div *ngIf="isSubmitted">
        <div>
            <h3>Merci !</h3>
            <p>Votre formulaire a été envoyé.</p>
            <h3>Thanks !</h3>
            <p>The form has been submitted successfully.</p>
        </div>
    </div>
</div>
