import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ContextNode } from "src/modules/diversite/model/context-node";
import { Report } from "src/modules/diversite/model/report";

import { ContextNodePaneActionsService } from "../context-node-pane-actions.service";

@Component({
    selector: "diversite-context-node-reports",
    templateUrl: "./context-node-reports.component.html",
    styleUrls: ["./context-node-reports.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextNodeReportsComponent implements OnInit {
    @Input() node: ContextNode;
    @Input() projectId: string;
    @Input() displayMode: "onlyContent" | "fullNode" = "fullNode";
    @Input() reports: Report[];
    @Input() isOpen = false;

    reportsOptionsOpen = false;

    constructor(private actionsPane: ContextNodePaneActionsService) {}

    ngOnInit(): void {}

    get isFullNodeDisplay(): boolean {
        return this.displayMode === "fullNode";
    }

    onRightClickReport(event: PointerEvent, node: ContextNode): void {
        // this.contextMenuService.openContextMenu(this.optionsContactsForNode(node), event);
        event.preventDefault();
        event.stopPropagation();
    }

    onEditReportClick(reportId: string, node: ContextNode): void {
        return this.actionsPane.openReportEditPane(reportId, node, this.projectId);
    }

    trackById(_: number, obj: any): string {
        return obj.id;
    }
}
