import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { from, map, Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class BookmarkService {

    constructor(private db: AngularFirestore, private authService: AuthService) { }


    bookmarksForSearch(searchDefinitionId: string): Observable<Bookmark[]> {
        return this.db.collection("searchContexts").doc(searchDefinitionId).collection("bookmarks", (ref) => {
            return ref.where("user", "==", this.db.collection("users").doc(this.authService.loggedInUser.id).ref).orderBy("date", "desc");
        }).snapshotChanges().pipe(map(docs => {
            return docs.map(doc => {
                const data = doc.payload.doc.data();
                return { id: doc.payload.doc.id, date: data.date?.toDate(), user: data.user };
            })
        }))
    }


    saveNewBookmark(searchDefinitionId: string): Observable<Bookmark> {
        const bookmark: Bookmark = {
            user: this.db.collection("users").doc(this.authService.loggedInUser.id).ref,
            date: new Date(),
            id: this.db.createId()
        }
        return from(this.db.collection("searchContexts").doc(searchDefinitionId).collection("bookmarks").doc(bookmark.id).set(bookmark)).pipe(map(_ => bookmark));
    }
}

export interface Bookmark {
    id?: string;
    date: Date;
    user: DocumentReference;
}