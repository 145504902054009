import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';
import { Notification } from 'src/modules/core/model/notification';
import { NotificationService } from 'src/modules/core/services/notification.service';

@Component({
    selector: "castlug-container",
    templateUrl: "./castlug-container.component.html",
    styleUrls: ["./castlug-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CastlugContainerComponent implements OnInit {
    constructor(private notificationService: NotificationService, private authService: AuthService) { }

    loaded$: Observable<boolean>;

    notification$: Observable<Notification>;

    ngOnInit(): void {
        this.loaded$ = this.authService.loaded$;
        this.notification$ = this.notificationService.alert$;
    }


}
