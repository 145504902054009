import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Contact } from 'src/modules/diversite/model/contact';
import {
    ChangeHistory,
    ChangeHistoryDelta,
    ContactChangeHistoryService,
} from 'src/modules/diversite/services/contact-change-history.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';

import { ContextNodePaneActionsService } from '../../context-node/context-node-pane-actions.service';

@Component({
    selector: "diversite-contact-change-history",
    templateUrl: "./contact-change-history.component.html",
    styleUrls: ["./contact-change-history.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactChangeHistoryComponent implements OnInit, OnDestroy {
    @Input() contact: Contact;

    changeHistory: ChangeHistory[] = [];
    private disposeBag = new DisposeBag();

    // formDataModal: { form: Form; contact: ContactPublic };
    openDelta: ChangeHistory;

    delta$: Observable<ChangeHistoryDelta>;

    constructor(
        private contactChangeHistoryService: ContactChangeHistoryService,
        private actionPaneService: ContextNodePaneActionsService,
        private chRef: ChangeDetectorRef,
        private translateService: TranslateService
    ) { }

    ngOnInit(): void {
        this.contactChangeHistoryService
            .historyForContact(this.contact.id)
            .subscribe((changeHistory) => {
                this.changeHistory = changeHistory;

                this.chRef.detectChanges();
            })
            .disposedBy(this.disposeBag);
    }

    get lang(): string {
        return this.translateService.lang;
    }

    hasDelta(history: ChangeHistory): boolean {
        return history.deltaId && history.deltaId !== "";
    }

    openDeltaModal(history: ChangeHistory): void {
        this.delta$ = this.contactChangeHistoryService.delta(this.contact.id, history.deltaId);
        this.openDelta = history;
    }

    onDeltaOpenChange(isOpen: boolean): void {
        if (!isOpen) {
            this.delta$ = undefined;
            this.openDelta = undefined;
        }
    }

    openFormResponse(history: ChangeHistory): void {
        this.actionPaneService.openFormPane(history.data.form.id, null, null, this.contact.id);
    }

    openUpdatePhoto(history: ChangeHistory): void {
        this.actionPaneService.openUpdatePhoto(history.data.contact.attributes, this.contact.id);
    }

    trackByFn(_, attr: any): string {
        return attr.id;
    }

    attributes(delta: ChangeHistoryDelta): DeltaView[] {
        return Object.keys(delta)
            .filter((k) => k !== "deltaDate" && k !== "id" && !k.startsWith("recruitment_"))
            .map((k) => {
                return { id: k, value: delta[k] } as DeltaView;
            });
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}

export interface DeltaView {
    id: string;
    value: string;
}
