import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { map, Observable } from 'rxjs';
import { SystemRequestType } from 'src/app/api-clients/system-request-api-client.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { ContextNodePaneActionsService } from 'src/modules/diversite/components/context-node/context-node-pane-actions.service';
import { Contact } from 'src/modules/diversite/model/contact';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { Activity } from 'src/modules/diversite/services/activity.service';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { ImageLayout } from 'src/modules/diversite/services/face-position.service';

@Component({
    selector: 'diversite-upload-photo-activity',
    templateUrl: './upload-photo-activity.component.html',
    styleUrl: './upload-photo-activity.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadPhotoActivityComponent {
    @Input() activity: UploadPhotoActivity;
    @Output() openProfile = new EventEmitter<string>();


    contact$: Observable<Contact>;

    constructor(private contactService: ContactService) { }

    ngOnInit(): void {
        if (this.activity.idfig) {
            this.contact$ = this.contactService.contactByIdfig(this.activity.idfig);
        } else if (this.activity.queryParams?.contactId) {
            this.contact$ = this.contactService.contactById(this.activity.queryParams?.contactId);
        }
    }

    hasProjectInfo(): string {
        return this.activity.queryParams?.fjbProjectSigle;
    }

    openPhoto(imageUrl: string): void {
        if (imageUrl) {
            const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=800,top=10`;
            window.open(imageUrl, imageUrl, params);
        }
    }

    openProfilePane(contactId: string): void {
        this.openProfile.emit(contactId)
    }


}

export interface UploadPhotoActivity extends Activity {
    id: string,
    contact: any,
    created: Date,
    status: "success" | "error",
    origin: string,
    idfig?: string,
    queryParams?: {
        fjbNoLB?: string,
        fjbProjectSigle?: string,
        contactId?: string
    },
    context: {
        type: string
    }
}