import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormElement } from 'src/modules/diversite/model/form/form-element/form-element';
import { FormText } from 'src/modules/diversite/model/form/form-element/form-text';
import { TranslatableLabel } from 'src/modules/diversite/services/data-catalog.service';

import { FormElementEditable } from '../form-element-editable';


@Component({
    selector: "diversite-form-text-edition",
    templateUrl: "./form-text-edition.component.html",
    styleUrls: ["./form-text-edition.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTextEditionComponent implements OnInit, OnDestroy, OnChanges, FormElementEditable {
    @Input() lang: string;
    @Input() formElement: FormText;
    @Input() readonly readonlyElement = false;
    @Output() formElementChange = new EventEmitter<FormElement>();
    delayedChanges$ = new BehaviorSubject<void>(undefined);

    tempFormElement: { text: TranslatableLabel } = {
        text: {},
    };

    private _disposeBag = new DisposeBag();

    constructor(private chRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.tempFormElement = {
            text: { ...this.formElement.text },
        };
        this.delayedChanges$
            .pipe(debounceTime(500), skip(1))
            .subscribe((_) => {
                this.formElement = this.formElement.change({
                    text: this.tempFormElement.text,
                });
                this.formElementChange.emit(this.formElement);
            })
            .disposedBy(this._disposeBag);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.formElement && changes.formElement.currentValue) {
            this.tempFormElement.text = { ...this.formElement.text };
        }

        if (changes.lang && changes.lang.currentValue) {
            this.tempFormElement.text = { ...this.formElement.text };
        }
    }
    ngOnDestroy(): void {
        this._disposeBag.dispose();
    }

    onTextChanged(html: string): void {
        this.tempFormElement.text[this.lang] = html;
        this.delayedChanges$.next();
    }
}
