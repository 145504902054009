import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { fromEvent, Observable } from 'rxjs';
import { pluck, take, tap } from 'rxjs/operators';
import { guid } from 'src/app/core/functions';
import { Translatable } from 'src/modules/core/components/translatable';
import { StaticLabels, StaticLabelsService } from 'src/modules/core/services/static-labels.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';

declare var MediaRecorder: any;

export enum RecordingState {
    STOPPED = "stopped",
    RECORDING = "recording",
    FORBIDDEN = "forbidden",
}

@Component({
    selector: "audio-recording",
    templateUrl: "./audio-recording.component.html",
    styleUrls: ["./audio-recording.component.scss"],
})
export class AudioRecordingComponent implements OnInit, Translatable {
    private translationId = "i24bpkOdf8XHsgDIW3zc";
    // TODO need to implements this for mp3 upload instead
    // of binary upload for download purposes
    // https://github.com/Kagami/vmsg
    seconds: number;
    state: RecordingState = RecordingState.STOPPED;
    audio: AudioData;
    private mediaRecorder;
    private recordings$: Observable<any>;

    private translationLabels: StaticLabels = {};
    private disposeBag = new DisposeBag();
    @Input() lang;
    @Input() disabled = false;

    @Output() audioChange = new EventEmitter<AudioData>();

    constructor(
        private sanitizer: DomSanitizer,
        private chRef: ChangeDetectorRef,
        private staticLabelsService: StaticLabelsService
    ) {}

    ngOnInit(): void {
        this.staticLabelsService
            .labelsForComponent(this.translationId)
            .pipe(take(1))
            .subscribe((labels) => {
                this.translationLabels = labels;
                this.chRef.markForCheck();
            })
            .disposedBy(this.disposeBag);

        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                this.mediaRecorder = new MediaRecorder(stream);
                this.recordings$ = fromEvent(this.mediaRecorder, "dataavailable");
            })
            .catch((error) => {
                console.log("CANNOT RECORD: ", error);
                this.state = RecordingState.FORBIDDEN;
            });
    }

    label(labelId: string): string {
        if (this.translationLabels && this.translationLabels[labelId] && this.translationLabels[labelId][this.lang]) {
            return this.translationLabels[labelId][this.lang];
        }
        return "";
    }

    onHold(time: number): void {
        this.state = RecordingState.RECORDING;
        this.seconds = Math.round(time / 1000);
    }

    onStart(): void {
        this.mediaRecorder.start();
        this.recordings$
            .pipe(
                take(1),
                pluck("data"),
                tap((data: BlobPart) => {
                    const blob = new Blob([data]);
                    this.audio = {
                        url: this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob)),
                        duration: this.seconds,
                        file: this.blobToFile(blob, `${guid()}`),
                    };
                    this.audioChange.emit(this.audio);
                    this.chRef.detectChanges();
                })
            )
            .subscribe();
    }

    onStop(): void {
        this.state = RecordingState.STOPPED;
        this.mediaRecorder.stop();
    }

    private blobToFile(theBlob: Blob, fileName: string): File {
        const file = new File([theBlob], fileName, { lastModified: new Date().getTime() });
        return file;
    }
}

export interface AudioData {
    url: SafeUrl;
    duration: number;
    file: File;
}
