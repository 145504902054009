import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Operator, SearchOperation } from 'src/modules/diversite/model/search-operation';
import { TranslateService } from 'src/modules/diversite/services/translate.service';

declare var $: any;

declare var $: any;
@Component({
    selector: "diversite-search-definition-tag",
    templateUrl: "./search-definition-tag.component.html",
    styleUrls: ["./search-definition-tag.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchDefinitionTagComponent implements OnInit {
    @Input() searchOperation: SearchOperation;
    @Input() isFullTextSearch: boolean = false;
    @Input() maxCharacters: number;
    @Input() disabled = false;
    @Input() removable = false;
    @Input() editable = false;
    @Input() display: "vertical" | "horizontal" = "vertical";
    @Output() removeSearchOperation = new EventEmitter<SearchOperation>();
    @Output() searchOperationChange = new EventEmitter<SearchOperation>();

    editMode = false;

    fullTextSearchValue = "";

    private tempSearchOperation: SearchOperation;

    constructor(private translateService: TranslateService) { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void { }

    signForOperator(op: Operator): string {
        if (!op) {
            return " : ";
        }
        if (op === "greaterThan") {
            return " > ";
        }
        if (op === "lowerThan") {
            return " < ";
        }
        if (op === "range") {
            return " < > ";
        }
        return " ";
    }

    get operand(): string {
        return this.searchOperation.type === "availability" ? "availability" : this.searchOperation.operand;
    }

    get lang(): string {
        return this.translateService.lang;
    }

    get isFormOperation(): boolean {
        return this.operand?.startsWith("recruitment_") ? true : false;
    }

    onFullTextKeypress(event: KeyboardEvent): void {
        if (event.key === "Enter") {
            this.onEnterPressed();
        }
    }

    value(searchOperation: SearchOperation): any {
        if (searchOperation.operand === "identity_dateOfBirth") {
            if (searchOperation.operator === "greaterThan") {
                const newDate = new Date(searchOperation.value);
                newDate.setFullYear(searchOperation.value.getFullYear() + 1);
                return newDate;
            } else if (searchOperation.operator === "range") {
                const newDate = new Date(searchOperation.value[0]);
                newDate.setFullYear(searchOperation.value[0].getFullYear() + 1);
                return [newDate, searchOperation.value[1]]
            }
        } else {
            return searchOperation.value
        }
    }

    onEditPrompt(event: MouseEvent): void {
        this.editMode = true;
        if (this.isFullTextSearch) {
            this.onSearchOperationValueChange(this.searchOperation);
            this.fullTextSearchValue = this.searchOperation.value;
        }
        event.preventDefault();
        event.stopPropagation();
    }

    onSearchOperationValueChange(searchOperation: SearchOperation): void {
        this.tempSearchOperation = searchOperation;
    }

    onEnterPressed(): void {
        this.onChangeSearchOperation();
    }

    cancelEdit(): void {
        this.editMode = false;
    }

    onChangeSearchOperation(): void {
        if (this.tempSearchOperation) {
            if (this.isFullTextSearch) {
                this.tempSearchOperation = this.tempSearchOperation.change({ value: this.fullTextSearchValue });
            }
            this.searchOperation = this.tempSearchOperation;
            this.editMode = false;
            this.searchOperationChange.emit(this.tempSearchOperation);
        }
    }

    clickTag(event: any): void {
        if (!this.disabled) {
            if (!this.removable) {
                this.searchOperation = this.searchOperation.toggleDisabled();
                this.searchOperationChange.emit(this.searchOperation);
            } else {
                const closeButtonWidth = 25;
                const isCloseClick = event.offsetX > $(event.target).closest("cds-tag").width() - closeButtonWidth;
                if (isCloseClick && this.removable) {
                    // REMOVE TAG
                    this.removeSearchOperation.emit(this.searchOperation);
                } else {
                    // DISABLE TAG
                    this.searchOperation = this.searchOperation.toggleDisabled();
                    this.searchOperationChange.emit(this.searchOperation);
                }
            }
        }
    }

    isBoolean(searchOperation: SearchOperation): boolean {
        return searchOperation.operator === "boolean";
    }
}
