import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { ContextNode } from 'src/modules/diversite/model/context-node';
import { Form } from 'src/modules/diversite/model/form/form';
import { SearchDefinition } from 'src/modules/diversite/model/search-definition';
import { ContextMenuElement, ContextMenuService } from 'src/modules/diversite/services/context-menu.service';
import { DragDropActionService } from 'src/modules/diversite/services/drag-drop-action.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';

import { ContextNodePaneActionsService } from '../context-node-pane-actions.service';
import { copyTextInClipboard, copyToClipboard } from 'src/app/core/functions';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { PublicContext } from 'src/modules/core/model/context';
import { PublicContextService } from 'src/modules/core/services/public-context.service';
import { FormService } from 'src/modules/diversite/services/form.service';

declare var $: any;
@Component({
    selector: "diversite-context-node-forms",
    templateUrl: "./context-node-forms.component.html",
    styleUrls: ["./context-node-forms.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextNodeFormsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() node: ContextNode;
    @Input() displayMode: "onlyContent" | "fullNode" = "fullNode";
    @Input() projectId: string;
    @Input() forms: Form[];
    @Input() isOpen = false;

    @ViewChildren("formContent") formContent: QueryList<ElementRef>;

    showHidden = false;

    private disposeBag = new DisposeBag();

    constructor(
        private contextMenuService: ContextMenuService,
        private actionsService: ContextNodePaneActionsService,
        private translateService: TranslateService,
        private dragDropActionService: DragDropActionService,
        private notificationService: NotificationService,
        private publicContextService: PublicContextService,
        private formService: FormService,
        private db: AngularFirestore
    ) { }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isOpen && changes.isOpen.currentValue) {
            setTimeout(() => {
                this.formContent.toArray().forEach((f) => {
                    const formId = f.nativeElement.getAttribute("data-formid");
                    $(f.nativeElement).draggable({
                        appendTo: "body",
                        handle: $(".drag", f.nativeElement),
                        helper: () => {
                            const node = $(f.nativeElement).get(0).cloneNode(true);
                            return node;
                        },
                        start: (e, ui) => {
                            this.dragDropActionService.startDragAction({
                                formId: formId,
                                nodeId: this.node.id,
                            });
                        },
                    });
                });
            });
        }
    }

    get isFullNodeDisplay(): boolean {
        return this.displayMode === "fullNode";
    }

    get lang(): string {
        return this.translateService.lang;
    }

    trackById(_: number, obj: any): string {
        return obj.id;
    }

    toggleArchive(form: Form): void {
        if (form.archived) {
            this.formService.unarchiveForm(form).subscribe().disposedBy(this.disposeBag);
        } else {
            this.formService.archiveForm(form).subscribe().disposedBy(this.disposeBag);
        }
    }

    onRightClickForm(event: PointerEvent, node: ContextNode): void {
        this.contextMenuService.openContextMenu(this.optionsFormsForNode(node), event);
        event.preventDefault();
        event.stopPropagation();
    }

    private optionsFormsForNode(node: ContextNode): ContextMenuElement[] {
        return this.actionsService.menuActionsFormsForNode({ node, projectId: this.projectId }, () => {
            this.isOpen = true;
        });
    }

    onEditFormClick(formId: string, node: ContextNode): void {
        this.actionsService.openEditFormPane(formId, node.id, this.projectId);
    }

    onViewFormClick(formId: string, node: ContextNode): void {
        this.actionsService.openFormPane(formId, node.id, this.projectId);
    }

    onViewRespondents(formId: string, node: ContextNode): void {
        this.actionsService.openSearchPane(
            SearchDefinition.DEFAULT_SEARCH_DEFINITION_FORM(this.db.createId(), formId).change({ projectId: this.projectId || null }),
            this.projectId,
            node?.id
        ).subscribe().disposedBy(this.disposeBag);
    }

    onCopyFormLink(formId: string): void {
        this.publicContextService.contextsForForm(formId).subscribe(contexts => {
            if (contexts.length > 0) {
                copyTextInClipboard(`${window.location.origin}#/c/${contexts[0].slug}`);
                this.notificationService.show("Le lien du formulaire à été copiés dans le presse-papier.", "info");
            } else {
                this.notificationService.show("Le formulaire n'est pas publié.", "warning");
            }
        }).disposedBy(this.disposeBag);
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
