import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, fromEvent, map, merge, Observable } from 'rxjs';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Contact } from 'src/modules/diversite/model/contact';
import { ContactsSelectionService } from 'src/modules/diversite/services/contacts-selection.service';
import { ImageLayout } from 'src/modules/diversite/services/face-position.service';

@Component({
    selector: 'diversite-contact-simple-profile',
    templateUrl: './contact-simple-profile.component.html',
    styleUrl: './contact-simple-profile.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSimpleProfileComponent {
    @Input() contact: Contact;

    @Output() removeContact = new EventEmitter<Contact>();
    @Output() viewProfile = new EventEmitter<Contact>();
    @Output() selectContact = new EventEmitter<Contact>();

    imageUrl: string;

    private clickEvents$: Observable<MouseEvent>;
    private disposeBag = new DisposeBag();

    selected$: Observable<boolean>;

    constructor(private contactSelectionService: ContactsSelectionService, private host: ElementRef) { }

    ngOnInit(): void {
        this.imageUrl = this.contact.images[0]?.url;
    }

    ngAfterViewInit(): void {
        this.handleClickAndDoubleClick(this.contact);
        this.selected$ = this.contactSelectionService.contactsSelection().pipe(
            map(contactsSelection => {
                return contactsSelection.find(selectedContactId => selectedContactId === this.contact.id) ? true : false
            }),
            distinctUntilChanged()
        )
    }


    private handleClickAndDoubleClick(contact: Contact): void {
        if (!this.clickEvents$ && this.contact) {
            const clickEvent = fromEvent<MouseEvent>(this.host.nativeElement, "mousedown");
            const dblClickEvent = fromEvent<MouseEvent>(this.host.nativeElement, "dblclick");
            this.clickEvents$ = merge(clickEvent, dblClickEvent).pipe(debounceTime(250));
            this.clickEvents$.subscribe((event) => {
                if (event.type === "mousedown") {
                    this.select(contact);
                } else {
                    this.openProfile(contact);
                }
            }).disposedBy(this.disposeBag);
        }
    }

    private select(contact: Contact): void {
        this.selectContact.emit(contact);
    }

    private openProfile(contact: Contact): void {
        this.viewProfile.emit(contact)
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
