import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { DisposeBag } from "src/modules/core/utilities/dispose-bag";

import { SearchDefinition } from "../../model/search-definition";
import { SearchOperation } from "../../model/search-operation";
import { SearchContextService } from "../../services/search-context.service";

const DEFAULT_SEARCH_PARAMS_OPTS: SearchParametersOptions = {
    removable: true,
    editable: true,
};
@Component({
    selector: "diversite-search-parameters-compact",
    templateUrl: "./search-parameters-compact.component.html",
    styleUrls: ["./search-parameters-compact.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchParametersCompactComponent implements OnInit {
    @Input() searchDefinition: SearchDefinition;
    @Input() options: SearchParametersOptions = DEFAULT_SEARCH_PARAMS_OPTS;

    private disposeBag = new DisposeBag();

    constructor(private searchContextService: SearchContextService) {}

    ngOnInit(): void {}

    trackById(_, element: any): string {
        return element.id;
    }

    onSearchOperationChange(sop: SearchOperation): void {
        this.searchDefinition = this.searchDefinition.replaceAddSearchOperations([sop]);
        this.searchContextService.saveSearchDefinition(this.searchDefinition).subscribe().disposedBy;
    }
    onRemoveSearchOperation(sop: SearchOperation): void {
        this.searchDefinition = this.searchDefinition.removeSearchOperation(sop.id);
        this.searchContextService.saveSearchDefinition(this.searchDefinition).subscribe().disposedBy;
    }

    isEditable(sop: SearchOperation): boolean {
        return !sop.operand || (sop.type !== "availability" && sop.operator !== "boolean" && sop.operator !== "exists");
    }

    hasOperation(): boolean {
        return this.searchDefinition.searchOperations.length > 0;
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}

export interface SearchParametersOptions {
    editable: boolean;
    removable: boolean;
}
