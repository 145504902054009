import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { ContextNode } from 'src/modules/diversite/model/context-node';
import { Pane, PaneType } from 'src/modules/diversite/model/pane';
import { SearchOperation } from 'src/modules/diversite/model/search-operation';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { ContextNodeService } from 'src/modules/diversite/services/context-node.service';
import { FormService } from 'src/modules/diversite/services/form.service';
import { ProjectService } from 'src/modules/diversite/services/projects.service';
import { ReportService } from 'src/modules/diversite/services/report.service';
import { SearchContextService } from 'src/modules/diversite/services/search-context.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';

const SORTED_ATTRIBUTES_PANE_TITLE = [
    "identity_gender",
    "identity_dateOfBirth",
]

@Injectable({
    providedIn: "root",
})
export class PaneTitleService {
    constructor(
        private contextNodeService: ContextNodeService,
        private formService: FormService,
        private searchContextService: SearchContextService,
        private contactService: ContactService,
        private reportService: ReportService,
        private projectService: ProjectService,
        private translateService: TranslateService
    ) { }

    actionPaneTitle(pane: Pane): string {
        if (pane.type === "action") {
            if (pane.contextData.actionType === "mass-edit") {
                return "Modification en masse";
            }
            if (pane.contextData.actionType === "send-form") {
                return "Envoyer un formulaire";
            }
            if (pane.contextData.actionType === "add-contact") {
                return "Ajouter un membre";
            }
            if (pane.contextData.actionType === "export") {
                return "Exporter";
            }
            if (pane.contextData.actionType === "merge-contacts") {
                return "Fusionner les profils";
            }
            if (pane.contextData.actionType === "edit-package") {
                return "Modifier un dossier de partage";
            }
            if (pane.contextData.actionType === "share-project") {
                return "Partager un projet";
            }
            if (pane.contextData.actionType === "copy-form") {
                return "Copier un formulaire";
            }
            if (pane.contextData.actionType === "create-folder") {
                return "Créer un nouveau dossier";
            }
        }
    }

    contextNodePathForPane(pane: Pane): Observable<ContextNodeTitle[]> {
        let obs;
        if (pane.type === "search") {
            return this.searchContextService.searchDefinition(pane.contextData.searchContextId, { listen: true }).pipe(

                switchMap(search => {
                    if (search.hasFormSearchOperation()) {
                        return this.projectService.project(search.projectId).pipe(
                            switchMap(p => {
                                const formId = search.formSearchOperation().operand.split("_").pop();
                                return this.formService.form(formId, { listen: false }).pipe(
                                    map(f => {
                                        return { form: f, project: p };
                                    }))
                            }),
                            map(data => {
                                return [{ name: "Recherche" }, { name: data.project.name }, { name: data.form.name[this.lang] }, ...this.shownAttributesInTitle(search.searchOperations)]
                            }))
                    } else {
                        return of([{ name: "Recherche" }, ...this.shownAttributesInTitle(search.searchOperations)]);
                    }
                }),
                startWith([{ name: "Recherche" }]),
            );
        } else if (pane.contextNodeId) {
            obs = this.contextNodeService.node(pane.contextNodeId, { listen: true }).pipe(
                switchMap((n) => {
                    if (n) {
                        return this.paneTitleRecursive(n).pipe(
                            switchMap((nodePath) => {
                                if (pane.contextData?.formId) {
                                    return this.addFormNameToPath(pane.contextData.formId, pane.type, nodePath);
                                }
                                if (pane.contextData?.reportId) {
                                    return this.addReportNameToPath(pane.contextData.reportId, pane.type, nodePath);
                                }
                                if (pane.contextData?.contactId) {
                                    return this.addContactNameToPath(pane.contextData.contactId, pane.type, nodePath);
                                }
                                if (pane.type === "classification") {
                                    return of([...nodePath, { name: this.titleForType(pane.type) }]);
                                }
                                if (pane.type === "action" && pane.contextData.actionType === "add-idfig-list") {
                                    return of([...nodePath, { name: "Ajouter une liste d'idfig" }]);
                                }

                                // if (pane.type === "search") {
                                //     console.log(pane);
                                // }
                                return of(nodePath);
                            })
                        );
                    } else {
                        return of([{ name: this.titleForType(pane.type) }]);
                    }
                })
            );
        } else {
            if (pane.type === "action" && pane.contextData.actionType === "add-idfig-list") {
                return of([{ name: "Ajouter une liste d'idfig" }]);
            } else {
                // if (pane.type === "search") {
                //     console.log(pane);
                // }
                return of([{ name: this.titleForType(pane.type) }]);
            }
        }
        return obs;
    }

    private shownAttributesInTitle(searchOperations: SearchOperation[]): ContextNodeTitle[] {
        const notSortedOperations = searchOperations.filter(searchOperation => {
            if (!searchOperation.operand) {
                return true;
            }
            if (searchOperation.operand.startsWith("recruitment_")) {
                return false;
            }
            if (!SORTED_ATTRIBUTES_PANE_TITLE.includes(searchOperation.operand)) {
                return true;
            }
            return false;

        });
        return [
            ...SORTED_ATTRIBUTES_PANE_TITLE.map(attributeId => {
                const sop = searchOperations.find(sop => attributeId === sop.operand);
                if (sop) {
                    return { searchOperation: sop }
                }
            }).filter(title => title ? true : false),
            ...notSortedOperations.map(sop => {
                return { searchOperation: sop }
            })
        ]
    }

    private addFormNameToPath(
        formId: string,
        paneType: PaneType,
        nodePath: ContextNodeTitle[]
    ): Observable<ContextNodeTitle[]> {
        return this.formService.form(formId).pipe(
            map((form) => {
                return [...nodePath, { name: this.titleForType(paneType) }, { name: form.name[this.lang] }];
            })
        );
    }

    private addReportNameToPath(
        reportId: string,
        paneType: PaneType,
        nodePath: ContextNodeTitle[]
    ): Observable<ContextNodeTitle[]> {
        return this.reportService.reportById(reportId, { listen: true }).pipe(
            map((report) => {
                return [...nodePath, { name: this.titleForType(paneType) }, { name: report.name }];
            })
        );
    }

    private addContactNameToPath(
        contactId: string,
        paneType: PaneType,
        nodePath: ContextNodeTitle[]
    ): Observable<ContextNodeTitle[]> {
        return this.contactService.contactById(contactId).pipe(
            map((contact) => {
                return [...nodePath, { name: this.titleForType(paneType) }, { name: contact.fullname }];
            })
        );
    }

    get lang(): string {
        return this.translateService.lang;
    }

    private paneTitleRecursive(n: ContextNode): Observable<ContextNodeTitle[]> {
        const title = { id: n.id, name: n.name };
        if (n.parentNodeId) {
            return this.contextNodeService.node(n.parentNodeId, { listen: true }).pipe(
                switchMap((p) => {
                    return this.paneTitleRecursive(p).pipe(
                        map((titles: ContextNodeTitle[]) => {
                            return [...titles, title];
                        })
                    );
                })
            );
        }
        return of([title]);
    }

    private titleForType(type: PaneType): string {
        if (type === "treeview") {
            return "Organisation";
        } else if (type === "form-edit" || type === "form-preview") {
            return "Formulaires";
        } else if (type === "projects") {
            return "Liste de projets";
        } else if (type === "contacts") {
            return "Contacts";
        } else if (type === "contact") {
            return "Profil";
        } else if (type === "report-edit") {
            return "Rapport";
        } else if (type === "classification") {
            return "Classification";
        } else if (type === "upload-photo-preview") {
            return "Mise à jour photo(s)";
        } else if (type === "activities") {
            return "Activitées"
        }

        return "Panneau";
    }
}

export interface ContextNodeTitle {
    id?: string;
    name?: string;
    searchOperation?: SearchOperation;
}
