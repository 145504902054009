import { ImageLayout, MultiCustomLayout } from '../services/face-position.service';
import { Contact } from './contact';
import { ContactImage } from './contact-image';

export interface Builder {
    id?: string;
    contact?: Contact;
    defaultImageIndex?: number;
    layout?: MultiCustomLayout;
    visibleImages?: string[];
    comments?: string;
    imageDispositionSpecs?: ImageDispositionSpecification[];
    imageDispositionProfile?: ImageDispositionSpecification[];
    createdAt?: number;
    lastUpdate?: number;
    isNew?: boolean;
    isUpdated?: boolean;
}

export class ContactPerspective {
    private _id: string;
    private _contact: Contact;
    private _defaultImageIndex: number;
    private _layout: MultiCustomLayout;
    private _visibleImages: string[];
    private _comments: string;
    private _imageDispositionSpecs: ImageDispositionSpecification[];
    private _imageDispositionProfile: ImageDispositionSpecification[];
    private _createdAt: number;
    private _lastUpdate: number;
    private _isNew: boolean;
    private _isUpdated: boolean;

    constructor(builder: Builder) {
        this._id = builder.id;
        this._contact = builder.contact;
        this._defaultImageIndex = builder.defaultImageIndex || undefined;
        this._layout = builder.layout;
        this._visibleImages = builder.visibleImages ? builder.visibleImages.filter(i => i ? true : false) : [];
        this._comments = builder.comments;
        this._imageDispositionSpecs = builder.imageDispositionSpecs || [];
        this._imageDispositionProfile = builder.imageDispositionProfile || [];
        this._createdAt = builder.createdAt;
        this._lastUpdate = builder.lastUpdate;
        this._isNew = builder.isNew === true || false;
        this._isUpdated = builder.isUpdated === true || false;
    }

    get id(): string {
        return this._id;
    }
    get contact(): Contact {
        return this._contact;
    }
    get defaultImageIndex(): number {
        if (this._defaultImageIndex || this._defaultImageIndex === 0) {
            return this._defaultImageIndex;
        } else if (this.contact) {
            return this.contact.defaultImageIndex;
        }
        return 0;
    }

    get defaultImage(): ContactImage {
        return this.contact.images[this.defaultImageIndex || 0];
    }
    get layout(): MultiCustomLayout {
        return this._layout;
    }
    get visibleImages(): string[] {
        return this._visibleImages;
    }
    get comments(): string {
        return this._comments;
    }
    get imageDispositionSpecs(): ImageDispositionSpecification[] {
        return this._imageDispositionSpecs;
    }
    get imageDispositionProfile(): ImageDispositionSpecification[] {
        return this._imageDispositionProfile;
    }
    get createdAt(): number {
        return this._createdAt;
    }
    get isNew(): boolean {
        return this._isNew;
    }
    get lastUpdate(): number {
        return this._lastUpdate;
    }
    get isUpdated(): boolean {
        return this._isUpdated;
    }

    defaultImagesLayout(): ImageLayout[] {
        if (!this.contact) {
            return [];
        }
        return this.contact.images.map((contactImage) => {
            return {
                id: contactImage.id,
                url: contactImage.url,
                rotate: contactImage.rotate,
                uploadedAt: contactImage.uploadedAt,
                originalDate: contactImage.originalDate,
            };
        });
    }

    change(builder: Builder): ContactPerspective {
        return new ContactPerspective({
            id: this.id,
            contact: this.contact,
            defaultImageIndex: this._defaultImageIndex,
            layout: this.layout,
            visibleImages: this.visibleImages,
            comments: this.comments,
            imageDispositionSpecs: this.imageDispositionSpecs,
            imageDispositionProfile: this.imageDispositionProfile,
            createdAt: this.createdAt,
            isNew: this.isNew,
            lastUpdate: this.lastUpdate,
            isUpdated: this.isUpdated,
            ...builder,
        });
    }

    changeProfileLayout(layout: MultiCustomLayout, imageSpecs: ImageDispositionSpecification[]): ContactPerspective {
        return this.change({
            layout,
            imageDispositionProfile: imageSpecs,
        });
    }

    changeImageDisposition(updatedImgSpec: ImageDispositionSpecification): ContactPerspective {
        const currentImageSpecs = [...this.imageDispositionSpecs];
        if (currentImageSpecs.find((imgSpec) => imgSpec.imageId === updatedImgSpec.imageId)) {
            return this.change({
                imageDispositionSpecs: currentImageSpecs.map((imgSpec) => {
                    if (updatedImgSpec.imageId === imgSpec.imageId) {
                        return updatedImgSpec;
                    }
                    return imgSpec;
                }),
            });
        } else {
            currentImageSpecs.push(updatedImgSpec);
            return this.change({
                imageDispositionSpecs: currentImageSpecs,
            });
        }
    }
}

export interface ImageDispositionSpecification {
    imageId: string;
    zoom: number;
    x: number;
    y: number;
}
