import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormTable, TableField } from 'src/modules/diversite/model/form/form-element/form-table';

@Component({
    selector: "fillout-table",
    templateUrl: "./table.component.html",
    styleUrls: ["./table.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnChanges {
    @Input() lang: string;
    @Input() formElement: FormTable;
    @Input() value: any[] = [{}, {}, {}];

    @Output() responseChange = new EventEmitter<any[]>();
    delayedChanges$ = new BehaviorSubject<void>(undefined);

    newEntry = {};
    private _disposeBag = new DisposeBag();

    constructor() {}

    ngOnInit(): void {
        this.delayedChanges$
            .pipe(debounceTime(250), skip(1))
            .subscribe((_) => {
                this.responseChange.emit(
                    this.value.filter((v) => {
                        const allValuesEqualsEmpty = Object.keys(v).every((k) => v[k].trim() === "");
                        return Object.keys(v).length !== 0 && !allValuesEqualsEmpty;
                    })
                    // this.value
                );
            })
            .disposedBy(this._disposeBag);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && (!Array.isArray(changes.value.currentValue) || changes.value.currentValue.length === 0)) {
            if (!this.formElement.readonly) {
                this.value = [{}, {}, {}];
            }
        }
    }

    onInputChange(): void {
        this.delayedChanges$.next();
    }

    removeEntry(index: number): void {
        this.value = this.value.filter((value, i) => index !== i);
        this.onInputChange();
    }

    onFileChange(fieldName: string, imageUrl: string): void {
        this.newEntry[fieldName] = imageUrl;
    }

    onExistingFileChange(fieldName: string, index: number, imageUrl: string): void {
        this.value[index][fieldName] = imageUrl;
    }

    id(fieldname: string): string {
        return `${fieldname}`;
    }

    trackByIndex(value: any, index: number): string {
        return `${index}`;
    }

    addEntry(indexEntry?: number): void {
        if (Array.isArray(this.value)) {
            if (indexEntry === undefined) {
                this.value = [...this.value, this.newEntry];
            } else {
                this.value.splice(indexEntry + 1, 0, {});
            }
        } else {
            this.value = [this.newEntry];
        }
        this.newEntry = {};
        this.delayedChanges$.next();
    }

    tooltipShow(field: TableField): boolean {
        return field.description && field.description !== "";
    }
}
