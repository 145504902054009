<div *ngIf="formElement">
    <clr-checkbox-container [class.no-label]="!options.showLabel">
        <label *ngIf="options.showLabel"
            >{{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
            ><ng-content select="[tooltip-content]"></ng-content>
        </label>
        <clr-checkbox-wrapper *ngFor="let option of formElement.options">
            <input
                [disabled]="formElement.readonly"
                (change)="checkStateChange($event, option)"
                [checked]="isOptionChecked(option)"
                type="checkbox"
                clrCheckbox
                name="options"
                required
            />
            <label>{{ option.label[lang] }}</label>
        </clr-checkbox-wrapper>
    </clr-checkbox-container>
</div>
