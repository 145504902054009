import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import { NotificationService } from "src/modules/core/services/notification.service";
import { DisposeBag } from "src/modules/core/utilities/dispose-bag";
import { DraggableEvent } from "src/modules/diversite/directives/draggable.directive";
import { ContactPerspective } from "src/modules/diversite/model/contactPerspective";
import {
    FacePositionService,
    ImageDispositionOption,
    ImageLayout,
} from "src/modules/diversite/services/face-position.service";

import { RotateImageEvent } from "../../photo-profile/photo-profile.component";

const DEFAULT_OPTIONS: CardAvailableOptions = {};

declare let $: any;
const CARD_FACE_OPTIONS: ImageDispositionOption = {
    containerWidth: 190,
    containerHeight: 120,
    finalFaceHeight: 70,
};
@Component({
    selector: "diversite-contact-card",
    templateUrl: "./contact-card.component.html",
    styleUrls: ["./contact-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactCardComponent implements OnInit, OnDestroy, OnChanges {
    @Input() cardFaceDispositionOptions: ImageDispositionOption = CARD_FACE_OPTIONS;
    @Input() contactPerspective: ContactPerspective;
    @Input() options: CardAvailableOptions = DEFAULT_OPTIONS;
    @Input() showFields: Map<string, string> = new Map();
    @Input() lazyload = true;
    @Input() editable = true;
    @Input() showSquareFace = true;
    @Input() height;

    @Output() remove = new EventEmitter<ContactPerspective>();
    @Output() view = new EventEmitter<ContactPerspective>();
    @Output() sendMessage = new EventEmitter<ContactPerspective>();
    @Output() sendForm = new EventEmitter<ContactPerspective>();
    @Output() contactPerspectiveChange = new EventEmitter<ContactPerspective>();
    @Output() rotatePicture = new EventEmitter<RotateImageEvent>();
    @Output() openContactModal = new EventEmitter<ContactPerspective>();

    showSlideshow = false;
    private _hasAnyOptions = false;
    private _imageLayouts: ImageLayout[] = [];
    private obs: IntersectionObserver;
    private _draggingStartOffset: { x: number; y: number };

    private _disposeBag = new DisposeBag();

    constructor(
        private hostElement: ElementRef,
        private chRef: ChangeDetectorRef,
        private notificationService: NotificationService,
        private facePosition: FacePositionService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.options) {
            this._hasAnyOptions = !Object.keys(this.options).every((opt) => !this.options[opt]);
        } else {
            this._hasAnyOptions = false;
        }

        if (changes.cardFaceDispositionOptions && !changes.cardFaceDispositionOptions.currentValue) {
            this.cardFaceDispositionOptions = CARD_FACE_OPTIONS;
        }

        if (
            changes.contactPerspective &&
            changes.contactPerspective.currentValue &&
            changes.contactPerspective &&
            changes.contactPerspective.currentValue.contact
        ) {
            this._imageLayouts = this.facePosition.mapContactImagesToImageLayout(
                this.contactPerspective.contact.images,
                this.cardFaceDispositionOptions,
                this.contactPerspective.imageDispositionSpecs
            );
        }
    }

    ngOnInit(): void {
        if (this.lazyload) {
            this.lazyLoadSlideshow();
        }
    }

    get imageLayouts(): ImageLayout[] {
        return this._imageLayouts;
    }

    get heightValue(): string {
        if (this.height) {
            return `${this.height}px`;
        }
    }

    get hasAnyOptions(): boolean {
        return this._hasAnyOptions;
    }

    removeOption(contact: ContactPerspective): void {
        this.remove.emit(contact);
    }

    viewOption(contact: ContactPerspective): void {
        this.view.emit(contact);
    }

    onButtonsClick(event: MouseEvent): void {
        event.stopPropagation();
    }

    private lazyLoadSlideshow(): void {
        this.obs = new IntersectionObserver((entries) => {
            entries.forEach(({ isIntersecting }) => {
                if (isIntersecting) {
                    this.loadSlideshow();
                } else if (!isIntersecting && this.showSlideshow) {
                    this.unloadSlideshow();
                }
            });
        });
        this.obs.observe(this.hostElement.nativeElement);
    }

    private loadSlideshow(): void {
        this.showSlideshow = true;
        this.chRef.detectChanges();
    }
    private unloadSlideshow(): void {
        this.showSlideshow = false;
        this.chRef.detectChanges();
    }

    onDefaultImageChange(index: number): void {
        this.contactPerspective = this.contactPerspective.change({ defaultImageIndex: index });
        this.contactPerspectiveChange.emit(this.contactPerspective);
    }

    onImageChange(imageLayout: ImageLayout): void {
        this.contactPerspectiveChange.emit(
            this.contactPerspective.changeImageDisposition({
                imageId: imageLayout.id,
                zoom: imageLayout.zoom,
                x: imageLayout.backgroundx,
                y: imageLayout.backgroundy,
            })
        );
    }

    zoomOut(): void {
        this._imageLayouts = this.imageLayouts.map((imgLayout, index) => {
            if (index === this.contactPerspective.defaultImageIndex) {
                return { ...imgLayout, zoom: imgLayout.zoom - 5 };
            } else {
                return imgLayout;
            }
        });
        this.onImageChange(this._imageLayouts[this.contactPerspective.defaultImageIndex]);
    }

    zoomIn(): void {
        this._imageLayouts = this.imageLayouts.map((imgLayout, index) => {
            if (index === this.contactPerspective.defaultImageIndex) {
                return { ...imgLayout, zoom: imgLayout.zoom + 5 };
            } else {
                return imgLayout;
            }
        });
        this.onImageChange(this._imageLayouts[this.contactPerspective.defaultImageIndex]);
    }

    rotate(): void {
        this._imageLayouts = this.imageLayouts.map((imgLayout, index) => {
            if (index === this.contactPerspective.defaultImageIndex) {
                return { ...imgLayout, rotate: imgLayout.rotate >= 270 ? 0 : imgLayout.rotate + 90 };
            } else {
                return imgLayout;
            }
        });
        this.onImageChange(this._imageLayouts[this.contactPerspective.defaultImageIndex]);
        this.rotatePicture.emit({
            contactId: this.contactPerspective.id,
            image: this._imageLayouts[this.contactPerspective.defaultImageIndex],
        });
    }

    onHandDrag(eventDrag: DraggableEvent): void {
        const imageLayout = this._imageLayouts[this.contactPerspective.defaultImageIndex];

        this.dragPhoto(eventDrag, imageLayout);
    }

    onDragEnd(_: DraggableEvent): void {
        const imageLayout = this._imageLayouts[this.contactPerspective.defaultImageIndex];

        document.body.style.cursor = "default";
        this._draggingStartOffset = null;
        this.onImageChange(imageLayout);
    }

    openProfile(): void {
        this.openContactModal.emit(this.contactPerspective);
    }

    copyLink(): void {
        navigator.clipboard.writeText(
            `${window.location.origin}#/castlug/diversite/contact/edit/${this.contactPerspective.id}`
        );
        this.notificationService.show("Le liens du membre à été copiés dans le presse-papier.", "info");
    }

    private dragPhoto(eventDrag: DraggableEvent, imageLayout: ImageLayout): void {
        if (this._draggingStartOffset) {
            imageLayout.backgroundx = this.getX(
                imageLayout.rotate,
                { x: this._draggingStartOffset.x, y: this._draggingStartOffset.y },
                eventDrag.delta
            );
            imageLayout.backgroundy = this.getY(
                imageLayout.rotate,
                { x: this._draggingStartOffset.x, y: this._draggingStartOffset.y },
                eventDrag.delta
            );
        } else {
            document.body.style.cursor = "move";
            this._draggingStartOffset = { x: imageLayout.backgroundx, y: imageLayout.backgroundy };
            imageLayout.backgroundx = this.getX(
                imageLayout.rotate,
                { x: imageLayout.backgroundx, y: imageLayout.backgroundy },
                eventDrag.delta
            );
            imageLayout.backgroundy = this.getY(
                imageLayout.rotate,
                { x: imageLayout.backgroundx, y: imageLayout.backgroundy },
                eventDrag.delta
            );
        }
        this._imageLayouts = this._imageLayouts.map((imgLayout) =>
            imgLayout.id === imageLayout.id ? { ...imageLayout } : imgLayout
        );
    }

    private getX(rotation: number, current: { x: number; y: number }, delta: { x: number; y: number }): number {
        if (rotation === 0) {
            return current.x + delta.x;
        } else if (rotation === 90) {
            return current.y + delta.y;
        } else if (rotation === 180) {
            return current.x - delta.x;
        } else if (rotation === 270) {
            return -(current.y + delta.y);
        }
    }

    private getY(rotation: number, current: { x: number; y: number }, delta: { x: number; y: number }): number {
        if (rotation === 0) {
            return current.y + delta.y;
        } else if (rotation === 90) {
            return current.x - delta.x;
        } else if (rotation === 180) {
            return current.y - delta.y;
        } else if (rotation === 270) {
            return -(current.x - delta.x);
        }
    }

    log(): void {
        // TOOLING
        // remove in prod
        console.log(this.contactPerspective);
    }

    ngOnDestroy(): void {
        if (this.obs) {
            this.obs.unobserve(this.hostElement.nativeElement);
        }
        this._disposeBag.dispose();
    }
}
export interface CardAvailableOptions {
    view?: boolean;
    remove?: boolean;

    zoom?: boolean;
    move?: boolean;
    rotate?: boolean;
}
