import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { distinctUntilChanged, filter, map, mergeMap, Observable, switchMap, take, tap } from 'rxjs';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { ReportType } from 'src/modules/diversite/model/report';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { CardAvailableOptions } from '../../../contacts/contact-card/contact-card.component';
import { FacePositionService, ImageDispositionOption } from 'src/modules/diversite/services/face-position.service';

@Component({
    selector: 'diversite-contact-report',
    templateUrl: './contact-report.component.html',
    styleUrl: './contact-report.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactReportComponent {
    @Input() contactPerspective: ContactPerspective;
    @Input() reportType: ReportType = 'picture-info';
    @Input() loadOnVisibility = true;
    @Input() editable = true;
    @Input() showAttributes: string[] = [];
    @Input() cardFaceDispositionOptions: ImageDispositionOption;

    readonly contactCardOptions: CardAvailableOptions = {
        zoom: true,
        move: true,
        rotate: true,
    };

    @Output() contactPerspectiveChange = new EventEmitter<ContactPerspective>();

    private disposeBag = new DisposeBag();

    constructor(private contactService: ContactService, private host: ElementRef, private facePositionService: FacePositionService, private chRef: ChangeDetectorRef) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.cardFaceDispositionOptions?.currentValue) {
            this.contactPerspective = this.updateContactPerspectiveImagePosition(changes.cardFaceDispositionOptions?.currentValue)
        }
    }

    ngAfterViewInit(): void {
        if (this.loadOnVisibility) {
            this.createAndObserve(this.host).pipe(
                filter((isVisible: boolean) => isVisible),
                switchMap(() => this.contactService.contactById(this.contactPerspective.id).pipe(
                    tap(c => {
                        this.contactPerspective = this.contactPerspective.change({ contact: c })
                        this.contactPerspective = this.updateContactPerspectiveImagePosition(this.cardFaceDispositionOptions)
                        this.chRef.detectChanges();
                    })
                )),
                take(1)
            ).subscribe().disposedBy(this.disposeBag);
        } else {
            this.contactService.contactById(this.contactPerspective.id).pipe(
                tap(c => {
                    this.contactPerspective = this.contactPerspective.change({ contact: c })
                    this.contactPerspective = this.updateContactPerspectiveImagePosition(this.cardFaceDispositionOptions)
                    this.chRef.detectChanges();
                })
            ).subscribe().disposedBy(this.disposeBag);
        }
    }


    private updateContactPerspectiveImagePosition(imgDispositionOption: ImageDispositionOption): ContactPerspective {
        if (this.contactPerspective.contact) {
            const selectedImage = this.contactPerspective.contact.images[this.contactPerspective.defaultImageIndex];
            if (selectedImage && selectedImage.faceRectangle && selectedImage.url && this.cardFaceDispositionOptions) {
                const centeredImageDisposition = this.facePositionService.positionForFaceRectangle(
                    selectedImage.faceRectangle,
                    selectedImage.width,
                    selectedImage.height,
                    selectedImage.rotate,
                    this.cardFaceDispositionOptions
                );
                return this.contactPerspective.changeImageDisposition({
                    imageId: selectedImage.id,
                    x: centeredImageDisposition.backgroundx,
                    y: centeredImageDisposition.backgroundy,
                    zoom: centeredImageDisposition.zoom,
                });
            }
        }
        return this.contactPerspective;
    }

    private createAndObserve(element: ElementRef): Observable<boolean> {
        return new Observable<IntersectionObserverEntry[]>(observer => {
            const intersectionObserver = new IntersectionObserver(entries => {
                observer.next(entries);
            });

            intersectionObserver.observe(element.nativeElement);

            return () => { intersectionObserver.disconnect(); };
        }).pipe(
            mergeMap((entries: IntersectionObserverEntry[]) => entries),
            map(entry => entry.isIntersecting),
            distinctUntilChanged()
        );
    }

    onContactPerspectiveChange(cp: ContactPerspective): void {
        this.contactPerspective = cp;
        this.updateContactPerspectiveImagePosition(this.cardFaceDispositionOptions);
        this.contactPerspectiveChange.emit(cp);
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }

}
