import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: "video-player",
    templateUrl: "./video-player.component.html",
    styleUrls: ["./video-player.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPlayerComponent implements OnInit {
    @Input() url: string;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit(): void {}

    safeUrl(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    isYoutubeVideo(url: string): boolean {
        if (url.indexOf("youtube") > 0) {
            return true;
        }
        return false;
    }

    isVimeoVideo(url: string): boolean {
        if (url.indexOf("vimeo") > 0) {
            return true;
        }
        return false;
    }

    isUploadedVideo(url: string): boolean {
        if (url.indexOf("windows.net") > 0) {
            return true;
        }
        return false;
    }
}
