<clr-range-container>
    <label>min.</label>
    <input
        name="minimumWeight"
        type="range"
        [(ngModel)]="searchValue.valueMin"
        clrRange
        (ngModelChange)="onWeightChange()"
        min="20"
        max="400"
    />
    <clr-control-helper
        >{{ weight("min") }}
        <span class="erase-value" [hidden]="!searchValue.valueMin" (click)="erase('min')">effacer</span>
    </clr-control-helper>
</clr-range-container>

<clr-range-container>
    <label>max.</label>
    <input
        name="maximumWeight"
        type="range"
        [(ngModel)]="searchValue.valueMax"
        clrRange
        (ngModelChange)="onWeightChange()"
        min="20"
        max="400"
    />
    <clr-control-helper
        >{{ weight("max") }}
        <span class="erase-value" [hidden]="!searchValue.valueMax" (click)="erase('max')">effacer</span>
    </clr-control-helper>
</clr-range-container>
