<div class="activity" *ngIf="contact$ | async as contact">
    <div class="top">
        <p>
            <a (click)="onViewProfile(contact.id)"
                ><strong>{{ contact.fullname }}</strong
                >&nbsp;<small>({{ contact.idfig }})</small></a
            >&nbsp; à modifié son profil.
        </p>
        <div class="sr-id">{{ activity.id }}</div>
    </div>
    <div class="activity-content">
        <diversite-contact-search-profile
            [width]="80"
            [height]="100"
            [infoSize]="0"
            [contact]="contact"
            (viewProfile)="onViewProfile($event.id)"
        ></diversite-contact-search-profile>
    </div>
    <div class="bottom">
        <div class="date">
            <small>{{ activity.created | date : "dd-MM-yyyy HH:mm" }}</small>
        </div>
    </div>
</div>
