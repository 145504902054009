<clr-checkbox-container clrInline>
    <clr-checkbox-wrapper *ngFor="let opt of options?.availableChoices; trackBy: trackById">
        <input
            clrCheckbox
            type="checkbox"
            [name]="opt.value ? opt.value : opt.id"
            [checked]="isChecked(opt)"
            (change)="onChange(opt)"
        />
        <label>{{ opt.label }}</label>
    </clr-checkbox-wrapper>
</clr-checkbox-container>
