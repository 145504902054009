import { Injectable } from '@angular/core';
import { User } from 'src/app/model/user';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AccessRoleService {

    constructor(private authService: AuthService) { }

    hasAccess(accessRequest: AccessRequest): boolean {
        if (!this.authService.isLoggedIn) {
            return false;
        }
        if (accessRequest.type === 'segment') {
            return this.hasAccessToSegment(accessRequest.segment, accessRequest.permissions);
        } else if (accessRequest.type === "project") {
            return this.hasAccessToProject(accessRequest.projectId, accessRequest.permissions);
        } else if (accessRequest.type === "department") {
            return this.hasAccessToDepartment(accessRequest.departmentId, accessRequest.permissions);
        }
        return false;
    }

    applicationSegments(): ApplicationSegment[] {
        return [...allApplicationSegments];
    }

    defaultApplicationSegmentsValue(): ApplicationSegmentAccess[] {
        return allApplicationSegments.map(s => {
            return {
                segment: s,
                permissions: ["read", "write", "update", "delete"]
            }
        })
    }

    private hasAccessToSegment(segment: ApplicationSegment, permissions: AccessPermission[]): boolean {
        const user: User = this.authService.loggedInUser;
        return user.access.applicationSegments === "all" || this.hasSegmentAccess(user.access.applicationSegments, segment, permissions);
    }

    private hasAccessToProject(projectId: string, permissions: AccessPermission[]): boolean {
        const user: User = this.authService.loggedInUser;
        return user.access.projects === "all" || this.hasProjectAccess(user.access.projects, projectId, permissions);
    }

    private hasAccessToDepartment(departmentId: string, permissions: AccessPermission[]): boolean {
        const user: User = this.authService.loggedInUser;
        return user.access.departments === "all" || this.hasDepartmentAccess(user.access.departments, departmentId, permissions);
    }

    private hasSegmentAccess(segments: ApplicationSegmentAccess[], segment: ApplicationSegment, permissions: AccessPermission[]): boolean {
        const segmentAccess = segments.find(segmentAccess => segmentAccess.segment === segment);
        return segmentAccess && permissions.every(permission => segmentAccess.permissions.includes(permission));
    }

    private hasProjectAccess(projectAccesses: ProjectAccess[], projectId: string, permissions: AccessPermission[]): boolean {
        const projectAccess = projectAccesses.find(pa => pa.projectId === projectId);
        return projectAccess && permissions.every(permission => projectAccess.permissions.includes(permission));
    }

    private hasDepartmentAccess(departmentAccesses: DepartmentAccess[], departmentId: string, permissions: AccessPermission[]): boolean {
        const departmentAccess = departmentAccesses.find(pa => pa.departmentId === departmentId);
        return departmentAccess && permissions.every(permission => departmentAccess.permissions.includes(permission));
    }
}

export interface AccessRequest {
    type: AccessRequestType;
    segment?: ApplicationSegment;
    projectId?: string;
    departmentId?: string;
    permissions: AccessPermission[];
}

export type AccessRequestType = "segment" | "project" | "department";
export interface AccessRole {
    applicationSegments: ApplicationSegmentsAccess;
    projects: ProjectsAccess;
    departments: DepartmentsAccess;
}
export type AccessPermission = "read" | "write" | "update" | "delete";

export type ApplicationSegmentsAccess = "all" | ApplicationSegmentAccess[];
export interface ApplicationSegmentAccess {
    segment: ApplicationSegment;
    permissions: AccessPermission[];
}
export type ApplicationSegment =
    "admin" |
    "activityOperation" |
    "globalSearch" |
    "projectOperation" |
    "searchOperation" |
    "folderOperation" |
    "formOperation" |
    "sharedPackageOperation" |
    "reportOperation" |
    "contactOperation";

const allApplicationSegments: ApplicationSegment[] = [
    "admin",
    "activityOperation",
    "globalSearch",
    "projectOperation",
    "searchOperation",
    "folderOperation",
    "formOperation",
    "sharedPackageOperation",
    "reportOperation",
    "contactOperation"
];

export type ProjectsAccess = "all" | ProjectAccess[];

export interface ProjectAccess {
    projectId: string;
    permissions: AccessPermission[];
}

export type DepartmentsAccess = "all" | DepartmentAccess[];

export interface DepartmentAccess {
    departmentId: string;
    permissions: AccessPermission[];
}

