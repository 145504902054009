<div *ngIf="formElement" class="form-element-edition">
    <div>
        <span
            #labelElement
            class="edit-label"
            contenteditable
            (keyup)="onLabelChange()"
            [innerHTML]="tempFormElement.label[lang]"
        ></span>
        <ng-content select="[tooltip]"></ng-content>
        <input readonly class="fake-input" />
    </div>
</div>
