import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { isObject } from 'src/app/core/functions';

import { SearchGroup } from '../model/search-group';
import { Occurance, Operator, SearchOperation, SearchOperationType, ToSearchField } from '../model/search-operation';
import { FormElementDTO, ModuleMapperService } from './module-mapper.service';
import { SearchGroupDTO } from './search-definition-mapper.service';



@Injectable({
    providedIn: "root",
})
export class SearchGroupMapperService {
    constructor(private moduleMapperService: ModuleMapperService) { }

    private searchGroupsToSearchGroupsDTO(searchGroups: SearchGroup[]): SearchGroupDTO[] {
        if (searchGroups) {
            return searchGroups.map((searchGroup) => {
                return this.searchGroupToSearchGroupDTO(searchGroup);
            });
        }
        return null;
    }

    searchGroupToSearchGroupDTO(searchGroup: SearchGroup): SearchGroupDTO {
        return {
            id: searchGroup.id,
            operator: searchGroup.operator,
            fullTextSearch: this.searchOperationToSearchOperationDTO(searchGroup.fullTextSearch),
            searchOperations: searchGroup.searchOperations
                ? searchGroup.searchOperations.map((sop) => this.searchOperationToSearchOperationDTO(sop))
                : null,
            searchGroups: this.searchGroupsToSearchGroupsDTO(searchGroup.searchGroups),
            disabled: searchGroup.disabled,
        };
    }

    private searchGroupsDTOToSearchGroups(searchGroupsDTO: SearchGroupDTO[]): SearchGroup[] {
        if (searchGroupsDTO) {
            return searchGroupsDTO.map((searchGroupDTO) => {
                return this.searchGroupDTOToSearchGroup(searchGroupDTO);
            });
        }
        return null;
    }

    searchGroupDTOToSearchGroup(searchGroupDTO: SearchGroupDTO): SearchGroup {
        return new SearchGroup({
            id: searchGroupDTO.id,
            operator: searchGroupDTO.operator,
            fullTextSearch: this.searchOperationDTOToSearchOperation(searchGroupDTO.fullTextSearch),
            searchOperations: searchGroupDTO.searchOperations
                ? searchGroupDTO.searchOperations.map((sop) => this.searchOperationDTOToSearchOperation(sop))
                : null,
            searchGroups: this.searchGroupsDTOToSearchGroups(searchGroupDTO.searchGroups),
            disabled: searchGroupDTO.disabled,
        });
    }

    searchOperationToSearchOperationDTO(searchOperation: SearchOperation): SearchOperationDTO {
        return {
            id: searchOperation.id,
            type: searchOperation.type,
            operand: searchOperation.operand,
            value: searchOperation.value,
            operator: searchOperation.operator,
            occurance: searchOperation.occurance,
            boost: searchOperation.boost,
            field: searchOperation.field,
            disabled: searchOperation.disabled,
            source: searchOperation.source ? searchOperation.source.toDto() : null,
        };
    }

    searchOperationDTOToSearchOperation(searchOperationDTO: SearchOperationDTO): SearchOperation {

        if (searchOperationDTO) {
            return new SearchOperation({
                ...searchOperationDTO,
                value: this.sopValue(searchOperationDTO),
                source: searchOperationDTO?.source
                    ? this.moduleMapperService.formElementDtoToFormElement(searchOperationDTO.source)
                    : null,
            });
        }
    }

    private sopValue(searchOperationDTO: SearchOperationDTO): any {
        if (searchOperationDTO) {
            if (this.isInstanceOfFirestoreTimestamp(searchOperationDTO.value)) {
                return searchOperationDTO.value.toDate();
            }

            if (
                Array.isArray(searchOperationDTO.value) &&
                searchOperationDTO.type !== "availability" &&
                this.isInstanceOfFirestoreTimestamp(searchOperationDTO.value[0]) &&
                this.isInstanceOfFirestoreTimestamp(searchOperationDTO.value[1])
            ) {
                return [searchOperationDTO.value[0].toDate(), searchOperationDTO.value[1].toDate()];
            }

            if (searchOperationDTO.type === "availability") {
                return searchOperationDTO.value
                    ? searchOperationDTO.value.map((dateRange) => {
                        return {
                            from: dateRange.from ? dateRange.from.toDate() : null,
                            to: dateRange.to ? dateRange.to.toDate() : null,
                        };
                    })
                    : null;
            }

            if (
                isObject(searchOperationDTO.value) &&
                searchOperationDTO.value.hasOwnProperty("from") &&
                searchOperationDTO.value.hasOwnProperty("to")
            ) {
                return {
                    from: searchOperationDTO.value.from ? searchOperationDTO.value.from.toDate() : null,
                    to: searchOperationDTO.value.to ? searchOperationDTO.value.to.toDate() : null,
                };
            }
            return searchOperationDTO.value;
        }
    }

    private isInstanceOfFirestoreTimestamp(value: any): boolean {
        if (value) {
            return value instanceof firebase.firestore.Timestamp ||
                (value.hasOwnProperty('seconds') && value.hasOwnProperty("nanoseconds") && typeof value.toDate === 'function') ? true : false;
        }
        return false;
    }
}

export interface SearchOperationDTO {
    id: string;
    type?: SearchOperationType;
    operand?: string;
    value?: any;
    operator?: Operator;
    occurance?: Occurance;
    boost?: number;
    field?: ToSearchField;
    disabled?: boolean;
    source?: FormElementDTO;
}
function isInstanceOfFirestoreTimestamp(arg0: boolean) {
    throw new Error('Function not implemented.');
}

