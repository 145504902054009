import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormElementGenericOptions } from 'src/modules/fillout/components/form-element/form-element.component';

import { Contact } from '../../model/contact';
import { FormElement } from '../../model/form/form-element/form-element';
import { AttributeNode } from '../../services/attributes-context-perspective.service';
import { ContactService } from '../../services/contact.service';
import { ModuleService } from '../../services/module.service';
import { TranslateService } from '../../services/translate.service';

declare var $: any;

@Component({
    selector: "diversite-attribute-node",
    templateUrl: "./attribute-node.component.html",
    styleUrls: ["./attribute-node.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeNodeComponent implements OnInit, OnDestroy {
    @Input() contact: Contact;
    @Input() attributeNode: AttributeNode;
    @Input() editContactMode: boolean = false;
    @Input() editPerspectiveMode: boolean = false;
    @Input() showEmptyAttributes: boolean = false;

    @Output() attributeNodeChange = new EventEmitter<AttributeNode>();
    @Output() sortTree = new EventEmitter<void>();
    @Output() removeNode = new EventEmitter<string>();

    @ViewChild("nodeTitleElement") nodeTitleElement: ElementRef;

    tempAttributeValue: any;
    tempFormElement: FormElement;

    loading = false;

    value: any;

    open = true;

    private disposeBag = new DisposeBag();

    readonly optionsForFormElements: FormElementGenericOptions = {
        showLabel: false,
    };

    constructor(
        private contactService: ContactService,
        private modulesService: ModuleService,
        private notificationService: NotificationService,
        private translateService: TranslateService,
        private authService: AuthService,
        private db: AngularFirestore,
        private host: ElementRef,
        private chRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.value = this.contact.getAttributeValue(this.attributeNode.attributeId);
    }

    ngAfterViewInit(): void {
        this.setSortable();
    }

    hasValue(attrNode: AttributeNode): boolean {
        if (attrNode.children) {
            return true;
        } else {
            let val = this.contact.getAttributeValue(attrNode.attributeId);
            return attrNode.attributeId && this.allHasValue(val, attrNode.attributeId);
        }
    }

    allHasValue(value: any, attributeId: string): boolean {
        if (Object.prototype.toString.call(value) === "[object String]") {
            return value !== "" ? true : false;
        }
        if (Array.isArray(value) && value.length > 0) {
            return true;
        } else if (value?.toString() === '[object Object]') {
            return Object.keys(value).find(key => value[key] ? true : false) ? true : false;
        }
        return value ? true : false;
    }


    private setSortable(): void {
        if (this.attributeNode.open) {
            setTimeout(() => {
                $(".attribute-nodes", this.host.nativeElement)
                    .first()
                    .sortable({
                        handle: ".handle-option",
                        connectWith: ".attribute-nodes",
                        helper: "clone",
                        placeholder: "sortable-placeholder",
                        update: (event, ui) => {
                            this.sortTree.emit();
                        },
                    });
            }, 1000);
        }
    }



    onSortTree(): void {
        this.sortTree.emit();
    }

    onRemoveNode(childrenNodeId?: string): void {
        if (childrenNodeId) {
            this.attributeNode = this.attributeNode.change({
                children: this.attributeNode.children.filter((an) => an.id !== childrenNodeId),
            });
            this.attributeNodeChange.emit(this.attributeNode);
        } else {
            this.removeNode.emit(this.attributeNode.id);
        }
    }

    addSubfolder(): void {
        this.attributeNode = this.attributeNode.change({
            children: [
                ...this.attributeNode.children,
                new AttributeNode({ id: this.db.createId(), name: "Nouveau Dossier", children: [], open: false }),
            ],
        });
        this.attributeNodeChange.emit(this.attributeNode);
    }

    trackById(index: number, entity: any): string {
        return entity.id;
    }

    toggleOpen(): void {
        // this.attributeNode = this.attributeNode.change({ open: !this.attributeNode.open });
        // if (this.isLogged) {
        //     this.attributeNodeChange.emit(this.attributeNode);
        //     this.setSortable();
        // }
        this.open = !this.open;
        this.setSortable();
    }

    get isLogged(): boolean {
        return this.authService.isLoggedIn;
    }

    get lang(): string {
        return this.translateService.lang;
    }

    onNodeNameChange(value: string): void {
        this.attributeNode = this.attributeNode.change({ name: value });
        this.attributeNodeChange.emit(this.attributeNode);
    }

    onAttributeNodeChildChange(attributeNode: AttributeNode): void {
        this.attributeNode = this.attributeNode.change({
            children: this.attributeNode.children.map((c) => {
                return c.id === attributeNode.id ? attributeNode : c;
            }),
        });
        this.attributeNodeChange.emit(this.attributeNode);
    }

    save(): void {
        if (this.isLogged) {
            this.loading = true;
            this.chRef.detectChanges();
            setTimeout(() => {
                if (this.contact) {
                    this.contactService
                        .editContact(this.contact.changeAttribute(this.tempFormElement.name, this.tempAttributeValue))
                        .subscribe((_) => {
                            this.editContactMode = false;
                            this.loading = false;
                            this.value = this.tempAttributeValue;
                            this.notificationService.show("Le membre à été modifié avec succes.", "success");
                            this.chRef.markForCheck();
                        })
                        .disposedBy(this.disposeBag);
                }
            }, 150);
        }
    }

    setToEditMode(): void {
        this.tempAttributeValue = this.value;
        this.tempFormElement = this.modulesService.formElementForIndex(this.attributeNode.attributeId);
        this.editContactMode = true;
    }
    cancelEdit(): void {
        this.tempAttributeValue = undefined;
        this.tempFormElement = undefined;
        this.editContactMode = false;
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
