import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { readableDate } from 'src/app/core/functions';
import { AuthService } from 'src/app/services/auth.service';

import { ContactPerspective } from '../../model/contactPerspective';
import { DownloadAssetsService } from '../../services/download-assets.service';
import { FacePositionService, ImageLayout, MultiCustomLayout } from '../../services/face-position.service';

declare var $: any;

@Component({
    selector: "diversite-photo-profile",
    templateUrl: "./photo-profile.component.html",
    styleUrls: ["./photo-profile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoProfileComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() contactPerspective: ContactPerspective;
    @Input() showThumbnailSelection = true;
    @Input() showOptions = true;

    @Output() contactPerspectiveChange = new EventEmitter<ContactPerspective>();
    @Output() rotatePicture = new EventEmitter<RotateImageEvent>();
    @Output() addPhotos = new EventEmitter<File[]>();
    @Output() removeImage = new EventEmitter<ImageLayout>();
    images: ImageLayout[] = [];
    openedImages = [];
    loading = false;

    imageLayoutDeletion: ImageLayout;

    private zone: any;

    constructor(
        private sanitizer: DomSanitizer,
        private chRef: ChangeDetectorRef,
        private authService: AuthService,
        private assetService: DownloadAssetsService,
        private host: ElementRef
    ) { }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.loading) {
            this.loading = false;
        }

        if (this.zone) {
            this.zone.removeClass("dragfile");
        }
        if (changes.contactPerspective && changes.contactPerspective.currentValue) {
            this.images = this.contactPerspective.defaultImagesLayout();
        }
    }

    ngAfterViewInit(): void {
        if (this.authenticated && this.addPhotos.observers.length > 0) {
            this.setDroppableZone();
        }
    }

    get authenticated(): boolean {
        return this.authService.isLoggedIn;
    }

    get layoutVariants(): MultiCustomLayout[] {
        return FacePositionService.profileLayoutVariantsForNumberOfPictures(
            this.contactPerspective.visibleImages.length
        );
    }

    downloadImage(event, image: ImageLayout): void {
        event.preventDefault();
        this.assetService.downloadImage(this.contactPerspective.contact, image.id);
    }




    safeImage(image: ImageLayout): SafeStyle {
        if (image) {
            return this.sanitizer.bypassSecurityTrustStyle(`url(${image.url})`);
        }
    }

    toogleImage(image: ImageLayout): void {
        if (this.contactPerspective.visibleImages.find((selectedId) => selectedId === image.id)) {
            const newVisibleImages = this.contactPerspective.visibleImages.filter(
                (selectedId) => selectedId !== image.id
            );
            this.contactPerspective = this.contactPerspective.change({
                visibleImages: newVisibleImages,
                layout: FacePositionService.profileLayoutVariantsForNumberOfPictures(newVisibleImages.length)[0],
            });
        } else {
            const newVisibleImages = [...this.contactPerspective.visibleImages, image.id];
            this.contactPerspective = this.contactPerspective.change({
                visibleImages: newVisibleImages,
                layout: FacePositionService.profileLayoutVariantsForNumberOfPictures(newVisibleImages.length)[0],
            });
        }
        this.contactPerspectiveChange.emit(this.contactPerspective);
    }

    isSelected(image: ImageLayout): boolean {
        return this.contactPerspective.visibleImages.find((selectedImageId) => selectedImageId === image.id)
            ? true
            : false;
    }

    trackByColumn(index: number): string {
        return `column${index}`;
    }

    trackByItem(index: number): string {
        return `item${index}`;
    }

    getImageFromPositionInLayout(columnNum: number, photoNum: number): ImageLayout {
        let finalIndex = this.contactPerspective.visibleImages.length - 1;
        this.contactPerspective.layout.forEach((photoPerColumn, indexColumn) => {
            const arrayPhotos = this.numberToArrayOfNumber(photoPerColumn);
            arrayPhotos.forEach((photoIndex) => {
                if (columnNum === indexColumn + 1 && photoIndex + 1 === arrayPhotos.length) {
                    finalIndex = finalIndex - photoNum;
                } else if (columnNum < indexColumn + 1) {
                    finalIndex = finalIndex - 1;
                }
            });
        });
        return this.images.find((img) => img.id === this.contactPerspective.visibleImages[finalIndex]);
    }

    changeLayout(layout: MultiCustomLayout): void {
        this.contactPerspective = this.contactPerspective.change({ layout });
        this.contactPerspectiveChange.emit(this.contactPerspective);
    }

    layoutSelected(layout: MultiCustomLayout): boolean {
        return this.arraysEqual(layout, this.contactPerspective.layout);
    }

    arraysEqual(a: any[], b: any[]): boolean {
        if (a === b) {
            return true;
        }
        if (a == null || b == null) {
            return false;
        }
        if (a.length !== b.length) {
            return false;
        }

        // If you don't care about the order of the elements inside
        // the array, you should sort both arrays here.
        // Please note that calling sort on an array will modify that array.
        // you might want to clone your array first.

        for (let i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true;
    }

    numberToArrayOfNumber(num: number): number[] {
        return Array(num)
            .fill(num)
            .map((_, i) => i);
    }

    // zoomOut(image: ImageLayout): void {
    //     const img = { ...image, zoom: image.zoom - 10 };
    //     this.selectedImagesId = this.selectedImagesId.map((selectedImageId) => {
    //         if (selectedImageId === image.id) {
    //             return img.id;
    //         }
    //         return selectedImageId;
    //     });
    //     this.replaceImage(img);
    //     this.emitlayoutChange();
    // }

    // zoomIn(image: ImageLayout): void {
    //     const img = { ...image, zoom: image.zoom + 10 };
    //     this.selectedImagesId = this.selectedImagesId.map((selectedImageId) => {
    //         if (selectedImageId === image.id) {
    //             return img.id;
    //         }
    //         return selectedImageId;
    //     });
    //     this.replaceImage(img);
    //     this.emitlayoutChange();
    // }

    rotate(image: ImageLayout): void {
        const img: ImageLayout = { ...image, rotate: image.rotate === 270 ? 0 : image.rotate + 90 };
        this.replaceImage(img);
        this.rotatePicture.emit({ contactId: this.contactPerspective.id, image: img });
        this.chRef.detectChanges();
    }

    dateImageToShow(image: ImageLayout): string {
        if (image.originalDate) {
            return readableDate(image.originalDate, "dd-MM-yyyy");
        } else if (image.uploadedAt) {
            return `${readableDate(image.uploadedAt, "dd-MM-yyyy")}*`;
        }
        return "";
    }

    private replaceImage(image: ImageLayout): void {
        this.images = this.images.map((img) => (img.id === image.id ? image : img));
    }

    // onHandDrag(imageLayout: ImageLayout, photorow: PhotoRowComponent, eventDrag: DraggableEvent): void {
    //     if (this._draggingStartOffset) {
    //         imageLayout.backgroundx = this._draggingStartOffset.x + eventDrag.delta.x;
    //         imageLayout.backgroundy = this._draggingStartOffset.y + eventDrag.delta.y;
    //     } else {
    //         document.body.style.cursor = "move";
    //         this._draggingStartOffset = { x: imageLayout.backgroundx, y: imageLayout.backgroundy };
    //         imageLayout.backgroundx = imageLayout.backgroundx + eventDrag.delta.x;
    //         imageLayout.backgroundy = imageLayout.backgroundy + eventDrag.delta.y;
    //     }
    //     photorow.imagebackground.nativeElement.style.backgroundPosition = `${imageLayout.backgroundx}px ${imageLayout.backgroundy}px`;
    // }

    // onDragEnd(imageLayout: ImageLayout, _: DraggableEvent): void {
    //     document.body.style.cursor = "default";
    //     this._draggingStartOffset = null;
    //     this.emitlayoutChange();
    // }

    openPhoto(image: ImageLayout): void {
        if (image && image.url) {
            const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=800,top=10`;
            window.open(image.url, image.url, params);
        }
    }

    get imageIndex(): number {
        if (this.contactPerspective.defaultImageIndex + 1 > this.contactPerspective.contact.images.length) {
            return 0;
        } else {
            if (this.contactPerspective.defaultImageIndex === null) {
                const imageWithFaceIndex = this.contactPerspective.contact.images.findIndex((i) => i.faceRectangle);
                if (imageWithFaceIndex !== -1) {
                    return imageWithFaceIndex;
                } else {
                    return 0;
                }
            } else {
                return this.contactPerspective.defaultImageIndex;
            }
        }
    }

    // onHandDrag(eventDrag: DraggableEvent, image: ImageLayout): void {
    //     this.dragPhoto(eventDrag, image);
    // }

    // onDragEnd(image: ImageLayout): void {
    //     document.body.style.cursor = "default";
    //     this._draggingStartOffset = null;
    //     this.onImageChange(image);
    // }

    onImageChange(imageLayout: ImageLayout): void {
        this.contactPerspectiveChange.emit(
            this.contactPerspective.changeImageDisposition({
                imageId: imageLayout.id,
                zoom: imageLayout.zoom,
                x: imageLayout.backgroundx,
                y: imageLayout.backgroundy,
            })
        );
    }

    removePicture(imageLayout: ImageLayout): void {
        this.removeImage.emit(imageLayout);
    }

    removePhotoPrompt(imageLayout: ImageLayout): void {
        this.imageLayoutDeletion = imageLayout;
    }

    onOpenImageLayoutDeletion(isOpen: boolean): void {
        this.imageLayoutDeletion = undefined;
    }

    confirmImageRemoval(): void {
        this.removeImage.emit(this.imageLayoutDeletion);
        this.imageLayoutDeletion = undefined;
    }

    private setDroppableZone(): void {
        this.zone = $(".multi-layout", this.host.nativeElement);

        this.zone.on("dragover", (e) => {
            if (!this.zone.hasClass("dragfile")) {
                this.zone.addClass("dragfile");
            }
            e.preventDefault();
            e.stopPropagation();
        });
        this.zone.on("dragenter", (e) => {
            this.zone.addClass("dragfile");
            e.preventDefault();
            e.stopPropagation();
        });
        this.zone.on("dragleave", (e) => {
            this.zone.removeClass("dragfile");
        });
        this.zone.on("drop", (e) => {
            if (e.originalEvent.dataTransfer && e.originalEvent.dataTransfer.files.length) {
                e.preventDefault();
                e.stopPropagation();
                const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
                /*UPLOAD FILES HERE*/
                const validImages = Array.from(e.originalEvent.dataTransfer.files as FileList).filter((imageFile) =>
                    SUPPORTED_FORMATS.includes(imageFile.type)
                );
                this.loading = true;
                this.addPhotos.emit(validImages);
            }
        });
    }

    ngOnDestroy(): void { }
}

export interface LayoutChangeEvent {
    images: ImageLayout[];
    layout: MultiCustomLayout;
}

export interface RotateImageEvent {
    contactId: string;
    image: ImageLayout;
}
