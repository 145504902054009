import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
    providedIn: "root",
})
export class ContactsSelectionService {
    private _disabled = false;
    private _selectedContactIds = new BehaviorSubject<string[]>([]);

    private _saveSelection = new BehaviorSubject<string[]>([]);

    constructor(private db: AngularFirestore, private authService: AuthService) { }

    contactsSelection(): Observable<string[]> {
        if (this.authService.isLoggedIn) {
            return this.db
                .collection("userSelection")
                .doc(this.authService.loggedInUser.id)
                .snapshotChanges()
                .pipe(
                    map((doc) => {
                        if (doc.payload.exists) {
                            const selection = (doc.payload.data() as any).selection;
                            this._selectedContactIds.next(selection);
                            return selection;
                        }
                        this._selectedContactIds.next([]);
                        return [];
                    })
                );
        }
        return of(undefined);
    }

    get disabled(): boolean {
        return this._disabled;
    }


    disabledSelection(): void {
        this._disabled = true;
        this.clearSelection();
    }

    enableSelection(): void {
        this._disabled = false;
    }

    get currentSelection(): string[] {
        return this._selectedContactIds.value;
    }

    isSelected(id: string): boolean {
        return this._selectedContactIds.value.indexOf(id) > -1;
    }

    clearSelection(): void {
        this._selectedContactIds.next([]);
        this.db.collection("userSelection").doc(this.authService.loggedInUser.id).set({ selection: [] });
    }

    toggleSelection(contactId: string): void {
        if (!this._disabled) {
            const containsContactId: boolean = this.isSelected(contactId);
            if (containsContactId) {
                this.removeSelection(contactId);
            } else {
                this.addSelection(contactId);
            }
        }
    }

    removeSelection(contactId: string): void {
        if (!this._disabled) {
            this._selectedContactIds.next(this._selectedContactIds.value.filter((id) => id !== contactId));
            this._saveSelection.next([...this._selectedContactIds.value]);

            this.db
                .collection("userSelection")
                .doc(this.authService.loggedInUser.id)
                .set({ selection: this._selectedContactIds.value });
        }
    }

    addSelection(contactId: string): void {
        if (!this._disabled) {
            this._selectedContactIds.next([...this._selectedContactIds.value, contactId]);
            this._saveSelection.next([...this._selectedContactIds.value]);

            this.db
                .collection("userSelection")
                .doc(this.authService.loggedInUser.id)
                .set({ selection: this._selectedContactIds.value });
        }
    }

    setSelection(contactIds: string[]): void {
        if (!this._disabled) {
            this._selectedContactIds.next([...contactIds]);
            this._saveSelection.next([...this._selectedContactIds.value]);
            this.db
                .collection("userSelection")
                .doc(this.authService.loggedInUser.id)
                .set({ selection: this._selectedContactIds.value });
        }
    }
}