import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { access } from 'fs';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiClient } from 'src/modules/core/api-clients/api-client';

import { DEFAULT_ACCESS, DEFAULT_DEPARTMENT, User } from '../model/user';

@Injectable({
    providedIn: 'root'
})
export class UserApiClientService implements ApiClient {

    constructor(private db: AngularFirestore) { }


    documentReference(id: string): DocumentReference {
        return this.db.collection("users").doc(id).ref;
    }

    user(id: string): Observable<User> {
        return this.db.doc<User>(`users/${id}`).valueChanges().pipe(map((userDTO: any) => {
            const lastLogin = userDTO && userDTO.lastLogin && userDTO.lastLogin.seconds ? userDTO.lastLogin.seconds * 1000 : 0;
            if (lastLogin) {
                return new User({
                    id,
                    email: userDTO.email,
                    lastLogin,
                    access: userDTO.access,
                    selectedDepartmentApplication: userDTO.selectedDepartmentApplication || null
                });
            } else {
                return undefined;
            }
        }));
    }

    addUser(user: User): Observable<User> {
        const userDTO = {
            email: user.email,
            new: true,
            lastLogin: new Date(),
            access: DEFAULT_ACCESS,
            selectedDepartmentApplication: DEFAULT_DEPARTMENT
        };

        const doc = this.db.doc(`users/${user.id}`);

        return from(doc.get()).pipe(map(firestoreDocument => {
            if (!firestoreDocument.exists) {
                doc.set(userDTO);
            }
            return new User({
                id: user.id,
                email: user.email,
                lastLogin: Math.round(userDTO.lastLogin.getTime() / 1000),
                access: user.access,
                selectedDepartmentApplication: user.selectedDepartmentApplication
            });
        }));
    }
}
