import { guid } from 'src/app/core/functions';

import { Bookmark } from '../services/bookmark.service';
import { SearchGroup } from './search-group';
import { SearchOperation } from './search-operation';

export const DEFAULT_SORT: SortDefinition = {
    field: "lastUpdatedAt",
    direction: "desc",
};
export const DEFAULT_LIMIT = 50;
export const DEFAULT_FULL_TEXT_SEARCH = new SearchOperation({ id: guid(), value: "", operator: "exact" });
export const DEFAULT_SEARCH_GROUP = new SearchGroup({
    operator: "OR",
    searchOperations: null,
    searchGroups: [new SearchGroup({ operator: "AND", searchOperations: [] })],
});

export interface SearchDefinitionBuilder {
    id?: string;
    bookmarkSpec?: BookmarkSpec;
    projectId?: string;
    searchGroup?: SearchGroup;
    excludeContactIds?: string[];
    datasource?: string;
}

export class SearchDefinition {
    private _id: string;
    private _bookmarkSpec: BookmarkSpec;
    private _projectId: string;
    private _searchGroup: SearchGroup;
    private _excludeContactIds: string[];
    private _datasource: string;

    static DEFAULT_SEARCH_DEFINITION(id: string): SearchDefinition {
        return new SearchDefinition({
            id,
            searchGroup: DEFAULT_SEARCH_GROUP,
            excludeContactIds: [],
        });
    }

    static DEFAULT_SEARCH_DEFINITION_FORM(id: string, formId: string): SearchDefinition {
        return new SearchDefinition({
            id,
            searchGroup: new SearchGroup({
                operator: "OR",
                searchOperations: null,
                searchGroups: [new SearchGroup({ operator: "AND", searchOperations: [] }).addFormSearchOperation(formId)],
            }),
        });
    }

    static DEFAULT_SEARCH_DEFINITION_CONTACT_IDS(contactIds: string[]): SearchDefinition {
        return new SearchDefinition({
            id: guid(),
            searchGroup: new SearchGroup({
                operator: "OR",
                searchOperations: null,
                searchGroups: [new SearchGroup({
                    operator: "AND", searchOperations: [new SearchOperation({
                        id: guid(),
                        operand: "contactId",
                        operator: "exact",
                        value: contactIds.join(" ")
                    })]
                })]
            }),
        });

    }

    static DEFAULT_SEARCH_DEFINITION_NODE(nodeId: string): SearchDefinition {
        return new SearchDefinition({
            id: guid(),
            searchGroup: new SearchGroup({
                operator: "OR",
                searchOperations: null,
                searchGroups: [new SearchGroup({
                    operator: "AND", searchOperations: [new SearchOperation({
                        id: guid(),
                        operand: `contextNode_${nodeId}`,
                        operator: "boolean",
                        value: true
                    })]
                })]
            }),
        });
    }


    constructor(builder: SearchDefinitionBuilder) {
        this._id = builder.id;
        this._bookmarkSpec = builder.bookmarkSpec || null;
        this._projectId = builder.projectId || null;
        this._searchGroup = builder.searchGroup || new SearchGroup({ operator: "AND" });
        this._excludeContactIds = builder.excludeContactIds || [];
        this._datasource = builder.datasource;
    }

    get id(): string {
        return this._id;
    }
    get bookmarkSpec(): BookmarkSpec {
        return this._bookmarkSpec;
    }
    get searchGroup(): SearchGroup {
        return this._searchGroup;
    }
    get excludeContactIds(): string[] {
        return this._excludeContactIds;
    }
    get datasource(): string {
        return this._datasource;
    }
    get isEmpty(): boolean {
        return this.searchGroup.isEmpty;
    }
    get projectId(): string {
        return this._projectId;
    }
    get searchOperations(): SearchOperation[] {
        return this.searchGroup ? this.searchGroup.allSearchOperations() : [];
    }

    setContextNode(nodeId: string): SearchDefinition {
        return this.replaceAddSearchOperations(
            [new SearchOperation({
                id: guid(),
                operand: `contextNode_${nodeId}`,
                operator: "boolean",
                value: true
            })]
        )
    }

    viewGroupResults(groupId: string): SearchDefinition {
        return this.change({
            searchGroup: this.searchGroup.change({
                searchGroups: this.searchGroup.searchGroups.map((sg) =>
                    sg.id === groupId ? sg.enable() : sg.disable()
                ),
            }),
        });
    }

    hasSearchOperationOperand(operand: string): boolean {
        return this.searchGroup.hasOperationOperand(operand);
    }

    removeSearchOperationOperand(operand: string): SearchDefinition {
        return this.change({
            searchGroup: this.searchGroup.change({
                searchGroups: this.searchGroup.searchGroups.map(sg => sg.removeSearchOperationOperand(operand))
            })
        })
    }

    setSearchOperationsState(isDisabled: boolean): SearchDefinition {
        return this.change({
            searchGroup: this.searchGroup.setSearchOperationsState(isDisabled),
        });
    }

    changeSearchOperations(sops: SearchOperation[]): SearchDefinition {
        return this.change({ searchGroup: this.searchGroup.replaceAddSearchOperations(sops) });
    }

    addSearchGroup(group: SearchGroup): SearchDefinition {
        return this.change({
            searchGroup: this.searchGroup.addSearchGroup(group),
        });
    }
    removeSearchGroupIndex(groupIndex: number): SearchDefinition {
        return this.change({
            searchGroup: this.searchGroup.removeSearchGroupIndex(groupIndex),
        });
    }

    eraseReset(): SearchDefinition {
        return this.change({
            searchGroup: DEFAULT_SEARCH_GROUP,
        });
    }

    editGroupIndex(searchGroup: SearchGroup, groupIndex: number): SearchDefinition {
        return this.change({
            searchGroup: this.searchGroup.change({
                searchGroups: this.searchGroup.searchGroups.map((sg, i) => {
                    return i === groupIndex ? searchGroup : sg;
                }),
            }),
        });
    }

    change(builder: SearchDefinitionBuilder): SearchDefinition {
        return new SearchDefinition({
            id: this._id,
            bookmarkSpec: this._bookmarkSpec,
            projectId: this._projectId,
            searchGroup: this._searchGroup,
            excludeContactIds: this._excludeContactIds,
            datasource: this._datasource,
            ...builder,
        });
    }

    // availabilities(): DateRange[] {
    //     const availabilitySop = this.searchOperations.find((sop) => sop.type === "availability");
    //     if (availabilitySop) {
    //         return availabilitySop.value || [];
    //     } else {
    //         return [];
    //     }
    // }

    // get partialSearchOperationEnabled(): boolean {
    //     const allSops = this.hasFullTextSearch()
    //         ? [this.fullTextSearch, ...this.searchOperations]
    //         : this.searchOperations;
    //     return allSops.some((sop) => !sop.disabled) && allSops.some((sop) => sop.disabled);
    // }

    // get noSearchOperationEnabled(): boolean {
    //     const allSops = this.hasFullTextSearch()
    //         ? [this.fullTextSearch, ...this.searchOperations]
    //         : this.searchOperations;

    //     return allSops.length > 0 && allSops.every((sop) => sop.disabled);
    // }

    replaceAddSearchOperations(toChangeSearchOperations: SearchOperation[]): SearchDefinition {
        return this.change({ searchGroup: this.searchGroup.change({ searchGroups: this.searchGroup.searchGroups.map(sg => sg.replaceAddSearchOperations(toChangeSearchOperations)) }) });
    }

    replaceAddSearchOperationsForSearchGroup(
        toChangeSearchOperations: SearchOperation[],
        indexOfGroup: number = 0
    ): SearchDefinition {
        return this.change({
            searchGroup: this.searchGroup.change({
                searchGroups: this.searchGroup.searchGroups.map((sg, index) => {
                    if (index === indexOfGroup) {
                        return sg.replaceAddSearchOperations(toChangeSearchOperations);
                    }
                    return sg;
                }),
            }),
        });
    }

    hasFormSearchOperation(): boolean {
        return this.searchGroup && this.searchGroup.hasFormSearchOperation();
    }

    formSearchOperation(): SearchOperation {
        return this.searchGroup.formSearchOperation();
    }

    removeFormSearchOperations(): SearchDefinition {
        return this.change({
            searchGroup: this.searchGroup ? this.searchGroup.removeFormSearchOperations() : null,
        });
    }

    disableSearchOperation(searchOperarionId: string): SearchDefinition {
        return this.change({
            searchGroup: this.searchGroup.disableSearchOperation(searchOperarionId),
        });
    }

    removeSearchOperation(searchOperarionId: string): SearchDefinition {
        return this.change({
            searchGroup: this.searchGroup.removeSearchOperation(searchOperarionId),
        });
    }

    searchOperationByName(name: string): SearchOperation {
        return this.searchGroup ? this.searchGroup.searchOperationByName(name) : null;
    }
}

export interface SearchPaginationBuilder {
    sort?: SortDefinition;
    limit?: number;
    page?: number;
}
export class SearchPagination {
    private _sort: SortDefinition;
    private _limit: number;
    private _page: number;

    static get DEFAULT_PAGINATION(): SearchPagination {
        return new SearchPagination();
    }

    constructor(builder?: SearchPaginationBuilder) {
        this._sort = builder?.sort || DEFAULT_SORT;
        this._limit = builder?.limit || DEFAULT_LIMIT;
        this._page = builder?.page || 1;
    }

    get sort(): SortDefinition {
        return this._sort;
    }
    get limit(): number {
        return this._limit;
    }
    get page(): number {
        return this._page;
    }

    nextPage(): SearchPagination {
        return this.change({ page: this._page + 1 });
    }

    change(builder: SearchPaginationBuilder): SearchPagination {
        return new SearchPagination({
            sort: this._sort,
            limit: this._limit,
            page: this._page,
            ...builder,
        });
    }
}

export interface SortDefinition {
    field: string;
    direction: "desc" | "asc";
}

export interface DateRange {
    from: Date;
    to: Date;
}

export interface BookmarkSpec {
    new: boolean;
    bookmark: Bookmark;
}