import { guid } from 'src/app/core/functions';

import { DEFAULT_FULL_TEXT_SEARCH } from './search-definition';
import { SearchOperation } from './search-operation';

export interface SearchGroupBuilder {
    id?: string;
    operator?: SearchGroupOperator;
    fullTextSearch?: SearchOperation;
    searchOperations?: SearchOperation[];
    searchGroups?: SearchGroup[];
    disabled?: boolean;
}
export class SearchGroup {
    private _id: string;
    private _fullTextSearch: SearchOperation;
    private _operator: SearchGroupOperator;
    private _searchOperations: SearchOperation[];
    private _searchGroups: SearchGroup[];
    private _disabled: boolean;

    constructor(builder?: SearchGroupBuilder) {
        this._id = builder?.id || guid();
        this._operator = builder?.operator || "AND";
        this._fullTextSearch = builder?.fullTextSearch || DEFAULT_FULL_TEXT_SEARCH;
        this._searchOperations = builder?.searchOperations || [];
        this._searchGroups = builder?.searchGroups || null;
        this._disabled = builder?.disabled === true ? true : false;
    }

    get id(): string {
        return this._id;
    }
    get isEmpty(): boolean {
        return !this.searchGroups && this.searchOperations.length < 1;
    }
    get operator(): SearchGroupOperator {
        return this._operator;
    }
    get fullTextSearch(): SearchOperation {
        return this._fullTextSearch;
    }
    get searchOperations(): SearchOperation[] {
        return this._searchOperations;
    }
    get searchGroups(): SearchGroup[] {
        return this._searchGroups;
    }
    get disabled(): boolean {
        return this._disabled;
    }

    enable(): SearchGroup {
        return this.change({
            disabled: false,
        });
    }
    disable(): SearchGroup {
        return this.change({
            disabled: true,
        });
    }

    clearSearchOperations(): SearchGroup {
        return this.change({
            searchOperations: [],
            fullTextSearch: DEFAULT_FULL_TEXT_SEARCH
        })
    }

    allSearchOperations(): SearchOperation[] {
        const ops = [...(this.searchOperations ? this.searchOperations : []), ...this.subGroupsSearchOperations()];
        if (this.hasFullTextSearch()) {
            ops.unshift(this._fullTextSearch);
        }
        return ops;
    }

    hasOperationOperand(operand: string): boolean {
        if (this.searchGroups && this.searchGroups.length > 0) {
            return this.searchGroups.find(sg => sg.hasOperationOperand(operand)) ? true : false;
        } else {
            return this.searchOperations.find(sop => sop.operand === operand) ? true : false;
        }
    }

    removeSearchOperationOperand(operand: string): SearchGroup {
        return this.change({
            searchOperations: this.searchOperations.filter(sop => sop.operand !== operand)
        })
    }

    private subGroupsSearchOperations(): SearchOperation[] {
        if (this.searchGroups) {
            return this.searchGroups
                .map((sg) => (sg.searchOperations ? sg.allSearchOperations() : sg.subGroupsSearchOperations()))
                .reduce((acc, val) => acc.concat(val), []);
        }
        return [];
    }

    setSearchOperationsState(isDisabled: boolean): SearchGroup {
        return this.change({
            searchGroups: this.setSubGroupsSearchOperationsState(isDisabled),
            fullTextSearch: this._fullTextSearch.setState(isDisabled),
            searchOperations: this.searchOperations
                ? this.searchOperations.map((sop) => sop.setState(isDisabled))
                : null,
        });
    }

    changeFullTextValue(value: string): SearchGroup {
        return this.change({
            fullTextSearch: this._fullTextSearch.change({ value }),
        });
    }

    formSearchOperation(): SearchOperation {
        const sopsHasFormSop = this.searchOperations && this.searchOperations.find((sop) => sop.operand.startsWith("recruitment_"))
        if (sopsHasFormSop) {
            return sopsHasFormSop;
        } else {
            const sg = this.searchGroups.find((sg) => sg.hasFormSearchOperation())
            if (sg) {
                return sg.formSearchOperation()
            }
        }
    }

    addFormSearchOperation(formId: string, meta?: { onlyEmailRespondents?: boolean, sinceDateSubmision?: Date }): SearchGroup {
        return this.removeFormSearchOperations().addFormId(formId, meta);
    }

    private addFormId(formId: string, meta?: { onlyEmailRespondents?: boolean, sinceDateSubmision?: Date }): SearchGroup {
        let searchGroup: SearchGroup;
        if (meta?.sinceDateSubmision) {
            searchGroup = this.replaceAddSearchOperations([this.formSearchOperationSince(formId, meta.sinceDateSubmision)]);
        } else {
            searchGroup = this.replaceAddSearchOperations([
                this.formDefaultSearchOperation(formId)
            ]);
            if (meta?.onlyEmailRespondents) {
                searchGroup = searchGroup.replaceAddSearchOperations([this.formSearchOperationEmailRespondant(formId)]);
            }
        }
        return searchGroup;
    }

    private formDefaultSearchOperation(formId: string): SearchOperation {
        return new SearchOperation({ id: guid(), operand: `recruitment_${formId}`, operator: "exists" });
    }

    private formSearchOperationEmailRespondant(formId: string): SearchOperation {
        return new SearchOperation({
            id: guid(),
            operand: `recruitment_email_${formId}`,
            operator: "boolean",
            value: true,
        })
    }

    private formSearchOperationSince(formId: string, sinceDate: Date): SearchOperation {
        return new SearchOperation({ id: guid(), operand: `recruitment_${formId}`, operator: "greaterThan", value: sinceDate })
    }

    hasFullTextSearch(): boolean {
        return this._fullTextSearch && this._fullTextSearch.value && this._fullTextSearch.value !== "";
    }

    private setSubGroupsSearchOperationsState(isDisabled: boolean): SearchGroup[] {
        return this.searchGroups ? this.searchGroups.map((sg) => sg.setSearchOperationsState(isDisabled)) : null;
    }

    replaceAddSearchOperations(toChangeAddSearchOperations: SearchOperation[]): SearchGroup {
        const changeFormSearchOperation = toChangeAddSearchOperations.find(sop => sop.operand?.startsWith("recruitment_") ? true : false);
        if (changeFormSearchOperation) {
            this._searchOperations = this._searchOperations.filter(sop => !sop.operand.startsWith("recruitment_"));
        }

        const editedCurrentSearchOperations = this.searchOperations.map((currentSop) => {
            const toChangeSop = toChangeAddSearchOperations.find(
                (toChange) => toChange.operand === currentSop.operand
            );
            return toChangeSop ? toChangeSop : currentSop;
        });
        const newSearchOperation = toChangeAddSearchOperations.filter((toChangeSop) => {
            return !this.searchOperations.find((currentSop) => currentSop.operand === toChangeSop.operand);
        });

        const editedSops = [...editedCurrentSearchOperations, ...newSearchOperation].filter((sop) =>
            sop.hasValue()
        );

        if (this.hasFullTextSearch()) {
            const fullTextInToChangeSearchOperations = toChangeAddSearchOperations.find(
                (sop) => sop.id === this.fullTextSearch.id
            );
            if (fullTextInToChangeSearchOperations) {
                return this.change({ fullTextSearch: fullTextInToChangeSearchOperations }).change({
                    searchOperations: editedSops,
                });
            }
        }
        return this.change({
            searchOperations: editedSops,
        });
    }

    hasFormSearchOperation(): boolean {
        const sopsHasFormSop =
            this.searchOperations && this.searchOperations.find((sop) => sop.operand && sop.operand.startsWith("recruitment_"))
                ? true
                : false;
        if (sopsHasFormSop) {
            return true;
        } else {
            return this.searchGroups?.find((sg) => sg.hasFormSearchOperation()) ? true : false;
        }
    }

    removeFormSearchOperations(): SearchGroup {
        return this.change({
            searchGroups: this.searchGroups ? this.removeSubGroupFormSearchOperations() : null,
            searchOperations: this.searchOperations
                ? this.searchOperations.filter((sop) => !sop.operand.startsWith("recruitment_"))
                : null,
        });
    }

    private removeSubGroupFormSearchOperations(): SearchGroup[] {
        return this.searchGroups ? this.searchGroups.map((sg) => sg.removeFormSearchOperations()) : null;
    }

    removeSearchOperation(searchOperationId: string): SearchGroup {
        if (this.fullTextSearch && this.fullTextSearch.id === searchOperationId) {
            return this.change({ fullTextSearch: DEFAULT_FULL_TEXT_SEARCH });
        }

        return this.change({
            searchGroups: this.searchGroups ? this.removeSubGroupSearchOperation(searchOperationId) : null,
            searchOperations: this.searchOperations
                ? this.searchOperations.filter((sop) => sop.id !== searchOperationId)
                : null,
        });
    }

    private removeSubGroupSearchOperation(searchOperationId: string): SearchGroup[] {
        return this.searchGroups ? this.searchGroups.map((sg) => sg.removeSearchOperation(searchOperationId)) : null;
    }

    disableSearchOperation(searchOperationId: string): SearchGroup {
        if (this.fullTextSearch && this.fullTextSearch.id === searchOperationId) {
            return this.change({ fullTextSearch: this.fullTextSearch.toggleDisabled() });
        }
        return this.change({
            searchOperations: this.searchOperations.map((sop) =>
                sop.id === searchOperationId ? sop.toggleDisabled() : sop
            ),
        });
    }

    searchOperationByName(name: string): SearchOperation {
        if (this.searchOperations && this.searchOperations.length > 0) {
            const foundSop = this.searchOperations.find((sop) => sop.operand === name);
            return foundSop;
        }
        if (this.searchGroups) {
            const sg = this.searchGroups.find((sg) => sg.searchOperationByName(name));
            if (sg) {
                return sg.searchOperationByName(name);
            }
        }
    }

    addSearchGroup(group: SearchGroup): SearchGroup {
        return this.change({
            searchGroups: this.searchGroups ? [...this.searchGroups, group] : [group],
        });
    }

    removeSearchGroupIndex(groupIndex: number): SearchGroup {
        return this.change({
            searchGroups: this.searchGroups.filter((_, index) => index !== groupIndex),
        });
    }

    change(builder: SearchGroupBuilder): SearchGroup {
        return new SearchGroup({
            id: this._id,
            operator: this._operator,
            fullTextSearch: this._fullTextSearch,
            searchGroups: this._searchGroups,
            searchOperations: this._searchOperations,
            disabled: this._disabled,
            ...builder,
        });
    }
}

export type SearchGroupOperator = "AND" | "OR";
