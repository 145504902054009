<div class="merge-content-container">
    <div class="merge-content">
        <div class="contact-column">
            <input
                (keyup)="onIdfig1Change($event.target.value)"
                placeholder="idfig"
                autocomplete="off"
                [(ngModel)]="idfig1"
                clrInput
                type="text"
            />

            <diversite-contact-search-profile
                *ngIf="contact1"
                [contactId]="contact1.id"
            ></diversite-contact-search-profile>

            <div *ngIf="!contact1" class="empty-profile">(aucun profil selectionné)</div>

            <clr-radio-wrapper>
                <input
                    type="radio"
                    clrRadio
                    [disabled]="!contact1"
                    [value]="contact1?.contact?.idfig"
                    [(ngModel)]="profileToKeep"
                    name="keepProfil"
                />
                <label>Conserver ce profil</label>
            </clr-radio-wrapper>
        </div>
        <div class="contact-column">
            <input
                (keyup)="onIdfig2Change($event.target.value)"
                placeholder="idfig"
                autocomplete="off"
                [(ngModel)]="idfig2"
                clrInput
                type="text"
            />
            <diversite-contact-search-profile
                *ngIf="contact2"
                [contactId]="contact2.id"
            ></diversite-contact-search-profile>
            <div *ngIf="!contact2" class="empty-profile">(aucun profil selectionné)</div>
            <clr-radio-wrapper>
                <input
                    type="radio"
                    clrRadio
                    [disabled]="!contact2"
                    [value]="contact2?.contact?.idfig"
                    [(ngModel)]="profileToKeep"
                    name="keepProfil"
                />
                <label>Conserver ce profil</label>
            </clr-radio-wrapper>
        </div>
    </div>
    <div class="bottom">
        <button (click)="cancel()" type="button" class="btn btn-sm">Cancel</button>
        <button
            (click)="confirmContactMerge()"
            type="button"
            [disabled]="!contact1 || !contact2 || !profileToKeep || loading"
            class="btn btn-primary btn-sm"
        >
            Fusionner
        </button>
    </div>
</div>
