import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { debounceTime, skip, take } from "rxjs/operators";
import { NotificationService } from "src/modules/core/services/notification.service";
import { ContactService } from "src/modules/diversite/services/contact.service";
import { ContactsDatabase } from "src/modules/diversite/services/contacts-databases.service";
import { ContactPublic } from "src/modules/fillout/services/recruit.service";

@Component({
    selector: "diversite-add-contact-action",
    templateUrl: "./add-contact-action.component.html",
    styleUrls: ["./add-contact-action.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddContactActionComponent implements OnInit, AfterViewInit {
    @Input() databases: ContactsDatabase[] = [];
    @Input() selectedDatabaseId = "";

    @Output() close = new EventEmitter<void>();

    selectionChanged$ = new BehaviorSubject<string>(undefined);

    currentHighlightField: string;

    loading = false;
    tempContact = {
        identity_firstname: "",
        identity_lastname: "",
        contactInformation_email: "",
        contactInformation_mainPhone: {
            areaCode: null,
            localPrefix: null,
            localSuffix: null,
        },
        identity_gender: "",
        identity_dateOfBirth: null,
    };

    constructor(
        private contactService: ContactService,
        private notificationService: NotificationService,
        private chRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.selectionChanged$.pipe(skip(1), debounceTime(500)).subscribe((v) => {
            if (this.currentHighlightField === "contactInformation_mainPhone") {
                const matchAnyNumber = v.match(/\d/g);
                const stripAlpha = matchAnyNumber ? matchAnyNumber.join("") : "";

                const areaCode = stripAlpha.slice(0, 3);
                const localPrefix = stripAlpha.slice(3, 6);
                const localSuffix = stripAlpha.slice(6, 10);

                this.tempContact.contactInformation_mainPhone = {
                    areaCode,
                    localPrefix,
                    localSuffix,
                };
            } else {
                this.tempContact[this.currentHighlightField] = v;
            }
            this.currentHighlightField = undefined;
            this.chRef.markForCheck();
        });
    }

    ngAfterViewInit(): void {
        document.addEventListener("selectionchange", () => {
            this.handleSelection();
        });
    }

    cancelSelection(): void {
        this.currentHighlightField = undefined;
    }

    onSubmit(): void {
        const contact: ContactPublic = this.createContactFromForm();
        this.loading = true;
        console.log(contact);

        this.contactService
            .createContactManually(contact, {
                origin: `${window.location.origin}/#`,
            })
            .pipe(take(1))
            .subscribe((_) => {
                this.loading = false;
                this.notificationService.show(
                    "Le membre à été ajouté avec succès. Il peut y avoir un certain délai avant qu'il n'apparaisse dans la liste.",
                    "info"
                );
                this.close.emit();
            });
    }

    trackById(_: any, entity: any): string {
        return entity.id;
    }

    get toHighlight(): string {
        if (this.currentHighlightField === "identity_firstname") {
            return "prénom";
        }
        if (this.currentHighlightField === "identity_lastname") {
            return "nom";
        }

        if (this.currentHighlightField === "contactInformation_email") {
            return "courriel";
        }

        if (this.currentHighlightField === "contactInformation_mainPhone") {
            return "téléphone principal";
        }

        return "";
    }

    cancel(): void {
        this.selectedDatabaseId = "";
        this.close.emit();
    }

    highlight(index: string): void {
        this.currentHighlightField = index;
    }

    private handleSelection(): void {
        const activeElement = document.activeElement as HTMLTextAreaElement;

        if (activeElement && activeElement.id === "copied-text") {
            const range = {
                start: activeElement.selectionStart,
                end: activeElement.selectionEnd,
            };

            if (range.start !== range.end) {
                const value = activeElement.value;
                const selectedValue = value.slice(range.start, range.end);
                if (selectedValue.trim() !== "" && this.currentHighlightField) {
                    this.selectionChanged$.next(selectedValue);
                }
            }
        }
    }

    private createContactFromForm(): ContactPublic {
        return {
            attributes: Array.from(Object.keys(this.tempContact)).map((key) => {
                return { index: key, value: this.tempContact[key] };
            }),
        } as ContactPublic;
    }
}
