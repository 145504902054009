import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Activity } from 'src/modules/diversite/services/activity.service';

@Component({
    selector: 'diversite-merge-profiles-activity',
    templateUrl: './merge-profiles-activity.component.html',
    styleUrl: './merge-profiles-activity.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MergeProfilesActivityComponent {
    @Input() activity: Activity;
}
