import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { weightLiteral } from "src/app/core/functions";

import { SearchValue } from "../../search-value-selector.component";

@Component({
    selector: "search-value-weight",
    templateUrl: "./search-value-weight.component.html",
    styleUrls: ["./search-value-weight.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchValueWeightComponent implements OnInit {
    @Input() searchValue: SearchValue;
    @Output() searchValueChange = new EventEmitter<SearchValue>();

    constructor() {}

    ngOnInit(): void {}

    onWeightChange(): void {
        this.searchValueChange.emit(this.searchValue);
    }

    weight(index: "min" | "max"): string {
        if (index === "min" && this.searchValue.valueMin) {
            return weightLiteral(this.searchValue.valueMin);
        } else if (index === "max" && this.searchValue.valueMax) {
            return weightLiteral(this.searchValue.valueMax);
        }
        return " ";
    }

    erase(mm: "min" | "max"): void {
        if (mm === "min") {
            this.searchValue.valueMin = undefined;
        }
        if (mm === "max") {
            this.searchValue.valueMax = undefined;
        }
        this.searchValueChange.emit(this.searchValue);
    }
}
