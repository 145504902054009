import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import { forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { catchError, concatMap, map, switchMap, take, tap } from 'rxjs/operators';
import { isDateInRange } from 'src/app/core/functions';

import { Contact } from '../model/contact';
import { ContactImage } from '../model/contact-image';
import { ContactPerspective } from '../model/contactPerspective';

@Injectable({
    providedIn: "root",
})
export class DownloadAssetsService {
    private downloadSub: Subscription;

    constructor() { }

    downloadPhotosForContact(cp: ContactPerspective, imageOption: SelectedImageOption = { type: "all" }): Observable<void> {
        const zip = new JSZip();
        const name = `${cp.contact.idfig}_${this.normalize(cp.contact.fullname)}_${formatDate(
            Date.now(),
            "yyyy-MM-dd",
            "en-US"
        )}`;
        const exportMain = zip.folder(`${cp.contact.idfig}_${this.normalize(cp.contact.fullname)}`);

        let imagesToCompile: string[];
        if (imageOption.type === "selected") {
            imagesToCompile = cp.visibleImages
                .map((imageId) => {
                    const image = cp.contact.images.find((img) => {
                        return imageId === img.id;
                    });
                    if (image) {
                        return image.url ? image.url : image.originalUrl;
                    }
                })
                .filter((url) => url);
        } else {
            imagesToCompile = cp.contact.images
                .map((img) => (img.url ? img.url : img.originalUrl))
                .filter((url) => url);
        }

        return forkJoin(imagesToCompile.map((url) => this.urlToFile(url))).pipe(
            map((files) => files.filter((f) => f)),
            switchMap((files: File[]) => {
                files.forEach((file, i) => {
                    exportMain.file(`${cp.contact.idfig}_${this.normalize(cp.contact.fullname)}_${i}.jpg`, file, {
                        base64: true,
                    });
                });
                return this.downloadBundle(name, zip);
            })
        );
    }

    downloadBundleForContacts(
        folderName: string,
        contactPerspectives: ContactPerspective[],
        imageOption: SelectedImageOption = { type: "all" },
        fileToInclude?: File
    ): Observable<string> {
        const zip = new JSZip();
        const name = `${folderName}`;
        const mainFolder = zip.folder(name);
        const obs = new Observable<string>((subscriber) => {
            subscriber.next("Starting bundle");
            this.downloadSub = forkJoin(
                contactPerspectives
                    .filter((cp) => cp && cp.contact)
                    .map((cp) => {
                        let imagesToCompile: ContactImage[];

                        if (imageOption.type === "selected") {
                            imagesToCompile = cp.visibleImages
                                .map((imageId) => {
                                    const image = cp.contact.images.find((img) => {
                                        return imageId === img.id;
                                    });
                                    if (image) {
                                        return image;
                                    }
                                })
                                .filter((image) => image.url ? image.url : image.originalUrl);
                        } else if (imageOption.type === "dateRange") {
                            imagesToCompile = cp.contact.images
                                .filter(img => {
                                    const date = img.uploadedAt;
                                    return date && isDateInRange(date, imageOption.data.dateRange[0], imageOption.data.dateRange[1]);
                                })
                                .filter((image) => image.url ? image.url : image.originalUrl);
                        } else if (imageOption.type === "all") {
                            imagesToCompile = cp.contact.images.filter((image) => image.url ? image.url : image.originalUrl);
                        }

                        if (imagesToCompile.length > 0) {
                            return forkJoin(imagesToCompile.map((image) => {
                                let finalUrl: string;
                                if (!imageOption.data?.optimizedImages) {
                                    finalUrl = image.originalUrl;
                                } else {
                                    finalUrl = image.url ? image.url : image.originalUrl;
                                }
                                return this.urlToFile(finalUrl).pipe(map(file => {
                                    return {
                                        file, image
                                    }
                                }));
                            })).pipe(
                                map((files: {
                                    file: File;
                                    image: ContactImage;
                                }[]) => files.filter((f) => f.file && f.image)),
                                map((imagesForContact: {
                                    file: File;
                                    image: ContactImage;
                                }[]) => {
                                    subscriber.next(`Contact ${cp.contact.fullname} urls fetching done`);
                                    return { contact: cp.contact, files: imagesForContact };
                                })
                            );
                        } else {
                            return of({ contact: cp.contact, files: [] });
                        }
                    })
            )
                .pipe(
                    switchMap((dataList: {
                        contact: Contact;
                        files: {
                            file: File;
                            image: ContactImage;
                        }[];
                    }[]) => {
                        dataList.forEach((data) => {
                            // const member = mainFolder.folder(
                            //     `${data.contact.idfig}_${this.normalize(data.contact.fullname)}`
                            // );
                            data.files.forEach((imageData, i) => {
                                const date = imageData.image.originalDate ? imageData.image.originalDate : imageData.image.uploadedAt;
                                mainFolder.file(
                                    this.imageName(data.contact.idfig, data.contact.fullname, date, i),
                                    imageData.file,
                                    {
                                        base64: true,
                                    }
                                );
                            });
                        });

                        if (fileToInclude) {
                            mainFolder.file(`${name}.xlsx`, fileToInclude);
                        }

                        subscriber.next("Bundle assembling done");
                        subscriber.next("Starting download compiling...");
                        return this.downloadBundle(name, zip).pipe(
                            tap((_) => {
                                subscriber.next("Download compiled successfully");
                                subscriber.complete();
                            })
                        );
                    })
                )
                .pipe(take(1))
                .subscribe();
        });

        return obs;
    }


    imageName(idfig: string, fullname: string, dateImage: Date, index?: number): string {
        const indexFull = index || index === 0 ? `_${index}` : "";
        return `${idfig}_${this.normalize(fullname)}_${formatDate(dateImage, "yyyy-MM-dd", "en-US")}${indexFull}.jpg`
    }

    downloadImage(contact: Contact, imageId: string): void {
        const image: ContactImage = contact.images.find(img => img.id === imageId);
        const url = image.originalUrl;

        fetch(url)
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = this.imageName(contact.idfig, contact.fullname, image.uploadedAt);
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(() => alert('An error sorry'));

    }

    cancelDownload(): void {
        if (this.downloadSub) {
            this.downloadSub.unsubscribe();
            this.downloadSub = undefined;
        }
    }

    private downloadBundle(name: string, zip: JSZip): Observable<void> {
        return from(zip.generateAsync({ type: "blob" })).pipe(
            tap((content) => {
                FileSaver.saveAs(content, `${name}.zip`);
            }),
            map((_) => undefined)
        );
    }

    private urlToFile(url: string): Observable<File> {
        return from(fetch(url)).pipe(
            // filter((r) => r.status === 200),
            catchError((e) => {
                console.error(`Could not fetch ${url}`);
                return of(undefined);
            }),
            concatMap((response: Response) => {
                if (response) {
                    return from(response.blob()).pipe(
                        map((blob) => {
                            // create a new file from the blob object
                            const imgData = new File([blob], "0.jpg");
                            return imgData;
                        })
                    );
                } else {
                    return of(undefined);
                }
            })
        );
    }

    private normalize(toNormalize: string): string {
        return toNormalize.replace(",", "").normalize().toLocaleLowerCase().trim().replace(/ /g, "_");
    }
}

export interface SelectedImageOption {
    type: SelectedImageOptionType;
    data?: any;
}

export type SelectedImageOptionType = "all" | "selected" | "dateRange";
