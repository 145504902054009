<div class="add-idfig-list-container">
    <clr-select-container>
        <label>Projet</label>
        <select clrSelect name="options" [(ngModel)]="projectId">
            <option value="">-</option>
            <option *ngFor="let project of projects$ | async" [value]="project.id">{{ project.name }}</option>
        </select>
    </clr-select-container>

    <div class="idfigs-container">
        <clr-textarea-container>
            <label>Liste d'idfig</label>
            <textarea (keyup)="onIdfigKeytype($event.target.value)" clrTextarea [(ngModel)]="idfigsRaw"></textarea>
            <clr-control-helper>Ecrire et/ou copier/coller une liste d'idfig ici.</clr-control-helper>
        </clr-textarea-container>
        <div class="parsed-idfigs" *ngIf="parsedIdfigs$ | async as parsedIdfigs">
            <label class="clr-control-label"
                >Idfigs finaux <span *ngIf="parsedIdfigs.length > 0">({{ parsedIdfigs.length }})</span></label
            >
            <div class="idfigs-final">
                <span *ngFor="let idfig of parsedIdfigs">{{ idfig }}</span>
            </div>
        </div>
    </div>
    <div class="folder-container">
        <clr-input-container>
            <label>Nom du dossier</label>
            <input clrInput required [(ngModel)]="folderName" />
        </clr-input-container>
    </div>
    <clr-spinner clrMedium [hidden]="!isCreating"></clr-spinner>
</div>

<div class="actions">
    <button
        (click)="addFolderAndContacts()"
        [disabled]="!valid() || isCreating"
        type="button"
        class="btn btn-sm btn-primary"
    >
        Ajouter un dossier
    </button>
    <button type="button" class="btn btn-sm" (click)="onCancel()">Cancel</button>
</div>
