<button class="btn btn-sm" (click)="onOpenChange(true)">Ajouter un element de formulaire</button>

<div class="modules">
    <div class="module" *ngFor="let module of modules$ | async">
        <table class="table table-sm table-compact">
            <tr>
                <th class="left"></th>
                <th class="left" colspan="4">
                    <strong>{{ module.name["en"] }}/{{ module.name["fr"] }}</strong>
                </th>
            </tr>
            <tr>
                <th class="left"></th>
                <th class="left">index</th>
                <th class="left">type</th>
                <th class="left">label fr</th>
                <th class="left">label en</th>
            </tr>
            <tbody class="form-element-reorder">
                <tr [attr.data-index]="attr.index" *ngFor="let attr of module.attributes">
                    <td class="left">
                        <clr-icon (click)="onOpenChange(true, attr)" shape="pencil"></clr-icon
                        ><clr-icon class="handle" shape="drag-handle"></clr-icon>
                    </td>
                    <td class="left">{{ attr.index }}</td>
                    <td class="left">{{ attr.formField.type }}</td>
                    <td class="left">{{ attr.label.fr }}</td>
                    <td class="left">{{ attr.label.en }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<clr-modal *ngIf="addOpen" [(clrModalOpen)]="addOpen" (clrModalOpenChange)="onOpenChange($event)">
    <h3 class="modal-title">Nouvel element de module</h3>
    <div class="modal-body">
        <clr-input-container>
            <label>Id</label>
            <input clrInput name="input" [(ngModel)]="tempFormElement.index" />
        </clr-input-container>

        <clr-input-container>
            <label>Label</label>
            <input clrInput placeholder="fr" name="label fr" [(ngModel)]="tempFormElement.label.fr" />
        </clr-input-container>
        <clr-input-container class="no-margin">
            <label></label>
            <input clrInput placeholder="en" name="label en" [(ngModel)]="tempFormElement.label.en" />
        </clr-input-container>

        <br />
        <clr-checkbox-wrapper>
            <input
                type="checkbox"
                clrCheckbox
                name="defaultDescription"
                (change)="tempFormElement.defaultDescription = $event.target.checked"
                [checked]="tempFormElement.defaultDescription"
            />
            <label>Default description</label>
        </clr-checkbox-wrapper>
        <clr-select-container>
            <label>Module</label>
            <select clrSelect name="module" [(ngModel)]="tempFormElement.module">
                <option [value]="module.id" *ngFor="let module of modules$ | async">
                    {{ module.name.en }}/{{ module.name.fr }}
                </option>
            </select>
        </clr-select-container>

        <clr-select-container>
            <label>Type</label>
            <select clrSelect name="formField.type" [(ngModel)]="tempFormElement.formField.type">
                <option [value]="type" *ngFor="let type of formElementTypes">
                    {{ type }}
                </option>
            </select>
        </clr-select-container>

        <clr-checkbox-wrapper>
            <input
                type="checkbox"
                clrCheckbox
                name="formField.required"
                [checked]="tempFormElement.formField.required"
            />
            <label>Required</label>
        </clr-checkbox-wrapper>

        <div
            *ngIf="
                tempFormElement.formField.type === 'dropdown' ||
                tempFormElement.formField.type === 'checkbox' ||
                tempFormElement.formField.type === 'radio'
            "
        >
            <table class="table table-sm table-compact">
                <thead>
                    <tr>
                        <th class="left"></th>
                        <th class="left">id</th>
                        <th class="left">label fr</th>
                        <th class="left">label en</th>
                    </tr>
                </thead>
                <tbody class="option-reorder">
                    <tr [attr.data-option-id]="option.id" *ngFor="let option of tempFormElement.formField.options">
                        <td class="left">
                            <clr-icon (click)="removeOption(option.id)" shape="times"></clr-icon
                            ><clr-icon class="handle-option" shape="drag-handle"></clr-icon>
                        </td>
                        <td class="left">{{ option.id }}</td>
                        <td class="left"><input [(ngModel)]="option.label.fr" /></td>
                        <td class="left"><input [(ngModel)]="option.label.en" /></td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td class="left" colspan="4">
                            <button (click)="addOption()" class="btn btn-sm">Ajouter</button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="onOpenChange(false)" class="btn btn-sm">Canceller</button>
        <button (click)="submitAttributeIndex()" class="btn btn-primary btn-sm" [disabled]="!isValid()">
            Soumettre
        </button>
    </div>
</clr-modal>
