import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';
import { QueryObject } from 'src/modules/core/mappers/elasticsearch-mapper.service';
import { AttributeChange } from 'src/modules/diversite/components/contacts-mass-edit/contacts-mass-edit.component';
import {
    ContactUpdateContext,
} from 'src/modules/diversite/components/contacts/suppress-contact-prompt/suppress-contact-prompt.component';
import { FormElementType } from 'src/modules/diversite/model/form/form-element/form-element';
import { SearchDefinition } from 'src/modules/diversite/model/search-definition';
import { AttributeReference } from 'src/modules/diversite/services/data-catalog.service';
import { ExportOptions } from 'src/modules/diversite/services/export-template.service';
import { AuthenticatedProfile } from 'src/modules/fillout/services/access-code.service';
import { ContactPublic } from 'src/modules/fillout/services/recruit.service';

const GATEWAY = "https://serv-001.azurewebsites.net/api/RequestGateway";
@Injectable({
    providedIn: "root",
})
export class SystemRequestApiClientService {
    constructor(private db: AngularFirestore, private http: HttpClient) { }

    add(systemRequest: SystemRequest, collection?: string): Observable<SystemRequest> {
        return from(this.db.collection(collection || "systemRequests").add(systemRequest)).pipe(
            delay(250),
            switchMap((doc) => {
                return this.http.post(this.gatewayUrl(doc.id, collection), {}).pipe(map((_) => doc));
            }),
            map((docRef: DocumentReference) => {
                const id = docRef.id;
                return { id, requestType: systemRequest.requestType };
            })
        );
    }

    get(id: string, collection?: string): Observable<SystemRequest> {
        return this.db
            .collection(collection || "systemRequests")
            .doc(id)
            .valueChanges()
            .pipe(
                map((data: any) => {
                    return { id, ...data } as SystemRequest;
                })
            );
    }

    private gatewayUrl(id: string, collection?: string): string {
        const refUrl = `%2F${collection || "systemRequests"}%2F${id}`;
        return `${GATEWAY}?requestRef=${refUrl}`;
    }
}

export interface SystemRequest {
    id?: string;
    user?: DocumentReference;
    requestType: SystemRequestType;
    status?: SystemRequestStatus;
    error?: string;
    created?: Date;
}

export type SystemRequestType =
    | "generateaccesstoken"
    | "processSubmission"
    | "processUpload"
    | "processVideoUpload"
    | "contactUpdate"
    | "removePhoto"
    | "addPhoto"
    | "search"
    | "getProfilesForPassword"
    | "generateesapikey"
    | "generateUploadLinks"
    | "createandrecruitcontact"
    | "recruitContact"
    | "verifycontactbyemail"
    | "loadContactFromLink"
    | "loadContactFromLegacyLink"
    | "updateattributesmappingswithform"
    | "deleteBucket"
    | "mergeProfiles"
    | "requestContactInfo"
    | "requestCode"
    | "contactPasswordChange"
    | "verifyCode"
    | "loadContact"
    | "contactLogin"
    | "indexContactAssigment"
    | "massEditContacts"
    | "sendForm"
    | "addAttributeReference"
    | "checkupdatemodel"
    | "generateterms"
    | "exportcontacts";

export type SystemRequestStatus = "success" | "error";

export interface MassEditContactsRequest extends SystemRequest {
    changes: AttributeChange[];
    contacts?: string[];
    query?: QueryObject;
}

export interface GenerateTermsRequest extends SystemRequest {
    query?: string;
    languages?: string[];
    size?: number;
}

export interface SendFormToContactsRequest extends SystemRequest {
    formId: string;
    url: string;
    contacts: string[];
}
export interface MergeProfilesRequest extends SystemRequest {
    sourceContacts: string[];
    targetContact: string;
}

export interface RequestCodeRequest extends SystemRequest {
    lang: string;
    contactInfo: {
        idfig?: string;
        email?: string;
        type: "sms" | "email";
    };
}

export interface VerifyCodeRequest extends SystemRequest {
    authenticatedProfiles?: AuthenticatedProfile[];
    contextId?: string;
    verifyCode: string;
}

export interface LoadContactRequest extends SystemRequest {
    contact?: any;
    contextId: string;
    idfig: string;
}
export interface RequestContactInfoRequest extends SystemRequest {
    idfig: string;
    response?: { [strategy: string]: string };
}
export interface GenerateAccessTokenRequest extends SystemRequest {
    token: string;
}

export interface GenerateESApiKeyRequest extends SystemRequest {
    api_key_base64: string;
}

export interface UpdateAttributesMappingRequest extends SystemRequest {
    formId: string;
}

export interface GenerateUploadLinkRequest extends SystemRequest {
    numberOfFiles: string;
    assets?: { assetName?: string; videoInputUrl?: string; imageInputUrl?: string }[];
}

export interface AssignBucketRequest extends SystemRequest {
    contactIds: string[];
    bucketId: string;
    operation: "assignBatch" | "unassignBatch";
}
export interface ProcessVideoUploadRequest extends SystemRequest {
    targetContainer: string;
    videoUrl: string;
    idfig: string;
}

export interface ExportContactsRequest extends SystemRequest {
    query: string;
    options: ExportOptions;
    progress?: {
        totalToExport?: number;
        exportedCount?: number
    };
    result?: any
}

export interface PublicContextSubmissionRequest extends SystemRequest {
    formId: string;
    origin: string;
    idfig?: string;
    contact?: any;
    queryParams?: any;
}

export interface SendLinkExistingContactRequest extends SystemRequest {
    contextId: string;
    origin: string;
    email: string;
}

export interface ContactFromIdRequest extends SystemRequest {
    link?: string;
    contact: ContactPublic;
    idfig: string;
    photos: string[];
}

export interface ContactFromLegacyLinkLnkRequest extends SystemRequest {
    link?: string;
    contact: ContactPublic;
    idfig: string;
    photos: string[];
}

export interface ContactEmailStateRequest extends SystemRequest {
    idfig?: string;
    email: string;
    model: number;
    attributesMissing: string[];
}

export interface SearchRequest extends SystemRequest {
    searchDefinition: SearchDefinition;
}

export interface ProcessSubmissionRequest extends SystemRequest {
    submission: DocumentReference;
}

export interface ProcessUploadRequest extends SystemRequest {
    imageUrl: string;
    targetContainer: string;
}

export interface EditContactRequest extends SystemRequest {
    idfig?: string;
    contactId?: string;
    contact: any;
    context?: ContactUpdateContext;
}

export interface RemovePictureRequest extends SystemRequest {
    image: DocumentReference;
}

export interface DeleteBucketRequest extends SystemRequest {
    bucket: DocumentReference;
}

export interface AddPictureRequest extends SystemRequest {
    idfig: string;
    photo: { id: string; url: string };
}

export interface AddAttributeReferenceRequest extends SystemRequest {
    attributeId: string;
    formElementType: FormElementType;
    references: AttributeReference[];
}


export type RequestSubType = "classification";