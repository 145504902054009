<div class="add-contact-container">
    <div class="middle">
        <form class="clr-form-vertical">
            <clr-input-container>
                <label
                    >Prénom
                    <cds-icon
                        [class.red]="currentHighlightField === 'identity_firstname'"
                        (click)="highlight('identity_firstname')"
                        shape="highlighter"
                    ></cds-icon
                ></label>
                <input
                    [(ngModel)]="tempContact.identity_firstname"
                    autocomplete="off"
                    type="text"
                    clrInput
                    name="firstname"
                />
            </clr-input-container>
            <clr-input-container>
                <label
                    >Nom
                    <cds-icon
                        [class.red]="currentHighlightField === 'identity_lastname'"
                        (click)="highlight('identity_lastname')"
                        shape="highlighter"
                    ></cds-icon
                ></label>
                <input
                    [(ngModel)]="tempContact.identity_lastname"
                    autocomplete="off"
                    type="text"
                    clrInput
                    name="lastname"
                />
            </clr-input-container>
            <clr-input-container>
                <label
                    >Courriel
                    <cds-icon
                        [class.red]="currentHighlightField === 'contactInformation_email'"
                        (click)="highlight('contactInformation_email')"
                        shape="highlighter"
                    ></cds-icon
                ></label>
                <input
                    [(ngModel)]="tempContact.contactInformation_email"
                    required
                    autocomplete="off"
                    type="email"
                    clrInput
                    name="email"
                />
            </clr-input-container>

            <div class="clr-form-control">
                <div class="clr-control-container phone-container">
                    <div class="clr-input-wrapper">
                        <label class="clr-control-label"
                            >Téléphone principal
                            <cds-icon
                                [class.red]="currentHighlightField === 'contactInformation_mainPhone'"
                                (click)="highlight('contactInformation_mainPhone')"
                                shape="highlighter"
                            ></cds-icon
                        ></label>
                        (<input
                            [(ngModel)]="tempContact.contactInformation_mainPhone.areaCode"
                            class="clr-input area-code"
                            autocomplete="off"
                            name="areaCode"
                        />)&nbsp;<input
                            [(ngModel)]="tempContact.contactInformation_mainPhone.localPrefix"
                            class="clr-input local-prefix"
                            autocomplete="off"
                            name="localPrefix"
                        />-<input
                            [(ngModel)]="tempContact.contactInformation_mainPhone.localSuffix"
                            class="clr-input local-suffix"
                            autocomplete="off"
                            name="localSuffix"
                        />
                    </div>
                </div>
            </div>

            <clr-select-container>
                <label>Genre</label>
                <select [(ngModel)]="tempContact.identity_gender" clrSelect name="genre">
                    <option value="">-</option>
                    <option value="f">Femme</option>
                    <option value="h">Homme</option>
                    <option value="x">Autre</option>
                </select>
            </clr-select-container>

            <clr-date-container>
                <label>Date de naissance</label>
                <input [(clrDate)]="tempContact.identity_dateOfBirth" type="date" clrDate name="dateOfBirth" />
            </clr-date-container>

            <clr-select-container *ngIf="databases && databases.length > 0">
                <label>Base de données</label>
                <select [(ngModel)]="selectedDatabaseId" clrSelect name="database">
                    <option value="">Globale</option>
                    <option *ngFor="let db of databases; trackBy: trackById" [value]="db.id">
                        {{ db.name }}
                    </option>
                </select>
            </clr-select-container>
        </form>
        <div class="full-vertical">
            <cds-alert-group type="banner" status="info">
                <cds-alert *ngIf="!currentHighlightField"> Copier du texte ici pour vous aidez. </cds-alert>
                <cds-alert *ngIf="currentHighlightField">
                    Veuillez selectionner "<strong>{{ toHighlight }}</strong
                    >" <small class="cancel-selection" (click)="cancelSelection()">canceller</small>
                </cds-alert>
            </cds-alert-group>
            <textarea id="copied-text" class="outside-text"></textarea>
        </div>
    </div>
    <div class="bottom">
        <button [disabled]="loading" class="btn" (click)="cancel()">Canceller</button>
        <button [disabled]="loading" class="btn btn-primary" (click)="onSubmit()">Inscrire ce membre</button>
    </div>
</div>
