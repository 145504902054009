import { ErrorHandler, Injectable, isDevMode } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import html2canvas from 'html2canvas';
import { saysWho } from 'src/app/core/functions';
import { AuthService } from 'src/app/services/auth.service';
import { VERSION } from 'src/environments/version';

@Injectable({
    providedIn: "root",
})
export class ErrorHandlerService implements ErrorHandler {
    constructor(
        private db: AngularFirestore,
        private authService: AuthService
    ) { }

    handleError(error: any): void {
        const screenshotTarget = document.body;
        html2canvas(screenshotTarget, {
            useCORS: true,
            scale: 0.7,
        }).then((canvas) => {
            const base64image = canvas.toDataURL("image/png");

            let collection;
            if (isDevMode()) {
                collection = "errors_dev";
            } else {
                collection = "errors";
            }

            if (!this.isAnIgnoredError(error)) {
                this.db.collection(collection).add({
                    user: this.authService.isLoggedIn
                        ? this.db.collection("users").doc(this.authService.loggedInUser.id).ref
                        : null,
                    url: window.location.href,
                    browser: saysWho(),
                    error: {
                        name: error && error.name ? error.name : null,
                        message: error && error.message ? error.message : null,

                        // Mozilla
                        fileName: error && error.fileName ? error.fileName : null,
                        lineNumber: error && error.lineNumber ? error.lineNumber : null,
                        stack: error && error.stack ? error.stack : null,

                        // Microsoft
                        description: error && error.description ? error.description : null,
                        number: error && error.number ? error.number : null,

                        dump: error && typeof error.toString === "function" ? error.toString() || null : null,
                    },
                    version: VERSION.hash,
                    image: base64image,
                    date: new Date(),
                });
            }
        });

        console.error(error);
    }

    private isAnIgnoredError(error: any): boolean {
        return error && error.message?.indexOf("Failed to get document because the client is offline.") >= 0;
    }
}
export interface ErrorYoulug {
    id?: string;
    user?: string;
    url?: string;
    browser?: string;
    error?: any;
    version?: string;
    date?: Date | firebase.firestore.Timestamp;
}
