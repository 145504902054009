import { Injectable } from "@angular/core";
import { AngularFirestore, DocumentReference } from "@angular/fire/compat/firestore";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class SystemJobsService {
    constructor(private db: AngularFirestore) { }

    massEditJob(id: string): Observable<SystemJob> {
        return this.db
            .collection("systemData")
            .doc("jobs")
            .collection("massEdits")
            .doc(id)
            .snapshotChanges()
            .pipe(
                map((doc) => {
                    return this.mapMassEditJob({ id: doc.payload.id, ...doc.payload.data() });
                }),
                switchMap((job) => {
                    return new Observable<MassEditContactsSystemJob>((subscriber) => {
                        subscriber.next(job);

                        if (job.percentageDone === 100) {
                            subscriber.complete();
                        }
                    });
                })
            );
    }

    private mapMassEditJob(massEditContactsSystemJobDTO: any): MassEditContactsSystemJob {
        massEditContactsSystemJobDTO.created = massEditContactsSystemJobDTO.created?.toDate
            ? massEditContactsSystemJobDTO.created?.toDate()
            : null;

        massEditContactsSystemJobDTO.lastUpdated = massEditContactsSystemJobDTO.lastUpdated?.toDate
            ? massEditContactsSystemJobDTO.created?.toDate()
            : null;

        let percent = 0;
        if (massEditContactsSystemJobDTO.contacts) {
            const quantity = Object.keys(massEditContactsSystemJobDTO.contacts).length;
            const totalAmount = massEditContactsSystemJobDTO.totalElementsToProcess;

            if (quantity && totalAmount) {
                percent = ~~((quantity / totalAmount) * 100);
            }
        }

        return { ...massEditContactsSystemJobDTO, percentageDone: percent } as MassEditContactsSystemJob;
    }
}

export interface SystemJob {
    id?: string;
    created?: Date;
    lastUpdated?: Date;
    percentageDone?: number;
    jobType?: SystemJobType;
    user?: DocumentReference;
}

export interface MassEditContactsSystemJob extends SystemJob {
    jobId?: string;
    contacts?: ContactsEditMetaData;
    totalElementsToProcess?: number;
}

export type SystemJobType = "massContactChange";

export interface ContactsEditMetaData {
    [contactId: string]: ContactEditMetaData;
}
export interface ContactEditMetaData {
    contactId: string;
    dateApplied: Date;
    originalValues: { [attributeId: string]: any };
    changesApplied: { [attributeId: string]: any };
}
