import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Pane } from 'src/modules/diversite/model/pane';

@Component({
    selector: 'diversite-upload-photo-content-container',
    templateUrl: './upload-photo-content-container.component.html',
    styleUrl: './upload-photo-content-container.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadPhotoContentContainerComponent {
    @Input() pane: Pane;

}
