<!-- <div class="faces">
    <div *ngFor="let contact of images; trackBy: trackByFn; let i = index;" class="block-face"
        [ngClass]="layoutOptions[i]" [style.background-image]="getBackgroundImage(contact)">
    </div>
</div> -->

<div class="faces">
    <div class="colx22">
        <div class="block-face x22" [style.background-image]="getBackgroundImage(images[0])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[1])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[2])"></div>
        <div class="block-face x22" [style.background-image]="getBackgroundImage(images[3])"></div>
        <div class="block-face empty" [style.background-image]="getBackgroundImage(images[4])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[5])"></div>
        <div class="block-face x22" [style.background-image]="getBackgroundImage(images[6])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[7])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[8])"></div>
    </div>
    <div class="colx22">
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[9])"></div>
        <div class="block-face empty" [style.background-image]="getBackgroundImage(images[10])"></div>
        <div class="block-face x22" [style.background-image]="getBackgroundImage(images[11])"></div>
        <div class="block-face empty" [style.background-image]="getBackgroundImage(images[12])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[13])"></div>
        <div class="block-face x22" [style.background-image]="getBackgroundImage(images[14])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[15])"></div>
        <div class="block-face empty" [style.background-image]="getBackgroundImage(images[16])"></div>
        <div class="block-face x22" [style.background-image]="getBackgroundImage(images[17])"></div>
    </div>
    <div class="colx44">
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[18])"></div>
        <div class="block-face x11 m1t" [style.background-image]="getBackgroundImage(images[19])"></div>
        <div class="block-face x22" [style.background-image]="getBackgroundImage(images[20])"></div>
        <div class="block-face x33" [style.background-image]="getBackgroundImage(images[21])"></div>
        <div class="block-face empty" [style.background-image]="getBackgroundImage(images[22])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[23])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[24])"></div>
        <div class="block-face x11 m1t" [style.background-image]="getBackgroundImage(images[25])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[26])"></div>
        <div class="block-face x22" [style.background-image]="getBackgroundImage(images[27])"></div>
        <div class="block-face empty" [style.background-image]="getBackgroundImage(images[28])"></div>
        <div class="block-face x22" [style.background-image]="getBackgroundImage(images[29])"></div>
        <div class="block-face x11 m1t" [style.background-image]="getBackgroundImage(images[30])"></div>
    </div>
    <div class="colx22">
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[31])"></div>
        <div class="block-face empty" [style.background-image]="getBackgroundImage(images[32])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[33])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[34])"></div>
        <div class="block-face x22" [style.background-image]="getBackgroundImage(images[35])"></div>
        <div class="block-face empty" [style.background-image]="getBackgroundImage(images[36])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[37])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[38])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[39])"></div>
        <div class="block-face x22" [style.background-image]="getBackgroundImage(images[40])"></div>
        <div class="block-face empty" [style.background-image]="getBackgroundImage(images[41])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[42])"></div>
    </div>
    <div class="colx33">
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[43])"></div>
        <div class="block-face x22" [style.background-image]="getBackgroundImage(images[44])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[45])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[46])"></div>
        <div class="block-face empty" [style.background-image]="getBackgroundImage(images[47])"></div>
        <div class="block-face empty" [style.background-image]="getBackgroundImage(images[48])"></div>
        <div class="block-face empty" [style.background-image]="getBackgroundImage(images[49])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[50])"></div>
        <div class="block-face x22" [style.background-image]="getBackgroundImage(images[51])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[52])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[53])"></div>
        <div class="block-face empty" [style.background-image]="getBackgroundImage(images[54])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[55])"></div>
        <div class="block-face x22" [style.background-image]="getBackgroundImage(images[56])"></div>
        <div class="block-face x11" [style.background-image]="getBackgroundImage(images[57])"></div>
    </div>
</div>
