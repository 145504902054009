<div class="users">
    <div class="user" *ngFor="let user of users$ | async; trackBy: trackById">
        <div class="user-top">
            <strong>{{ user.email }}</strong>

            <div class="department-default">
                <label>departement application</label>
                <select
                    (change)="onDepartmentApplicationChange(rights[user.id].selectedDepartmentApplication, user)"
                    [(ngModel)]="rights[user.id].selectedDepartmentApplication"
                >
                    <option value="">-</option>
                    <option [value]="department.id" *ngFor="let department of departments$ | async; trackBy: trackById">
                        {{ department.name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="rights">
            <div class="column">
                <strong>Functionnality</strong>
                <clr-radio-wrapper>
                    <input
                        [name]="user.id + '_segmentMain'"
                        type="radio"
                        clrRadio
                        (change)="onSegmentMainChange(rights[user.id].segmentMainSelection, user)"
                        value="all"
                        [(ngModel)]="rights[user.id].segmentMainSelection"
                    />
                    <label>All</label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                    <input
                        [name]="user.id + '_segmentMain'"
                        type="radio"
                        clrRadio
                        (change)="onSegmentMainChange(rights[user.id].segmentMainSelection, user)"
                        value="custom"
                        [(ngModel)]="rights[user.id].segmentMainSelection"
                    />
                    <label>Custom</label>
                </clr-radio-wrapper>

                <div *ngIf="rights[user.id].segmentMainSelection === 'custom'">
                    <div *ngFor="let segment of applicationSegments">
                        <clr-checkbox-container clrInline>
                            <label>{{ segment }}</label>
                            <clr-checkbox-wrapper>
                                <input
                                    type="checkbox"
                                    clrCheckbox
                                    [checked]="rights[user.id].segmentPermissions[segment]?.read"
                                    (change)="onPermissionChange('read', $event.target.checked, segment, user)"
                                />
                                <label>read</label>
                            </clr-checkbox-wrapper>
                            <clr-checkbox-wrapper>
                                <input
                                    type="checkbox"
                                    clrCheckbox
                                    [checked]="rights[user.id].segmentPermissions[segment]?.write"
                                    (change)="onPermissionChange('write', $event.target.checked, segment, user)"
                                />
                                <label>write</label>
                            </clr-checkbox-wrapper>
                            <clr-checkbox-wrapper>
                                <input
                                    type="checkbox"
                                    clrCheckbox
                                    [checked]="rights[user.id].segmentPermissions[segment]?.update"
                                    (change)="onPermissionChange('update', $event.target.checked, segment, user)"
                                />
                                <label>update</label>
                            </clr-checkbox-wrapper>
                            <clr-checkbox-wrapper>
                                <input
                                    type="checkbox"
                                    clrCheckbox
                                    [checked]="rights[user.id].segmentPermissions[segment]?.delete"
                                    (change)="onPermissionChange('delete', $event.target.checked, segment, user)"
                                />
                                <label>delete</label>
                            </clr-checkbox-wrapper>
                        </clr-checkbox-container>
                    </div>
                </div>
            </div>

            <div class="column">
                <strong>Projects</strong>
                <clr-radio-wrapper>
                    <input
                        [name]="user.id + '_projectMain'"
                        type="radio"
                        clrRadio
                        (change)="onProjectMainChange(rights[user.id].projectMainSelection, user)"
                        value="all"
                        [(ngModel)]="rights[user.id].projectMainSelection"
                    />
                    <label>All</label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                    <input
                        [name]="user.id + '_projectMain'"
                        type="radio"
                        clrRadio
                        (change)="onProjectMainChange(rights[user.id].projectMainSelection, user)"
                        value="custom"
                        [(ngModel)]="rights[user.id].projectMainSelection"
                    />
                    <label>Custom</label>
                </clr-radio-wrapper>

                <div *ngIf="rights[user.id].projectMainSelection !== 'all'">
                    <clr-select-container>
                        <label>Projects</label>
                        <select clrSelect [(ngModel)]="rights[user.id].selectedProjectId">
                            <option value="">-</option>
                            <option [value]="project.id" *ngFor="let project of projects$ | async; trackBy: trackById">
                                {{ project.name }}
                            </option>
                        </select>
                    </clr-select-container>
                    <clr-checkbox-container clrInline>
                        <clr-checkbox-wrapper>
                            <input
                                type="checkbox"
                                clrCheckbox
                                [disabled]="rights[user.id].selectedProjectId === ''"
                                (change)="
                                    onProjectPermisionChange(
                                        'read',
                                        rights[user.id].selectedProjectId,
                                        $event.target.checked,
                                        user
                                    )
                                "
                                [checked]="rights[user.id].projectPermissions[rights[user.id].selectedProjectId]?.read"
                            />
                            <label>read</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                            <input
                                type="checkbox"
                                clrCheckbox
                                [disabled]="rights[user.id].selectedProjectId === ''"
                                (change)="
                                    onProjectPermisionChange(
                                        'write',
                                        rights[user.id].selectedProjectId,
                                        $event.target.checked,
                                        user
                                    )
                                "
                                [checked]="rights[user.id].projectPermissions[rights[user.id].selectedProjectId]?.write"
                            />
                            <label>write</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                            <input
                                type="checkbox"
                                clrCheckbox
                                [disabled]="rights[user.id].selectedProjectId === ''"
                                (change)="
                                    onProjectPermisionChange(
                                        'update',
                                        rights[user.id].selectedProjectId,
                                        $event.target.checked,
                                        user
                                    )
                                "
                                [checked]="
                                    rights[user.id].projectPermissions[rights[user.id].selectedProjectId]?.update
                                "
                            />
                            <label>update</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                            <input
                                type="checkbox"
                                clrCheckbox
                                [disabled]="rights[user.id].selectedProjectId === ''"
                                (change)="
                                    onProjectPermisionChange(
                                        'delete',
                                        rights[user.id].selectedProjectId,
                                        $event.target.checked,
                                        user
                                    )
                                "
                                [checked]="
                                    rights[user.id].projectPermissions[rights[user.id].selectedProjectId]?.delete
                                "
                            />
                            <label>delete</label>
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                    <button
                        [disabled]="rights[user.id].selectedProjectId === ''"
                        (click)="onAddProjectPermission(user, rights[user.id].selectedProjectId)"
                        class="btn btn-sm btn-primary"
                    >
                        Add project permission
                    </button>
                </div>
            </div>

            <div class="column">
                <strong>Departements</strong>
                <clr-radio-wrapper>
                    <input
                        [name]="user.id + '_departmentMain'"
                        type="radio"
                        clrRadio
                        (change)="onDepartmentMainChange(rights[user.id].departmentMainSelection, user)"
                        value="all"
                        [(ngModel)]="rights[user.id].departmentMainSelection"
                    />
                    <label>All</label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                    <input
                        [name]="user.id + '_departmentMain'"
                        type="radio"
                        clrRadio
                        (change)="onDepartmentMainChange(rights[user.id].departmentMainSelection, user)"
                        value="custom"
                        [(ngModel)]="rights[user.id].departmentMainSelection"
                    />
                    <label>Custom</label>
                </clr-radio-wrapper>

                <div *ngIf="rights[user.id].departmentMainSelection !== 'all'">
                    <clr-select-container>
                        <label>Departements</label>
                        <select clrSelect [(ngModel)]="rights[user.id].selectedDepartmentId">
                            <option value="">-</option>
                            <option
                                [value]="department.id"
                                *ngFor="let department of departments$ | async; trackBy: trackById"
                            >
                                {{ department.name }}
                            </option>
                        </select>
                    </clr-select-container>
                    <clr-checkbox-container clrInline>
                        <clr-checkbox-wrapper>
                            <input
                                type="checkbox"
                                clrCheckbox
                                [disabled]="rights[user.id].selectedDepartmentId === ''"
                                (change)="
                                    onDepartmentPermisionChange(
                                        'read',
                                        rights[user.id].selectedDepartmentId,
                                        $event.target.checked,
                                        user
                                    )
                                "
                                [checked]="
                                    rights[user.id].departmentPermissions[rights[user.id].selectedDepartmentId]?.read
                                "
                            />
                            <label>read</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                            <input
                                type="checkbox"
                                clrCheckbox
                                [disabled]="rights[user.id].selectedDepartmentId === ''"
                                (change)="
                                    onDepartmentPermisionChange(
                                        'write',
                                        rights[user.id].selectedDepartmentId,
                                        $event.target.checked,
                                        user
                                    )
                                "
                                [checked]="
                                    rights[user.id].departmentPermissions[rights[user.id].selectedDepartmentId]?.write
                                "
                            />
                            <label>write</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                            <input
                                type="checkbox"
                                clrCheckbox
                                [disabled]="rights[user.id].selectedDepartmentId === ''"
                                (change)="
                                    onDepartmentPermisionChange(
                                        'update',
                                        rights[user.id].selectedDepartmentId,
                                        $event.target.checked,
                                        user
                                    )
                                "
                                [checked]="
                                    rights[user.id].departmentPermissions[rights[user.id].selectedDepartmentId]?.update
                                "
                            />
                            <label>update</label>
                        </clr-checkbox-wrapper>
                        <clr-checkbox-wrapper>
                            <input
                                type="checkbox"
                                clrCheckbox
                                [disabled]="rights[user.id].selectedDepartmentId === ''"
                                (change)="
                                    onDepartmentPermisionChange(
                                        'delete',
                                        rights[user.id].selectedDepartmentId,
                                        $event.target.checked,
                                        user
                                    )
                                "
                                [checked]="
                                    rights[user.id].departmentPermissions[rights[user.id].selectedDepartmentId]?.delete
                                "
                            />
                            <label>delete</label>
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container>
                    <button
                        [disabled]="rights[user.id].selectedDepartmentId === ''"
                        (click)="onAddDepartmentPermission(user, rights[user.id].selectedDepartmentId)"
                        class="btn btn-sm btn-primary"
                    >
                        Add department permission
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
