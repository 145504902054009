import { Injectable } from "@angular/core";
import { fromEvent, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class CustomCopyPasteService {
    private nativeCopy$ = fromEvent(window, "copy") as Observable<ClipboardEvent>;

    private customSelection: any;

    customPaste$ = (fromEvent<ClipboardEvent>(window, "paste") as Observable<ClipboardEvent>).pipe(
        filter((e: ClipboardEvent) => {
            const data = e.clipboardData.getData("youlug");
            if (data && data.trim() !== "") {
                e.preventDefault();
                return true;
            }
            return false;
        }),
        map((e: ClipboardEvent) => {
            const data = e.clipboardData.getData("youlug");

            let dto = JSON.parse(data);
            // DATE PARSING PROBLEM
            dto = this.customParsingForAvailabilityFormElement(dto);

            return dto;
        })
    );

    customCopy$ = this.nativeCopy$.pipe(
        filter((e: ClipboardEvent) => {
            if (this.customSelection) {
                const jsonData = JSON.stringify(this.customSelection);
                e.clipboardData.setData("youlug", jsonData);
                e.preventDefault();
                this.customSelection = null;
                return true;
            }
            return false;
        })
    );

    constructor() {}

    private customParsingForAvailabilityFormElement(dto: any): any {
        if (dto.type === "availability") {
            dto.options = dto.options.map((opt) => {
                if (opt && opt.value?.from) {
                    opt.value.from = new Date(opt.value.from);
                }
                if (opt && opt.value?.to) {
                    opt.value.to = new Date(opt.value.to);
                }
                return opt;
            });
        }
        return dto;
    }

    customCopy(object: any): void {
        this.customSelection = object;
        document.execCommand("copy");
    }
}
