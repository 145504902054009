<div class="one-picture-layout">
    <span *ngIf="images.length > 0 && editable" class="num-photos"
        >{{ currentImageIndex + 1 }}/{{ images.length }}</span
    >
    <diversite-photo-row [showFaceSquare]="showSquareFace" [image]="images[currentImageIndex]"></diversite-photo-row>
    <clr-icon
        *ngIf="images.length > 0 && editable"
        (mousedown)="previousPhoto($event)"
        class="previous-photo"
        shape="angle"
        dir="left"
    ></clr-icon>
    <clr-icon
        *ngIf="images.length > 0 && editable"
        (mousedown)="nextPhoto($event)"
        class="next-photo"
        shape="angle"
        dir="right"
    ></clr-icon>
</div>
