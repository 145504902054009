<div [class.no-label]="!options.showLabel" class="clr-form-control" *ngIf="formElement">
    <label *ngIf="options.showLabel" class="clr-control-label">
        {{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>

    <div class="clr-control-container">
        (<input
            [readonly]="formElement.readonly"
            [required]="formElement.required"
            [(ngModel)]="areaCode"
            autocomplete="tel-area-code"
            class="clr-input regionDigits"
            type="text"
            tabindex="0"
            maxlength="3"
            minlength="3"
            (keydown)="onlyAllowDigits($event)"
            (keyup)="onInputChange()"
        />)&nbsp;<input
            [readonly]="formElement.readonly"
            [required]="formElement.required"
            [(ngModel)]="localPrefix"
            autocomplete="tel-local-prefix"
            class="clr-input first3digits"
            type="text"
            tabindex="0"
            maxlength="3"
            minlength="3"
            (keydown)="onlyAllowDigits($event)"
            (keyup)="onInputChange()"
        />-<input
            [readonly]="formElement.readonly"
            [required]="formElement.required"
            [(ngModel)]="localSuffix"
            tabindex="0"
            autocomplete="tel-local-suffix"
            class="clr-input last4digits"
            type="text"
            maxlength="4"
            minlength="4"
            (keydown)="onlyAllowDigits($event)"
            (keyup)="onInputChange()"
        />
    </div>
</div>
