<!-- <ng-container *ngIf="node.type === 'folder'">
    <cds-icon shape="folder"></cds-icon>{{ node.name }}
    <div *ngIf="node.children && node.children.length > 0">
        <diversite-search-favorite-node *ngFor="let child of node.children" [node]="child" />
    </div>
</ng-container>
<ng-container *ngIf="node.type === 'search-field'">
    <diversite-search-form-element
        [lang]="lang"
        [formElement]="node.formElement"
        [searchOperation]="null"
    ></diversite-search-form-element>
</ng-container> -->

<div class="search-favorite-node" *ngIf="node.type === 'folder'">
    <div class="toggle-open">
        <cds-icon (click)="toggleOpen()" shape="angle" [direction]="node.open ? 'down' : 'right'"></cds-icon>
        <cds-icon (click)="onRemoveNode()" *ngIf="editFavoriteMode" shape="times"></cds-icon>
    </div>
    <div class="search-favorite-node-content">
        <div class="node-title">
            <cds-icon *ngIf="editFavoriteMode" class="handle-option" shape="drag-handle"></cds-icon>
            <input
                *ngIf="editFavoriteMode"
                class="node-name-input"
                [value]="node.name"
                (change)="onNodeNameChange($event.target.value)"
            />

            <input *ngIf="!editFavoriteMode" readonly class="node-name-input readonly" [value]="node.name" />
        </div>
        <div class="search-favorite-nodes" [hidden]="!node.open">
            <diversite-search-favorite-node
                *ngFor="let child of node.children; trackBy: trackById"
                class="node-child"
                [attr.data-nodeid]="child.id"
                [node]="child"
                [searchGroup]="searchGroup"
                [editFavoriteMode]="editFavoriteMode"
                (nodeChange)="onSearchFavoriteNodeChildChange($event)"
                (removeNode)="onRemoveNode(child.id)"
                (sortTree)="onSortTree()"
                (searchOperationChange)="onSopAdd($event)"
            />
            <em *ngIf="!node.children || node.children.length === 0">(dossier vide)</em>
        </div>
        <a class="add-sub-folder" *ngIf="node.open && editFavoriteMode" (click)="addSubfolder()" class="action-link"
            >Ajouter un sous-dossier</a
        >
    </div>
</div>

<div class="search-favorite-node search-field" *ngIf="node.type === 'search-field' && node.formElement">
    <cds-icon *ngIf="editFavoriteMode" class="handle-option" shape="drag-handle"></cds-icon>
    <cds-icon (click)="onRemoveNode()" *ngIf="editFavoriteMode" shape="times"></cds-icon>

    <div class="content-value">
        <diversite-search-form-element-standalone
            [lang]="lang"
            [formElement]="node.formElement"
            (searchOperationAdd)="onSopAdd($event)"
        />
        <!-- [searchOperation]="searchOperation" -->
    </div>
</div>
