<clr-modal *ngIf="contact" [(clrModalOpen)]="contact" [clrModalSkipAnimation]="'true'">
    <h3 class="modal-title">Supprimer le profil</h3>
    <div class="modal-body">
        <p>
            Voulez-vous vraiment supprimer "<strong
                ><em>{{ contact.fullname }}</em></strong
            >" ?
        </p>

        <br />
        <br />
        <cds-input-group layout="vertical">
            <label>Raison...</label>
            <cds-select>
                <label>Raison</label>
                <select name="reason" [(ngModel)]="context.reason" required (change)="onReasonChange($event)">
                    <option value="death">Décès</option>
                    <option value="back_country">Retour pays origine</option>
                    <option value="ask_by_contact">Demandé par figurant</option>
                    <option value="other">Autre</option>
                </select>
            </cds-select>
            <cds-input>
                <label>Raison autre</label>
                <input
                    name="reasonOther"
                    [disabled]="context.reason !== 'other'"
                    type="text"
                    [(ngModel)]="context.reasonOther"
                />
            </cds-input>
        </cds-input-group>

        <clr-textarea-container>
            <label>Informations additionelles</label>
            <textarea clrTextarea [(ngModel)]="context.additionalInfo" name="additionalInfo" required></textarea>
        </clr-textarea-container>

        <clr-input-container>
            <label>Écrire 'EFFACER' dans le champ ci-dessous</label>
            <input autocomplete="off" required clrInput placeholder="EFFACER" type="text" [(ngModel)]="effacer" />
        </clr-input-container>
    </div>
    <div class="modal-footer">
        <button type="button" [disabled]="effacer !== 'EFFACER'" class="btn btn-primary" (click)="confirmSuppress()">
            Oui, supprimer le profile
        </button>
    </div>
</clr-modal>
