<div *ngIf="formElement && tempFormElement && tempFormElement.label" class="form-element-edition">
    <span
        #labelElement
        class="edit-label"
        contenteditable
        (keyup)="onChange()"
        [innerHTML]="tempFormElement.label[lang]"
    ></span>
    <ng-content select="[tooltip]"></ng-content>

    <div class="type-option">
        <clr-radio-container>
            <clr-radio-wrapper>
                <input
                    type="radio"
                    clrRadio
                    [name]="formElement.id + 'availability'"
                    required
                    value="predetermined"
                    (change)="onChange()"
                    [(ngModel)]="tempFormElement.availabilityType"
                />
                <label>Le membre choisit dans une plage déterminée</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
                <input
                    type="radio"
                    clrRadio
                    [name]="formElement.id + 'availability'"
                    required
                    value="custom"
                    (change)="onChange()"
                    [(ngModel)]="tempFormElement.availabilityType"
                />
                <label>Le membre choisi ses dispos</label>
            </clr-radio-wrapper>
        </clr-radio-container>
    </div>

    <div *ngIf="tempFormElement.availabilityType === 'custom'" class="dates">
        <clr-date-container>
            <input disabled type="date" clrDate readonly />
        </clr-date-container>
        <clr-date-container>
            <input disabled type="date" clrDate readonly />
        </clr-date-container>
    </div>

    <div class="options" *ngIf="tempFormElement.availabilityType === 'predetermined'">
        <div [attr.data-index]="index" class="option" *ngFor="let option of tempFormElement.options; let index = index">
            <cds-icon class="drag-field" shape="drag-handle"></cds-icon>

            <clr-radio-container clrInline>
                <clr-radio-wrapper>
                    <input [name]="option.id" type="radio" clrRadio readonly />
                    <label>Dispo</label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                    <input [name]="option.id" type="radio" clrRadio readonly />
                    <label>Non-dispo</label>
                </clr-radio-wrapper>
            </clr-radio-container>

            <clr-date-container>
                <input
                    (clrDateChange)="onFromChange(option.id, $event)"
                    [(clrDate)]="option.value.from"
                    type="date"
                    clrDate
                />
            </clr-date-container>
            <clr-date-container>
                <input
                    (clrDateChange)="onToChange(option.id, $event)"
                    [(clrDate)]="option.value.to"
                    type="date"
                    clrDate
                />
            </clr-date-container>
            <small *ngIf="!formElement.nameDisabled" (click)="deleteOption(option)"
                ><clr-icon shape="times"></clr-icon
            ></small>
        </div>
    </div>
    <small
        *ngIf="!formElement.nameDisabled && formElement.availabilityType === 'predetermined'"
        (click)="addOptionToField()"
        >Ajouter une option</small
    >
</div>
