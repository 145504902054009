import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { guid, round } from 'src/app/core/functions';
import { FileUploadGenericService } from 'src/modules/core/services/file-upload.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';

@Component({
    selector: "fillout-table-imageupload-input",
    templateUrl: "./table-imageupload-input.component.html",
    styleUrls: ["./table-imageupload-input.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableImageuploadInputComponent implements OnInit {
    @Input() imageUrl: string;
    @Input() readonly = false;
    @Output() imageChange = new EventEmitter<string>();

    uploading = false;
    file: File;
    id: string;
    private _disposeBag = new DisposeBag();

    constructor(private fileUploadService: FileUploadGenericService, private chRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.id = guid();
    }

    onInputChange(event: any): void {
        if (!this.readonly) {
            const fileSize: number =
                event.target.files && event.target.files.length > 0
                    ? round(event.target.files[0].size / 1024 / 1024, 2)
                    : 0;
            if (event.target.files.length > 0 && fileSize <= 10) {
                this.file = event.target.files[0];
                this.uploading = true;
                this.fileUploadService
                    .upload(this.file)
                    .subscribe((response) => {
                        this.imageUrl = response.asset.finalUrl;
                        this.imageChange.emit(this.imageUrl);
                        this.uploading = false;
                        this.chRef.detectChanges();
                    })
                    .disposedBy(this._disposeBag);
            } else {
                this.file = undefined;
                this.imageChange.emit(undefined);
            }
        }
    }
}
