import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, map, Observable, switchMap } from 'rxjs';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Contact } from 'src/modules/diversite/model/contact';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { ContextNode } from 'src/modules/diversite/model/context-node';
import { SearchDefinition, SearchPagination } from 'src/modules/diversite/model/search-definition';
import { ContactContextNodeAssignService } from 'src/modules/diversite/services/contact-context-node-assign.service';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { ContactsSelectionService } from 'src/modules/diversite/services/contacts-selection.service';
import { ContextMenuElement, ContextMenuService } from 'src/modules/diversite/services/context-menu.service';
import { ContextNodeService } from 'src/modules/diversite/services/context-node.service';

import { ContextNodePaneActionsService } from '../context-node-pane-actions.service';
import { guid } from 'src/app/core/functions';

@Component({
    selector: "diversite-context-node-contacts",
    templateUrl: "./context-node-contacts.component.html",
    styleUrls: ["./context-node-contacts.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextNodeContactsComponent implements OnInit, OnDestroy {
    @Input() projectId: string;
    @Input() contacts: Contact[] = [];
    @Input() total: number;
    @Input() node: ContextNode;
    @Input() displayMode: "onlyContent" | "fullNode" = "fullNode";
    @Input() isOpen = false;


    faceSize = 80;
    private contacts$ = new BehaviorSubject<Contact[]>([]);
    private orderBy$ = new BehaviorSubject<"lastUpdated" | "created">("lastUpdated");
    private disposeBag = new DisposeBag();
    orderedContacts$: Observable<Contact[]>;

    constructor(
        private contextMenuService: ContextMenuService,
        private actionsService: ContextNodePaneActionsService,
        private contactSelectionService: ContactsSelectionService,
        private assignContextNodeService: ContactContextNodeAssignService,
        private contextNodeService: ContextNodeService,
        private contactService: ContactService,
    ) { }

    ngOnInit(): void {



        this.orderedContacts$ = combineLatest([this.contacts$, this.orderBy$]).pipe(
            map(data => {
                const contacts = data[0];
                const orderBy = data[1];
                return contacts.sort((c1, c2) => {
                    if (orderBy === "lastUpdated") {
                        return c1.lastUpdate < c2.lastUpdate ? 1 : -1;
                    } else if (orderBy === "created") {
                        return c1.createdAt < c2.createdAt ? 1 : -1;
                    }
                    return 0;
                }).filter(c => c);
            })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.contacts?.currentValue) {
            this.contacts$.next(changes.contacts?.currentValue);
        }
    }

    onRightClickContact(event: PointerEvent, node: ContextNode): void {
        this.contextMenuService.openContextMenu(this.optionsContactsForNode(node), event);
        event.preventDefault();
        event.stopPropagation();
    }

    get isFullNodeDisplay(): boolean {
        return this.displayMode === "fullNode";
    }

    onOrderChange(value: "created" | "lastUpdated"): void {
        this.orderBy$.next(value);
    }


    trackById(_, entity: any): string {
        return entity.id;
    }

    private optionsContactsForNode(node: ContextNode): ContextMenuElement[] {
        return this.actionsService.menuActionsContactsForNode((action) => {
            switch (action) {
                case "export":
                    this.onExport();
                    break;
                case "generate-report":
                    this.onGenerateReport();
                    break;
                case "mass-edit-contacts":
                    this.onEditContacts();
                    break;
                case "send-form-to-contacts":
                    // this.onSendForm(node.id);
                    break;
                default:
            }
            this.contextMenuService.closeContextMenu();
        });
    }

    onExport(): void {
        this.actionsService.openActionPaneAtIndex("export", 1, { searchDefinition: SearchDefinition.DEFAULT_SEARCH_DEFINITION_NODE(this.node.id) }).subscribe().disposedBy(this.disposeBag);
    }

    onGenerateReport(): void {

        this.contextNodeService
            .createReportForNodeId(this.node.id)
            .subscribe(() => { })
            .disposedBy(this.disposeBag);
    }

    onViewProfile(contact: Contact): void {
        this.actionsService.openContactProfilePane(contact.id, this.projectId)
            .subscribe()
            .disposedBy(this.disposeBag);
    }

    onEditContacts(): void {
        this.assignContextNodeService.contactsForNodeId(this.node.id, { listen: false }).pipe(switchMap(contacts => {
            return this.actionsService.openActionPaneAtIndex("mass-edit", 1, { contactIds: contacts.map(c => c.id) })
        })).subscribe().disposedBy(this.disposeBag);
    }

    onRemoveContact(contact: Contact, node: ContextNode): void {

        this.assignContextNodeService.unassignContactsFromNode([contact.id], node, { wait: true }).subscribe().disposedBy(this.disposeBag);
    }

    openContactsTab(node: ContextNode): void {
        this.actionsService.openContactsPane(node, this.projectId);
    }
    openSearchTab(node: ContextNode): void {
        this.actionsService.openSearchPaneForNode(node, this.projectId).subscribe().disposedBy(this.disposeBag);
    }

    onSelectContact(contact: Contact): void {
        this.contactSelectionService.toggleSelection(contact.id);
    }

    selected(contact: Contact): boolean {
        return this.contactSelectionService.isSelected(contact.id);
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
