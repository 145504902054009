import { Injectable } from "@angular/core";
import { AngularFirestore, DocumentReference } from "@angular/fire/compat/firestore";
import { forkJoin, from, Observable, of } from "rxjs";
import { first, map, switchMap } from "rxjs/operators";

import { ApiClient } from "../../core/api-clients/api-client";
import { ContactMapperService } from "../mappers/contact-mapper.service";
import { ContactPerspectiveMapperService } from "../mappers/contact-perspective-mapper.service";
import { ReportDTO, ReportMapperService } from "../mappers/report-mapper.service";
import { Contact } from "../model/contact";
import { Report } from "../model/report";
import { ContactApiClientService } from "./contact-api-client.service";

@Injectable({
    providedIn: "root",
})
export class ReportApiClientService implements ApiClient {
    constructor(
        private db: AngularFirestore,
        private contactMapper: ContactMapperService,
        private contactApi: ContactApiClientService,
        private contactPerspectiveMapper: ContactPerspectiveMapperService,
        private reportMapper: ReportMapperService
    ) { }

    documentReference(id: string): DocumentReference {
        return this.db.collection("reports").doc(id).ref;
    }

    reportById(id: string, options?: { listen: boolean }): Observable<Report> {
        let obs: Observable<ReportDTO>;
        if (options && options.listen) {
            obs = this.db
                .collection("reports")
                .doc(id)
                .snapshotChanges()
                .pipe(
                    map((snapshot: any) => {
                        const data = snapshot.payload.data();
                        return { ...data, id: snapshot.payload.id } as ReportDTO;
                    })
                );
        } else {
            obs = this.db
                .collection("reports")
                .doc(id)
                .get()
                .pipe(
                    map((snapshot) => {
                        const data: any = snapshot.data();
                        return { ...data, id: snapshot.id } as ReportDTO;
                    })
                );
        }

        return obs.pipe(
            map(reportDto => {
                return this.reportMapper.reportDtoToReport(reportDto);
            })
        );
    }

    addReport(report: Report): Observable<string> {
        const reportDto = this.reportMapper.reportToReportDto(report.change({ created: new Date() }));
        return from(
            this.db
                .collection("reports")
                .add(reportDto)
        ).pipe(
            map((docRef) => {
                return docRef.id;
            })
        );
    }

    editReport(report: Report): Observable<void> {
        const reportDto = this.reportMapper.reportToReportDto(report);
        return from(
            this.db.collection("reports").doc(report.id).update(reportDto)
        ).pipe(map((_) => undefined));
    }
}
