import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContactPerspectiveMapperService } from 'src/modules/diversite/mappers/contact-perspective-mapper.service';
import { SortDefinition } from 'src/modules/diversite/model/search-definition';
import { MetaTotal } from 'src/modules/diversite/model/search-meta';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { ContactResult } from 'src/modules/diversite/services/elasticsearch.service';

@Injectable({
    providedIn: "root",
})
export class SearchStateService {
    constructor(
        private db: AngularFirestore,
        private contactPerspectiveMapperService: ContactPerspectiveMapperService,
        private contactService: ContactService
    ) { }

    createState(order?: SortDefinition): Observable<string> {
        const idd = this.db.createId();

        let doc: SearchState = {
            id: idd,
        }
        if (order) {
            doc.sort = order;
        }
        return from(this.db.collection("searchStates").doc(idd).set(doc)).pipe(map((_) => idd));
    }

    saveState(searchState: SearchState): void {
        const state: any = { ...searchState };
        this.db.collection("searchStates").doc(searchState.id).update(state);
    }

    state(id: string, options?: { listen: boolean }): Observable<SearchState> {
        if (options && options.listen) {
        } else {
            return this.db
                .collection("searchStates")
                .doc(id)
                .get()
                .pipe(
                    map((doc) => {
                        if (doc.exists) {
                            const data: any = doc.data();

                            return {
                                ...data,
                                id: doc.id,
                            } as SearchState;

                        }
                    })
                );
        }
    }
}

export interface SearchState {
    id?: string;
    limit?: number;
    page?: number;
    total?: MetaTotal;
    scrollTop?: number;
    sort?: SortDefinition;
    contactResults?: ContactResult[];
}
