import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, debounceTime, delay, filter, finalize, skip, take, tap } from 'rxjs/operators';
import { Translatable } from 'src/modules/core/components/translatable';
import { FileUploadImageService } from 'src/modules/core/services/file-upload-image.service';
import { UploadFilesResponse } from 'src/modules/core/services/file-upload.service';
import { StaticLabels, StaticLabelsService } from 'src/modules/core/services/static-labels.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormImageupload } from 'src/modules/diversite/model/form/form-element/form-imageupload';

import { DEFAULT_OPTIONS, FormElementGenericOptions } from '../../form-element.component';

@Component({
    selector: "fillout-imageupload",
    templateUrl: "./imageupload.component.html",
    styleUrls: ["./imageupload.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageuploadComponent implements OnInit, OnDestroy, OnChanges, Translatable {
    private translationId = "ujgA2vJNXoCF9iINLDos";
    @Input() lang: string;
    @Input() formElement: FormImageupload;
    @Input() value: string[];
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Output() responseChange = new EventEmitter<string[]>();

    private translationLabels: StaticLabels = {};
    imageUrls: string[] = [];
    files: File[];
    uploading = false;
    percentage = 0;
    delayedChanges$ = new BehaviorSubject<string[]>(undefined);

    private disposeBag = new DisposeBag();

    constructor(
        private chRef: ChangeDetectorRef,
        private fileUploadService: FileUploadImageService,
        private staticLabelsService: StaticLabelsService
    ) { }

    ngOnInit(): void {
        this.staticLabelsService
            .labelsForComponent(this.translationId)
            .pipe(take(1))
            .subscribe((labels) => {
                this.translationLabels = labels;
                this.chRef.markForCheck();
            })
            .disposedBy(this.disposeBag);

        this.delayedChanges$
            .pipe(debounceTime(250), skip(1))
            .subscribe((v) => {
                const finalValue = Array.isArray(v) ? v : [];
                this.responseChange.emit(finalValue);
            })
            .disposedBy(this.disposeBag);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && changes.value.currentValue && Array.isArray(changes.value.currentValue)) {
            this.imageUrls = changes.value.currentValue;
        }
    }

    label(labelId: string): string {
        if (this.translationLabels && this.translationLabels[labelId] && this.translationLabels[labelId][this.lang]) {
            return this.translationLabels[labelId][this.lang];
        }
        return "";
    }

    onInputChange(event: any, retry = false): void {
        if (!this.formElement.readonly) {
            if (event.target.files.length > 0) {
                this.percentage = 0;
                this.files = event.target.files;
                this.uploading = true;
                this.fileUploadService
                    .uploadFiles(Array.from(this.files))
                    .pipe(
                        tap((uploadVideoResponse: UploadFilesResponse) => {
                            this.percentage = uploadVideoResponse.percentage;
                            this.chRef.detectChanges();
                        }),
                        filter((uploadVideoResponse: UploadFilesResponse) => {
                            return uploadVideoResponse.percentage === 100;
                        }),
                        take(1),
                        delay(500),
                        catchError((err) => {
                            if (!retry) {
                                this.onInputChange(event, true);
                                return null;
                            }
                            return throwError(err);
                        }),
                        finalize(() => { })
                    )
                    .subscribe((uploadVideoResponse) => {
                        this.imageUrls = [...this.imageUrls, ...uploadVideoResponse.urls];
                        this.responseChange.emit(this.imageUrls);
                        this.uploading = false;
                        this.chRef.detectChanges();
                    })
                    .disposedBy(this.disposeBag);
            } else {
                this.files = [];
                this.responseChange.emit([]);
            }
        }
    }

    deleteImage(i: number): void {
        this.imageUrls = this.imageUrls.filter((_, index) => index !== i);
        this.responseChange.emit(this.imageUrls);
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
