import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { ContactContextNodeAssignService } from './contact-context-node-assign.service';
import { ContactService } from './contact.service';
import { ContactsSelectionService } from './contacts-selection.service';
import { ContextNodeService, MoveNodeEventResponse } from './context-node.service';
import { ReportService } from './report.service';

@Injectable({
    providedIn: "root",
})
export class DragDropActionService {
    startDragData: StartDragActionData;
    dropDragData: DropDragActionData;

    private actionTypeStrategy = new Map<
        DragActionType,
        (startDragData: StartDragActionData, dropDragData: DropDragActionData) => Observable<DropResponseEvent>
    >();

    constructor(
        private contextNodeService: ContextNodeService,
        private contactService: ContactService,
        private assignContactContextNodeService: ContactContextNodeAssignService,
        private reportService: ReportService
    ) {
        this.actionTypeStrategy.set("assign-contacts-to-node", (a, b) => this.assignContactsToNode(a, b));
        this.actionTypeStrategy.set("move-node-to-node", (a, b) => this.moveNodeToNode(a, b));
        this.actionTypeStrategy.set("move-form-to-node", (a, b) => this.moveFormToNode(a, b));
        this.actionTypeStrategy.set("add-contact-to-report", (a, b) => this.addContactsToReport(a, b));
    }

    startDragAction(startDragData: StartDragActionData): void {
        this.startDragData = startDragData;
    }

    dropDragAction(dropDragData: DropDragActionData): Observable<DropResponseEvent> {
        this.dropDragData = dropDragData;
        return this.executeDrop();
    }

    private executeDrop(): Observable<DropResponseEvent> {
        const actionTypeForStartDropData: DragActionType = this.actionTypeForDragDropData(
            this.startDragData,
            this.dropDragData
        );

        const actionFunction = this.actionTypeStrategy.get(actionTypeForStartDropData);
        if (actionFunction) {
            return actionFunction(this.startDragData, this.dropDragData);
        } else {
            console.warn("Need to define a drop action for this drag type");
        }

        return of({
            success: false,
        });
    }

    private actionTypeForDragDropData(
        startDragData: StartDragActionData,
        dropDragData: DropDragActionData
    ): DragActionType {
        if (startDragData?.formId && startDragData?.nodeId && dropDragData?.nodeId && dropDragData?.projectId) {
            return "move-form-to-node";
        }
        if (startDragData?.contactIds && dropDragData?.nodeId) {
            return "assign-contacts-to-node";
        }
        if (startDragData?.nodeId && dropDragData?.nodeId) {
            return "move-node-to-node";
        }
        if (startDragData?.contactIds && dropDragData?.reportId) {
            return "add-contact-to-report";
        }
    }

    private assignContactsToNode(
        startDragData: StartDragActionData,
        dropDragData: DropDragActionData
    ): Observable<DropResponseEvent> {
        return this.contextNodeService.node(dropDragData.nodeId, { listen: false }).pipe(
            switchMap(node => {
                return this.assignContactContextNodeService.assignContactsToNode(startDragData.contactIds, node)
            }),
            map(_ => {
                return { success: true }
            })
        );
    }

    private addContactsToReport(
        startDragData: StartDragActionData,
        dropDragData: DropDragActionData
    ): Observable<DropResponseEvent> {
        return this.reportService.reportById(dropDragData.reportId, { listen: false }).pipe(
            switchMap((report) => {
                return this.contactService.contactsByIds(startDragData.contactIds, { listen: false }).pipe(
                    map((contacts) => {
                        return contacts.map((c) => c.defaultPerspective);
                    }),
                    switchMap((cps) => {
                        return this.reportService.editReport(report.addContactsPerspectives(cps));
                    })
                );
            }),
            map((_) => {
                return { success: true };
            })
        );

        return undefined;
    }

    private moveNodeToNode(
        startDragData: StartDragActionData,
        dropDragData: DropDragActionData
    ): Observable<DropResponseEvent> {
        return this.contextNodeService.moveNodeToNode(startDragData.nodeId, dropDragData.nodeId).pipe(
            map((moveNodeEventResponse: MoveNodeEventResponse) => {
                return { success: moveNodeEventResponse.success };
            })
        );
    }

    private moveFormToNode(
        startDragData: StartDragActionData,
        dropDragData: DropDragActionData
    ): Observable<DropResponseEvent> {
        return this.contextNodeService.moveFormToNode(
            startDragData.formId,
            startDragData.nodeId,
            dropDragData.nodeId,
            dropDragData.projectId
        );
    }
}

export type DragActionType =
    | "assign-contacts-to-node"
    | "move-node-to-node"
    | "move-form-to-node"
    | "add-contact-to-report";

export interface StartDragActionData {
    contactIds?: string[];
    formId?: string;
    nodeId?: string;
}

export interface DropDragActionData {
    nodeId?: string;
    projectId?: string;
    reportId?: string;
}

export interface DropResponseEvent {
    success: boolean;
}
