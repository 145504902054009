<clr-input-container [class.no-label]="!options.showLabel" *ngIf="formElement && options.showLabel">
    <label
        >{{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>
</clr-input-container>

<ng-container *ngIf="formElement.availabilityType === 'predetermined' && value">
    <div class="date-predetermined" *ngFor="let option of value">
        {{ readableDate(option.from)
        }}<span *ngIf="readableDate(option.from) !== readableDate(option.to)"> - {{ readableDate(option.to) }}</span>
        <clr-radio-container clrInline>
            <clr-radio-wrapper>
                <input
                    type="radio"
                    clrRadio
                    [name]="option.formOptionId"
                    [required]="formElement.required"
                    value="available"
                    (change)="onInputChange()"
                    [(ngModel)]="option.responseStatus"
                />
                <label>Dispo</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
                <input
                    type="radio"
                    clrRadio
                    [name]="option.formOptionId"
                    [required]="formElement.required"
                    value="not-available"
                    (change)="onInputChange()"
                    [(ngModel)]="option.responseStatus"
                />
                <label>Non-dispo</label>
            </clr-radio-wrapper>
        </clr-radio-container>
    </div>
</ng-container>

<ng-container *ngIf="formElement.availabilityType === 'custom'">
    <div class="dates">
        <!-- <input type="date" (clrDateChange)="onFromChange($event)" [(clrDate)]="tempFrom" name="fromDate" />
        <input type="date" (clrDateChange)="onToChange($event)" [(clrDate)]="tempTo" name="toDate" /> -->

        <input type="text" class="datep-from" [name]="formElement.name + 'from'" [readonly]="true" />
        <input type="text" class="datep-to" [name]="formElement.name + 'to'" [readonly]="true" />

        <cds-icon (click)="addCustomRange()" shape="plus-circle"></cds-icon>
    </div>

    <table class="date-table table table-compact table-noborder">
        <tr *ngIf="!value || value.length < 1">
            <td colspan="2" class="left">Aucune date ajoutés</td>
        </tr>
        <tr *ngFor="let range of value; let i = index">
            <td class="left">{{ range.from | date : "dd-MM-yyyy" }}</td>
            <td class="left">{{ range.to | date : "dd-MM-yyyy" }}</td>
            <td class="left" class="actions">
                <cds-icon (click)="deleteRange(i)" shape="times"></cds-icon>
            </td>
        </tr>
    </table>
</ng-container>
