import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { ImageLayout } from './face-position.service';

@Injectable({
    providedIn: "root",
})
export class RotateContactImageService {
    constructor(private db: AngularFirestore) {}
    saveRotation(contactId: string, imageLayout: ImageLayout): void {
        this.db
            .collection("contacts")
            .doc(contactId)
            .collection("pictures")
            .doc(imageLayout.id)
            .update({ rotate: imageLayout.rotate });
    }
}
