import { Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { ExportContactsRequest } from 'src/app/api-clients/system-request-api-client.service';
import { SystemRequestService } from 'src/app/services/system-request.service';
import { ElasticSearchMapperService } from 'src/modules/core/mappers/elasticsearch-mapper.service';

import { SearchDefinition } from '../model/search-definition';
import { ExportOptions } from './export-template.service';
import { ApplicationDepartmentService } from 'src/app/services/application-department.service';

@Injectable({
    providedIn: 'root'
})
export class ExportContactsService {

    constructor(private systemRequestService: SystemRequestService, private esMapper: ElasticSearchMapperService, private applicationDepartmentService: ApplicationDepartmentService) { }

    exportContacts(searchDefinition: SearchDefinition, options: ExportOptions): Observable<ExportContactsResponse> {
        const queryObject = this.esMapper.searchParamsToESQuery(this.applicationDepartmentService.currentDepartmentId(), searchDefinition);
        delete queryObject.sort;
        delete queryObject.from;
        delete queryObject.size;
        delete queryObject.track_total_hits;
        return this.systemRequestService.add({
            query: JSON.stringify(queryObject.query),
            requestType: "exportcontacts",
            options,
        } as ExportContactsRequest).pipe(
            switchMap(sr => {
                console.log(sr.id);
                return this.systemRequestService.get(sr.id) as Observable<ExportContactsRequest>
            }),
            map((sr: ExportContactsRequest) => {
                return { progress: sr.progress, result: sr.result, status: sr.status } as ExportContactsResponse;
            })
        )
    }

}

export interface ExportContactsResponse {
    progress: {
        totalToExport?: number;
        exportedCount?: number
    },
    status: "success" | "error",
    result: {}
}