import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, skip, switchMap, take, tap } from 'rxjs/operators';
import { focusPane } from 'src/app/core/functions';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Contact } from 'src/modules/diversite/model/contact';
import { Form } from 'src/modules/diversite/model/form/form';
import { Pane } from 'src/modules/diversite/model/pane';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { ContactsSelectionService } from 'src/modules/diversite/services/contacts-selection.service';
import { FormService } from 'src/modules/diversite/services/form.service';
import { ReportService } from 'src/modules/diversite/services/report.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';

import { PaneCollapseEvent, PaneLockEvent } from '../pane/pane.component';
import { PaneContent, PaneReferenceService, SPLIT_SETTINGS } from '../services/pane-reference.service';
import { PaneService } from '../services/pane.service';

declare var $: any;

@Component({
    selector: "diversite-main-dashboard",
    templateUrl: "./main-dashboard.component.html",
    styleUrls: ["./main-dashboard.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainDashboardComponent implements OnDestroy, AfterViewInit {
    constructor(
        private paneReferenceService: PaneReferenceService,
        private paneService: PaneService,
        private formService: FormService,
        private translateService: TranslateService,
        private contactsSelectionService: ContactsSelectionService,
        private reportService: ReportService,
        private router: Router,
        private contactService: ContactService,
        private route: ActivatedRoute,
        private chRef: ChangeDetectorRef
    ) { }


    paneContent$: Observable<PaneContent[]>;
    forms$: Observable<Form[]>;

    firstLoad = true;

    paneSpecs = {};
    treeviewPaneMinSize: number;

    private scrollMain$ = new BehaviorSubject<HTMLElement>(undefined);

    selection$: Observable<string[]>;
    private disposeBag = new DisposeBag();
    private maxSize: number;


    actionPaneId: string;

    contact$: Observable<Contact>;

    ngOnInit(): void {

        this.selection$ = this.contactsSelectionService.contactsSelection();
        this.forms$ = this.formService.forms();

        this.paneContent$ = this.paneReferenceService.init().pipe(
            switchMap(_ => this.paneReferenceService.layout().pipe(
                tap(paneContents => {
                    if (!this.firstLoad) {
                        this.paneReferenceService.saveLayout();

                    } else {
                        this.firstLoad = false;
                    }

                    paneContents.forEach(paneContent => {
                        this.paneSpecs[paneContent.paneRef.id] = {
                            size: this.paneIsCollapse(paneContent) ? SPLIT_SETTINGS.paneCollapsedSize : paneContent.paneRef.size,
                            isLastOpened: false
                        };
                        if (paneContent.pane.type === "action") {
                            this.actionPaneId = paneContent.pane.id;
                        }
                    });

                    const lasOpenPaneContent = [...paneContents].reverse().find((paneContent) => {
                        return !this.paneIsCollapse(paneContent);
                    });
                    if (lasOpenPaneContent) {
                        this.paneSpecs[lasOpenPaneContent.paneRef.id].isLastOpened = true;
                    }


                })
            ))
        );

        this.treeviewPaneMinSize = this.paneService.defaultPaneMinSizeForType("treeview");

        this.paneReferenceService.paneAdded$.subscribe((p) => {
            setTimeout(() => {
                this.scrollToPaneId(p.id);
            })
        });

        this.scrollMain$
            .pipe(debounceTime(2000), skip(1))
            .subscribe((scrollElement: HTMLElement) => {
                localStorage.setItem("mainScroll", `${scrollElement.scrollLeft}`);
            })
            .disposedBy(this.disposeBag);

    }

    paneIsCollapse(paneContent: PaneContent): boolean {
        return paneContent.paneRef.size < 100;
    }

    ngAfterViewInit(): void {
        this.maxSize = window.innerWidth - 10;
        if (localStorage.getItem("mainScroll")) {
            setTimeout(() => {
                $("#main-scroll").animate({ scrollLeft: `+=${localStorage.getItem("mainScroll")}` }, 250);
            }, 1000);
        }
    }


    trackById(_, element: PaneContent): string {
        return element.paneRef.id;
    }


    get lang(): string {
        return this.translateService.lang;
    }


    onChangeLock(event: PaneLockEvent): void {
        this.paneService
            .pane(event.paneId, { listen: false })
            .pipe(
                switchMap((p) => {
                    return this.paneReferenceService.editPane(p.change({ locked: event.locked })).pipe(take(1));
                })
            )
            .pipe(take(1))
            .subscribe((p) => {
            });
    }

    onToggleCollapse(event: PaneCollapseEvent): void {
        if (event.collapsed) {
            this.paneReferenceService.toggleCollapsePane(event.pane.id);
        } else {
            this.paneReferenceService.toggleCollapsePane(event.pane.id);
            this.scrollToPaneId(event.pane.id);
        }

        this.chRef.markForCheck();
    }

    onClosePane(paneId: string): void {

        if (paneId === this.actionPaneId) {
            this.actionPaneId = undefined;
        }
        this.paneReferenceService.removePane(paneId);
    }

    onMaximize(pane: Pane, index: number): void {
        const maxSize = this.maxSize;
        if (maxSize) {
            if (this.paneSpecs[pane.id].size !== this.maxSize) {
                this.scrollToPaneId(pane.id);
            }
            this.onPaneSizeChange(this.paneSpecs[pane.id].size === this.maxSize ? this.paneService.defaultPaneMinSizeForType(pane.type) : this.maxSize, pane.id);
        }
    }


    addPane(): void {
        this.paneReferenceService.addPane(Pane.DEFAULT_PANE).subscribe().disposedBy(this.disposeBag);
    }
    scrollToPaneId(paneId: string): void {
        if (paneId) {
            focusPane(paneId, this.chRef);
        }
    }

    onScrollMain(event: any): void {
        this.scrollMain$.next(event.target);
    }

    onPaneSizeChange(width: number, paneRefId: string): void {
        this.paneReferenceService.editPaneRefSize(paneRefId, width);
    }

    onChangeSelection(cps: string[]): void {
        this.contactsSelectionService.setSelection(cps);
    }

    onGenerateReport(contactIds: string[]): void {
        this.reportService
            .createReport(contactIds)
            .pipe(take(1))
            .subscribe((reportId) => {
                this.router.navigate([`../report/${reportId}`], { relativeTo: this.route });
                console.log(reportId);
            })
            .disposedBy(this.disposeBag);
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
