<div *ngIf="isFullNodeDisplay" class="node-content node-fixed">
    <!-- (contextmenu)="onRightClickReport($event, node)" -->
    <div class="node-title">
        <cds-icon [shape]="'file-share'"></cds-icon>
        <input class="node-name-input" disabled [value]="'Dossier de partage'" />
        <span class="actions">
            <strong>&nbsp;&nbsp;{{ node.contextData.sharedPackages?.length }}&nbsp;&nbsp;</strong>
        </span>
    </div>

    <div class="reports" *ngIf="isOpen">
        <ng-container [ngTemplateOutlet]="sharedPackagesContent"> </ng-container>
    </div>
</div>

<div *ngIf="!isFullNodeDisplay" class="node-content">
    <div class="reports">
        <ng-container [ngTemplateOutlet]="sharedPackagesContent"> </ng-container>
    </div>
</div>

<ng-template #sharedPackagesContent>
    <div class="packages">
        <div class="package" *ngFor="let pckage of packages; trackBy: trackById">
            <div class="title">
                <cds-icon shape="tools"></cds-icon
                ><input
                    class="node-name-input"
                    (change)="onPackageNameChange(pckage, $event.target.value)"
                    [value]="pckage.name[lang]"
                />
                <div class="actions">
                    <div class="clr-file-wrapper">
                        <label class="clr-control-label">
                            <cds-icon shape="upload"></cds-icon>
                            <input
                                (change)="onInputChange(pckage, $event)"
                                class="clr-file"
                                type="file"
                                accept="application/pdf"
                                multiple
                            />
                        </label>
                    </div>
                    <cds-icon (click)="openEditPackagePane(pckage.id)" shape="note"></cds-icon>
                    <cds-icon (click)="copyLink(pckage.id)" shape="link"></cds-icon>
                    <cds-icon (click)="removePackage(pckage)" shape="trash"></cds-icon>
                </div>
            </div>

            <div class="files">
                <div class="file" *ngFor="let file of pckage.files; trackBy: trackById">
                    <small>{{ file.url }}</small>
                    <div class="actions"><cds-icon (click)="removeFile(file.id, pckage)" shape="trash"></cds-icon></div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
