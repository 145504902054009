<div class="videos-container">
    <div (click)="previous()" class="control">
        <cds-icon shape="angle" direction="left"></cds-icon>
    </div>

    <ng-container *ngFor="let video of videos; let i = index; trackBy: trackById">
        <div [hidden]="video.id !== videoId" class="video">
            <video-player [url]="video.videoUrl"></video-player>
            <div *ngIf="authenticated" class="controls">
                <cds-icon (click)="removePhotoPrompt(video)" shape="times"></cds-icon>
            </div>
        </div>
    </ng-container>

    <div class="video empty" [hidden]="videos.length > 0"><em>Le membre n'a pas de video.</em></div>

    <div (click)="next()" class="control">
        <cds-icon shape="angle" direction="right"></cds-icon>
    </div>
    <div class="loading"><clr-spinner [hidden]="!loading">Loading ...</clr-spinner></div>
</div>
<ng-content></ng-content>

<clr-modal
    *ngIf="videoDeletion"
    [(clrModalOpen)]="videoDeletion"
    (clrModalOpenChange)="onOpenVideoDeletion($event)"
    [clrModalSize]="'sm'"
>
    <h3 class="modal-title">Effacer un video</h3>
    <div class="modal-body">
        <small>Effacer ce video ?</small>
        <video-player [url]="videoDeletion.videoUrl"></video-player>
    </div>
    <div class="modal-footer">
        <button (click)="onOpenVideoDeletion(false)" class="btn btn-sm">Cancel</button>
        <button (click)="confirmImageRemoval()" class="btn btn-sm btn-primary">Effacer</button>
    </div>
</clr-modal>
