import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { debounceTime, skip } from "rxjs/operators";
import { DisposeBag } from "src/modules/core/utilities/dispose-bag";
import { TranslatableLabel } from "src/modules/diversite/services/data-catalog.service";

@Component({
    selector: "diversite-tooltip-edit",
    templateUrl: "./tooltip-edit.component.html",
    styleUrls: ["./tooltip-edit.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipEditComponent implements OnInit, OnDestroy {
    @Input() lang: string;
    @Input() tooltip: TranslatableLabel;
    @Input() readonly readonlyElement = false;
    @Output() tooltipChanged = new EventEmitter<TranslatableLabel>();

    delayedChange$ = new BehaviorSubject<TranslatableLabel>(undefined);
    private _disposeBag = new DisposeBag();

    open = false;

    constructor() {}

    ngOnInit(): void {
        this.delayedChange$.pipe(skip(1), debounceTime(500)).subscribe((tooltip) => {
            this.tooltipChanged.emit(tooltip);
        });
    }

    onTextareaChange(event: Event): void {
        const input = event.target as HTMLTextAreaElement;
        const label = { ...this.tooltip };
        label[this.lang] = input.value;
        this.delayedChange$.next(label);
    }

    ngOnDestroy(): void {
        this._disposeBag.dispose();
    }
}
