<ng-container *ngIf="formElement$ | async as formElement">
    <diversite-search-form-element-standalone
        [display]="'vertical'"
        [formElement]="formElement"
        (searchOperationAdd)="onSearchOperationAdd($event)"
    />
    <div>
        <cds-icon (click)="onAddToFavorite(formElement)" shape="star"></cds-icon>
    </div>
</ng-container>
