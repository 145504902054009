<clr-modal *ngIf="project" [(clrModalOpen)]="project" (clrModalOpenChange)="onOpenChange($event)">
    <h3 class="modal-title">Supprimer le projet</h3>
    <div class="modal-body">
        <p>
            Voulez-vous vraiment effacer ce project "<strong
                ><em>{{ project.name }}</em></strong
            >" ?
        </p>

        <clr-input-container>
            <label>Écrire 'EFFACER' dans le champ ci-dessous</label>
            <input autocomplete="off" required clrInput placeholder="EFFACER" type="text" [(ngModel)]="effacer" />
        </clr-input-container>
    </div>
    <div class="modal-footer">
        <button type="button" [disabled]="disabled" class="btn btn-primary" (click)="confirmDeletion()">
            Oui, supprimer le projet
        </button>
    </div>
</clr-modal>
