import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PublicContextService } from 'src/modules/core/services/public-context.service';

@Directive({
    selector: "[publicContextAsyncValidator]",
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: PublicContextAsyncValidatorDirective, multi: true }],
})
export class PublicContextAsyncValidatorDirective implements AsyncValidator {
    constructor(private publicContextService: PublicContextService) {}

    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        if (control.value && control.value !== "") {
            return this.publicContextService.context(control.value).pipe(
                map((context) => {
                    return context ? { alreadyExist: true } : null;
                })
            );
        } else {
            return of(null);
        }
    }
}
