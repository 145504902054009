import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class TranslateService {
    static DEFAULT_LANG = "fr";
    private CURRENT_LANG = TranslateService.DEFAULT_LANG;

    constructor() {}

    get lang(): string {
        return this.CURRENT_LANG;
    }
}
