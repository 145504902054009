import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { filter, pairwise, switchMap, tap } from 'rxjs/operators';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { AttributeCombobox } from 'src/modules/diversite/components/contacts-mass-edit/contacts-mass-edit.component';
import {
    ContextNodePaneActionsService,
} from 'src/modules/diversite/components/context-node/context-node-pane-actions.service';
import { Contact } from 'src/modules/diversite/model/contact';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { Form } from 'src/modules/diversite/model/form/form';
import { FormModule } from 'src/modules/diversite/model/form/form-module';
import { SearchDefinition, SearchPagination } from 'src/modules/diversite/model/search-definition';
import { SearchMeta } from 'src/modules/diversite/model/search-meta';
import { Bookmark } from 'src/modules/diversite/services/bookmark.service';
import { ContactContextNodeAssignService } from 'src/modules/diversite/services/contact-context-node-assign.service';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { ContactsSelectionService } from 'src/modules/diversite/services/contacts-selection.service';
import { ContextNodeService } from 'src/modules/diversite/services/context-node.service';
import { DragDropActionService } from 'src/modules/diversite/services/drag-drop-action.service';
import { ContactHighlight, ContactResult } from 'src/modules/diversite/services/elasticsearch.service';
import { ModuleService } from 'src/modules/diversite/services/module.service';
import { SearchFavoriteNode } from 'src/modules/diversite/services/search-favorite-node.service';
import {
    SearchProfileVisibleAttributesService,
} from 'src/modules/diversite/services/search-profile-visible-attributes.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';

import {
    ChangeContactDefaultImageEvent,
    ContactLoadedEvent,
} from '../../../../contacts/contact-search-profile/contact-search-profile.component';
import { SearchParametersTab } from '../../search-parameters/search-parameters.component';
import { ReportService } from 'src/modules/diversite/services/report.service';

declare var $: any;
const MAX_DEFAULT_SCROLL_TOP_LOADING_HEIGHT = 175;
const MAX_DEFAULT_SCROLL_BOTTOM_LOADING_HEIGHT = 175;
const BUFFER_SCROLL = 100;
const DEFAULT_AREA_SIZES = [350];
@Component({
    selector: "diversite-search-pane-content",
    templateUrl: "./search-pane-content.component.html",
    styleUrls: ["./search-pane-content.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPaneContentComponent implements OnInit, OnDestroy, OnChanges {
    constructor(
        private translateService: TranslateService,
        private contactsSelectionService: ContactsSelectionService,
        private assignNodeService: ContactContextNodeAssignService,
        private dragDropActionService: DragDropActionService,
        private modulesService: ModuleService,
        private visibleAttributeService: SearchProfileVisibleAttributesService,
        private reportService: ReportService,
        private actionPaneService: ContextNodePaneActionsService,
        private contactService: ContactService,
        private selectionService: ContactsSelectionService,
        private chRef: ChangeDetectorRef
    ) { }

    @Input() projectId: string;
    @Input() contextNodeId: string;
    @Input() searchPagination: SearchPagination;
    @Input() searchDefinition: SearchDefinition;
    @Input() loading = true;
    @Input() tabOpen: SearchParametersTab = "search";
    @Input() searchMeta: SearchMeta;
    @Input() contactResults: ContactResult[] = [];

    @Input() favoriteSearchNodes: SearchFavoriteNode[];
    @Input() areaSizes = DEFAULT_AREA_SIZES;
    @Output() search = new EventEmitter<SearchDefinition>();
    @Output() selectAll = new EventEmitter<SearchDefinition>();
    @Output() contactDefaultImage = new EventEmitter<ChangeContactDefaultImageEvent>();
    @Output() searchDefinitionChange = new EventEmitter<SearchDefinition>();
    @Output() searchPaginationChange = new EventEmitter<SearchPagination>();
    @Output() areaSizesChange = new EventEmitter<number[]>();
    @Output() massEditResults = new EventEmitter<SearchDefinition>();
    @Output() openAddColumnPane = new EventEmitter<void>();
    @Output() changeContactWidth = new EventEmitter<number>();
    @Output() changeTab = new EventEmitter<SearchParametersTab>();


    viewMode: "cards" | "list" = "cards";

    @Input() infoSize = 110;
    @Input() contactWidth = 200;

    private attributeModules$: Observable<FormModule[]>;
    attrs$: Observable<AttributeCombobox[]>;

    shownAttributesSelection: string[] = [];

    formPreviewModal: Form[] | false = false;
    toggleSelectionDisabled = false;

    detailedSearchMetaOpen = false;
    showInfo = false;
    loadingExport = false;

    private scrollContacts$ = new BehaviorSubject<number>(0);
    topHeightLoading = 0;
    bottomHeightLoading = 0;
    isChangingPage = false;


    searchPaneClosed = false;
    private disposeBag = new DisposeBag();

    private _scrollToEnd = false;

    ngOnInit(): void {

        this.scrollContacts$.pipe(filter(_ => !this.isChangingPage), pairwise()).subscribe(scrollData => {
            const beforeScroll = scrollData[0];
            const currentScroll = scrollData[1];
            const scrollingParent = document.getElementById(`contacts_${this.searchDefinition.id}`);

            if (
                this.searchPagination.page !== 1 &&
                beforeScroll > currentScroll &&
                currentScroll <= 0
            ) {

                const rounded = Math.round(-(currentScroll) / 6);
                const height = rounded > MAX_DEFAULT_SCROLL_TOP_LOADING_HEIGHT ? MAX_DEFAULT_SCROLL_TOP_LOADING_HEIGHT : rounded;
                this.topHeightLoading = height;

                if (this.topHeightLoading >= MAX_DEFAULT_SCROLL_TOP_LOADING_HEIGHT) {
                    this.isChangingPage = true;
                    setTimeout(() => {
                        this.scrollContacts$.next(0);
                        this.topHeightLoading = 0;
                        this.isChangingPage = false;
                        this.onPageChange(this.searchPagination.page - 1);
                        this.chRef.markForCheck();
                    }, 1000);
                }

            } else if (
                this.searchPagination.page !== this.totalPages &&
                beforeScroll < currentScroll &&
                currentScroll + scrollingParent.clientHeight >= scrollingParent.scrollHeight
            ) {
                const current = currentScroll + scrollingParent.clientHeight;
                const total = scrollingParent.scrollHeight;
                const rounded = Math.floor((current - total) / 4);

                const height = rounded > MAX_DEFAULT_SCROLL_BOTTOM_LOADING_HEIGHT ? MAX_DEFAULT_SCROLL_BOTTOM_LOADING_HEIGHT : rounded;
                this.bottomHeightLoading = height;

                if (this.bottomHeightLoading >= MAX_DEFAULT_SCROLL_BOTTOM_LOADING_HEIGHT) {
                    this.isChangingPage = true;
                    setTimeout(() => {
                        this.scrollContacts$.next(0);
                        this.bottomHeightLoading = 0;
                        this.isChangingPage = false;
                        this.onPageChange(this.searchPagination.page + 1);
                        this.chRef.markForCheck();
                    }, 1000);
                }
            } else {
                this.topHeightLoading = 0;
                this.bottomHeightLoading = 0;
            }
        }).disposedBy(this.disposeBag);


        this.visibleAttributeService.attributes().subscribe(attrs => {
            this.shownAttributesSelection = attrs.map(attr => attr.attributeId);
            this.chRef.markForCheck();
        }).disposedBy(this.disposeBag)

        this.contactsSelectionService
            .contactsSelection()
            .subscribe((_) => {
                this.chRef.detectChanges();
            })
            .disposedBy(this.disposeBag);

        fromEvent<MouseEvent>(document, "mousedown")
            .subscribe((_) => {
                this.toggleSelectionDisabled = true;
            })
            .disposedBy(this.disposeBag);
        fromEvent<MouseEvent>(document, "mouseup")
            .subscribe((_) => {
                this.toggleSelectionDisabled = false;
            })
            .disposedBy(this.disposeBag);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.areaSizes && !changes.areaSizes.currentValue) {
            this.areaSizes = DEFAULT_AREA_SIZES;
        }

        if (changes.searchPagination) {
            if (changes.searchPagination.previousValue && changes.searchPagination.currentValue) {
                if (changes.searchPagination.previousValue.page === changes.searchPagination.currentValue.page + 1) {
                    this._scrollToEnd = true;
                } else {
                    this._scrollToEnd = false;
                }
            }
        }

        if (changes.contactResults?.currentValue && this._scrollToEnd === true) {
            this.scrollToEnd();
        }

        if (changes.contactWidth) {
            changes.contactWidth.currentValue ? this.contactWidth = changes.contactWidth.currentValue : this.contactWidth = 200;
        }
    }

    get totalPages(): number {
        if (this.searchMeta.total.value && this.searchPagination.limit) {

            return Math.ceil(this.searchMeta.total.value / this.searchPagination.limit);
        }
        return 0;

    }

    onMassEditResults(searchDefinition: SearchDefinition): void {
        this.massEditResults.emit(searchDefinition);
    }

    onCreateReport(): void {
        this.reportService.createReportForSearch(this.searchDefinition).pipe(
            tap(reportId => {
                return this.actionPaneService.openReportEditPane(reportId, null, this.projectId ? this.projectId : null);
            })
        ).subscribe().disposedBy(this.disposeBag)
    }

    labelForIndex(index: string): string {
        return this.modulesService.labelForIndex(index, this.lang);
    }

    isSelected(contactId: string): boolean {
        return this.contactsSelectionService.isSelected(contactId);
    }

    onCloseFormPreview(): void {
        this.formPreviewModal = false;
    }

    selectAllFromSearchDef(): void {
        this.selectAll.emit(this.searchDefinition);
    }

    onScrollparent(event: any): void {
        this.scrollContacts$.next(event.target.scrollTop);
    }

    onChangeTab(tab: SearchParametersTab): void {
        this.changeTab.emit(tab);
    }

    scrollToEnd(): void {
        setTimeout(() => {
            this.chRef.detectChanges();
            const element = document.getElementById(`contacts_${this.searchDefinition.id}`);
            element.scrollTop = element.scrollHeight;
        });
    }

    onWheel(event: any): void {
        const scrollingParent = document.getElementById(`contacts_${this.searchDefinition.id}`);
        if (this.scrollContacts$.value <= 0 && event.deltaY < 0) {
            this.scrollContacts$.next(this.scrollContacts$.value + event.deltaY);
        } else if (this.scrollContacts$.value + scrollingParent.clientHeight + BUFFER_SCROLL >= scrollingParent.scrollHeight) {
            this.scrollContacts$.next(this.scrollContacts$.value + event.deltaY);
        }


    }

    get shownTotalForPage(): number {
        return ((this.searchPagination.page - 1) * this.searchPagination.limit) + this.contactResults.length;
    }

    onComboboxSelectionChange(attributeIds: string[]): void {
        this.visibleAttributeService.setAttributes((attributeIds || []).map(id => {
            return { attributeId: id };
        }));
        this.chRef.markForCheck();
    }

    onSearchPaginationChange(searchPagination: SearchPagination): void {
        this.searchPaginationChange.emit(searchPagination)
    }

    onImageWidthChange(width: any): void {
        this.contactWidth = parseInt(width);
        this.changeContactWidth.emit(this.contactWidth)
    }

    onPageChange(page: number): void {
        this.searchPaginationChange.emit(this.searchPagination.change({ page }))
    }

    onLimitChange(limit: number): void {

        this.searchPaginationChange.emit(this.searchPagination.change({ limit }))
    }

    onColumnsDragEnd(event: any): void {
        this.areaSizes = event.sizes;
        this.areaSizesChange.emit(this.areaSizes);
    }

    onExport(): void {
        this.loadingExport = true;
        return this.actionPaneService.openActionPaneAtIndex("export", 1, { searchDefinition: this.searchDefinition }).subscribe(_ => {
            this.loadingExport = false;
        }).disposedBy(this.disposeBag);
    }

    onCreateFolder(): void {
        return this.actionPaneService.openActionPaneAtIndex("create-folder", 1, { searchDefinition: this.searchDefinition }).subscribe(_ => {
        }).disposedBy(this.disposeBag);
    }

    onSelectBookmark(bookmark: Bookmark): void {
        this.searchDefinition = this.searchDefinition.change({ bookmarkSpec: { ...this.searchDefinition.bookmarkSpec, bookmark } });
        this.searchDefinitionChange.emit(this.searchDefinition);
    }

    changeNewBookmarkSpec(isnew: boolean): void {
        this.searchDefinition = this.searchDefinition.change({ bookmarkSpec: { ...this.searchDefinition.bookmarkSpec, new: isnew } });
        this.searchDefinitionChange.emit(this.searchDefinition);
    }

    onContactsShown(event: ContactLoadedEvent): void {
        $(event.element.nativeElement).draggable({
            appendTo: "body",
            // helper: "clone",
            helper: () => {
                const node = (event.element.nativeElement as HTMLElement).cloneNode(true);
                return node;
            },
            start: (e, ui) => {
                if (!this.contactsSelectionService.isSelected(event.contact.id)) {
                    this.contactsSelectionService.addSelection(event.contact.id);
                }
                this.dragDropActionService.startDragAction({
                    contactIds: this.contactsSelectionService.currentSelection,
                });
            },
            delay: 100,
        });
    }

    onContactDefaultImageChange(event: ChangeContactDefaultImageEvent): void {
        this.contactDefaultImage.emit(event);
    }

    onSearchDefinitionChange(searchDef: SearchDefinition): void {
        this.selectionService.clearSelection();
        this.searchDefinition = searchDef;
        this.searchDefinitionChange.emit(searchDef);
    }

    onOpenFormPane(formId: string, contactId: string): void {
        this.actionPaneService.openFormPane(formId, this.contextNodeId, this.projectId, contactId);
    }

    contactHeight(contactHighlights: ContactHighlight[]): number {
        if (this.shownAttributesSelection?.length > 0 || contactHighlights?.length > 0) {
            return this.contactWidth * 1.2 + this.infoSize;
        }
        return this.contactWidth * 1.2;
    }

    get loadDynamically(): string | boolean {
        return "contactsScroll";
    }

    get lang(): string {
        return this.translateService.lang;
    }

    get total(): string {
        if (this.searchMeta && this.searchMeta.total) {
            if (this.searchMeta.total.relation === "eq") {
                return `${this.searchMeta.total.value}`;
            }
            if (this.searchMeta.total.relation === "gte") {
                return `>${this.searchMeta.total.value}`;
            }
        }
        return "";
    }

    onOpenAddColumnActionPane(): void {
        this.openAddColumnPane.emit();
    }

    toggleSelection(contact: Contact): void {
        this.contactsSelectionService.toggleSelection(contact.id);
    }


    onSortChange(orderField: any): void {
        if (orderField !== this.searchPagination.sort.field) {
            this.searchPagination = this.searchPagination.change({
                page: 1,
                sort: orderField && orderField !== "" ? { field: orderField, direction: "desc" } : null
            });
            this.searchPaginationChange.emit(this.searchPagination);
        }
    }

    onEditContact(contact: Contact): void {
        this.contactService.editContact(contact).subscribe().disposedBy(this.disposeBag);
    }


    trackById(_: number, item: any): string {
        return item.id;
    }

    trackByContactId(_: number, contactResult: ContactResult): string {
        return contactResult.contactId;
    }

    onViewProfile(cp: ContactPerspective): void {
        this.actionPaneService.openContactProfilePane(cp.id, this.projectId).subscribe()
            .disposedBy(this.disposeBag);
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}