<div *ngIf="loaded$ | async; else loading" class="main-container">
    <castlug-notification [notification]="notification$ | async"></castlug-notification>

    <div class="content-container">
        <div class="content-area">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<ng-template #loading>
    <div class="loading"><span>Chargement...</span></div>
</ng-template>
