import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { map, Observable, of } from 'rxjs';

import { FormElement } from '../../model/form/form-element/form-element';
import { SearchOperation } from '../../model/search-operation';
import { FormService } from '../../services/form.service';
import { ModuleService } from '../../services/module.service';

@Component({
    selector: 'diversite-search-form-element-loader',
    templateUrl: './search-form-element-loader.component.html',
    styleUrl: './search-form-element-loader.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchFormElementLoaderComponent implements OnChanges {
    @Input() source: "form" | "config";
    @Input() formId: string;
    @Input() formElementName: string;

    formElement$: Observable<FormElement>;
    @Output() addToFavorite = new EventEmitter<FormElement>();
    @Output() searchOperationAdd = new EventEmitter<SearchOperation>();

    constructor(private attributeModulesService: ModuleService, private formService: FormService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.source?.currentValue && !this.formElement$) {
            const source = changes.source?.currentValue;

            if (source === "form") {
                this.formElement$ = this.formService.form(changes.formId?.currentValue, { listen: false }).pipe(map(form => {
                    return form.formElementByName(this.formElementName);
                }))

            }

            if (source === "config") {
                this.formElement$ = of(this.attributeModulesService.formElementForIndex(this.formElementName))
            }

        }
    }

    onSearchOperationAdd(sop: SearchOperation): void {
        this.searchOperationAdd.emit(sop);
    }

    onAddToFavorite(formElement: FormElement): void {
        this.addToFavorite.emit(formElement);
    }
}
