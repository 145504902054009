import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ContextNodePaneActionsService } from 'src/modules/diversite/components/context-node/context-node-pane-actions.service';
import { Contact } from 'src/modules/diversite/model/contact';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { Form } from 'src/modules/diversite/model/form/form';
import { Activity } from 'src/modules/diversite/services/activity.service';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { FormService } from 'src/modules/diversite/services/form.service';

@Component({
    selector: 'diversite-submit-form-activity',
    templateUrl: './submit-form-activity.component.html',
    styleUrl: './submit-form-activity.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmitFormActivityComponent {
    @Input() activity: SubmitFormActivity;
    @Output() openProfile = new EventEmitter<string>();

    form$: Observable<Form>;
    contact$: Observable<Contact>;

    constructor(private formService: FormService, private contactService: ContactService, private actionService: ContextNodePaneActionsService) { }

    ngOnInit(): void {
        this.form$ = this.formService.form(this.activity.formId);

        if (this.activity.idfig) {
            this.contact$ = this.contactService.contactByIdfig(this.activity.idfig);
        }
    }

    onViewProfile(contact: ContactPerspective): void {
        this.openProfile.emit(contact.id)
    }

    onViewSubmission(contactId: string, formId: string): void {
        this.actionService.openFormPane(formId, undefined, undefined, contactId);
    }
}


export interface SubmitFormActivity extends Activity {
    id: string,
    created: Date,
    idfig: string,
    queryParams?: any,
    formId: string,
    contact: any;
}