import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    LOCALE_ID,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { isDate } from 'src/app/core/functions';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormDatetime } from 'src/modules/diversite/model/form/form-element/form-datetime';

import { DEFAULT_OPTIONS, FormElementGenericOptions } from '../../form-element.component';

registerLocaleData(localeFr);
declare var $: any;
@Component({
    selector: "fillout-datetime",
    templateUrl: "./datetime.component.html",
    styleUrls: ["./datetime.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: LOCALE_ID, useValue: "fr" }],
})
export class DatetimeComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() lang: string;
    @Input() formElement: FormDatetime;
    @Input() value: Date;
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Output() responseChange = new EventEmitter<Date>();
    delayedChanges$ = new BehaviorSubject<void>(undefined);

    private _disposeBag = new DisposeBag();

    private datePicker: any;

    constructor(private host: ElementRef, private chRef: ChangeDetectorRef) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && changes.value.currentValue) {
            if (!isDate(this.value)) {
                this.value = undefined;
            } else {
                this.setDate(this.value);
            }
        }
    }

    ngOnInit(): void {
        this.delayedChanges$
            .pipe(debounceTime(250), skip(1))
            .subscribe(() => {
                this.responseChange.emit(this.datePicker.datepicker("getDate"));
            })
            .disposedBy(this._disposeBag);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.formElement) {
                const datePicketOptions: any = {
                    nextText: ">",
                    prevText: "<",
                    showMonthAfterYear: true,
                    dateFormat: "dd-mm-yy",
                    constrainInput: true,
                    changeMonth: true,
                    changeYear: true,
                    yearRange: "c-100:c+10",
                    monthNames: [
                        "Janvier",
                        "Février",
                        "Mars",
                        "Avril",
                        "Mai",
                        "Juin",
                        "Juillet",
                        "Août",
                        "Septembre",
                        "Octobre",
                        "Novembre",
                        "Décembre",
                    ],
                    dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
                    onSelect: (dateText) => {
                        this.datePicker.datepicker("setDate", dateText);
                        this.onInputChange(dateText);
                    },
                };

                this.datePicker = $("input.datep", this.host.nativeElement);
                this.datePicker.datepicker(datePicketOptions);
                this.setDate(this.value);
                if (this.formElement.readonly) {
                    this.datePicker.datepicker("option", "disabled", true);
                }
            }
        }, 500)


    }

    private setDate(value: Date | string): void {
        if (this.datePicker) {
            this.datePicker.datepicker("setDate", value);
            this.datePicker.datepicker("refresh");
        }

        this.chRef.detectChanges();
    }

    onInputChange(dateText: string): void {
        if (!this.formElement.readonly) {
            if (dateText === "") {
                this.setDate(null);
            } else {
                this.setDate(dateText);
            }
            this.delayedChanges$.next();
        }
    }

    ngOnDestroy(): void {
        if (this.datePicker) {
            // manually removing the calendar because destroy doesnt remove the calendar bug
            const element = this.datePicker.datepicker("widget").get(0);
            setTimeout(() => {
                element.remove();
            })
            this.datePicker.datepicker("destroy");
        }
    }
}
