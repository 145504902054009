<div class="edit-content" *ngIf="editMode; else notEdit">
    <input
        class="fulltextsearch"
        [(ngModel)]="fullTextSearchValue"
        (keypress)="onFullTextKeypress($event)"
        type="text"
        *ngIf="isFullTextSearch"
    />
    <diversite-search-form-element
        *ngIf="searchOperation.source"
        [lang]="lang"
        [display]="display"
        [formElement]="searchOperation.source"
        [searchOperation]="searchOperation"
        (enterPressed)="onEnterPressed()"
        (valueChange)="onSearchOperationValueChange($event)"
    />
    <div class="actions">
        <cds-icon shape="check" (click)="onChangeSearchOperation()"></cds-icon>
        <cds-icon shape="times" (click)="cancelEdit()"></cds-icon>
    </div>
</div>

<ng-template #notEdit>
    <cds-tag
        (click)="clickTag($event)"
        [disabled]="disabled || searchOperation.disabled"
        [closable]="removable"
        color="gray"
        ><cds-icon *ngIf="editable" shape="note" (click)="onEditPrompt($event)"></cds-icon
        ><diversite-attribute-label [maxCharacters]="maxCharacters" [id]="operand"></diversite-attribute-label
        >{{ signForOperator(searchOperation.operator)
        }}<cds-icon *ngIf="searchOperation.occurance === 'must-not'" shape="ban"></cds-icon
        ><strong
            ><diversite-attribute-value
                [maxCharacters]="maxCharacters"
                [attributeName]="searchOperation.operand"
                [value]="value(searchOperation)"
            ></diversite-attribute-value></strong
    ></cds-tag>
</ng-template>
