import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Pane } from "src/modules/diversite/model/pane";

@Component({
    selector: "diversite-edit-form-pane-content-container",
    templateUrl: "./edit-form-pane-content-container.component.html",
    styleUrls: ["./edit-form-pane-content-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditFormPaneContentContainerComponent implements OnChanges {
    @Input() pane: Pane;
    @Input() contextNodeId: string;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {}
}
