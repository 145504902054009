<div class="attribute-index" *ngIf="searchValue">
    <div [class.inline]="display === 'horizontal'">
        <ng-container *ngIf="type === 'text'">
            <input
                class="text"
                autocomplete="off"
                placeholder="valeur"
                name="value"
                (keyup)="onChange(); onKeypress($event)"
                [(ngModel)]="searchValue.value"
            />
        </ng-container>

        <ng-container *ngIf="type === 'textarea'">
            <textarea
                (keyup)="onChange(); onKeypress($event)"
                clrTextarea
                autocomplete="off"
                name="value"
                [(ngModel)]="searchValue.value"
            ></textarea>
        </ng-container>
        <ng-container *ngIf="type === 'number'">
            <select (change)="onChange()" clrSelect [(ngModel)]="searchValue.operator">
                <option value="greaterThan">plus grand que</option>
                <option value="lowerThan">plus petit que</option>
                <option value="range">intervalle</option>
            </select>
            <input
                *ngIf="searchValue.operator !== 'range'"
                clrInput
                autocomplete="off"
                type="number"
                placeholder="valeur"
                name="value"
                (keyup)="onChange(); onKeypress($event)"
                [(ngModel)]="searchValue.value"
            />

            <div class="inline" *ngIf="searchValue.operator === 'range'">
                <input
                    clrInput
                    type="number"
                    placeholder="valeur min."
                    name="valueMin"
                    (keyup)="onChange(); onKeypress($event)"
                    [(ngModel)]="searchValue.valueMin"
                />
                <input
                    clrInput
                    type="number"
                    placeholder="valeur max."
                    name="valueMax"
                    (keyup)="onChange(); onKeypress($event)"
                    [(ngModel)]="searchValue.valueMax"
                />
            </div>
        </ng-container>

        <ng-container *ngIf="type === 'date'">
            <select (change)="onChange()" clrSelect [(ngModel)]="searchValue.operator">
                <option value="lowerThan">Avant le</option>
                <option value="range">Entre le et le</option>
                <option value="greaterThan">Après le</option>
            </select>
            <input
                *ngIf="searchValue.operator !== 'range'"
                autocomplete="off"
                placeholder="valeur"
                name="value"
                clrDate
                (clrDateChange)="onChange()"
                [(clrDate)]="searchValue.value"
            />

            <div [class.inline]="display === 'horizontal'" *ngIf="searchValue.operator === 'range'">
                <input
                    placeholder="valeur min."
                    name="valueMin"
                    clrDate
                    (clrDateChange)="onChange()"
                    [(clrDate)]="searchValue.valueMin"
                />
                <input
                    placeholder="valeur max."
                    name="valueMax"
                    clrDate
                    (clrDateChange)="onChange()"
                    [(clrDate)]="searchValue.valueMax"
                />
            </div>
        </ng-container>

        <ng-container *ngIf="type === 'multiple-selection' || type === 'single-selection'">
            <search-value-mulitple-selection
                [lang]="lang"
                [searchValue]="searchValue"
                [options]="options"
                (searchValueChange)="onChange($event)"
            ></search-value-mulitple-selection>
        </ng-container>

        <ng-container *ngIf="type === 'union'">
            <!-- <input
                clrCheckbox
                type="checkbox"
                [checked]="searchValue.value"
                (change)="searchValue.value = $event.target.checked; onChange()"
            /> -->
            <search-value-union [searchValue]="searchValue" (searchValueChange)="onChange($event)" />
        </ng-container>
        <ng-container *ngIf="type === 'age'">
            <input
                class="age"
                type="number"
                min="0"
                max="120"
                clrInput
                autocomplete="off"
                placeholder="age min."
                (keyup)="onChange(); onKeypress($event)"
                [(ngModel)]="searchValue.valueMin"
            />
            <input
                class="age"
                type="number"
                min="0"
                max="120"
                clrInput
                autocomplete="off"
                placeholder="age max."
                (keyup)="onChange(); onKeypress($event)"
                [(ngModel)]="searchValue.valueMax"
            />
        </ng-container>

        <ng-container *ngIf="type === 'weight'">
            <search-value-weight (searchValueChange)="onChange()" [(searchValue)]="searchValue"></search-value-weight>
        </ng-container>

        <ng-container *ngIf="type === 'height'">
            <search-value-height (searchValueChange)="onChange()" [(searchValue)]="searchValue"></search-value-height>
        </ng-container>

        <ng-container *ngIf="type === 'phone'">
            <input
                clrCheckbox
                type="checkbox"
                [checked]="searchValue.value"
                (change)="searchValue.value = !searchValue.value; onChange()"
            />
        </ng-container>

        <ng-container *ngIf="type === 'availability'">
            TODO
            <!-- <search-value-mulitple-selection
                *ngIf="options.availableChoices"
                [lang]="lang"
                [searchOperationUi]="tempSearchOperation"
                [options]="options"
                (searchOperationUiChange)="onChange($event)"
            ></search-value-mulitple-selection> -->

            <small *ngIf="!options.availableChoices">( voir disponibilité )</small>
        </ng-container>
    </div>
</div>
