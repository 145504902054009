import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ImageLayout } from 'src/modules/diversite/services/face-position.service';

@Component({
    selector: "diversite-photo-row",
    templateUrl: "./photo-row.component.html",
    styleUrls: ["./photo-row.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoRowComponent implements OnInit, OnChanges {
    @Input() image: ImageLayout;
    @Input() showImage = true;
    @Input() showFaceSquare = true;
    @ViewChild("imagebackground") imagebackground: ElementRef;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.image && changes.image.currentValue) {
            this.image.zoom = this.image.zoom || 100;
            this.image.rotate = this.image.rotate || 0;
            this.image.backgroundx = this.image.backgroundx || 0;
            this.image.backgroundy = this.image.backgroundy || 0;
        }
    }

    ngOnInit(): void {}

    safeBackgroundImage(image: ImageLayout): SafeStyle {
        if (this.showImage && image && image.url) {
            return this.sanitizer.bypassSecurityTrustStyle(`url(${image.url})`);
        }
    }

    safeBackgroundSize(image: ImageLayout): SafeStyle {
        const backgroundSize =
            image.width && image.height
                ? `${(image.zoom * image.width) / 100}px ${(image.zoom * image.height) / 100}px`
                : `${image.zoom}%`;

        return this.sanitizer.bypassSecurityTrustStyle(backgroundSize);
    }

    get faceRectangleLeft(): number {
        return (this.image.faceRectangle.left * this.image.zoom) / 100 + this.image.backgroundx;
    }

    get faceRectangleTop(): number {
        return (this.image.faceRectangle.top * this.image.zoom) / 100 + this.image.backgroundy;
    }

    get faceRectangleHeight(): number {
        return (this.image.faceRectangle.height * this.image.zoom) / 100;
    }

    get faceRectangleWidth(): number {
        return (this.image.faceRectangle.width * this.image.zoom) / 100;
    }

    get rotation(): string {
        return `rotate(${this.image.rotate}deg)`;
    }

    get topPos(): number {
        return this.image.backgroundy;
    }

    get leftPos(): number {
        return this.image.backgroundx;
    }
}
