<div class="activity">
    <div class="top">
        Deux profils ont été fusionnés.
        <div class="sr-id">{{ activity.id }}</div>
    </div>
    <!-- <pre style="font-size: 0.45rem; line-height: 0.5rem">{{ activity | json }}</pre> -->
    <div class="bottom">
        <div class="date">
            <small>{{ activity.created | date : "dd-MM-yyyy HH:mm" }}</small>
        </div>
    </div>
</div>
