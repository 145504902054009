import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Package, SharedPackageService } from 'src/modules/diversite/services/shared-folder.service';
import { Observable, switchMap } from 'rxjs';

@Component({
    selector: 'castlug-shared-packages-container',
    templateUrl: './shared-packages-container.component.html',
    styleUrl: './shared-packages-container.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedPackagesContainerComponent {
    package$: Observable<Package>;


    constructor(private packageService: SharedPackageService, private activatedRoute: ActivatedRoute) { }

    ngOnInit(): void {
        this.package$ = this.activatedRoute.params.pipe(switchMap(params => {
            console.log(params);
            return this.packageService.package(params.sharedPackageId, { listen: false });
        }))
    }
}
