<video *ngIf="isUploadedVideo(url)" controls width="250" height="250">
    <source [src]="url" />
    Sorry, your browser doesn't support embedded videos.
</video>

<iframe
    *ngIf="isYoutubeVideo(url)"
    [src]="safeUrl(url)"
    frameborder="0"
    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
></iframe>

<iframe
    *ngIf="isVimeoVideo(url)"
    [src]="safeUrl(url)"
    frameborder="0"
    allow="fullscreen; picture-in-picture"
    allowfullscreen
></iframe>
