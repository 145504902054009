import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { ApiClient } from '../../core/api-clients/api-client';
import { ContactMapperService } from '../mappers/contact-mapper.service';
import { FaceRectangle } from '../model/contact-image';

// import { FormApiClientService } from './form-api-client.service';

@Injectable({
    providedIn: "root",
})
export class ContactApiClientService implements ApiClient {
    constructor(private db: AngularFirestore, private contactMapper: ContactMapperService) { }

    documentReference(id: string): DocumentReference {
        return this.db.collection("contacts").doc(id).ref;
    }
    photoDocumentReference(contactId: string, photoId: string): DocumentReference {
        return this.db.collection("contacts").doc(contactId).collection("pictures").doc(photoId).ref;
    }

    contactById(id: string, options = { listen: true }): Observable<ContactDTO> {
        const obs = this.db
            .collection("contacts")
            .doc(id)
            .collection("pictures", (ref) => ref.orderBy("uploadedAt", "desc"))
            .snapshotChanges()
            .pipe(
                map((docsData) => {
                    return docsData.map((docData) => {
                        const data = docData.payload.doc.data();
                        const imageId = docData.payload.doc.id;
                        return { id: imageId, ...data } as ContactImageDTO;
                    }).filter((image) => !image.deleted);
                }),
                switchMap((sortedImages) => {
                    return this.db
                        .collection("contacts")
                        .doc(id)
                        .snapshotChanges()
                        .pipe(
                            map((doc) => {
                                if (doc.payload.exists) {
                                    const data: any = doc.payload.data();
                                    const contactDto: ContactDTO = {
                                        ...(data as any),
                                        createdAt: data.createdAt,
                                        images: sortedImages,
                                        id: doc.payload.id,
                                    };
                                    return contactDto;
                                } else {
                                    return undefined;
                                }
                            })
                        );
                })
            );
        if (!options?.listen) {
            return obs.pipe(take(1));
        } else {
            return obs;
        }
    }
}

export interface ContactDTO {
    id: string;
    createdAt: firebase.firestore.Timestamp;
    lastUpdate: firebase.firestore.Timestamp;
    attributes: any;
    images: ContactImageDTO[];
    defaultImageIndex: number;
    idfig: string;
    suppressed: boolean;
}

export interface ContactImageDTO {
    id: string;
    originalUrl: string;
    inputSource: string;
    thumbnails: ThumbnailDTO[];
    uploadedAt?: firebase.firestore.Timestamp | Date;
    originalDate?: firebase.firestore.Timestamp | Date;
    rotate?: number;
    deleted?: boolean;
    isPictureOfCellMember?: boolean;
}

export interface ThumbnailDTO {
    thumbnailUrl: string;
    type?: ThumbnailType;
    width: number;
    height: number;
    faceRectangle: FaceRectangle;
    metaData: any;
}

export type ThumbnailType = "face" | "thumbnail";