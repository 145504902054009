import { Injectable } from '@angular/core';
import { Contact } from 'src/modules/diversite/model/contact';

import { ContactPublic } from '../services/recruit.service';

@Injectable({
    providedIn: "root",
})
export class ContactPublicMapperService {
    constructor() { }

    mapDataToContactPublic(c: any, photos = []): ContactPublic {
        return {
            attributes: Object.keys(c)
                .filter((attr) => c[attr])
                .map((index) => {
                    return {
                        index,
                        value: this.valueOf(c[index], index),
                    };
                }),
            photos: photos,
        };
    }

    mapContactToContactPublic(contact: Contact): ContactPublic {

        const imagesByInputSource = {};
        contact.images.filter(img => img.inputSource).forEach(img => {
            if (!imagesByInputSource[img.inputSource]) {
                imagesByInputSource[img.inputSource] = {
                    index: img.inputSource,
                    value: [img.originalUrl],
                }
            } else {
                imagesByInputSource[img.inputSource] = {
                    index: img.inputSource,
                    value: [...imagesByInputSource[img.inputSource].value, img.originalUrl],
                }
            }
        })


        return {
            attributes: [...contact.attributes.map((attr) => {
                return {
                    index: attr.def.id,
                    value: this.valueOf(attr.value, attr.def.id),
                };
            }), ...Object.keys(imagesByInputSource).map(key => {
                return imagesByInputSource[key]
            })],
            photos: [],
        };
    }

    mapContactPublicToData(contact: ContactPublic): any {
        const c: any = {};
        contact.attributes.forEach((attrValue) => {
            c[attrValue.index] = attrValue.value;
        });
        return c;
    }

    private valueOf(value: any, index: string): any {
        if (this.valueIsTimestamp(value)) {
            return new Date(value.seconds * 1000);
        }
        if (Array.isArray(value) && index.startsWith("availability_")) {
            return value.map((dateRange) => {
                return {
                    ...dateRange,
                    from: this.unknownDateToDate(dateRange.from),
                    to: this.unknownDateToDate(dateRange.to),
                };
            });
        }
        return value;
    }

    private unknownDateToDate(object: any): Date {
        if (this.valueIsTimestamp(object)) {
            return object.toDate();
        }
        return object;
    }

    private valueIsTimestamp(value: any): boolean {
        return (
            value && typeof value === "object" && value.hasOwnProperty("seconds") && value.hasOwnProperty("nanoseconds")
        );
    }
}
