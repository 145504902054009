import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: "root",
})
export class SystemDataService {

    constructor(
        private db: AngularFirestore
    ) { }


    version(): Observable<Version> {
        return this.db
            .collection("systemData")
            .doc("version")
            .valueChanges()
            .pipe(
                map((doc: any) => {
                    if (doc && doc.commit && doc.status) {
                        return {
                            commit: doc.commit,
                            status: doc.status,
                        };
                    }
                    return undefined;
                })
            );
    }


}


export interface Version {
    commit: string;
    status: "deploying" | "success" | "error";
}

