<div *ngIf="formElement" class="form-element-edition">
    <span
        #labelElement
        class="edit-label"
        contenteditable
        (keyup)="onLabelChange()"
        [innerHTML]="tempFormElement.label[lang]"
    ></span>
    <ng-content select="[tooltip]"></ng-content>
    <div>
        <input readonly class="fake-input" type="range" />
    </div>
</div>
