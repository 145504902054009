import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import {
    ContactEmailStateRequest,
    ContactFromIdRequest,
    ContactFromLegacyLinkLnkRequest,
    PublicContextSubmissionRequest,
} from 'src/app/api-clients/system-request-api-client.service';
import { SystemRequestService } from 'src/app/services/system-request.service';

import { ContactPublicMapperService } from '../mapper/contact-public-mapper.service';


@Injectable({
    providedIn: "root",
})
export class RecruitService {
    constructor(
        private systemRequestService: SystemRequestService,
        private contactPublicMapper: ContactPublicMapperService
    ) { }

    publicRecruitment(contact: ContactPublic, formId: string, origin: string, queryParams: any, context?: any): Observable<void> {
        const c: any = this.contactPublicMapper.mapContactPublicToData(contact);
        const sysReq: PublicContextSubmissionRequest = {
            requestType: "createandrecruitcontact",
            contact: c || null,
            formId: formId || null,
            origin,
            queryParams,
        };

        if (context) {
            sysReq["context"] = context;
        }
        return this.systemRequestService
            .add(sysReq)
            .pipe(
                tap((sr) => console.log(sr.id)),
                switchMap((systemRequest) => this.systemRequestService.get(systemRequest.id)),
                filter((doc) => doc.hasOwnProperty("status")),
                map((systemRequest: any) => {
                    if (systemRequest.status !== "success") {
                        console.error(systemRequest);
                        throw new Error(systemRequest.error_message);
                    }
                    return undefined;
                })
            );
    }

    recruitExistingContact(
        contact: ContactPublic,
        idfig: string,
        formId: string,
        origin: string,
        queryParams: any,
        context?: any
    ): Observable<undefined> {
        const c: any = this.contactPublicMapper.mapContactPublicToData(contact);

        const sysReq: PublicContextSubmissionRequest = {
            requestType: "recruitContact",
            contact: c || null,
            idfig,
            formId,
            origin,
            queryParams,
        }

        if (context) {
            sysReq["context"] = context;
        }
        return this.systemRequestService
            .add(sysReq)
            .pipe(
                tap((sr) => console.log(sr.id)),
                switchMap((systemRequest) => this.systemRequestService.get(systemRequest.id)),
                filter((doc) => doc.hasOwnProperty("status")),
                map((systemRequest: any) => {
                    if (systemRequest.status !== "success") {
                        console.error(systemRequest);
                        throw new Error(systemRequest.error_message);
                    }
                    return undefined;
                })
            );
    }

    // sendRecruitInvitationLink(email: string, contextSlug: string, origin: string): Observable<void> {
    //     return this.systemRequestService
    //         .add({
    //             requestType: "verifycontactbyemail",
    //             contextId: contextSlug,
    //             origin,
    //             email,
    //         } as SendLinkExistingContactRequest)
    //         .pipe(
    //             tap((sr) => console.log(sr.id)),
    //             switchMap((systemRequest) => this.systemRequestService.get(systemRequest.id)),
    //             filter((doc) => doc.hasOwnProperty("status")),
    //             map((doc) => {
    //                 if (doc.status !== "success") {
    //                     console.error(doc);
    //                 }
    //                 return undefined;
    //             })
    //         );
    // }

    getContactForRandomId(uuid: string): Observable<{
        idfig: string;
        contact: ContactPublic;
    }> {
        return this.systemRequestService
            .add({
                requestType: "loadContactFromLink",
                link: uuid,
            } as ContactFromIdRequest)
            .pipe(
                tap((sr) => console.log(sr.id)),
                switchMap(
                    (systemRequest) =>
                        this.systemRequestService.get(systemRequest.id) as Observable<ContactFromIdRequest>
                ),
                filter((doc) => doc.hasOwnProperty("status")),
                map((doc: ContactFromIdRequest) => {
                    if (doc.status === "success") {
                        const contact: ContactPublic = this.contactPublicMapper.mapDataToContactPublic(
                            doc.contact,
                            doc.photos || []
                        );
                        return {
                            idfig: doc.idfig,
                            contact,
                        };
                    }
                    if (doc.status === "error") {
                        console.error("invalid link");
                    }
                    return undefined;
                })
            );
    }

    getContactForLegacyLinkLnk(uuid: string): Observable<{
        idfig: string;
        contact: ContactPublic;
    }> {
        return this.systemRequestService
            .add({
                requestType: "loadContactFromLegacyLink",
                link: uuid,
            } as ContactFromLegacyLinkLnkRequest)
            .pipe(
                tap((sr) => console.log(sr.id)),
                switchMap(
                    (systemRequest) =>
                        this.systemRequestService.get(systemRequest.id) as Observable<ContactFromLegacyLinkLnkRequest>
                ),
                filter((doc) => doc.hasOwnProperty("status")),
                map((doc: ContactFromIdRequest) => {
                    if (doc.status === "success") {
                        const contact: ContactPublic = this.contactPublicMapper.mapDataToContactPublic(
                            doc.contact,
                            doc.photos || []
                        );
                        return {
                            idfig: doc.idfig,
                            contact,
                        };
                    }
                    if (doc.status === "error") {
                        console.error("invalid link");
                    }
                    return undefined;
                })
            );
    }

    getEmailContactState(email: string): Observable<ContactEmailViewState> {
        if (email) {

            const systemRequest = {
                requestType: "checkupdatemodel",
                email,
            } as ContactEmailStateRequest;
            return this.systemRequestService
                .add(systemRequest)
                .pipe(
                    tap((sr) => console.log(sr.id)),
                    switchMap(
                        (systemRequest) =>
                            this.systemRequestService.get(systemRequest.id) as Observable<ContactEmailStateRequest>
                    ),
                    filter((doc) => doc.hasOwnProperty("status")),
                    map((doc: ContactEmailStateRequest) => {
                        if (doc.status === "success") {



                            const typeForModel = (model: number): ViewStrategy => {
                                if (model === 1) {
                                    return "one-contact-exist"
                                } else if (model === 2) {
                                    return "multiple-contact-exist";
                                } else if (model === 3) {
                                    return "new-contact"
                                } else if (model === 4) {
                                    return "all-supressed";
                                }
                            }

                            return {
                                idfig: this.idfigFromModelResponse(doc),
                                viewStrategy: typeForModel(doc.model),
                                attributesMissing: doc.attributesMissing
                            }
                        }
                        if (doc.status === "error") {
                            console.error("invalid link");
                        }
                        return undefined;
                    })
                );
        }
    }

    private idfigFromModelResponse(doc: ContactEmailStateRequest): string {
        return doc.idfig ? doc.idfig : null
    }
}

export type ViewStrategy = "one-contact-exist" | "multiple-contact-exist" | "new-contact" | "all-supressed";
export interface ContactEmailViewState {
    idfig?: string;
    viewStrategy: ViewStrategy;
    attributesMissing: string[];
}

export interface ContactPublic {
    attributes: AttributeValue[];
    photos: string[];
}
export interface Photo {
    id: string;
    url: string;
}

export interface AttributeValue {
    index: string;
    value: any;
}
