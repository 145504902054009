import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { Observable } from "rxjs";
import { DisposeBag } from "src/modules/core/utilities/dispose-bag";
import { Pane } from "src/modules/diversite/model/pane";
import { Report } from "src/modules/diversite/model/report";
import { ReportService } from "src/modules/diversite/services/report.service";

@Component({
    selector: "diversite-report-edit-pane-content-container",
    templateUrl: "./report-edit-pane-content-container.component.html",
    styleUrls: ["./report-edit-pane-content-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportEditPaneContentContainerComponent implements OnInit, OnChanges, OnDestroy {
    @Input() pane: Pane;

    report$: Observable<Report>;

    private disposeBag = new DisposeBag();

    constructor(private reportService: ReportService) { }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pane && changes.pane.currentValue) {
            const pane = changes.pane.currentValue;
            if (pane.contextData && pane.contextData.reportId) {
                this.report$ = this.reportService.reportById(pane.contextData.reportId, { listen: false });
            } else {
                this.report$ = undefined;
            }
        }
    }

    onEditReport(report: Report): void {
        this.reportService.editReport(report).subscribe().disposedBy(this.disposeBag);
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
