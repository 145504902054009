import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: "root",
})
export class ContextMenuService {
    private contextMenu$ = new BehaviorSubject<ContextMenu>(undefined);

    constructor() {}

    contextMenu(): Observable<ContextMenu> {
        return this.contextMenu$.asObservable();
    }

    openContextMenu(contextMenuElements: ContextMenuElement[], event: PointerEvent): void {
        if (contextMenuElements && contextMenuElements.length > 0) {
            event.preventDefault();
            event.stopPropagation();
            setTimeout(() => {
                this.contextMenu$.next({
                    position: { x: event.clientX, y: event.clientY },
                    elements: contextMenuElements,
                });
            }, 150);
        } else {
            setTimeout(() => {
                this.contextMenu$.next(undefined);
            }, 150);
        }
    }

    closeContextMenu(): void {
        this.contextMenu$.next(undefined);
    }
}

export interface ContextMenuElement {
    icon?: string;
    label: string;
    action: () => void;
}

export interface ContextMenuPosition {
    x: number;
    y: number;
}

export interface ContextMenu {
    position: ContextMenuPosition;
    elements: ContextMenuElement[];
}
