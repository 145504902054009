import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { SearchDefinition } from 'src/modules/diversite/model/search-definition';
import { SearchGroup } from 'src/modules/diversite/model/search-group';
import { SearchOperation } from 'src/modules/diversite/model/search-operation';
import { SearchFavoriteNode } from 'src/modules/diversite/services/search-favorite-node.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';

declare var $: any;

@Component({
    selector: "diversite-search-favorite-node",
    templateUrl: "./search-favorite-node.component.html",
    styleUrls: ["./search-favorite-node.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFavoriteNodeComponent implements AfterViewInit, OnChanges {
    constructor(
        private host: ElementRef,
        private authService: AuthService,
        private db: AngularFirestore,
        private translateService: TranslateService
    ) { }
    @Input({ required: true }) node: SearchFavoriteNode;
    @Input() editFavoriteMode: boolean = false;
    @Input() searchGroup: SearchGroup;

    @Output() nodeChange = new EventEmitter<SearchFavoriteNode>();
    @Output() sortTree = new EventEmitter<void>();
    @Output() removeNode = new EventEmitter<string>();
    @Output() searchOperationChange = new EventEmitter<SearchOperation>();

    searchOperation: SearchOperation = null;

    ngAfterViewInit(): void {
        this.setSortable();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.searchDefinition?.currentValue && this.node.formElement) {
            const searchDefinition: SearchDefinition = changes.searchDefinition?.currentValue;
            this.searchOperation = searchDefinition.searchOperationByName(this.node.formElement.name) || null;
        }
    }

    trackById(_: number, item: any): string {
        return item.id;
    }

    get lang(): string {
        return this.translateService.lang;
    }
    get isLogged(): boolean {
        return this.authService.isLoggedIn;
    }

    private setSortable(): void {
        if (this.node.open) {
            setTimeout(() => {
                $(".search-favorite-nodes", this.host.nativeElement)
                    .first()
                    .sortable({
                        handle: ".handle-option",
                        connectWith: ".search-favorite-nodes",
                        helper: "clone",
                        placeholder: "sortable-placeholder",
                        update: (event, ui) => {
                            this.sortTree.emit();
                        },
                    });
            }, 1000);
        }
    }

    onNodeNameChange(value: string): void {
        this.node = this.node.change({ name: value });
        this.nodeChange.emit(this.node);
    }

    toggleOpen(): void {
        this.node = this.node.change({ open: !this.node.open });
        if (this.isLogged) {
            this.nodeChange.emit(this.node);
            this.setSortable();
        }
    }

    onRemoveNode(childrenNodeId?: string): void {
        if (childrenNodeId) {
            this.node = this.node.change({
                children: this.node.children.filter((an) => an.id !== childrenNodeId),
            });
            this.nodeChange.emit(this.node);
        } else {
            this.removeNode.emit(this.node.id);
        }
    }

    onSortTree(): void {
        this.sortTree.emit();
    }

    onSopAdd(sop: SearchOperation): void {
        this.searchOperationChange.emit(sop);
    }

    addSubfolder(): void {
        const id = this.db.createId();

        const newFolder = new SearchFavoriteNode({
            id,
            name: "Nouveau Groupe",
            children: [],
            type: "folder",
            open: false,
        });
        this.node = this.node.change({
            children:
                this.node.children && this.node.children.length > 0 ? [...this.node.children, newFolder] : [newFolder],
        });
        this.nodeChange.emit(this.node);
    }

    onSearchFavoriteNodeChildChange(node: SearchFavoriteNode): void {
        this.node = this.node.change({
            children: this.node.children.map((c) => {
                return c.id === node.id ? node : c;
            }),
        });
        this.nodeChange.emit(this.node);
    }
}
