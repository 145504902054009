import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminContainerComponent } from 'src/modules/admin/admin-container/admin-container.component';
import { adminRoutes } from 'src/modules/admin/admin-routing';
import { AuthGuard } from 'src/modules/core/auth.guard';
import { DiversiteContainerComponent } from 'src/modules/diversite/diversite-container/diversite-container.component';
import { ContextResolver } from 'src/modules/diversite/resolvers/context.resolver';

import { diversiteRoutes } from '../modules/diversite/diversite-routing';
import { CastlugContainerComponent } from './components/castlug-container/castlug-container.component';
import { LoginContainerComponent } from './components/login-register/login-container/login-container.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PublicContextContainerComponent } from './components/public-context-container/public-context-container.component';
import { ReportPrintContainerComponent } from './components/report-print-container/report-print-container.component';
import {
    SharedPackagesContainerComponent,
} from './components/shared-packages-container/shared-packages-container.component';

const routes: Routes = [

    {
        path: "c/:contextId",
        component: PublicContextContainerComponent,
        resolve: { context: ContextResolver },
    },
    {
        path: "documents/:sharedPackageId",
        component: SharedPackagesContainerComponent,
    },
    {
        path: "report/:report",
        component: ReportPrintContainerComponent,
        canActivate: [AuthGuard],
    },
    { path: "", redirectTo: "castlug", pathMatch: "full" },
    {
        path: "castlug",
        component: CastlugContainerComponent,
        data: {
            breadcrumb: null,
        },
        children: [
            { path: "", redirectTo: "login", pathMatch: "full" },
            { path: "login", component: LoginContainerComponent },
            // { path: "sign-up", component: SignUpContainerComponent },
            // {
            //     path: "l",
            //     component: LoggedContainerComponent,
            //     children: [
            //         {
            //             path: "applications",
            //             component: ApplicationContainerComponent,
            //             data: {
            //                 breadcrumb: null,
            //             },
            //             children: [
            //                 // { path: "", component: ApplicationListContainerComponent },
            //                 // { path: "b/:app/:plan", component: ApplicationBuyContainerComponent },
            //                 ,
            //                 { path: "**", component: NotFoundComponent },
            //             ],
            //         },
            //     ],
            // },
            {
                path: "diversite",
                component: DiversiteContainerComponent,
                data: {
                    breadcrumb: "Diversité",
                },
                children: diversiteRoutes,
            },
            {
                path: "admin",
                component: AdminContainerComponent,
                children: adminRoutes,
                canActivate: [AuthGuard],
            },
            { path: "**", component: NotFoundComponent },
        ],
    },
    { path: "**", component: NotFoundComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
