import { Subscription } from 'rxjs';

export class DisposeBag {
    private _subscription: Subscription[] = [];

    insert(subscription: Subscription): void {
        this._subscription.push(subscription);
    }

    dispose(): void {
        this._subscription.forEach(subscription => {
            subscription.unsubscribe();
        });
        this._subscription = [];
    }
}

export function disposedBy(this: Subscription, bag: DisposeBag): void {
    bag.insert(this);
}

Subscription.prototype.disposedBy = disposedBy;

declare module "rxjs/internal/Subscription" {
    interface Subscription {
        disposedBy: typeof disposedBy;
    }
}
