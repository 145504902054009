import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Submission } from '../model/submission';
import { ContactPublic, RecruitService } from './recruit.service';

@Injectable({
    providedIn: "root",
})
export class SubmissionService {
    constructor(private recruitService: RecruitService) { }

    publicSubmitForm(submission: Submission, formId: string, origin: string, queryParams: any, context?: any): Observable<void> {
        return this.recruitService.publicRecruitment(
            this.mapSubmissionToContactPublic(submission),
            formId,
            origin,
            queryParams,
            context || null
        );
    }

    existingContactSubmitForm(
        submission: Submission,
        idfig: string,
        formId: string,
        origin: string,
        queryParams: any,
        context?: any
    ): Observable<void> {
        return this.recruitService.recruitExistingContact(
            this.mapSubmissionToContactPublic(submission),
            idfig,
            formId,
            origin,
            queryParams,
            context || null
        );
    }


    publicSubmitUploadPhoto(submission: Submission, origin: string, queryParams: any): Observable<void> {
        return this.recruitService.publicRecruitment(
            this.mapSubmissionToContactPublic(submission),
            null,
            origin,
            queryParams
        );
    }

    private mapSubmissionToContactPublic(submission: Submission): ContactPublic {
        const attributes = submission.responseInputs.map((response) => {
            return { index: response.input.name, value: response.value };
        });

        return {
            attributes,
        } as ContactPublic;
    }
}

export interface QuerySubmissionOptions {
    formId?: string;
    contactId?: string;
}
