import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Contact } from 'src/modules/diversite/model/contact';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { Pane } from 'src/modules/diversite/model/pane';
import { SearchDefinition, SearchPagination } from 'src/modules/diversite/model/search-definition';
import { ContactContextNodeAssignService } from 'src/modules/diversite/services/contact-context-node-assign.service';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { ContactsSelectionService } from 'src/modules/diversite/services/contacts-selection.service';
import { ContextNodeService } from 'src/modules/diversite/services/context-node.service';
import { DragDropActionService } from 'src/modules/diversite/services/drag-drop-action.service';

import { ContextNodePaneActionsService } from '../../../context-node/context-node-pane-actions.service';
import { PaneReferenceService } from '../../services/pane-reference.service';
import { guid } from 'src/app/core/functions';

declare var $: any;

@Component({
    selector: "diversite-contacts-pane-content-container",
    templateUrl: "./contacts-pane-content-container.component.html",
    styleUrls: ["./contacts-pane-content-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsPaneContentContainerComponent implements OnInit, OnDestroy {
    @Input() pane: Pane;

    contacts$: Observable<Contact[]>;

    faceHeight = 150;

    private disposeBag = new DisposeBag();

    constructor(
        private contactService: ContactService
    ) { }

    ngOnInit(): void {
        this.contacts$ = this.contactService.searchContacts(
            SearchDefinition.DEFAULT_SEARCH_DEFINITION(guid()).setContextNode(this.pane.contextNodeId),
            new SearchPagination({ limit: 9999 }),
            { simpleProfile: true }
        ).pipe(map(results => results.contactResults.map(cr => cr.contact)))
    }

    ngOnChanges(changes: SimpleChanges): void { }


    trackById(_, entity: any): string {
        return entity.id;
    }

    // ngAfterViewInit(): void {
    //     this.setDroppable();
    // }


    // onViewProfile(cp: ContactPerspective): void {
    //     this.actionService.openContactProfilePane(cp.id, this.pane.projectId, this.pane?.contextData?.formId)
    //         .subscribe()
    //         .disposedBy(this.disposeBag);
    // }

    // onRemoveContact(contact: Contact): void {
    //     this.contactService.editContact(contact.toggleContextNode(this.pane.contextNodeId)).subscribe().disposedBy(this.disposeBag);
    //     this.assignContactService.unassignContactIdsFromContextNode([contact.id], this.pane.contextNodeId);
    // }

    // onMassEditContacts(contactIds: string[]): void {
    //     this.actionService
    //         .openActionPaneAtIndex(
    //             "mass-edit",
    //             this.paneRefService.currentLayout.findIndex((paneContent) => paneContent.paneRef.id === this.pane.id) + 1,
    //             { contactIds }
    //         )
    //         .subscribe()
    //         .disposedBy(this.disposeBag);
    // }

    // onSendForm(contactIds: string[]): void {
    //     this.actionService
    //         .openActionPaneAtIndex(
    //             "send-form",
    //             this.paneRefService.currentLayout.findIndex((paneContent) => paneContent.paneRef.id === this.pane.id) + 1,
    //             { contactIds, projectId: this.pane.projectId || null }
    //         )
    //         .subscribe()
    //         .disposedBy(this.disposeBag);
    // }

    // onCreateReport(): void {
    //     this.contextNodeService.createReportForNodeId(this.pane.contextNodeId).subscribe().disposedBy(this.disposeBag);
    // }

    // onExport(contactIds: string[]): void {
    //     this.actionService
    //         .openActionPaneAtIndex(
    //             "export",
    //             this.paneRefService.currentLayout.findIndex((paneContent) => paneContent.paneRef.id === this.pane.id) + 1,
    //             { searchDefinition: SearchDefinition.DEFAULT_SEARCH_DEFINITION_CONTACT_IDS(contactIds) }
    //         )
    //         .subscribe()
    //         .disposedBy(this.disposeBag);
    // }

    // onSelectContact(contactId: string): void {
    //     this.contactSelectionService.toggleSelection(contactId);
    // }

    // private setDroppable(): void {
    //     const node = $(this.host.nativeElement);
    //     node.droppable({
    //         greedy: true,
    //         tolerance: "pointer",
    //         drop: (e: any) => {
    //             this.dragDropActionService
    //                 .dropDragAction({ nodeId: this.pane.contextNodeId, projectId: this.pane.projectId })
    //                 .pipe(take(1))
    //                 .subscribe((response) => { })
    //                 .disposedBy(this.disposeBag);
    //         },
    //     });
    // }

    // onOpenAddColumnActionPane(): void {
    //     this.actionService
    //         .openActionPaneAtIndex(
    //             "add-grid-column",
    //             this.paneReferenceService.currentLayout.findIndex((paneContent) => paneContent.paneRef.id === this.pane.id) + 1,
    //             {
    //                 contextNodeId: this.pane.contextNodeId,
    //                 paneId: this.pane.id,
    //             }
    //         )
    //         .subscribe()
    //         .disposedBy(this.disposeBag);
    // }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
