import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { FileUploadGenericService } from 'src/modules/core/services/file-upload.service';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Contact } from 'src/modules/diversite/model/contact';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { ContactVideosService } from 'src/modules/diversite/services/contact-videos.service';
import { ContactService } from 'src/modules/diversite/services/contact.service';

import { AddPhotosForContactEvent } from '../contact-view/contact-view.component';

@Component({
    selector: "app-contact-edit-container",
    templateUrl: "./contact-edit-container.component.html",
    styleUrls: ["./contact-edit-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactEditContainerComponent implements OnInit, OnDestroy {
    contactPerspective$: Observable<ContactPerspective>;
    private disposeBag = new DisposeBag();

    constructor(
        private route: ActivatedRoute,
        private contactService: ContactService,
        private notificationService: NotificationService,
        private videoService: ContactVideosService,
        private fileUploadService: FileUploadGenericService,
        private title: Title
    ) { }

    ngOnInit(): void {
        this.contactPerspective$ = this.contactService.contactById(this.route.snapshot.params.id).pipe(
            filter((c) => (c && c.defaultPerspective ? true : false)),
            map((c) => c.defaultPerspective),
            tap((cp) => this.title.setTitle(`${cp.contact.fullname} - ${cp.contact.idfig} - youlug`))
        );
    }

    onEditContact(contact: Contact): void {
        this.contactService
            .editContact(contact)
            .subscribe((_) => {
                this.notificationService.show("Le profil à été modifié avec succès !", "success");
            })
            .disposedBy(this.disposeBag);
    }

    onAddPhotos(event: AddPhotosForContactEvent): void {
        this.fileUploadService
            .uploadFiles(event.files)
            .pipe(
                switchMap((response) => {
                    return forkJoin(
                        response.urls.map((url) => this.contactService.addContactPhoto(event.contact.idfig, url))
                    ).pipe(switchMap(() => this.contactService.contactById(event.contact.id)));
                }),
                take(1)
            )
            .subscribe((c) => {
                this.notificationService.show(
                    "Vos images on bien été téléchargé. Un temps de traitement est requis.",
                    "info"
                );
            })
            .disposedBy(this.disposeBag);
    }

    ngOnDestroy(): void {
        this.title.setTitle("youlug");
        this.disposeBag.dispose();
    }
}
