import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { debounceTime, skip } from "rxjs/operators";
import { DisposeBag } from "src/modules/core/utilities/dispose-bag";
import { FormElement, FormElementType } from "src/modules/diversite/model/form/form-element/form-element";
import { FormTable } from "src/modules/diversite/model/form/form-element/form-table";
import { TranslatableLabel } from "src/modules/diversite/services/data-catalog.service";

@Component({
    selector: "diversite-form-table-edition",
    templateUrl: "./form-table-edition.component.html",
    styleUrls: ["./form-table-edition.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTableEditionComponent implements OnInit, OnChanges, OnDestroy {
    @Input() formElement: FormTable;
    @Input() readonly readonlyElement = false;
    @Output() formElementChange = new EventEmitter<FormElement>();
    @ViewChild("labelElement") labelElement: ElementRef;

    delayedChanges$ = new BehaviorSubject<void>(undefined);

    private _disposeBag = new DisposeBag();

    tempFormElement: {
        label: TranslatableLabel;
        fields: TableFieldUI[];
    } = {
        label: {},
        fields: [],
    };
    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.lang && changes.lang.currentValue && !this.tempFormElement.label[changes.lang.currentValue]) {
            this.tempFormElement.label[changes.lang.currentValue] = "";
        }
    }

    ngOnInit(): void {
        this.tempFormElement = {
            label: this.formElement ? this.formElement.label : {},
            fields:
                this.formElement && this.formElement.fields
                    ? this.formElement.fields.map((f) => {
                          return {
                              label: f.label,
                              type: f.type as FormElementType,
                              description: f.description,
                              required: f.required,
                              options: f.options
                                  ? f.options.map((tf) => {
                                        return { label: tf.label, name: tf.name };
                                    })
                                  : undefined,
                          };
                      })
                    : [],
        };

        this.delayedChanges$
            .pipe(debounceTime(500), skip(1))
            .subscribe((_) => {
                this.formElement = this.formElement.change({
                    label: this.labelElement.nativeElement.textContent,
                    fields: this.tempFormElement.fields.map((f) => {
                        return {
                            label: f.label,
                            type: f.type,
                            required: f.required,
                            description: f.description,
                            options: f.options || null,
                        };
                    }),
                });
                this.formElementChange.emit(this.formElement);
            })
            .disposedBy(this._disposeBag);
    }

    ngOnDestroy(): void {
        this._disposeBag.dispose();
    }

    onLabelChange(): void {
        this.delayedChanges$.next();
    }

    onFieldTypeChange(value: FormElementType, field: TableFieldUI): void {
        if (value === "dropdown" && !field.options) {
            field.options = [
                { label: "Option 1", name: "" },
                { label: "Option 2", name: "" },
            ];
        }
        this.delayedChanges$.next();
    }

    changeFieldRequired(field: TableFieldUI, index: number): void {
        this.tempFormElement.fields.map((f, i) => {
            if (index === i) {
                return (field.required = !field.required);
            }
            return field;
        });
        this.delayedChanges$.next();
    }

    removeField(index: number): void {
        this.tempFormElement.fields = this.tempFormElement.fields.filter((f, i) => i !== index);
        this.delayedChanges$.next();
    }

    addTableField(): void {
        this.tempFormElement.fields.push({
            label: "Champ",
            description: "",
            type: "textbox",
            required: false,
        });
        this.delayedChanges$.next();
    }

    onTooltipChanged(tooltip: string, fieldUi: TableFieldUI): void {
        fieldUi.description = tooltip;
        this.delayedChanges$.next();
    }

    addOptionToField(field: TableFieldUI): void {
        field.options.push({
            label: "Option",
            name: "",
        });
        this.delayedChanges$.next();
    }

    deleteOption(field: TableFieldUI, indexOption: number): void {
        field.options = field.options.filter((value, index) => indexOption !== index);
        this.delayedChanges$.next();
    }
}

export interface TableFieldUI {
    label: string;
    type: FormElementType;
    description: string;
    required: boolean;
    options?: { label: string; name: string }[];
}
