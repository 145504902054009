<clr-input-container *ngIf="formElement" [class.no-label]="!options.showLabel" class="form-element-text">
    <label *ngIf="options.showLabel"
        >{{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>
    <input
        type="text"
        clrInput
        class="datep"
        (change)="onInputChange($event.target.value)"
        [name]="formElement.name"
        [readonly]="formElement.readonly"
        [required]="formElement.required"
    />
</clr-input-container>
