<div class="clr-row">
    <div class="clr-col-3 login-content">
        <form class="login">
            <div class="input-castlug-container">
                <input type="text" name="email" placeholder="Courriel" [(ngModel)]="email" />
            </div>

            <div class="input-castlug-container">
                <input type="password" name="password" placeholder="Mot de passe" [(ngModel)]="password" />
            </div>

            <button (click)="login(email, password)" class="btn btn-primary castlug-primary">Connexion</button>
            <!-- <a [routerLink]="['../sign-up']" class="sign-up">S'enregistrer</a> -->
        </form>
    </div>
    <div class="clr-col-9 faces-content">
        <castlug-faces></castlug-faces>
    </div>
</div>
<div class="lang">FR / EN</div>
