import { Routes } from '@angular/router';

import { AuthGuard } from '../core/auth.guard';
import { AccessRequest } from '../diversite/services/access-role.service';
import { AccessRolesComponent } from './components/access-roles/access-roles.component';
import { ErrorsComponent } from './components/errors/errors.component';
import { ModulesComponent } from './components/modules/modules.component';

export const adminRoutes: Routes = [
    { path: "", redirectTo: "errors", pathMatch: "full" },
    {
        path: "errors", component: ErrorsComponent, canActivate: [AuthGuard], data: {
            access: {
                type: 'segment',
                segment: "admin",
                permissions: ["read", "write", "update", "delete"]
            } as AccessRequest
        }
    },
    {
        path: "modules", component: ModulesComponent, canActivate: [AuthGuard], data: {
            access: {
                type: 'segment',
                segment: "admin",
                permissions: ["read", "write", "update", "delete"]
            } as AccessRequest
        }
    },
    {
        path: "access-role", component: AccessRolesComponent, canActivate: [AuthGuard], data: {
            access: {
                type: 'segment',
                segment: "admin",
                permissions: ["read", "write", "update", "delete"]
            } as AccessRequest
        }
    },
];
