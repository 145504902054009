<div class="form-elements">
    <diversite-search-form-element-standalone
        *ngFor="let formElement of formElements"
        #formElementSearchComponents
        [lang]="lang"
        [formElement]="formElement"
        [searchOperation]="searchOperationsFormElement[formElement.name]"
        (searchOperationAdd)="onAdd($event)"
    />
</div>
