<div *ngIf="image && image.url" #imagebackground class="photo-row face-centralized">
    <div
        class="image-holder"
        [style.background-image]="safeBackgroundImage(image)"
        [style.background-size]="safeBackgroundSize(image)"
        [style.background-position-x.px]="leftPos"
        [style.background-position-y.px]="topPos"
        [style.transform]="rotation"
    ></div>
    <div
        *ngIf="image.faceRectangle && showFaceSquare"
        class="face-show"
        [style.top.px]="faceRectangleTop"
        [style.left.px]="faceRectangleLeft"
        [style.width.px]="faceRectangleWidth"
        [style.height.px]="faceRectangleHeight"
    ></div>
</div>

<div *ngIf="image && !image.url" class="photo-row no-image">
    <em>(image non-traité)</em>
</div>
<div *ngIf="!image" class="photo-row no-image">
    <em>(sans images)</em>
</div>
