import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ClrCombobox } from '@clr/angular';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

import { ElasticSearchService, LabelMeta } from '../../services/elasticsearch.service';
import { TranslateService } from '../../services/translate.service';

@Component({
    selector: "diversite-attribute-multiselect",
    templateUrl: "./attribute-multiselect.component.html",
    styleUrls: ["./attribute-multiselect.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeMultiselectComponent {
    @Input() label: string;
    @Input() selection: string[] = [];
    @Output() selectionChange = new EventEmitter<string[]>();
    loading = false;
    value: string[] = [];

    @ViewChild("combobox", { read: ClrCombobox }) combobox: ClrCombobox<string[]>;

    constructor(private translateService: TranslateService, private attributeSearchService: ElasticSearchService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selection?.currentValue) {
            if (changes.selection.currentValue.join("") !== this.value.join("")) {
                this.value = changes.selection.currentValue;
            }
        }
    }

    searchAttributeChange$ = new BehaviorSubject<string>(undefined);
    attributes$: Observable<LabelMeta[]> = this.searchAttributeChange$.pipe(
        debounceTime(1000),
        switchMap((v) => {
            const trimmedValue = v?.trim();
            if (trimmedValue && trimmedValue !== "") {
                return this.attributeSearchService.findLabels(trimmedValue);
            }
            return of([]);
        }),
        tap((_) => {
            // console.log(_);
            this.loading = false;
        })
    );

    onSelectionChange(): void {
        if (this.combobox.textbox) {
            this.combobox.textbox.nativeElement.value = "";
        }
        if (!this.value) {
            this.value = [];
        }
        this.selectionChange.emit(this.value);
    }

    get lang(): string {
        return this.translateService.lang
    }

    trackById(_: number, entity: any): string {
        return entity.id;
    }
    onAttributeCheckboxChange(index: string): void {
        const exists = this.selection.find((i) => index === i);
        if (exists) {
            this.selection = this.selection.filter((i) => index !== i);
        } else {
            this.selection = [...this.selection, index];
        }
    }

    onComboboxOpenChange(event: any): void {
        // console.log("onComboboxOpenChange", event, this.attributeCombobox);
    }

    onComboboxInputChange(event: any): void {
        // console.log("onComboboxInputChange", event, this.attributeCombobox);
    }

    onComboboxSelectionChange(event: any): void {
        // console.log("onComboboxSelectionChange", event, this.attributeCombobox);
        this.combobox.textbox.nativeElement.value = "";
    }

    isAttributeChecked(index: string): boolean {
        return this.selection.find((i) => index === i) ? true : false;
    }

    fetchAttributes(value?: any): void {
        this.loading = true;
        this.searchAttributeChange$.next(value);
    }
}
