import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: "root",
})
export class StaticLabelsService {
    constructor(private db: AngularFirestore) {}

    labelsForComponent(componentId: string): Observable<StaticLabels> {
        return this.db
            .collection("systemData")
            .doc("translations")
            .collection("labels")
            .doc(componentId)
            .get()
            .pipe(
                map((doc: DocumentData) => {
                    if (doc.exists) {
                        return doc.data().labels;
                    }
                    return [];
                })
            );
    }
}

export interface StaticLabels {
    [labelDescription: string]: StaticTranslatableLabel;
}

export interface StaticTranslatableLabel {
    isHtml?: boolean;
    en?: string;
    fr?: string;
    // and potentially all languages
}
