import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { readableDate } from 'src/app/core/functions';
import {
    SearchOperationStrategyService,
} from 'src/modules/core/components/search-value-selector/search-operation-strategy.service';
import {
    SearchValue,
    SearchValueOption,
    SearchValueSelectorComponent,
    SearchValueSelectorOption,
} from 'src/modules/core/components/search-value-selector/search-value-selector.component';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormAvailability } from 'src/modules/diversite/model/form/form-element/form-availability';
import { FormCheckbox } from 'src/modules/diversite/model/form/form-element/form-checkbox';
import { FormDropdown } from 'src/modules/diversite/model/form/form-element/form-dropdown';
import { FormElementInput } from 'src/modules/diversite/model/form/form-element/form-element';
import { FormOption } from 'src/modules/diversite/model/form/form-element/form-option';
import { FormRadio } from 'src/modules/diversite/model/form/form-element/form-radio';
import { Occurance, Operator, SearchOperation, SearchOperationType } from 'src/modules/diversite/model/search-operation';

import { SearchValueType, SearchValueTypeService } from './search-value-type.service';

export const DEFAULT_TEMP: TempSearchOperation = {
    operator: "query",
    occurance: "must",
    operand: null,
    value: null,
    valueMin: null,
    valueMax: null,
};
@Component({
    selector: "diversite-search-form-element",
    templateUrl: "./search-form-element.component.html",
    styleUrls: ["./search-form-element.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFormElementComponent implements OnInit, OnDestroy {
    @Input() formElement: FormElementInput;
    @Input() lang: string;
    @Input() searchOperation: SearchOperation;
    @Input() display: "vertical" | "horizontal" = "vertical";
    @Output() valueChange = new EventEmitter<SearchOperation>();
    @Output() enterPressed = new EventEmitter<void>();
    @ViewChild("searchValueSelectorComponent") searchValueSelectorComponent: SearchValueSelectorComponent;

    options: SearchValueSelectorOption;
    type: SearchValueType;

    searchValue: SearchValue;
    occurance: Occurance = "must";

    boost: number = 1;

    disposeBag = new DisposeBag();

    constructor(
        private searchOpStrategyBuilder: SearchOperationStrategyService,
        private searchValueTypeService: SearchValueTypeService,
        private chRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.options = this.optionsForFormElement();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.type) {
            this.type = this.searchValueTypeForFormElement();
        }

        if (changes.searchOperation && changes.searchOperation.currentValue) {
            const sop = changes.searchOperation.currentValue;
            this.occurance = sop.occurance;
            this.boost = sop.boost;
            this.searchValue = this.searchOpStrategyBuilder.searchValueForSearchOperation(
                changes.searchOperation.currentValue,
                this.type
            );
        }
        if (changes.searchOperation && !changes.searchOperation.currentValue) {
            if (!this.type) {
                this.type = this.searchValueTypeForFormElement();
            }
            this.searchValue = this.searchOpStrategyBuilder.defaultSearchValueForType(this.type);
        }
    }

    reset(): void {
        this.searchValueSelectorComponent.reset();
    }

    onKeypress(event: string): void {
        if (event === "Enter") {
            this.enterPressed.emit();
        }
    }

    private searchValueTypeForFormElement(): SearchValueType {
        return this.searchValueTypeService.valueType(this.formElement);
    }

    formOptionToSearchValueOption(options: FormOption[]): SearchValueOption[] {
        return options.map((opt) => {
            return {
                id: opt.id,
                label: opt.label[this.lang],
                value: opt.id
            };
        });
    }

    value(searchValue: SearchValue): SearchOperation {
        const sop = this.searchOpStrategyBuilder
            .assignSearchValueToSearchOperations(this.searchOperation, this.type, searchValue)
            .change({
                source: this.formElement,
                occurance: this.occurance,
                boost: this.boost,
                operand: this.formElement.name,
            });
        return sop;
    }

    formOptionSingleSelectionSourceToSearchValueOption(fe: FormDropdown | FormRadio): SearchValueOption[] {
        return fe.options.map((opt) => {
            return {
                id: fe.name,
                label: opt.label[this.lang],
                value: opt.id,
            };
        });
    }

    private optionsForFormElement(): SearchValueSelectorOption {
        const options: SearchValueSelectorOption = {};
        if (this.formElement) {
            if (this.formElement.type === "dropdown") {
                const fe = this.formElement as FormDropdown;
                options.availableChoices = this.formOptionSingleSelectionSourceToSearchValueOption(fe);
            }
            if (this.formElement.type === "radio") {
                const fe = this.formElement as FormRadio;
                options.availableChoices = this.formOptionSingleSelectionSourceToSearchValueOption(fe);
            }
            if (this.formElement.type === "checkbox") {
                const fe = this.formElement as FormCheckbox;
                options.availableChoices = this.formOptionToSearchValueOption(fe.options);
            }
            if (this.formElement.type === "availability") {
                const fe = this.formElement as FormAvailability;
                // options.availableChoices = [...fe.options];
                if (fe.availabilityType === "predetermined") {
                    options.availableChoices = fe.options.map((opt) => {
                        return {
                            id: opt.id,
                            label: `${readableDate(opt.value.from, "dd-MM-yyyy")} - ${readableDate(
                                opt.value.to,
                                "dd-MM-yyyy"
                            )}`,
                            value: opt.value,
                            type: "availability",
                        };
                    });
                }
            }
        }
        return options;
    }

    onOccuranceChange(): void {
        this.valueChange.emit(this.value(this.searchValue));
    }
    onBoostChange(): void {
        this.valueChange.emit(this.value(this.searchValue));
    }

    onSearchValueChange(value: SearchValue): void {
        this.searchValue = value;
        this.searchOperation = this.value(this.searchValue);
        this.valueChange.emit(this.searchOperation);
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}

export interface TempSearchOperation {
    id?: string;
    type?: SearchOperationType;
    operator: Operator;
    occurance: Occurance;
    operand: string;
    value?: any;
    valueMin?: any;
    valueMax?: any;
}
