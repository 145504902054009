<div [class.no-label]="!options.showLabel" class="clr-form-control" *ngIf="formElement">
    <label *ngIf="options.showLabel" class="clr-control-label">
        {{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>

    <div class="clr-control-container">
        <input
            [(ngModel)]="prefix"
            class="clr-input prefix"
            [name]="name('prefix')"
            type="text"
            (keyup)="onInputChange()"
            [readonly]="formElement.readonly"
            [required]="formElement.required"
        />-<input
            [name]="name('id')"
            [(ngModel)]="id"
            class="clr-input id"
            type="text"
            (keyup)="onInputChange()"
            [readonly]="formElement.readonly"
            [required]="formElement.required"
        />
    </div>
</div>
