import '@cds/core/tag/register.js';
import '@clr/icons';
import '@clr/icons/shapes/all-shapes';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { ErrorHandler, NgModule } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CdsModule } from '@cds/angular';
import { ClarityModule } from '@clr/angular';
import { AdminModule } from 'src/modules/admin/admin.module';
import {
    SearchValueSelectorComponent,
} from 'src/modules/core/components/search-value-selector/search-value-selector.component';
import { CoreModule } from 'src/modules/core/core.module';
import { ErrorHandlerService } from 'src/modules/core/services/error-handler.service';
import { DiversiteModule } from 'src/modules/diversite/diversite.module';
import { FilloutModule } from 'src/modules/fillout/fillout.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CastlugContainerComponent } from './components/castlug-container/castlug-container.component';
import { FacesComponent } from './components/faces/faces.component';
import { LoginContainerComponent } from './components/login-register/login-container/login-container.component';
import { LoginComponent } from './components/login-register/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PublicContextContainerComponent } from './components/public-context-container/public-context-container.component';
import { ReportPrintContainerComponent } from './components/report-print-container/report-print-container.component';
import {
    SharedPackagesContainerComponent,
} from './components/shared-packages-container/shared-packages-container.component';



@NgModule({
    declarations: [
        AppComponent,
        NotFoundComponent,
        LoginComponent,
        CastlugContainerComponent,
        NotificationComponent,
        LoginContainerComponent,
        FacesComponent,
        PublicContextContainerComponent,
        ReportPrintContainerComponent,
        SharedPackagesContainerComponent,
    ],
    imports: [AppRoutingModule, CoreModule, DiversiteModule, FilloutModule, AdminModule, ClarityModule, CdsModule],
    providers: [{ provide: ErrorHandler, useClass: ErrorHandlerService }, Title],
    exports: [SearchValueSelectorComponent],
    bootstrap: [AppComponent],
})
export class AppModule { }
