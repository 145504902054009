import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { Translatable } from 'src/modules/core/components/translatable';
import { FileUploadGenericService } from 'src/modules/core/services/file-upload.service';
import { StaticLabels, StaticLabelsService } from 'src/modules/core/services/static-labels.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormVoiceupload } from 'src/modules/diversite/model/form/form-element/form-voiceupload';

import { AudioData } from '../../audio-recording/audio-recording.component';
import { DEFAULT_OPTIONS, FormElementGenericOptions } from '../../form-element.component';

@Component({
    selector: "fillout-voiceupload",
    templateUrl: "./voiceupload.component.html",
    styleUrls: ["./voiceupload.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VoiceuploadComponent implements OnInit, OnDestroy, Translatable {
    private translationId = "lFxB1at2O3rbF4h7MuAd";
    @Input() lang: string;
    @Input() formElement: FormVoiceupload;
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Output() responseChange = new EventEmitter<string>();

    uploading = false;
    audio: AudioData;
    audioUrl: string;

    private translationLabels: StaticLabels = {};

    private disposeBag = new DisposeBag();

    constructor(
        private chRef: ChangeDetectorRef,
        private fileUploadService: FileUploadGenericService,
        private staticLabelsService: StaticLabelsService
    ) { }

    ngOnInit(): void {
        this.staticLabelsService
            .labelsForComponent(this.translationId)
            .pipe(take(1))
            .subscribe((labels) => {
                this.translationLabels = labels;
                this.chRef.markForCheck();
            })
            .disposedBy(this.disposeBag);
    }

    label(labelId: string): string {
        if (this.translationLabels && this.translationLabels[labelId] && this.translationLabels[labelId][this.lang]) {
            return this.translationLabels[labelId][this.lang];
        }
        return "";
    }

    onAudioChange(audio: AudioData): void {
        if (!this.formElement.readonly) {
            this.audio = audio;
            this.uploading = true;
            this.fileUploadService
                .upload(audio.file)
                .subscribe((response) => {
                    this.audioUrl = response.asset.finalUrl;
                    this.responseChange.emit(this.audioUrl);
                    this.uploading = false;
                    this.chRef.detectChanges();
                })
                .disposedBy(this.disposeBag);
        }
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
