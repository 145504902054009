<cds-divider />

<table class="table table-compact table-noborder">
    <tbody>
        <ng-container *ngFor="let project of projects; trackBy: trackById">
            <tr *hasAccess="{ type: 'project', projectId: project.id, permissions: ['read'] }">
                <td class="left">{{ project.name }}</td>
                <td class="left">
                    <span
                        class="label"
                        [class.label-success]="project.status === 'active'"
                        [class.label-warning]="project.status === 'archived'"
                        [class.label-danger]="project.status === 'deleted'"
                    >
                        {{ status(project.status) }}</span
                    >
                </td>
                <td class="right">
                    <clr-tooltip>
                        <cds-icon clrTooltipTrigger [shape]="'share'" (click)="onShare(project)"></cds-icon>
                        <clr-tooltip-content [clrPosition]="'top-left'" [clrSize]="'sm'">
                            Partager le projet
                        </clr-tooltip-content>
                    </clr-tooltip>

                    <clr-tooltip>
                        <cds-icon
                            clrTooltipTrigger
                            *ngIf="project.status !== 'active'"
                            [shape]="'backup-restore'"
                            (click)="onRestore(project)"
                        ></cds-icon>
                        <clr-tooltip-content [clrPosition]="'top-left'" [clrSize]="'sm'">
                            Restaurer le projet
                        </clr-tooltip-content>
                    </clr-tooltip>

                    <clr-tooltip *hasAccess="{ type: 'project', projectId: project.id, permissions: ['delete'] }">
                        <cds-icon
                            clrTooltipTrigger
                            *ngIf="project.status !== 'deleted'"
                            [shape]="'times'"
                            (click)="onDeletePrompt(project)"
                        ></cds-icon>
                        <clr-tooltip-content [clrPosition]="'top-left'" [clrSize]="'sm'">
                            Effacer le projet
                        </clr-tooltip-content>
                    </clr-tooltip>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>

<diversite-delete-project-modal
    [project]="projectToDelete"
    (closeModal)="onCloseProjectDeletion()"
    (confirmDelete)="onConfirmProjectDeletion(projectToDelete)"
></diversite-delete-project-modal>
