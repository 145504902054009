<div
    *ngIf="contactPerspective && contactPerspective.contact"
    class="contact-card"
    [class.deactivate]="contactPerspective.contact.suppressed"
>
    <div [hidden]="!contactPerspective.contact.suppressed" class="deactivate-box"></div>
    <cds-icon size="md" class="has-cell" *ngIf="contactPerspective.contact.hasCell" shape="users"></cds-icon>
    <cds-icon
        size="md"
        class="is-picture-cell"
        [hidden]="!contactPerspective.contact.images[contactPerspective.defaultImageIndex]?.cellPicture"
        shape="employee"
    ></cds-icon>

    <div [style.height.px]="height" class="card-img">
        <diversite-slideshow
            (dblclick)="openProfile()"
            [cardFaceDispositionOptions]="cardFaceDispositionOptions"
            [showSquareFace]="showSquareFace"
            [currentImageIndex]="contactPerspective.defaultImageIndex"
            (defaultImageChange)="onDefaultImageChange($event)"
            [hidden]="!showSlideshow && lazyload"
            [images]="imageLayouts"
            [editable]="editable"
            (imageChanged)="onImageChange($event)"
        >
        </diversite-slideshow>
        <div class="name">
            {{ contactPerspective.contact.fullname }}
            {{ contactPerspective.contact.age }}
        </div>
    </div>

    <div [hidden]="!hasAnyOptions" class="buttons" (mousedown)="onButtonsClick($event)">
        <div class="controls">
            <cds-icon *ngIf="options.zoom" (click)="zoomOut()" shape="minus-circle"></cds-icon>
            <cds-icon *ngIf="options.zoom" (click)="zoomIn()" shape="plus-circle"></cds-icon>
            <cds-icon *ngIf="options.rotate" (click)="rotate()" shape="refresh"></cds-icon>
            <cds-icon
                *ngIf="options.move"
                shape="cursor-hand-open"
                [draggable]
                (dragged)="onHandDrag($event)"
                (draggedEnd)="onDragEnd($event)"
            >
            </cds-icon>
            <cds-icon (click)="log()" shape="bug"></cds-icon>
        </div>
    </div>

    <ng-content></ng-content>
</div>
