import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, DocumentData } from '@angular/fire/compat/firestore';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

import { FormService } from './form.service';
import { ModulesAttributesService } from './modules-attributes.service';
import { TranslateService } from './translate.service';

@Injectable({
    providedIn: "root",
})
export class AttributesContextPerspectiveService {
    constructor(
        private modulesAttributesService: ModulesAttributesService,
        private translateService: TranslateService,
        private formService: FormService,
        private authService: AuthService,
        private db: AngularFirestore
    ) { }

    perspectiveForContext(context?: AttributeNodesContext): Observable<AttributeNode[]> {
        // priorization of context
        // form -> project -> contact -> fallback on modules structure

        if (this.authService.isLoggedIn) {
            let docSnapshot: AngularFirestoreDocument<DocumentData>;

            if (context?.formId) {
                docSnapshot = this.db
                    .collection("attributePerspectives")
                    .doc(this.authService.loggedInUser.id)
                    .collection("perspectives")
                    .doc(context.formId);
            } else if (context?.projectId) {
                docSnapshot = this.db
                    .collection("attributePerspectives")
                    .doc(this.authService.loggedInUser.id)
                    .collection("perspectives")
                    .doc(context.projectId);
            } else if (context?.contactId) {
                docSnapshot = this.db
                    .collection("attributePerspectives")
                    .doc(this.authService.loggedInUser.id)
                    .collection("perspectives")
                    .doc(context.contactId);
            } else {
                docSnapshot = this.db
                    .collection("attributePerspectives")
                    .doc(this.authService.loggedInUser.id)
                    .collection("perspectives")
                    .doc("global");
            }

            return docSnapshot.snapshotChanges().pipe(
                switchMap((doc) => {
                    if (doc.payload.exists) {
                        let data = doc.payload.data() as any;
                        if (data.nodes) {
                            return of(this.attributeNodesDtoToAttributeNodes(data.nodes));
                        } else {
                            return this.modulesDefaultPerspective();
                        }
                    } else {
                        if (context?.formId) {
                            return this.formContextStrategy(context)
                        } else if (context?.projectId) {
                            // return this.projectContextStrategy(context)
                        } else if (context?.contactId) {
                            // return this.contactContextStrategy(context)
                        } else {
                        }
                        return this.modulesDefaultPerspective();
                    }
                })
            );
        }

        return this.modulesDefaultPerspective();
    }

    lastSelectedContext(): Observable<AttributeNodesContext> {
        return this.db.collection("attributePerspectives").doc(this.authService.loggedInUser.id).get().pipe(map(d => {
            if (d.exists) {
                const context: any = d.data();
                if (context?.lastSelectedContext) {
                    return context.lastSelectedContext as AttributeNodesContext;
                }
            }
            return null;

        }));
    }

    updateLastSelectedContext(context: AttributeNodesContext): Observable<void> {
        return from(this.db.collection("attributePerspectives").doc(this.authService.loggedInUser.id).set({ lastSelectedContext: context ? context : null }));
    }

    saveAttributePerspective(nodes: AttributeNode[], context?: AttributeNodesContext): Observable<void> {
        if (this.authService.isLoggedIn) {
            console.log(nodes, context);

            const collection = this.db
                .collection("attributePerspectives")
                .doc(this.authService.loggedInUser.id)
                .collection("perspectives");

            if (context) {
                if (context.formId) {
                    return from(
                        collection.doc(context.formId).set({ nodes: this.attributeNodesToAttributeNodesDto(nodes) })
                    ).pipe(take(1));
                } else if (context.projectId) {
                    return from(
                        collection.doc(context.projectId).set({ nodes: this.attributeNodesToAttributeNodesDto(nodes) })
                    ).pipe(take(1));
                } else if (context.contactId) {
                    return from(
                        collection.doc(context.contactId).set({ nodes: this.attributeNodesToAttributeNodesDto(nodes) })
                    ).pipe(take(1));
                } else {
                    return from(
                        collection.doc("global").set({ nodes: this.attributeNodesToAttributeNodesDto(nodes) })
                    ).pipe(take(1));
                }
            } else {
                return from(
                    collection.doc("global").set({ nodes: this.attributeNodesToAttributeNodesDto(nodes) })
                ).pipe(take(1));
            }
        } else {
            return of(undefined);
        }
    }

    resetAttributeNodesForContext(context: AttributeNodesContext): Observable<void> {
        return this.modulesDefaultPerspective().pipe(
            switchMap((attrNodes) => {
                return this.saveAttributePerspective(attrNodes, context);
            })
        );
    }

    private formContextStrategy(context: AttributeNodesContext): Observable<AttributeNode[]> {
        return this.modulesDefaultPerspective().pipe(switchMap(attributeModules => {
            return this.formService.form(context.formId, { listen: false }).pipe(map(form => {
                return [new AttributeNode({
                    id: form.id, open: true, name: form.name[this.translateService.lang], children: form.activeFormElements().map(fe => {
                        return new AttributeNode({ id: fe.id, attributeId: fe.name })
                    })
                })];
            }))
        }))
    }
    private projectContextStrategy(context: AttributeNodesContext): Observable<AttributeNode[]> {
        console.log("projectContextStrategy", context);
        return of([]);
    }
    private contactContextStrategy(context: AttributeNodesContext): Observable<AttributeNode[]> {
        console.log("contactContextStrategy", context);
        return of([]);
    }

    private modulesDefaultPerspective(): Observable<AttributeNode[]> {
        return this.modulesAttributesService.getModules().pipe(
            map((modules) => {
                return modules
                    .sort((a, b) => a.order - b.order)
                    .filter(m => m.id ? true : false)
                    .map((m) => {
                        return new AttributeNode({
                            id: m.id,
                            open: false,
                            name: m.name[this.translateService.lang],
                            children: m.attributes
                                .sort((a, b) => a.order - b.order)
                                .filter(a => a.index ? true : false)
                                .map((a) => {
                                    return new AttributeNode({ id: a.index, attributeId: a.index });
                                }),
                        });
                    });
            })
        );
    }

    private attributeNodesToAttributeNodesDto(nodes: AttributeNode[]): AttributeNodeDTO[] {
        return nodes.filter(n => n.id ? true : false).map((n) => {
            return {
                id: n.id,
                name: n.name,
                children: n.children ? this.attributeNodesToAttributeNodesDto(n.children) : null,
                attributeId: n.attributeId,
                open: n.open,
            };
        });
    }

    private attributeNodesDtoToAttributeNodes(nodesDto: AttributeNodeDTO[]): AttributeNode[] {
        return nodesDto.map((n) => {
            return new AttributeNode({
                id: n.id,
                name: n.name,
                children: n.children ? this.attributeNodesDtoToAttributeNodes(n.children) : null,
                attributeId: n.attributeId,
                open: n.open,
            });
        });
    }
}

export interface AtributeNodeBuilder {
    id?: string;
    name?: string;
    children?: AttributeNode[];
    attributeId?: string;
    open?: boolean;
}
export class AttributeNode {
    private _id: string;
    private _name?: string;
    private _children?: AttributeNode[];
    private _attributeId?: string;
    private _open?: boolean;

    constructor(builder: AtributeNodeBuilder) {
        this._id = builder.id;
        this._name = builder.name || null;
        this._children = builder.children || null;
        this._attributeId = builder.attributeId || null;
        this._open = builder.open || null;
    }

    get id(): string {
        return this._id;
    }
    get name(): string {
        return this._name;
    }
    get children(): AttributeNode[] {
        return this._children;
    }
    get attributeId(): string {
        return this._attributeId;
    }
    get open(): boolean {
        return this._open;
    }

    change(builder: AtributeNodeBuilder): AttributeNode {
        return new AttributeNode({
            id: this.id,
            name: this.name,
            children: this.children,
            attributeId: this.attributeId,
            open: this.open,
            ...builder,
        });
    }
}

export interface AttributeNodeDTO {
    id?: string;
    name?: string;
    children?: AttributeNodeDTO[];
    attributeId?: string;
    open?: boolean;
}

export interface AttributeNodesContext {
    contactId?: string;
    formId?: string;
    projectId?: string;
}
export type AttributePerspectiveType = "global" | "project" | "form" | "contact";
