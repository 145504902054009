<div class="create-folder-container">
    <div class="top">
        <div *ngIf="contacts$ | async as contacts" class="contacts">
            <div class="contact" *ngFor="let contact of contacts; trackBy: trackById">
                <diversite-photo-cover
                    [mode]="'cover'"
                    [url]="contact.images[0]?.url"
                    [rotate]="contact.images[0]?.rotate"
                >
                </diversite-photo-cover>
            </div>
            <div class="more" *ngIf="metaMore$ | async as meta">
                ... plus <strong>{{ meta }}</strong>
            </div>
        </div>
    </div>
    <div class="middle">
        <clr-select-container>
            <label>Projet</label>
            <select clrSelect name="share-project" [(ngModel)]="projectId">
                <option value="">-</option>
                <option [value]="project.id" *ngFor="let project of projects$ | async">{{ project.name }}</option>
            </select>
        </clr-select-container>
        <clr-input-container>
            <label>Nom du dossier</label>
            <input clrInput required [(ngModel)]="folderName" />
        </clr-input-container>
        <clr-spinner clrMedium [hidden]="!isCreating"></clr-spinner>
    </div>
    <div class="bottom">
        <button [disabled]="!submitEnabled" (click)="createFolder()" class="btn btn-sm btn-primary">
            Créer le dossier
        </button>
        <button (click)="onCancel()" class="btn btn-sm">Cancel</button>
    </div>
</div>
