<div *ngIf="errors$ | async as errors">
    <table class="table compact-table">
        <tr>
            <th class="left">actions</th>
            <th class="left">id</th>
            <th class="left">user</th>
            <th class="left">url</th>
            <th class="left">message</th>
            <th class="left">browser</th>
            <th class="left">error</th>
            <th class="left">version</th>
            <th class="left">image</th>
            <th class="left">date</th>
            <th class="left">actions</th>
        </tr>

        <tr [id]="'row' + error.id" *ngFor="let error of errors; trackBy: trackById">
            <td class="left">
                <cds-icon shape="times" (click)="removeError(error.id)"></cds-icon>
            </td>
            <td class="left">{{ error.id }}</td>
            <td class="left">{{ error.user?.id }}</td>
            <td class="left url">
                <a [href]="error.url" target="_blank">{{ error.url }}</a>
            </td>
            <td class="left">{{ error.error?.message }}</td>
            <td class="left browser">{{ error.browser }}</td>
            <td class="left error">
                <pre>{{ error.error | json }}</pre>
                <div class="error" [innerHTML]="error.error.stack"></div>
            </td>
            <td class="left">{{ error.version }}</td>
            <td class="left"><a (click)="openImage(error.image)">Image</a></td>
            <td class="left">{{ error.date | date }}</td>
            <td class="left">
                <cds-icon shape="times" (click)="removeError(error.id)"></cds-icon>
            </td>
        </tr>
    </table>
</div>
