import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, Observable } from 'rxjs';

import { TranslatableLabel } from './data-catalog.service';

@Injectable({
    providedIn: 'root'
})
export class ExportTemplateService {

    constructor(private db: AngularFirestore) { }


    templates(options: { listen: boolean }): Observable<ExportTemplate[]> {
        return this.db.collection("systemData").doc("definitions").collection("templates").get().pipe(
            map(docs => {
                return docs.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        ...data
                    } as ExportTemplate
                })
            })
        )
    }
}

export interface ExportTemplate {
    id: string;
    attributes: string[];
    documents: any[];
    name: TranslatableLabel;
    options: ExportOptions;
}

export interface ExportOptions {
    imagesOptions: ExportImageOptions;
    excelOptions: ExportExcelOptions
}

export interface ExportImageOptions {
    includeImages: boolean;
    quality: ImageQuality;
    images?: ImageCollectionDefinition;
}

export interface ExportExcelOptions {
    includeFile: boolean;
    fields: string[];
}

export interface ImageCollectionDefinition {
    type: "all" | "dateRange",
    date?: {
        from: Date;
        to: Date;
    }
}

export type ImageQuality = "original" | "compressed"