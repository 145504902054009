<div class="report-container">
    <div *ngIf="isPictureGrid()" class="pages-container picture-grid">
        <div
            *ngIf="report.introPage"
            class="page-intro"
            [style.width.in]="pagesWidth"
            [style.min-width.in]="pagesWidth"
            [style.max-width.in]="pagesWidth"
            [style.height.in]="pagesHeight"
            [style.min-height.in]="pagesHeight"
            [style.max-height.in]="pagesHeight"
            [style.padding.in]="report.pageMargin"
        >
            <diversite-text-html-editor
                *ngIf="!print"
                [contentHtml]="introPageContent"
                (contentChange)="onIntroPageContentChange($event)"
            ></diversite-text-html-editor>

            <div *ngIf="print" [innerHTML]="introPageContent"></div>
        </div>
        <div
            *ngFor="let page of pagesPictureGrid; let i = index"
            [style.width.in]="pagesWidth"
            [style.min-width.in]="pagesWidth"
            [style.max-width.in]="pagesWidth"
            [style.height.in]="pagesHeight"
            [style.min-height.in]="pagesHeight"
            [style.max-height.in]="pagesHeight"
            [style.padding.in]="report.pageMargin"
            [style.backgroundImage]="logoBackgroundImage()"
            [style.backgroundSize.%]="report.logoSize"
            class="page"
        >
            <input
                class="page-title"
                [readOnly]="print"
                (keyup)="onReportNameChange($event.target.value)"
                [value]="report.name"
            />
            <div class="contacts">
                <div
                    class="contact"
                    [style.width.in]="report.photoSize"
                    [style.height.in]="report.photoSize"
                    [style.margin.in]="report.photoMargin"
                    *ngFor="let contactPerspective of page.contactPerspectives"
                >
                    <diversite-contact-report
                        [loadOnVisibility]="print ? false : true"
                        [reportType]="report.type"
                        [editable]="!print"
                        [contactPerspective]="contactPerspective"
                        [cardFaceDispositionOptions]="faceDispositionChange$ | async"
                        (contactPerspectiveChange)="onContactPerspectiveChange($event)"
                    ></diversite-contact-report>
                </div>
            </div>

            <div class="page-number">Propulsé par Castlug &copy;<br />{{ i + 1 }}/{{ pagesPictureGrid.length }}</div>
        </div>
    </div>

    <div *ngIf="isPictureInfo()" class="pages-container picture-info">
        <div
            *ngIf="report.introPage"
            class="page-intro"
            [style.width.in]="pagesWidth"
            [style.min-width.in]="pagesWidth"
            [style.max-width.in]="pagesWidth"
            [style.height.in]="pagesHeight"
            [style.min-height.in]="pagesHeight"
            [style.max-height.in]="pagesHeight"
            [style.padding.in]="report.pageMargin"
        >
            <diversite-text-html-editor
                *ngIf="!print"
                [contentHtml]="introPageContent"
                (contentChange)="onIntroPageContentChange($event)"
            ></diversite-text-html-editor>

            <div *ngIf="print" [innerHTML]="introPageContent"></div>
        </div>
        <div
            *ngFor="let page of pagesPictureInfo; let i = index; trackBy: trackByIndex"
            [style.width.in]="pagesWidth"
            [style.min-width.in]="pagesWidth"
            [style.max-width.in]="pagesWidth"
            [style.height.in]="pagesHeight"
            [style.min-height.in]="pagesHeight"
            [style.max-height.in]="pagesHeight"
            [style.backgroundImage]="logoBackgroundImage()"
            [style.backgroundSize.%]="report.logoSize"
            class="page"
        >
            <input
                class="page-title"
                [readOnly]="print"
                (keyup)="onReportNameChange($event.target.value)"
                [value]="report.name"
            />
            <div class="contacts layout{{ report.profilePerPage }} {{ report.orientation }}">
                <div class="contact" *ngFor="let contactPerspective of page.contactPerspectives; trackBy: trackById">
                    <diversite-contact-report
                        [loadOnVisibility]="!print"
                        [showAttributes]="report.contactProfileDefinition"
                        [reportType]="report.type"
                        [editable]="!print"
                        [contactPerspective]="contactPerspective"
                        (contactPerspectiveChange)="onContactPerspectiveChange($event)"
                    ></diversite-contact-report>
                </div>
            </div>

            <div class="page-number">Propulsé par youlug &copy;<br />{{ i + 1 }}/{{ pagesPictureInfo.length }}</div>
        </div>
    </div>

    <div *ngIf="!print" class="options-container" [class.open]="optionsOpen">
        <div [hidden]="!optionsOpen" class="options">
            <button (click)="doPrint()" class="btn btn-primary btn-sm">Imprimer le rapport</button>

            <div class="print-options">
                <small>Type de rapport</small>
                <clr-radio-wrapper (click)="changeType('picture-grid')">
                    <input type="radio" clrRadio value="picture-grid" [checked]="isPictureGrid()" name="reportType" />
                    <label>Grille photo</label>
                </clr-radio-wrapper>

                <clr-radio-wrapper (click)="changeType('picture-info')">
                    <input type="radio" clrRadio value="picture-info" [checked]="isPictureInfo()" name="reportType" />
                    <label>Information/photo</label>
                </clr-radio-wrapper>
            </div>
            <div class="print-options">
                <small>Options générale</small>
                <clr-radio-wrapper (click)="changeOrientation('landscape')">
                    <input type="radio" clrRadio value="picture-grid" name="orientation" [checked]="isLandscape()" />
                    <label>Paysage</label>
                </clr-radio-wrapper>
                <clr-radio-wrapper (click)="changeOrientation('portrait')">
                    <input type="radio" clrRadio value="list" name="orientation" [checked]="isPortrait()" />
                    <label>Portrait</label>
                </clr-radio-wrapper>

                <clr-checkbox-container>
                    <clr-checkbox-wrapper>
                        <input
                            type="checkbox"
                            clrCheckbox
                            (change)="onIntroPageChange($event)"
                            [checked]="report.introPage"
                            name="introPage"
                        />
                        <label>Page d'introduction</label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>

                <div class="clr-form-control">
                    <label class="clr-control-label">Logo</label>
                    <div *ngIf="!report.logo" class="clr-control-container">
                        <div class="clr-file-wrapper">
                            <label for="basic" class="clr-control-label"
                                ><span class="btn btn-sm">Image...</span></label
                            >
                            <input
                                #fileLogo
                                type="file"
                                id="basic"
                                placeholder="Enter value here"
                                class="clr-file"
                                (change)="fileLogoChange()"
                            />
                        </div>
                    </div>
                </div>
                <img class="logo" *ngIf="report.logo" [attr.src]="report.logo" />
                <clr-range-container *ngIf="report.logo" class="no-margin">
                    <label>Grandeur logo</label>
                    <input
                        type="range"
                        clrRange
                        [value]="report.logoSize"
                        min="1"
                        max="100"
                        step="2"
                        name="logoSize"
                        (change)="logoSizeChange($event.target.value)"
                        class="slider"
                    />
                    <clr-control-helper>{{ report.logoSize }} %</clr-control-helper>
                </clr-range-container>
                <small class="clear-logo" *ngIf="report.logo" (click)="clearLogo()">Effacer</small>
            </div>

            <div *ngIf="isPictureGrid()" class="print-options">
                <small>Options de grille</small>

                <clr-range-container class="no-margin">
                    <label>Grandeur de photo</label>
                    <input
                        type="range"
                        clrRange
                        (change)="thumbnailSizeChange($event.target.value)"
                        [value]="report.photoSize"
                        min="0.1"
                        max="7.5"
                        step="0.1"
                        class="slider"
                    />
                    <clr-control-helper>{{ report.photoSize }} in.</clr-control-helper>
                </clr-range-container>
                <!-- <clr-checkbox-container>
            <clr-checkbox-wrapper>
                <input type="checkbox" clrCheckbox [checked]="faceCentered" (change)="changeFaceDisposition()" />
                <label>Keep face centered</label>
            </clr-checkbox-wrapper>
        </clr-checkbox-container> -->

                <clr-range-container>
                    <label>Proportion face/photo</label>
                    <input
                        type="range"
                        clrRange
                        (change)="proportionFaceChange($event.target.value)"
                        [value]="report.proportionFace"
                        min="0.1"
                        max="1"
                        step="0.1"
                        class="slider"
                    />
                    <clr-control-helper>{{ report.proportionFace * 100 }} % du carré</clr-control-helper>
                </clr-range-container>

                <clr-range-container>
                    <label>Marge des photos</label>
                    <input
                        type="range"
                        clrRange
                        (change)="thumbnailMarginChange($event.target.value)"
                        [value]="report.photoMargin"
                        min="0"
                        max="0.3"
                        step="0.01"
                        class="slider"
                    />
                    <clr-control-helper>{{ report.photoMargin }} in.</clr-control-helper>
                </clr-range-container>
                <clr-range-container>
                    <label>Marge des pages</label>
                    <input
                        type="range"
                        clrRange
                        (change)="pagesMarginChange($event.target.value)"
                        [value]="report.pageMargin"
                        min="0"
                        max="1"
                        step="0.01"
                        class="slider"
                    />
                    <clr-control-helper>{{ report.pageMargin }} in.</clr-control-helper>
                </clr-range-container>
            </div>

            <div *ngIf="isPictureInfo()" class="print-options">
                <small>Options d'info/photo</small>
                <clr-select-container class="no-margin">
                    <label>Profils/page</label>
                    <select
                        (change)="onProfilePerPageChange($event)"
                        clrSelect
                        [value]="report.profilePerPage"
                        name="options"
                        required
                    >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="4">4</option>
                    </select>
                </clr-select-container>
                <br />
                <diversite-attribute-multiselect
                    [selection]="report.contactProfileDefinition"
                    (selectionChange)="onFieldSelectionChange($event)"
                ></diversite-attribute-multiselect>
            </div>
        </div>
        <div (click)="toggleOptions()" class="options-bar">
            <cds-icon shape="cog"></cds-icon>
        </div>
    </div>
</div>
