import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { map, Observable } from "rxjs";
import { BatchSystemRequestService } from "../api-clients/batch-system-request.service";

import { SystemRequest, SystemRequestApiClientService } from "../api-clients/system-request-api-client.service";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: "root",
})
export class SystemRequestService {
    constructor(
        private systemRequestApi: SystemRequestApiClientService,
        private batchSystemRequestApi: BatchSystemRequestService,
        private db: AngularFirestore,
        private authService: AuthService
    ) { }

    systemRequests(options?: SystemRequestQueryOptions): Observable<SystemRequest[]> {
        return this.db.collection("systemRequests", ref => {
            if (options?.afterDate) {
                return ref.limit(options?.limit ? options.limit : 250).orderBy("created", "desc").startAfter(options.afterDate)
            } else {
                return ref.limit(options?.limit ? options.limit : 250).orderBy("created", "desc");
            }
        }).snapshotChanges().pipe(
            map((docRefs) => {
                return docRefs.map(docRef => {
                    const id = docRef.payload.doc.id;
                    return { id, ...docRef.payload.doc.data() as any };
                })
            })
        );
    }

    add(systemRequest: SystemRequest, collection?: string): Observable<SystemRequest> {
        if (this.authService.isLoggedIn) {
            systemRequest.user = this.db.collection("users").doc(this.authService.loggedInUser.id).ref;
        }
        systemRequest.created = new Date();
        return this.systemRequestApi.add(systemRequest, collection);
    }

    get(id: string, collection?: string): Observable<SystemRequest> {
        return this.systemRequestApi.get(id, collection);
    }

    addBatch(systemRequest: SystemRequest): Observable<SystemRequest> {
        return this.batchSystemRequestApi.add(systemRequest);
    }
}

export interface SystemRequestQueryOptions {
    afterDate?: Date;
    limit?: number
}