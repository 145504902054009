<ng-container *ngFor="let bookmark of bookmarks$ | async; trackBy: trackById; let last = last">
    <diversite-bookmark
        [selected]="selectedBookmark?.id === bookmark.id"
        [bookmark]="bookmark"
        (selectBookmark)="select($event)"
    ></diversite-bookmark>
    <cds-icon *ngIf="!last" direction="right" shape="angle-double"></cds-icon>
</ng-container>

<clr-toggle-wrapper>
    <input type="checkbox" clrToggle (change)="changeNewState($event.target.checked)" [checked]="viewNew" />
    <label>Nouveaux</label>
</clr-toggle-wrapper>
<!-- 
<div class="btn-group toggle-new">
    <div class="radio btn btn-sm">
        <input type="radio" name="toggle-new" />
        <label>Apples</label>
    </div>
    <div class="radio btn btn-sm">
        <input type="radio" name="toggle-new" />
        <label>Oranges</label>
    </div>
    <div class="radio btn btn-sm">
        <input type="radio" name="toggle-new" />
        <label>Kiwis</label>
    </div>
    <div class="radio btn btn-sm">
        <input type="radio" name="toggle-new" checked />
        <label>Pears</label>
    </div>
</div> -->
