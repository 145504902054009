import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Bookmark } from 'src/modules/diversite/services/bookmark.service';

@Component({
    selector: 'diversite-bookmark',
    templateUrl: './bookmark.component.html',
    styleUrl: './bookmark.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookmarkComponent {
    @Input() bookmark: Bookmark;
    @Input() selected: boolean = false;

    @Output() selectBookmark = new EventEmitter<Bookmark>();


    constructor() { }

    clickBookmark(): void {
        if (!this.selected) {
            this.selectBookmark.emit(this.bookmark);
        }
    }

}
