import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { copyTextInClipboard, isDate, readableDate, selectElementContents, validateVimeoURL, validateYouTubeUrl } from 'src/app/core/functions';
import { AuthService } from 'src/app/services/auth.service';
import { FileUploadGenericService } from 'src/modules/core/services/file-upload.service';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Attribute, Contact } from 'src/modules/diversite/model/contact';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { Form } from 'src/modules/diversite/model/form/form';
import { FormElement } from 'src/modules/diversite/model/form/form-element/form-element';
import { Project } from 'src/modules/diversite/model/project';
import { AttributeNodesContext } from 'src/modules/diversite/services/attributes-context-perspective.service';
import { Availability, AvailabilityService } from 'src/modules/diversite/services/availability.service';
import { ContactVideosService, Video, VideoSourceType } from 'src/modules/diversite/services/contact-videos.service';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { TranslatableLabel } from 'src/modules/diversite/services/data-catalog.service';
import { DownloadAssetsService, SelectedImageOptionType } from 'src/modules/diversite/services/download-assets.service';
import { ImageDispositionOption, ImageLayout } from 'src/modules/diversite/services/face-position.service';
import { FormService } from 'src/modules/diversite/services/form.service';
import { ModuleService } from 'src/modules/diversite/services/module.service';
import {
    AttributeModule,
    FieldGroup,
    ModulesAttributesService,
} from 'src/modules/diversite/services/modules-attributes.service';
import { ProjectService } from 'src/modules/diversite/services/projects.service';
import { RotateContactImageService } from 'src/modules/diversite/services/rotate-contact-image.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';
import { FormElementGenericOptions } from 'src/modules/fillout/components/form-element/form-element.component';

import { LayoutChangeEvent, RotateImageEvent } from '../../photo-profile/photo-profile.component';
import { VideoProfileComponent } from '../../video-profile/video-profile.component';
import { SuppressContactEvent } from '../suppress-contact-prompt/suppress-contact-prompt.component';

declare var $: any;

const PROFILE_IMAGE_OPTIONS: ImageDispositionOption = {
    containerWidth: 450,
    containerHeight: 400,
    finalFaceHeight: 200,
};
@Component({
    selector: "diversite-contact-view",
    templateUrl: "./contact-view.component.html",
    styleUrls: ["./contact-view.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactViewComponent implements OnChanges, OnDestroy, OnInit, AfterViewInit {
    constructor(
        private notificationService: NotificationService,
        private downloadPhotoService: DownloadAssetsService,
        private contactService: ContactService,
        private videoService: ContactVideosService,
        private fileUploadService: FileUploadGenericService,
        private formService: FormService,
        private rotatePhotoService: RotateContactImageService,
        private modulesAttributesService: ModulesAttributesService,
        private modulesService: ModuleService,
        private translateService: TranslateService,
        private projectService: ProjectService,
        private authService: AuthService,
        private chRef: ChangeDetectorRef,
        private availabilityService: AvailabilityService,
        private router: Router,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer
    ) { }

    @Input() contactPerspective: ContactPerspective;
    @Input() context: AttributeNodesContext;
    @Input() compact = false;

    @Input() formId = "";

    @Output() editContactPerspective = new EventEmitter<ContactPerspective>();

    @ViewChild("form", { read: NgForm }) form: NgForm;
    @ViewChild("videoComponent", { read: VideoProfileComponent }) videoComponent: VideoProfileComponent;

    projects$: Observable<Project[]>;
    readonly selectedProjectId$ = new BehaviorSubject<string>("");
    forms$: Observable<Form[]>;
    readonly notesChange$ = new BehaviorSubject<string>(undefined);
    readonly attributeChange$ = new BehaviorSubject<ChangeAttributeEvent>(undefined);

    availabilities$: Observable<Availability[]>;

    imagesLayout: ImageLayout[] = [];
    selectedImagesId: string[] = [];
    isDownloading = false;
    statutVaxOpen = false;
    tempAttributes: { [index: string]: any } = {};
    tempFormElement: { [index: string]: FormElement } = {};
    loading = false;
    imagesSelected: SelectedImageOptionType = "selected";
    suppressContactPrompt: Contact | false;
    disposeBag = new DisposeBag();
    videos$: Observable<Video[]>;
    percentageUploadVideo: number;

    mergeProfilePrompt = false;
    groupFieldPrompt: string;

    openAddVideosPrompt = false;
    openAddPhotosPrompt = false;

    videoSourceType: VideoSourceType = "file";
    uploading = false;

    videoUrls: string[] = [];
    photoUrls: string[] = [];

    readonly optionsForFormElements: FormElementGenericOptions = {
        showLabel: false,
    };

    submittingVideos = false;
    submittingPhotos = false;
    modulesOpenState = {};

    modules$: Observable<{ module: AttributeModule; attributes: Attribute[] }[]>;

    ngOnInit(): void {
        this.projects$ = this.projectService.projects({ deleted: false, listen: false });
        this.forms$ = this.selectedProjectId$.pipe(
            switchMap((projectId) =>
                projectId && projectId !== "" ? this.formService.formForProject(projectId) : of([])
            )
        );
        this.modules$ = this.modulesAttributesService.getModules().pipe(
            map((modules) => {
                return [
                    ...modules.map((module) => {
                        return {
                            module,
                            attributes: module.attributes
                                .filter((attrDef) => this.contactPerspective.contact?.hasAttribute(attrDef.index) ? true : false)
                                .map((attrDef) => this.contactPerspective.contact.getAttribute(attrDef.index)),
                        };
                    }),
                ];
            })
        );

        let contactId = "";
        if (this.contactPerspective) {
            contactId = this.contactPerspective.id;
        } else {
            contactId = this.route.snapshot.params.id;
        }

        this.videos$ = this.videoService.videosForContact(contactId).pipe(distinctUntilChanged());
        this.availabilities$ = this.availabilityService.availabilities(contactId);
    }

    ngAfterViewInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.contactPerspective && changes.contactPerspective.currentValue) {
            this.initiateTempAttributesForEdit();
        }

        if (this.loading) {
            this.loading = false;
        }

        if (changes.formId && !changes.formId.currentValue) {
            this.formId = "";
        }
    }

    onProjectChange(projectId: string): void {
        this.selectedProjectId$.next(projectId);
    }

    formElementForIndex(index: string): FormElement {
        console.log(index);
        return this.modulesService.formElementForIndex(index);
    }

    get lang(): string {
        return this.translateService.lang;
    }

    name(name: TranslatableLabel): string {
        return name[this.translateService.lang];
    }

    attributesForFields(groupFields: FieldGroup[]): Attribute[] {
        return groupFields
            .map((field) => this.contactPerspective.contact.getAttribute(field.id))
            .filter((attr) => !this.isEmptyOrUseless(attr?.value));
    }

    private authenticated(): boolean {
        return this.authService.isLoggedIn;
    }

    onPanelOpenChange(moduleId: string): void {
        // for LocalStorage to be saved
        const newState = { ...this.modulesOpenState };
        newState[moduleId] = !newState[moduleId];

        this.modulesOpenState = newState;
    }

    attributes(forms: Form[]): Attribute[] {
        if (this.formId && forms) {
            const form = forms.find((r) => r.id === this.formId);
            if (form) {
                let formElementNames = form
                    .formElements()
                    .filter((fe) => fe.isInput())
                    .map((fe) => fe.name);

                return [...new Set(formElementNames)]
                    .map((name) => {
                        const attr = this.contactPerspective.contact.informativeAttributes.find(
                            (att) => att.def.id === name
                        );
                        return attr;
                    })
                    .filter((a) => a);
            }
        }

        return this.contactPerspective.contact.informativeAttributes;
    }

    onContextChange(resetForm?: boolean): void {
        if (resetForm) {
            this.formId = "";
        }
    }

    clearForm(): void {
        this.formId = "";
        this.onContextChange();
    }

    goToProfile(): void {
        const host: string = location.origin;
        const url: string =
            host +
            "/#/" +
            String(
                this.router.createUrlTree([`/castlug/diversite/contact/edit/${this.contactPerspective.id}`], {
                    queryParams: {
                        f: this.formId === "" ? null : this.formId,
                    },
                })
            );
        window.open(url, "_blank");
    }

    trackByAttribute(index: number, attribute: Attribute): string {
        return attribute.def.id;
    }

    onNotesChange(event: KeyboardEvent): void {
        const inputElement = event.target as HTMLTextAreaElement;
        this.notesChange$.next(inputElement.value);
    }

    isEmptyOrUseless(attr: Attribute): boolean {
        if (!attr || !attr.value || (typeof attr.value === "string" && attr.value.trim() === "")) {
            return true;
        }
        if (attr.def.id.startsWith("photo_") || attr.def.id.startsWith("video_")) {
            return true;
        }
        if (attr.value === "?") {
            return true;
        }
        return false;
    }

    // toggleOpenGroup(groupId: string): void {
    //     this.openGroupState[groupId] = !this.openGroupState[groupId];
    //     this.openGroupState = { ...this.openGroupState };
    // }

    onLayoutChange(layoutChangeEvent: LayoutChangeEvent): void {
        const updatedContactPerspective = this.contactPerspective.changeProfileLayout(
            layoutChangeEvent.layout,
            layoutChangeEvent.images.map((img: ImageLayout) => {
                return {
                    imageId: img.id,
                    zoom: img.zoom,
                    x: img.backgroundx,
                    y: img.backgroundy,
                    rotate: img.rotate,
                };
            })
        );
        this.contactPerspective = updatedContactPerspective;
        this.editContactPerspective.emit(updatedContactPerspective);
    }

    downloadPhoto(): void {
        this.isDownloading = true;
        this.downloadPhotoService
            .downloadPhotosForContact(this.contactPerspective)
            .pipe(take(1))
            .subscribe((_) => {
                this.isDownloading = false;
                this.chRef.markForCheck();
            });
    }

    openSuppressContactPrompt(): void {
        this.suppressContactPrompt = this.contactPerspective.contact;
    }

    onContactSuppress(event: SuppressContactEvent): void {
        this.suppressContactPrompt = false;
        this.contactService.deactivateContact(event.contact, event.context).subscribe().disposedBy(this.disposeBag);
    }

    reactivateContact(): void {
        this.contactService.activateContact(this.contactPerspective.contact).subscribe().disposedBy(this.disposeBag);
    }

    onRotatePicture(event: RotateImageEvent): void {
        this.rotatePhotoService.saveRotation(this.contactPerspective.id, event.image);
    }

    onRemovePhoto(imageLayout: ImageLayout): void {
        this.contactService
            .removeImage(this.contactPerspective.contact.id, imageLayout.id)
            .subscribe()
            .disposedBy(this.disposeBag);
    }

    onRemoveVideo(video: Video): void {
        this.contactService
            .removeVideo(this.contactPerspective.contact.id, video.id)
            .subscribe()
            .disposedBy(this.disposeBag);
    }

    onAddPhotos(files: File[]): void {
        this.fileUploadService
            .uploadFiles(files)
            .pipe(
                switchMap((response) => {
                    return forkJoin(
                        response.urls.map((url) =>
                            this.contactService.addContactPhoto(this.contactPerspective.contact.idfig, url)
                        )
                    ).pipe(switchMap(() => this.contactService.contactById(this.contactPerspective.contact.id)));
                }),
                take(1)
            )
            .subscribe((c) => {
                this.notificationService.show(
                    "Vos images on bien été téléchargé. Un temps de traitement est requis.",
                    "info"
                );
            })
            .disposedBy(this.disposeBag);
    }

    onAddVideos(files: File[]): void {
        this.videoService
            .addVideosForContact(this.contactPerspective.contact, files)
            .subscribe(
                (uploadResponse) => {
                    this.percentageUploadVideo = uploadResponse.percentage;
                    this.chRef.markForCheck();
                },
                () => { },
                () => {
                    this.percentageUploadVideo = undefined;
                    this.chRef.markForCheck();
                }
            )
            .disposedBy(this.disposeBag);
    }

    addVideosPrompt(): void {
        this.openAddVideosPrompt = true;
    }

    addPhotosPrompt(): void {
        this.openAddPhotosPrompt = true;
    }

    onOpenAddVideoModalChange(isOpen = false): void {
        if (!isOpen) {
            this.uploading = false;
            this.videoUrls = [];
            this.openAddVideosPrompt = false;
        }
    }

    onOpenAddPhotoModalChange(isOpen = false): void {
        if (!isOpen) {
            this.uploading = false;
            this.photoUrls = [];
            this.openAddPhotosPrompt = false;
        }
    }

    addVideoSite(url: string): void {
        if (validateYouTubeUrl(url)) {
            this.videoUrls = [...this.videoUrls, validateYouTubeUrl(url) as string];
        } else if (validateVimeoURL(url)) {
            this.videoUrls = [...this.videoUrls, validateVimeoURL(url) as string];
        }
    }

    onInputChange(type: "video" | "image", event: any): void {
        let files: File[];
        if (event.target.files.length > 0) {
            files = event.target.files;

            this.uploading = true;
            this.fileUploadService
                .uploadFiles(Array.from(files))
                .subscribe((response) => {

                    if (type === "video") {
                        this.videoUrls = [...this.videoUrls, ...response.urls];
                    } else if (type === "image") {
                        this.photoUrls = [...this.photoUrls, ...response.urls];
                    }

                    this.uploading = false;
                    this.chRef.detectChanges();
                })
                .disposedBy(this.disposeBag);
        }
    }

    safeImage(imageUrl: string): SafeStyle {
        if (imageUrl) {
            return this.sanitizer.bypassSecurityTrustStyle(`url(${imageUrl})`);
        }
    }

    deleteVideo(i: number): void {
        this.videoUrls = this.videoUrls.filter((_, index) => index !== i);
    }

    deleteImage(i: number): void {
        this.photoUrls = this.photoUrls.filter((_, index) => index !== i);
    }

    onConfirmAddVideos(): void {
        this.submittingVideos = true;
        this.videoService.sendProcessVideoRequests(this.contactPerspective.contact, this.videoUrls).subscribe(() => {
            this.submittingVideos = false;
            this.onOpenAddVideoModalChange();
            this.chRef.detectChanges();
        });
    }

    onConfirmAddPhotos(): void {
        this.submittingPhotos = true;
        forkJoin(
            this.photoUrls.map((url) => this.contactService.addContactPhoto(this.contactPerspective.contact.idfig, url))
        )
            .pipe(take(1))
            .subscribe(() => {
                this.submittingPhotos = false;
                this.onOpenAddPhotoModalChange();
                this.chRef.detectChanges();
            });
    }

    onContactPerspectiveChange(cp: ContactPerspective): void {
        this.editContactPerspective.emit(cp);
    }

    attributeValue(value: any): string {
        if (isDate(value)) {
            return readableDate(value);
        }
        return value;
    }

    save(): void {
        const editedAttributes = this.contactPerspective.contact.attributes.map((attr) => {
            if (this.tempAttributes[attr.def.id]) {
                attr.value = this.tempAttributes[attr.def.id];
            }

            return attr;
        });
        this.loading = true;

        this.contactService
            .editContact(this.contactPerspective.contact.change({ attributes: editedAttributes }))
            .subscribe((_) => {
                this.notificationService.show("Le profil du contact a bien été sauvegardé.", "success");
                this.loading = false;
                this.chRef.markForCheck();
            });
    }

    get isFormValid(): boolean {
        return true;
    }
    private initiateTempAttributesForEdit(): void {
        this.contactPerspective.contact.attributes.forEach((attr) => {
            this.tempAttributes[attr.def.name] = attr.value;
            this.tempFormElement[attr.def.name] = this.modulesService.formElementForIndex(attr.def.id);
        });
    }

    copy(): void {
        const table = $("#table-for-copy");
        table.removeClass("hidden");
        selectElementContents(table.get(0));
        navigator.clipboard.writeText(window.getSelection().toString());
        table.addClass("hidden");
        this.notificationService.show(
            "Les informations de l'utilisateur ont été copiés dans le presse-papier.",
            "info"
        );
    }

    copyLink(): void {
        copyTextInClipboard(`${window.location.origin}#/castlug/diversite/contact/edit/${this.contactPerspective.id}`);
        this.notificationService.show("Le liens du membre à été copiés dans le presse-papier.", "info");
    }

    openMergePrompt(): void {
        this.mergeProfilePrompt = true;
    }
    onCloseMergeProfile(): void {
        this.mergeProfilePrompt = false;
    }

    groupField(attributeName: string): void {
        this.groupFieldPrompt = attributeName;
    }
    onCloseFieldGroupAssociation(): void {
        this.groupFieldPrompt = undefined;
    }

    trackById(_, idElement: any): string {
        return idElement.id;
    }
    trackByAttr(_: any, attr: Attribute): string {
        return attr?.def?.name;
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}

export interface ChangeAttributeEvent {
    value: string;
    attr: Attribute;
}

export interface AddPhotosForContactEvent {
    contact: Contact;
    files: File[];
}
