import '@clr/icons';
import '@clr/icons/shapes/all-shapes';

import { ChangeDetectionStrategy, Component, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Title } from '@angular/platform-browser';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { ModulesAttributesService } from 'src/modules/diversite/services/modules-attributes.service';
import { SystemDataService } from 'src/modules/diversite/services/system-data.service';

import { VERSION } from '../environments/version';
import { AuthService } from './services/auth.service';

@Component({
    selector: "castlug-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
    version: string;

    private _disposeBag = new DisposeBag();

    constructor(
        private authService: AuthService,
        private systemDataService: SystemDataService,
        private title: Title,
        private notificationService: NotificationService,
        private modulesAttributesService: ModulesAttributesService,
        private db: AngularFirestore,

    ) {
        this.title.setTitle("youlug");
        this.authService.init().subscribe().disposedBy(this._disposeBag);
        this.systemDataService
            .version()
            .subscribe((version) => {
                if (version) {
                    if (version.status === "success") {
                        console.warn("Deployed version", version.commit);
                    }

                    if (version.status === "deploying") {
                        console.warn("Currently deploying version", version.commit);
                    }

                    console.warn("Current code version", VERSION.hash);
                    if (version.status === "success" && VERSION.hash.indexOf(version.commit) < 0 && !isDevMode()) {
                        // RELOAD
                        this.notificationService.show(
                            "Une nouvelle version de l'application vient d'être déployée. S'il vous plait veuillez recharger cette page.",
                            "info",
                            { autoClose: false }
                        );
                    }
                }
            })
            .disposedBy(this._disposeBag);
        this.modulesAttributesService.init().subscribe().disposedBy(this._disposeBag);

        if (isDevMode()) {
            // firebase.firestore.setLogLevel("debug")
        } else {
            console.log = () => { }
        }
    }

    ngOnInit(): void {
        this.version = VERSION.hash;
    }

    ngOnDestroy(): void {
        this._disposeBag.dispose();
    }
}
