import { NgModule } from "@angular/core";

import { CoreModule } from "../core/core.module";
import { DiversiteModule } from "../diversite/diversite.module";
import { AdminContainerComponent } from "./admin-container/admin-container.component";
import { ErrorsComponent } from "./components/errors/errors.component";
import { ModulesComponent } from "./components/modules/modules.component";
import { AccessRolesComponent } from './components/access-roles/access-roles.component';

@NgModule({
    imports: [CoreModule, DiversiteModule],
    declarations: [AdminContainerComponent, ErrorsComponent, ModulesComponent, AccessRolesComponent],
})
export class AdminModule {}
