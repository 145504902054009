import { Injectable } from '@angular/core';
import { debounceTime, first, map, Observable } from 'rxjs';
import { SystemRequest } from 'src/app/api-clients/system-request-api-client.service';
import { SystemRequestService } from 'src/app/services/system-request.service';

@Injectable({
    providedIn: 'root'
})
export class ActivityService {

    constructor(private systemRequestService: SystemRequestService) { }

    activities(options?: ActivitiesQueryOptions): Observable<Activity[]> {
        return this.systemRequestService.systemRequests(options).pipe(
            map(systemRequests => {
                return systemRequests.filter((sr: any) => {
                    return sr.requestType !== "processUpload" &&
                        sr.requestType !== "getProfilesForPassword" &&
                        sr.requestType !== "checkupdatemodel" &&
                        sr.requestType !== "contactLogin" &&
                        sr.requestType !== "contactPasswordChange" &&
                        sr.requestType !== "verifyCode" &&
                        sr.requestType !== "requestContactInfo" &&
                        sr.requestType !== "requestCode" &&
                        sr.requestType !== "addPhoto" &&
                        sr.requestType !== "removePhoto" &&
                        sr.requestType !== "loadContact" &&
                        sr.requestType !== "query" &&
                        sr.requestType !== "updateattributesmappingswithform" &&
                        !(sr.requestType === "createandrecruitcontact" && !sr.formId) &&
                        !sr.hasOwnProperty("eventType") &&
                        !sr.hasOwnProperty("legacySync")

                }).map(sr => {
                    return {
                        ...sr,
                        id: sr.id,
                        type: this.activityTypeForSystemRequest(sr),
                        created: (sr.created as any).toDate(),
                    } as Activity
                })
            })
        )
    }

    activityTypeForSystemRequest(sr: any): ActivityType {
        if (sr.requestType && !sr.formId && sr.idfig && sr.requestType.toLowerCase() === "recruitcontact") {
            return "upload-photo";
        } else if (sr.formId && (sr.requestType === "recruitContact" || sr.requestType === "createandrecruitcontact")) {
            return "submit-form";
        } else if (sr.requestType?.toLowerCase() === "contactsignup") {
            return "contact-signup";
        } else if (sr.requestType?.toLowerCase() === "contactupdate") {
            return "contact-update";
        } else if (sr.requestType?.toLowerCase() === "contactsupport") {
            return "contact-support";
        } else if (sr.requestType?.toLowerCase() === "mergeprofiles") {
            return "merge-profiles";
        } else if (sr.requestType?.toLowerCase() === "exportcontacts") {
            return "export-contacts"
        }
    }
}

export interface Activity extends SystemRequest {
    created: Date;
    type: ActivityType;

}
export type ActivityType = "upload-photo" | "submit-form" | "contact-signup" | "contact-update" | "contact-support" | "merge-profiles" | "export-contacts";

export interface ActivitiesQueryOptions {
    afterDate?: Date;
    limit?: number
}