<div class="share-project-container">
    <div class="share-project-content">
        <clr-select-container>
            <label>Projet</label>
            <select clrSelect name="share-project" [(ngModel)]="projectId">
                <option value="">-</option>
                <option [value]="project.id" *ngFor="let project of projects$ | async">{{ project.name }}</option>
            </select>
        </clr-select-container>

        <clr-checkbox-container>
            <label>Utilisateur(s)</label>
            <clr-checkbox-wrapper *ngFor="let user of users$ | async">
                <input type="checkbox" name="user" clrCheckbox [(ngModel)]="selectedUsers[user.id]" />
                <label>{{ user.email }}</label>
            </clr-checkbox-wrapper>
        </clr-checkbox-container>
    </div>
    <div class="bottom">
        <button (click)="cancel()" type="button" class="btn btn-sm">Cancel</button>
        <button
            (click)="shareProject()"
            type="button"
            [disabled]="!projectId || projectId === '' || usersLength() === 0"
            class="btn btn-primary btn-sm"
        >
            Partager
        </button>
    </div>
</div>
