<diversite-mass-edit-action
    *ngIf="pane.contextData.actionType === 'mass-edit'"
    [contactIds]="pane.contextData?.contactIds"
    [datasource]="pane.contextData?.datasource"
    [searchDefinitionId]="pane.contextData?.searchContextId"
    (close)="onClose()"
></diversite-mass-edit-action>

<diversite-send-form-action
    *ngIf="pane.contextData.actionType === 'send-form'"
    [contactIds]="pane.contextData.contactIds"
    [projectId]="pane.contextData.projectId"
    (close)="onClose()"
></diversite-send-form-action>

<diversite-add-contact-action
    *ngIf="pane.contextData.actionType === 'add-contact'"
    [databases]="databases$ | async"
    [selectedDatabaseId]="pane.contextData?.contactsDatabaseId || ''"
    (close)="onClose()"
></diversite-add-contact-action>

<diversite-export-action
    *ngIf="pane.contextData.actionType === 'export'"
    [searchDefinition]="pane.contextData.searchDefinition"
    (close)="onClose()"
></diversite-export-action>

<diversite-create-folder-action
    *ngIf="pane.contextData.actionType === 'create-folder'"
    [searchDefinition]="pane.contextData.searchDefinition"
    (close)="onClose()"
></diversite-create-folder-action>

<diversite-merge-contacts-action
    *ngIf="pane.contextData.actionType === 'merge-contacts'"
    [idfig1]="pane.contextData.contactIdfigs[0]"
    [idfig2]="pane.contextData.contactIdfigs[1]"
    (close)="onClose()"
></diversite-merge-contacts-action>

<diversite-add-idfig-list-action
    *ngIf="pane.contextData.actionType === 'add-idfig-list'"
    [projectId]="pane.contextData.projectId || ''"
    [idfigs]="pane.contextData.contactIdfigs || []"
    (close)="onClose()"
></diversite-add-idfig-list-action>

<diversite-edit-package-action
    *ngIf="pane.contextData.actionType === 'edit-package'"
    [projectId]="pane.projectId"
    [packageId]="pane.contextData.packageId"
    (close)="onClose()"
></diversite-edit-package-action>

<diversite-share-project-action
    *ngIf="pane.contextData.actionType === 'share-project'"
    [projectId]="pane.contextData.projectId"
    (close)="onClose()"
></diversite-share-project-action>

<diversite-copy-form-action
    *ngIf="pane.contextData.actionType === 'copy-form'"
    [projectId]="pane.contextData.projectId"
    [formId]="pane.contextData.formId"
    (close)="onClose()"
></diversite-copy-form-action>
