<as-split unit="pixel">
    <as-split-area
        [size]="searchPaneClosed ? 26 : areaSizes[0]"
        [minSize]="searchPaneClosed ? 26 : 350"
        [lockSize]="searchPaneClosed"
    >
        <div class="search-parameters">
            <diversite-search-parameters
                [hidden]="searchPaneClosed"
                [projectId]="projectId"
                [contextNodeId]="contextNodeId"
                [favoriteNodes]="favoriteSearchNodes"
                [searchMeta]="searchMeta"
                [tabOpen]="tabOpen"
                [searchDefinition]="searchDefinition"
                (search)="onSearchDefinitionChange($event)"
                (changeTab)="onChangeTab($event)"
            />
            <div role="button" (click)="searchPaneClosed = !searchPaneClosed" class="toggle-open">
                <cds-icon shape="angle" [direction]="searchPaneClosed ? 'right' : 'left'"></cds-icon>
            </div>
        </div>
    </as-split-area>
    <as-split-area [size]="'*'">
        <div class="contacts-pane">
            <div class="pane-section">
                <div class="menu-up" [class.open]="detailedSearchMetaOpen">
                    <div class="detailed-section" *ngIf="detailedSearchMetaOpen">
                        <div>
                            <button
                                class="btn btn-sm button-menu"
                                [disabled]="searchMeta?.total.value >= 10000"
                                (click)="onMassEditResults(searchDefinition)"
                            >
                                Modifier les membres
                            </button>
                            <button
                                [disabled]="searchMeta?.total.value > 500"
                                class="btn btn-sm button-menu"
                                (click)="onCreateReport()"
                            >
                                Créer un rapport
                            </button>
                            <button
                                [disabled]="searchMeta?.total.value >= 5000"
                                class="btn btn-sm button-menu"
                                (click)="onExport()"
                            >
                                Exporter
                            </button>
                            <button
                                [disabled]="searchMeta?.total.value >= 2000"
                                class="btn btn-sm button-menu"
                                (click)="onCreateFolder()"
                            >
                                Créer un dossier
                            </button>
                            <!-- <button class="btn btn-sm button-menu" (click)="openTestPane(searchDefinition)">
                                Test
                            </button> -->
                        </div>
                        <div class="top">
                            <div *ngIf="searchMeta && searchMeta.total" class="total-info">
                                <small>{{ shownTotalForPage }} / {{ total }}</small>
                            </div>

                            <clr-select-container>
                                <label></label>
                                <select
                                    (change)="onSortChange($event.target.value)"
                                    clrSelect
                                    [value]="searchPagination.sort.field"
                                >
                                    <option value="">(plus précis des paramêtres)</option>
                                    <option value="createdAt">Date de création</option>
                                    <option value="lastUpdatedAt">Date de modification</option>
                                </select>
                            </clr-select-container>

                            <clr-range-container>
                                <label>Grandeur des cartes</label>
                                <input
                                    type="range"
                                    (change)="onImageWidthChange($event.target.value)"
                                    [(ngModel)]="contactWidth"
                                    clrRange
                                    min="80"
                                    max="500"
                                />
                            </clr-range-container>

                            <clr-range-container>
                                <label>Grandeur des infos</label>
                                <input type="range" [(ngModel)]="infoSize" clrRange min="35" max="500" />
                            </clr-range-container>
                        </div>

                        <div class="bottom">
                            <diversite-attribute-multiselect
                                [label]="'Champs à afficher'"
                                [selection]="shownAttributesSelection"
                                (selectionChange)="onComboboxSelectionChange($event)"
                            />
                            <diversite-search-parameters-compact [searchDefinition]="searchDefinition" />
                        </div>
                    </div>

                    <div class="toggle-details" (click)="detailedSearchMetaOpen = !detailedSearchMetaOpen">
                        <div *ngIf="!detailedSearchMetaOpen && searchMeta && searchMeta.total" class="total-info">
                            <small>{{ shownTotalForPage }} / {{ total }}</small>
                        </div>
                        <cds-icon
                            size="14"
                            shape="angle-double"
                            [direction]="detailedSearchMetaOpen ? 'up' : 'down'"
                        ></cds-icon>
                    </div>
                </div>

                <diversite-bookmark-selector
                    [searchDefinitionId]="searchDefinition?.id"
                    [selectedBookmark]="searchDefinition?.bookmarkSpec?.bookmark"
                    [viewNew]="searchDefinition?.bookmarkSpec?.new ? true : false"
                    (changeNew)="changeNewBookmarkSpec($event)"
                    (selectBookmark)="onSelectBookmark($event)"
                />
                <clr-spinner class="loading-main" [hidden]="!loading"></clr-spinner>
                <div
                    (wheel)="onWheel($event)"
                    (scroll)="onScrollparent($event)"
                    *ngIf="searchDefinition && !loading"
                    [id]="'contacts_' + searchDefinition.id"
                    class="contacts-paginated"
                >
                    <div class="load-more top" [style.height.px]="topHeightLoading">
                        <clr-spinner [hidden]="isChangingPage || topHeightLoading === 0" clrSmall></clr-spinner
                        ><cds-icon [hidden]="!isChangingPage" [size]="'md'" shape="thumbs-up"></cds-icon
                        ><span [style.font-size.px]="topHeightLoading / 9">...Page précèdente</span>
                    </div>

                    <diversite-contact-search-profile
                        *ngFor="let contactResult of contactResults; trackBy: trackByContactId"
                        class="card-draggable"
                        [selected]="
                            isSelected(contactResult.contactId ? contactResult.contactId : contactResult.contact?.id)
                        "
                        [attr.data-contact-id]="contactResult.contactId"
                        [height]="contactHeight(contactResult.highlights)"
                        [width]="contactWidth"
                        [contactId]="contactResult.contactId"
                        [contact]="contactResult.contact"
                        [infoSize]="infoSize"
                        [highlights]="contactResult.highlights"
                        (selectContact)="toggleSelection($event)"
                        (loaded)="onContactsShown($event)"
                        (contactDefaultImage)="onContactDefaultImageChange($event)"
                        (viewProfile)="onViewProfile($event)"
                        (editContact)="onEditContact($event)"
                        (openFormPane)="onOpenFormPane($event, contactResult.contactId)"
                    />

                    <div class="load-more bottom">
                        <clr-spinner
                            [hidden]="isChangingPage || loading || bottomHeightLoading === 0"
                            clrSmall
                        ></clr-spinner
                        ><cds-icon [hidden]="!isChangingPage" [size]="'md'" shape="thumbs-up"></cds-icon
                        ><span [style.font-size.px]="bottomHeightLoading / 9">Page suivante...</span>
                    </div>
                </div>
                <diversite-search-pagination
                    *ngIf="searchMeta && searchPagination"
                    [total]="searchMeta.total.value"
                    [limit]="searchPagination.limit"
                    [page]="searchPagination.page"
                    (changePage)="onPageChange($event)"
                    (changeLimit)="onLimitChange($event)"
                />
            </div>
        </div>
    </as-split-area>
</as-split>
