<ng-container *ngIf="state === 'forbidden'; else tmpl"> Forbidden </ng-container>
<ng-template #tmpl>
    <div class="record-container">
        <button
            [disabled]="disabled"
            class="btn btn-primary btn-sm"
            holdable
            (holdTime)="onHold($event)"
            (start)="onStart()"
            (stop)="onStop()"
        >
            {{ state === "recording" ? seconds : label("keepPushingToRecordText") }}
        </button>
    </div>
</ng-template>
