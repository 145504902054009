import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';

import { SearchGroup } from '../../model/search-group';
import { SearchOperation } from '../../model/search-operation';
import { SearchFavoriteNode, SearchFavoriteNodeService } from '../../services/search-favorite-node.service';
import { DropSortDefinitionDefinition } from '../attribute-nodes/attribute-nodes.component';

declare var $: any;

@Component({
    selector: 'diversite-search-favorites',
    templateUrl: './search-favorites.component.html',
    styleUrl: './search-favorites.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchFavoritesComponent {

    @Input() contextNodeId: string;
    @Input() favoriteNodes: SearchFavoriteNode[];
    @Input() searchGroup: SearchGroup;
    @Output() search = new EventEmitter<SearchGroup>();

    editFavoriteMode = false;
    searchFieldValue = "";
    private disposeBag = new DisposeBag();
    private searchFieldChange$ = new BehaviorSubject<{ value: string; reset?: boolean }>(undefined);


    constructor(
        private searchFavoriteNodeService: SearchFavoriteNodeService,
        private host: ElementRef
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.favoriteNodes?.currentValue) {
            this.setSortable();
        }
    }

    private setSortable(): void {
        setTimeout(() => {
            $(".search-favorite-nodes", this.host.nativeElement)
                .first()
                .sortable({
                    handle: ".handle-option",
                    connectWith: ".search-favorite-nodes",
                    helper: "clone",
                    placeholder: "sortable-placeholder",
                    update: (event, ui) => {
                        this.onSortTree();
                    },
                });
        }, 1000);
    }


    trackById(_: number, item: any): string {
        return item.id;
    }


    onNodeChange(node: SearchFavoriteNode): void {
        let newnodes = this.favoriteNodes.map((n) => (n.id === node.id ? node : n));
        this.searchFavoriteNodeService.saveFavorites(newnodes).subscribe();
    }
    onRemoveFavoriteNode(nodeId: string): void {
        let newnodes = this.favoriteNodes.filter((n) => n.id !== nodeId);
        this.searchFavoriteNodeService.saveFavorites(newnodes).subscribe();
    }

    onSortTree(): void {
        const nodesRefs = this.processChild();

        let flattennedAttributes = new Map<string, SearchFavoriteNode>();
        this.deepFlatten(this.favoriteNodes, flattennedAttributes);

        const reorderedAttributeNodes = this.mapAttributeNodes(nodesRefs, flattennedAttributes);

        this.searchFavoriteNodeService.saveFavorites(reorderedAttributeNodes).subscribe().disposedBy(this.disposeBag);
    }

    onSearchElementChange(searchOperation: SearchOperation): void {
        this.searchGroup = this.searchGroup.replaceAddSearchOperations([searchOperation]);
        this.onSearch();
    }

    addFavoriteGroup(): void {
        this.searchFavoriteNodeService.addFavoriteGroup().subscribe().disposedBy(this.disposeBag);
    }

    onSearch(): void {
        this.search.emit(this.searchGroup);
    }

    private mapAttributeNodes(
        nodesRefs: DropSortDefinitionDefinition[],
        dictionary: Map<string, SearchFavoriteNode>
    ): SearchFavoriteNode[] {
        return nodesRefs.map((node) => {
            const attrNode = dictionary.get(node.id);
            if (node.children) {
                return attrNode.change({ children: this.mapAttributeNodes(node.children, dictionary) });
            } else {
                return attrNode;
            }
        });
    }

    private deepFlatten(origin: SearchFavoriteNode[], toAppend: Map<string, SearchFavoriteNode>): any {
        origin.forEach((element) => {
            if (element.children && element.children.length > 0) {
                this.deepFlatten(element.children, toAppend);
            }
            if (!toAppend.has(element.id)) {
                toAppend.set(element.id, element);
            }
        });
    }

    private processChild(node?: HTMLElement): DropSortDefinitionDefinition[] {
        const parentNode = node ? $(".search-favorite-nodes", node).first() : $(".search-favorite-nodes").first();
        const nodes = $("> diversite-search-favorite-node", parentNode).toArray();
        return nodes.map((n) => {
            return { id: n.getAttribute("data-nodeid"), children: this.processChild(n) };
        });
    }

}
