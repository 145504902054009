import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, forkJoin, from, Observable, of } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { ErrorYoulug } from 'src/modules/core/services/error-handler.service';


declare var $: any;
@Component({
    selector: "app-errors",
    templateUrl: "./errors.component.html",
    styleUrls: ["./errors.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorsComponent implements OnInit {
    constructor(private userService: UserService, private db: AngularFirestore, private chRef: ChangeDetectorRef) { }



    errors$: Observable<ErrorYoulug[]>;
    us: { [id: string]: string } = {};
    lastId = new BehaviorSubject<any>(undefined);
    ngOnInit(): void {
        this.errors$ = this.lastId.pipe(switchMap(lastId => {
            return this.db
                .collection("errors", (ref) => {
                    if (lastId) {
                        return ref.limit(100).orderBy("date", "desc").startAt(lastId);
                    } else {
                        return ref.limit(100).orderBy("date", "desc");
                    }
                })
                .snapshotChanges()
                .pipe(
                    debounceTime(2000),
                    map((docSnapshots) => {
                        return docSnapshots.map((snapshot) => {
                            const data = snapshot.payload.doc.data() as any;
                            return { ...data, id: snapshot.payload.doc.id, date: data.date.toDate() } as ErrorYoulug;
                        });
                    }),
                    switchMap(errors => {
                        let toDeleteErrors = [];

                        // const authErrors = errors.filter(error => error.error.message === "Firebase: The supplied auth credential is incorrect, malformed or has expired. (auth/invalid-credential)." || error.error.message === "Firebase: Error (auth/invalid-login-credentials)." || error.error.message === "Firebase: The email address is badly formatted. (auth/invalid-email)." || error.error.message === "Firebase: A non-empty password must be provided (auth/missing-password).");

                        // toDeleteErrors = [
                        //     ...authErrors,
                        // ];
                        // console.log("toDeleteErrors", toDeleteErrors);
                        if (toDeleteErrors.length > 0) {

                            return forkJoin(toDeleteErrors.map(e => {
                                return from(this.db.collection("errors").doc(e.id).delete())
                            })).pipe(
                                map(_ => {
                                    return errors
                                })
                            )
                        } else {

                            return of(errors)
                        }


                    }),
                    tap(errors => {
                        // setTimeout(() => {
                        //     const nextPageLastId = errors[errors.length - 1].date;
                        //     console.log(nextPageLastId);
                        //     if ((nextPageLastId as Date).getTime() !== new Date(this.lastId.value).getTime()) {
                        //         this.lastId.next(nextPageLastId);
                        //     }
                        // }, 2000)
                    })

                );
        }))





    }


    trackById(_: number, error: ErrorYoulug): string {
        return error.id;
    }

    openImage(base64Img: string): void {
        this.debugBase64(base64Img);
    }

    private debugBase64(base64URL: string): void {
        const win = window.open();
        win.document.write(
            '<iframe src="' +
            base64URL +
            // tslint:disable-next-line:max-line-length
            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
        );
    }

    removeError(id: string): void {
        $(`#row${id}`).remove();
        this.db.collection("errors").doc(id).delete();
    }
}
