import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
    selector: "[slug]",
    providers: [{ provide: NG_VALIDATORS, useExisting: SlugValidatorDirective, multi: true }],
})
export class SlugValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        if (control.value && control.value !== "") {
            const regexp = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
            if (regexp.test(control.value)) {
                return null;
            }
            return { invalidSlug: true };
        }
        return null;
    }
}

//
