<div class="shared-package-container" *ngIf="package">
    <div class="branding">
        <img class="main-logo-castlug" [src]="logo()" />
    </div>

    <div class="lang">
        <span (click)="selectLang(lang)" [class.current]="lang === selectedLang" *ngFor="let lang of languages">{{
            lang
        }}</span>
    </div>

    <h3>{{ package.name[selectedLang] }}</h3>

    <div class="desc" [innerHTML]="safe(package.description[selectedLang])"></div>

    <div class="files">
        <div class="file" *ngFor="let file of package.files; trackBy: trackById">
            <cds-icon [size]="'md'" shape="pdf-file"></cds-icon><a [href]="file.url">{{ file.name }}</a>
            <div class="description" [innerText]="file.description[selectedLang]"></div>
        </div>
    </div>
</div>
