<div id="main-dashboard" class="main-dashboard" *ngIf="paneContent$ | async as paneContents; else loading">
    <div id="main-scroll" (scroll)="onScrollMain($event)" class="main-scroll">
        <div class="dynamic-layout">
            <diversite-pane
                *ngFor="let paneContent of paneContents; trackBy: trackById; let columnIndex = index"
                [id]="paneContent.paneRef.id"
                [disabled]="actionPaneId && actionPaneId !== paneContent.paneRef.id"
                [class.last]="paneSpecs[paneContent.paneRef.id].isLastOpened"
                [style.width.px]="paneSpecs[paneContent.paneRef.id].size"
                [pane]="paneContent.pane"
                [collapsed]="paneIsCollapse(paneContent)"
                [paneSize]="paneSpecs[paneContent.paneRef.id]"
                (toggleCollapse)="onToggleCollapse($event)"
                (maximizePane)="onMaximize($event, columnIndex)"
                (closePane)="onClosePane($event)"
                (changeLock)="onChangeLock($event)"
                (paneSizeChange)="onPaneSizeChange($event, paneContent.paneRef.id)"
            ></diversite-pane>
        </div>
    </div>
    <diversite-contact-perspective-selection
        [contactIds]="selection$ | async"
        (changeSelection)="onChangeSelection($event)"
        (generateReport)="onGenerateReport($event)"
    ></diversite-contact-perspective-selection>
</div>

<ng-template #loading>
    <div class="loading">
        <clr-spinner></clr-spinner>
    </div>
</ng-template>
