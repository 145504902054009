import { Injectable } from "@angular/core";
import { Action, AngularFirestore, DocumentData, DocumentSnapshot } from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";
import { Observable, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";

import { ModuleMapperService } from "../mappers/module-mapper.service";
import { AttributeIndex } from "../model/contact-profile-definition";
import { FormElement, FormElementInput, FormElementType } from "../model/form/form-element/form-element";
import { FormOption } from "../model/form/form-element/form-option";
import { FormTextbox } from "../model/form/form-element/form-textbox";
import { FormModule } from "../model/form/form-module";
import { AttributeIndexFormElementMapperService } from "./attribute-index-form-element-mapper.service";
import { AttributeModule, ModulesAttributesService } from "./modules-attributes.service";

@Injectable({
    providedIn: "root",
})
export class ModuleService {
    constructor(
        private attributeIndexMapper: AttributeIndexFormElementMapperService,
        private modulesAttributesService: ModulesAttributesService,
        private moduleMapper: ModuleMapperService,
        private db: AngularFirestore
    ) {}

    templateModules(): Observable<FormModule[]> {
        return this.modulesAttributesService.getModules().pipe(
            map((attrModules: AttributeModule[]) => {
                return attrModules.map((attrModule) => {
                    return new FormModule({
                        id: this.db.createId(),
                        name: attrModule.name,
                        formElements: attrModule.attributes.map((attr: AttributeIndex) => {
                            return this.attributeIndexMapper.attributeIndexToFormElement(attr);
                        }),
                    });
                });
            })
        );
    }

    defaultFormElementForFormElementType(type: FormElementType, options?: FormOption[]): FormElement {
        // "textbox"
        // | "text"
        // | "radio"
        // | "datetime"
        // | "number"
        // | "dropdown"
        // | "union"
        // | "phone"
        // | "checkbox"
        // | "textarea"
        // | "imageupload"
        // | "videoupload"
        // | "fileupload"
        // | "voiceupload"
        // | "table"
        // | "boolean"
        // | "weight"
        // | "height"
        // | "availability";

        if (type === "textbox") {
            return FormTextbox.defaultElement();
        }

        return null;
    }

    formElementForIndex(index: string): FormElement {
        return this.attributeIndexMapper.attributeIndexToFormElement(
            this.modulesAttributesService.getAttributeIndex(index)
        );
    }

    labelForIndex(index: string, lang: string): string {
        return this.modulesAttributesService.labelForIndex(index, lang);
    }

    addFormElementToTemplateModules(formElement: FormElementInput, moduleId?: string): Observable<void> {
        const attributeIndex = this.attributeIndexMapper.formElementToAttributeIndex(formElement, moduleId);

        return this.templateModules().pipe(
            take(1),
            switchMap((formModules) => {
                if (formModules.every((formModule) => !formModule.hasFormElement(attributeIndex.index))) {
                    return this.db
                        .collection("systemData")
                        .doc("definitions")
                        .get()
                        .pipe(
                            switchMap((doc: firebase.firestore.DocumentSnapshot<DocumentData>) => {
                                if (doc.exists) {
                                    const data = doc.data();
                                    data.profile.attributes = [...data.profile.attributes, attributeIndex];
                                    doc.ref.set({ ...data });
                                }
                                return of(undefined);
                            })
                        );
                } else {
                    console.error("modules already has this index.");
                    console.log("TODO: suggest to user to override data or cancel");
                }
            })
        );
    }

    addQuestionLibraryModule(formModule: FormModule): Observable<void> {
        const formModuleDto = this.moduleMapper.moduleToModuleDto(formModule);
        return this.db
            .collection("systemData")
            .doc("formElementLibrary")
            .get()
            .pipe(
                switchMap((doc: firebase.firestore.DocumentSnapshot<DocumentData>) => {
                    if (doc.exists) {
                        const data = doc.data();
                        data.modules = [...data.modules, formModuleDto];
                        doc.ref.set({ ...data });
                    } else {
                        doc.ref.set({
                            modules: [formModuleDto],
                        });
                    }
                    return of(undefined);
                })
            );
    }

    deleteQuestionLibraryModule(moduleId: string): Observable<void> {
        return this.db
            .collection("systemData")
            .doc("formElementLibrary")
            .get()
            .pipe(
                switchMap((docData: any) => {
                    const data = docData.data();
                    if (docData.exists && data.modules && data.modules.find((m) => m.id === moduleId)) {
                        return this.db
                            .collection("systemData")
                            .doc("formElementLibrary")
                            .update({ modules: data.modules.filter((m) => m.id !== moduleId) });
                    }
                    return of(undefined);
                })
            );
    }

    editQuestionLibraryModule(module: FormModule): Observable<void> {
        return this.db
            .collection("systemData")
            .doc("formElementLibrary")
            .get()
            .pipe(
                switchMap((docData: any) => {
                    const data = docData.data();
                    if (docData.exists && data.modules && data.modules.find((m) => m.id === module.id)) {
                        return this.db
                            .collection("systemData")
                            .doc("formElementLibrary")
                            .update({
                                modules: data.modules.map((m) =>
                                    m.id === module.id ? this.moduleMapper.moduleToModuleDto(module) : m
                                ),
                            });
                    }
                    return of(undefined);
                })
            );
    }

    editQuestionLibraryModules(modules: FormModule[]): Observable<void> {
        return this.db
            .collection("systemData")
            .doc("formElementLibrary")
            .get()
            .pipe(
                switchMap((docData: any) => {
                    const data = docData.data();
                    if (docData.exists && data.modules) {
                        return this.db
                            .collection("systemData")
                            .doc("formElementLibrary")
                            .update({
                                modules: modules
                                    .map((m, index) => {
                                        return m.change({ order: index });
                                    })
                                    .map((m) => this.moduleMapper.moduleToModuleDto(m)),
                            });
                    }
                    return of(undefined);
                })
            );
    }

    questionLibraryModules(): Observable<FormModule[]> {
        return this.db
            .collection("systemData")
            .doc("formElementLibrary")
            .snapshotChanges()
            .pipe(
                map((doc: Action<DocumentSnapshot<unknown>>) => {
                    if (doc.payload.exists) {
                        const data: any = doc.payload.data();
                        if (data.modules) {
                            return data.modules
                                .sort((a, b) => a.order - b.order)
                                .map((m) => {
                                    return this.moduleMapper.moduleDtoToModule(m);
                                });
                        }
                    }
                    return [];
                })
            );
    }
}
