import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FormElement } from 'src/modules/diversite/model/form/form-element/form-element';
import { AttributeLabel, DataCatalogService } from 'src/modules/diversite/services/data-catalog.service';
import { ModuleService } from 'src/modules/diversite/services/module.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';
import { ResponseInput } from 'src/modules/fillout/model/response-input';

@Component({
    selector: "diversite-search-profile-attribute",
    templateUrl: "./search-profile-attribute.component.html",
    styleUrls: ["./search-profile-attribute.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchProfileAttributeComponent {
    @Input() attributeId: string;
    @Input() value: any;
    @Input() stringValue: string;
    @Input() isSearched: boolean = false;
    @Output() valueChange = new EventEmitter<AttributeChangeEvent>();
    @Output() openFormPane = new EventEmitter<string>();


    editMode = false;
    tempFormElement: FormElement;
    tempAttributeValue: any;

    attrLabel$: Observable<AttributeLabel>;

    constructor(private dataCatalogService: DataCatalogService, private modulesService: ModuleService, private translateService: TranslateService) { }

    ngOnInit(): void {
        this.attrLabel$ = this.dataCatalogService.attributeLabel(this.attributeId);
    }

    edit(): void {
        this.editMode = true;
        this.tempAttributeValue = this.value;
        this.tempFormElement = this.modulesService.formElementForIndex(this.attributeId);
    }

    cancel(): void {
        this.editMode = false;
    }

    save(): void {
        this.editMode = false;
        this.valueChange.emit({ attributeId: this.attributeId, value: this.tempAttributeValue });
    }

    get lang(): string {
        return this.translateService.lang;
    }

    openForm(attributeId: string): void {
        this.openFormPane.emit(attributeId.replace("recruitment_", ""))
    }

    onResponseChange(event: ResponseInput): void {
        this.tempAttributeValue = event.value;
    }
}

export interface AttributeChangeEvent {
    attributeId: string;
    value: any;
}
