import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Pane, PaneType } from 'src/modules/diversite/model/pane';

import { PaneService } from '../../services/pane.service';

@Component({
    selector: "diversite-default-pane-content",
    templateUrl: "./default-pane-content.component.html",
    styleUrls: ["./default-pane-content.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultPaneContentComponent implements OnInit, OnDestroy {
    @Input() pane: Pane;

    private disposeBag = new DisposeBag();

    constructor(private paneService: PaneService) {}

    ngOnInit(): void {}

    assignType(type: PaneType): void {
        this.paneService.editPane(this.pane.change({ type })).subscribe().disposedBy(this.disposeBag);
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
