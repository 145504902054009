<clr-combobox-container>
    <label *ngIf="label">{{ label }}</label>
    <clr-combobox
        #combobox
        [clrLoading]="loading"
        (clrInputChange)="fetchAttributes($event)"
        (clrOpenChange)="$event ? fetchAttributes() : null"
        [(ngModel)]="value"
        (ngModelChange)="onSelectionChange()"
        name="multiSelect"
        clrMulti="true"
    >
        <ng-container *clrOptionSelected="let selectedAttributeName"
            ><diversite-attribute-label
                [attr.data-attribute-id]="selectedAttributeName"
                [maxCharacters]="100"
                [id]="selectedAttributeName"
            ></diversite-attribute-label
        ></ng-container>
        <clr-options>
            <clr-option *ngFor="let attribute of attributes$ | async" [clrValue]="attribute.attributeName">
                <diversite-attribute-label
                    [maxCharacters]="100"
                    [id]="attribute.attributeName"
                ></diversite-attribute-label>
            </clr-option>
        </clr-options>
    </clr-combobox>
</clr-combobox-container>
<!-- 
<clr-accordion>
    <clr-accordion-panel *ngFor="let module of attributeModules$ | async">
        <clr-accordion-title>{{ module.name[this.lang] }}</clr-accordion-title>
        <clr-accordion-content *clrIfExpanded>
            <clr-checkbox-container clrInline>
                <clr-checkbox-wrapper *ngFor="let formElement of module.formElements; trackBy: trackById">
                    <input
                        type="checkbox"
                        [checked]="isAttributeChecked(formElement.name)"
                        (change)="onAttributeCheckboxChange(formElement.name)"
                        clrCheckbox
                        [name]="formElement.name"
                        required
                        [value]="formElement.name"
                    />
                    <label>{{ formElement.label[this.lang] }}</label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>
        </clr-accordion-content>
    </clr-accordion-panel>
</clr-accordion> -->
