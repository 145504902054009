import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';

import { FormElementInput } from '../../model/form/form-element/form-element';
import { SearchOperation } from '../../model/search-operation';
import { PanelsConfiguration, SearchPanelsService } from '../../services/search-panels.service';
import { SearchFormElementComponent } from './search-form-element/search-form-element.component';

@Component({
    selector: "diversite-search-form-elements",
    templateUrl: "./search-form-elements.component.html",
    styleUrls: ["./search-form-elements.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFormElementsComponent implements OnInit, OnDestroy {
    @Input() lang: string;
    @Input() formElements: FormElementInput[];
    @Input() searchOperations: SearchOperation[];
    @Output() searchOperationChange = new EventEmitter<SearchOperation>();
    @ViewChildren("formElementSearchComponents") formElementSearchComponents: QueryList<SearchFormElementComponent>;

    constructor(private searchPanelsService: SearchPanelsService, private chRef: ChangeDetectorRef) { }

    panels: PanelsConfiguration;
    searchOperationsFormElement = {};
    private disposeBag = new DisposeBag();

    ngOnInit(): void {
        this.searchPanelsService.panels().subscribe((panelsConfiguration) => {
            this.panels = panelsConfiguration;
            this.chRef.markForCheck();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.searchOperations?.currentValue) {
            changes.searchOperations.currentValue.forEach((sop: SearchOperation) => {
                this.searchOperationsFormElement[sop.operand] = sop;
            });
        }
    }


    reset(): void {
        this.formElementSearchComponents.forEach((svc) => svc.reset());
    }



    onAdd(searchOperation: SearchOperation): void {
        let changedElements = this.searchOperations.map((actualSop) => {
            return searchOperation.operand === actualSop.operand ? searchOperation : actualSop;
        });

        const exists = this.searchOperations.find((existingSop) => existingSop.operand === searchOperation.operand);
        if (!exists) {
            changedElements = [...changedElements, searchOperation];
        }

        this.searchOperations = changedElements;
        this.searchOperationChange.emit(searchOperation);
    }



    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
