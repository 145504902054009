import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';

import { ContextNode } from '../model/context-node';
import { ContextNodeService } from './context-node.service';

@Injectable({
    providedIn: 'root'
})
export class ClassificationService {

    private _disabled: boolean = true;
    private _selectedNodeId: string = null;
    private _nodesToShowOnProfile = new BehaviorSubject<string[]>(null);

    nodesToShowOnProfiles: Observable<ContextNode[]> = this._nodesToShowOnProfile.pipe(switchMap(nodeIds => {
        return this.contextNodeService.nodes(nodeIds);
    }))

    constructor(private contextNodeService: ContextNodeService) { }

    get selectedNodeId(): string {
        return this._selectedNodeId;
    }

    get disabled(): boolean {
        return this._disabled;
    }

    setContextNodeDestination(contextNodeId: string): void {
        this._selectedNodeId = contextNodeId;
    }

    setNodesToShowOnProfiles(contextNodeIds: string[]): void {
        this._nodesToShowOnProfile.next(contextNodeIds);
    }

    disabledClassification(): void {
        this._selectedNodeId = null;
        this._disabled = true;
        this._nodesToShowOnProfile.next(null);
    }

    enableClassification(): void {
        this._selectedNodeId = null;
        this._disabled = false;
        this._nodesToShowOnProfile.next(null);
    }

}



export interface ContextNodeStats {
    contextNodeId: string;
    contactIds: string[];
}
