<div class="multi-layout">
    <div *ngIf="showThumbnailSelection" class="layout-variants hide-print">
        <div
            [class.selected]="layoutSelected(layoutVariant)"
            (click)="changeLayout(layoutVariant)"
            class="layout-variant"
            *ngFor="let layoutVariant of layoutVariants"
        >
            <div class="layout-column" *ngFor="let columnItem of layoutVariant">
                <div class="layout-photo" *ngFor="let item of numberToArrayOfNumber(columnItem)"></div>
            </div>
        </div>
    </div>
    <div class="layout-container">
        <div
            class="column"
            *ngFor="let photoPerColumn of contactPerspective.layout; let index = index; trackBy: trackByColumn"
        >
            <div
                class="column-item"
                *ngFor="let photoNum of numberToArrayOfNumber(photoPerColumn); trackBy: trackByItem"
            >
                <ng-container *ngIf="getImageFromPositionInLayout(index + 1, photoNum) as imageLayout">
                    <diversite-photo-cover
                        (dblclick)="openPhoto(imageLayout)"
                        [url]="imageLayout.url"
                        [rotate]="imageLayout.rotate"
                    ></diversite-photo-cover>

                    <cds-icon
                        *ngIf="imageLayout.cellPicture"
                        class="is-cell-picture"
                        size="md"
                        shape="employee"
                    ></cds-icon>
                    <div *ngIf="authenticated" class="controls hide-print">
                        <!-- <small>{{ dateImageToShow(imageLayout) }}</small> -->
                        <div [hidden]="!showOptions" class="picture-date">
                            <span>Date upload: {{ imageLayout.uploadedAt | date : "dd-MM-yyyy" }}</span
                            >&nbsp;<span
                                >Fichier:
                                <span *ngIf="imageLayout.originalDate; else nil">{{
                                    imageLayout.originalDate | date : "dd-MM-yyyy"
                                }}</span
                                ><ng-template #nil>nil</ng-template></span
                            >
                        </div>
                        <div [hidden]="!showOptions" class="icons">
                            <cds-icon shape="download" (click)="downloadImage($event, imageLayout)"></cds-icon>
                            <cds-icon shape="refresh" (click)="rotate(imageLayout)"></cds-icon>
                            <cds-icon shape="times" (click)="removePhotoPrompt(imageLayout)"></cds-icon>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div *ngIf="showThumbnailSelection" class="thumbnail-selection hide-print">
        <div
            (click)="toogleImage(image)"
            [class.selected]="isSelected(image)"
            *ngFor="let image of images"
            class="image"
            [style.background-image]="safeImage(image)"
        ></div>
    </div>
    <div class="loading"><clr-spinner [hidden]="!loading">Loading ...</clr-spinner></div>
</div>

<clr-modal
    *ngIf="imageLayoutDeletion"
    [(clrModalOpen)]="imageLayoutDeletion"
    (clrModalOpenChange)="onOpenImageLayoutDeletion($event)"
    [clrModalSize]="'sm'"
>
    <h3 class="modal-title">Effacer une image</h3>
    <div class="modal-body">
        <small>Effacer cette image ?</small>
        <div class="image-delete" [style.background-image]="safeImage(imageLayoutDeletion)"></div>
    </div>
    <div class="modal-footer">
        <button (click)="onOpenImageLayoutDeletion(false)" class="btn btn-sm">Cancel</button>
        <button (click)="confirmImageRemoval()" class="btn btn-sm btn-primary">Effacer</button>
    </div>
</clr-modal>
