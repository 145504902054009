<div *ngIf="formElement" class="form-element-edition">
    <span
        #labelElement
        class="edit-label"
        contenteditable
        (keyup)="onLabelChange()"
        [innerHTML]="tempFormElement.label"
    ></span>
    <ng-content select="[tooltip]"></ng-content>
    <table class="table">
        <thead>
            <tr *ngFor="let field of tempFormElement.fields; let index = index">
                <th class="left">
                    <div class="field-content">
                        <div class="input-tooltip">
                            <input
                                class="label-edition"
                                (change)="onLabelChange()"
                                [(ngModel)]="field.label"
                                type="text"
                            />
                            <diversite-tooltip-edit
                                [readonlyElement]="readonlyElement"
                                [tooltip]="field.description"
                                (tooltipChanged)="onTooltipChanged($event, field)"
                            >
                            </diversite-tooltip-edit>
                        </div>
                        <clr-select-container>
                            <select
                                clrSelect
                                (change)="onFieldTypeChange($event.target.value, field)"
                                name="options"
                                [(ngModel)]="field.type"
                            >
                                <option value="textbox">Texte court</option>
                                <option value="textarea">Texte long</option>
                                <option value="number">Nombre</option>
                                <option value="dropdown">Menu déroulant</option>
                                <option value="fileupload">Fichier</option>
                                <option value="imageupload">Image</option>
                            </select>
                        </clr-select-container>

                        <div class="options" *ngIf="field.type === 'dropdown'">
                            <div class="option" *ngFor="let opt of field.options; let indexOption = index">
                                <input
                                    (change)="onLabelChange()"
                                    class="label-edition"
                                    type="text"
                                    [(ngModel)]="opt.label"
                                />
                                <input
                                    (change)="onLabelChange()"
                                    class="value-edition"
                                    placeholder="Nom du champ"
                                    type="text"
                                    [(ngModel)]="opt.name"
                                />
                                <small (click)="deleteOption(field, indexOption)"
                                    ><clr-icon shape="times"></clr-icon
                                ></small>
                            </div>
                        </div>
                        <small *ngIf="field.type === 'dropdown'" (click)="addOptionToField(field)"
                            >Ajouter une option de menu déroulant</small
                        >
                    </div>
                </th>
                <th>
                    <div class="field-actions">
                        <clr-checkbox-container>
                            <clr-checkbox-wrapper>
                                <input
                                    type="checkbox"
                                    clrCheckbox
                                    [checked]="field.required"
                                    (change)="changeFieldRequired(field, index)"
                                />
                                <label>Champs requis</label>
                            </clr-checkbox-wrapper>
                        </clr-checkbox-container>
                        <button (click)="removeField(index)" class="btn btn-sm">-</button>
                    </div>
                </th>
            </tr>
        </thead>
    </table>
    <button (click)="addTableField()" class="btn btn-sm"><clr-icon shape="plus"></clr-icon></button>
</div>
