import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormText } from 'src/modules/diversite/model/form/form-element/form-text';

import { DEFAULT_OPTIONS, FormElementGenericOptions } from '../../form-element.component';

@Component({
    selector: "fillout-text",
    templateUrl: "./text.component.html",
    styleUrls: ["./text.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent implements OnInit {
    @Input() lang: string;
    @Input() formElement: FormText;
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit(): void { }

    safe(value: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}
