import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

import { FormElementDTO, ModuleMapperService } from '../mappers/module-mapper.service';
import { FormElement } from '../model/form/form-element/form-element';
import { ModuleService } from './module.service';

@Injectable({
    providedIn: "root",
})
export class SearchPanelsService {
    constructor(
        private db: AngularFirestore,
        private authService: AuthService,
        private moduleService: ModuleService,
        private moduleMapperService: ModuleMapperService
    ) {}

    panels(): Observable<PanelsConfiguration> {
        return this.db
            .collection("searchPanels")
            .doc(this.authService.loggedInUser.id)
            .snapshotChanges()
            .pipe(
                map((doc) => {
                    if (doc.payload.exists) {
                        return this.mapPanelsConfigurationDtoToPanelsConfiguration({ ...(doc.payload.data() as any) });
                    } else {
                        this.db.collection("searchPanels").doc(this.authService.loggedInUser.id).set({
                            basePanel: [],
                        });
                    }
                })
            );
    }

    addFormElementToBasePanel(formElement: FormElement): Observable<void> {
        const newSearchMacroDefinition = {
            type: "single-form-element-reference",
            formElement: formElement.toDto(),
        };

        return this.panels().pipe(
            take(1),
            switchMap((panels) => {
                return this.db
                    .collection("searchPanels")
                    .doc(this.authService.loggedInUser.id)
                    .update({
                        basePanel: [
                            ...panels.basePanel.map((searchMacroDef) =>
                                this.mapSearchMacroDefinitionToSearchMacroDefinitionDto(searchMacroDef)
                            ),
                            newSearchMacroDefinition,
                        ],
                    });
            })
        );
    }

    removeFormElementFromBasePanel(formElementName: string): Observable<void> {
        return this.panels().pipe(
            take(1),
            switchMap((panels) => {
                return this.db
                    .collection("searchPanels")
                    .doc(this.authService.loggedInUser.id)
                    .update({
                        basePanel: [
                            ...panels.basePanel
                                .filter((searchMacroDef) => {
                                    if (
                                        searchMacroDef.type === "single-form-element-reference" &&
                                        (searchMacroDef as SingleFormElementSearchMacroDefinition).formElement.name ===
                                            formElementName
                                    ) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                })
                                .map((s) => this.mapSearchMacroDefinitionToSearchMacroDefinitionDto(s)),
                        ],
                    });
            })
        );
    }

    updateBasePanelElementOrder(formElementNames: string[]): Observable<void> {
        return this.panels().pipe(
            take(1),
            switchMap((panels) => {
                return this.db
                    .collection("searchPanels")
                    .doc(this.authService.loggedInUser.id)
                    .update({
                        basePanel: [
                            ...formElementNames.map((name) => {
                                const foundElement = panels.basePanel.find(
                                    (searchElementMacro) =>
                                        (searchElementMacro as SingleFormElementSearchMacroDefinition).formElement
                                            .name === name
                                );
                                return this.mapSearchMacroDefinitionToSearchMacroDefinitionDto(foundElement);
                            }),
                        ],
                    });
            })
        );
    }

    private mapPanelsConfigurationDtoToPanelsConfiguration(data: any): PanelsConfiguration {
        if (data && data.basePanel) {
            data.basePanel = data.basePanel.map((searchMacroDef) => {
                return this.mapSearchMacroDefinitionDtoToSearchMacroDefinition(searchMacroDef);
            });
            return data;
        }
    }
    private mapSearchMacroDefinitionDtoToSearchMacroDefinition(searchMacroDef: any): SearchMacroDefinition {
        if (searchMacroDef.type === "single-form-element-reference") {
            return {
                ...searchMacroDef,
                formElement: this.moduleMapperService.formElementDtoToFormElement(searchMacroDef.formElement),
            };
        }
    }

    private mapSearchMacroDefinitionToSearchMacroDefinitionDto(
        searchMacroDef: SearchMacroDefinition
    ): SearchMacroDefinitionDTO {
        if (searchMacroDef.type === "single-form-element-reference") {
            let smd = searchMacroDef as SingleFormElementSearchMacroDefinition;
            return {
                ...smd,
                formElement: smd.formElement.toDto(),
            };
        }
    }
}

export interface PanelsConfiguration {
    basePanel: SearchMacroDefinition[];
}

export interface SearchMacroDefinition {
    type: SearchMacroType;
}

export interface SingleFormElementSearchMacroDefinition extends SearchMacroDefinition {
    formElement: FormElement;
}

export type SearchMacroType = "single-form-element-reference";

export interface SearchMacroDefinitionDTO {
    type: SearchMacroType;
    formElement: FormElementDTO;
}
