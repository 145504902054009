<div class="activity">
    <div class="top">
        Un export de membre(s) à été fait.
        <div class="sr-id">{{ activity.id }}</div>
    </div>
    <!-- <pre style="font-size: 0.45rem; line-height: 0.5rem">{{ activity | json }}</pre> -->
    <div class="activity-content">
        <div class="log-progress">
            <div class="progress">
                <progress [value]="progress" max="100"></progress>
            </div>

            <span *ngIf="progress !== 0">{{ progress }}/100</span>
            <clr-spinner *ngIf="progress < 100" [clrInline]="true"></clr-spinner>
        </div>

        <div *ngIf="activity.result?.excelFileUrl" class="excel-url">
            <a class="url-file" [href]="activity.result.excelFileUrl" target="_blank"
                ><cds-icon shape="xls-file"></cds-icon>&nbsp;Fichier XLS</a
            >
        </div>
        <div *ngIf="activity.result?.imagePackageUrl" class="images-url">
            <a class="url-file" [href]="activity.result.imagePackageUrl" target="_blank"
                ><cds-icon shape="file-zip"></cds-icon>&nbsp;Image(s)</a
            >
        </div>
    </div>
    <div class="bottom">
        <div class="date">
            <small>{{ activity.created | date : "dd-MM-yyyy HH:mm" }}</small>
        </div>
    </div>
</div>
