import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: "root",
})
export class AvailabilityService {
    constructor(private db: AngularFirestore) {}

    availabilities(contactId: string): Observable<Availability[]> {
        return this.db
            .collection("contacts")
            .doc(contactId)
            .collection("availabilities")
            .snapshotChanges()
            .pipe(
                map((docs) => {
                    return docs.map((doc) => {
                        const data = doc.payload.doc.data();
                        return {
                            id: doc.payload.doc.id,
                            available: data.availability === true ? true : false,
                            date: data.date?.toDate(),
                        };
                    });
                })
            );
    }
}

export interface Availability {
    id: string;
    available: boolean;
    date: Date;
}
