import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
    selector: "diversite-photo-cover",
    templateUrl: "./photo-cover.component.html",
    styleUrls: ["./photo-cover.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoCoverComponent implements OnInit, OnChanges {
    @Input() rotate: number;
    @Input() url: string;
    @Input() hideImage = false;
    @Input() mode: "contain" | "cover" = "contain";

    @Output() loaded = new EventEmitter<HTMLElement>();

    constructor(private hostElement: ElementRef, private sanitizer: DomSanitizer, private chRef: ChangeDetectorRef) { }

    ngOnInit(): void { }

    ngOnChanges(): void {
        this.chRef.detectChanges();
    }

    ngAfterViewInit(): void {
        this.loaded.emit(this.hostElement.nativeElement);
    }

    safeBackgroundImage(image: string): SafeStyle {
        if (image) {
            return this.sanitizer.bypassSecurityTrustStyle(`url(${image})`);
        }
    }

    rotation(rotation: number): string {
        return `rotate(${rotation}deg)`;
    }
}
