import { FormElementDTO } from "src/modules/diversite/mappers/module-mapper.service";
import { TranslatableLabel } from "src/modules/diversite/services/data-catalog.service";

import { FormElement, FormElementError, FormElementInput, FormElementType } from "./form-element";

interface Builder extends FormElementDTO {
    score?: number;
}
export class FormDatetime implements FormElement, FormElementInput {
    private _id: string;
    private _nameDescriptor: TranslatableLabel;
    private _description: TranslatableLabel;
    private _name: string;
    private _nameDisabled: boolean;
    private _type: FormElementType = "datetime";
    private _label: TranslatableLabel;
    private _readonly: boolean;
    private _required: boolean;
    private _score: number;
    private _deleted: boolean;

    static defaultElement(): FormDatetime {
        return new FormDatetime({
            nameDescriptor: { fr: "Champ date" },
            label: { fr: "Nouveau champ date" },
        });
    }
    constructor(builder: Builder) {
        this._id = builder.id;
        this._nameDescriptor = builder.nameDescriptor || {};
        this._description = builder.description || {};
        this._name = builder.name || "";
        this._nameDisabled = builder.nameDisabled === true ? true : false;
        this._label = builder.label;
        this._readonly = builder.readonly === true ? true : false;
        this._required = builder.required === true ? true : false;
        this._score = builder.score || 0;
        this._deleted = builder.deleted === true ? true : false;
    }

    get id(): string {
        return this._id;
    }
    get nameDescriptor(): TranslatableLabel {
        return this._nameDescriptor;
    }
    get description(): TranslatableLabel {
        return this._description;
    }

    get name(): string {
        return this._name;
    }
    get nameDisabled(): boolean {
        return this._nameDisabled;
    }
    get type(): FormElementType {
        return this._type;
    }

    get label(): TranslatableLabel {
        return this._label;
    }
    get readonly(): boolean {
        return this._readonly;
    }
    get required(): boolean {
        return this._required;
    }
    get score(): number {
        return this._score;
    }
    get deleted(): boolean {
        return this._deleted;
    }

    change(builder: FormElementDTO): FormDatetime {
        return new FormDatetime({
            id: this.id,
            nameDescriptor: this._nameDescriptor,
            description: this.description,
            name: this.name,
            nameDisabled: this.nameDisabled,
            label: this.label,
            required: this.required,
            readonly: this.readonly,
            score: this.score,
            deleted: this.deleted,
            ...builder,
        });
    }

    isInput(): true {
        return true;
    }

    toDto(): FormElementDTO {
        return {
            id: this.id,
            nameDescriptor: this._nameDescriptor,
            description: this.description,

            name: this.name,
            nameDisabled: this.nameDisabled,
            type: this.type,
            label: this.label,
            required: this.required,
            readonly: this.readonly,
            score: this.score,
            deleted: this._deleted,
        };
    }

    validate(value: any): FormElementError[] | null {
        if (this.deleted) {
            return null;
        }
        if (!this.required) {
            return null;
        }

        if (value && value !== "") {
            return null;
        } else {
            return [
                {
                    type: "required",
                    formElement: this,
                },
            ];
        }
    }
}
