import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

import { Orientation, Report, ReportType } from '../model/report';
import { ContactPerspectiveDTO, ContactPerspectiveMapperService } from './contact-perspective-mapper.service';

@Injectable({
    providedIn: "root",
})
export class ReportMapperService {
    constructor(private db: AngularFirestore, private contactPerspectiveMapper: ContactPerspectiveMapperService) { }

    reportToReportDto(report: Report): ReportDTO {

        const contactsDtos = report.contactPerspectives.map((cp) => {
            const contactDto = this.contactPerspectiveMapper.contactPerspectiveToContactPerspectiveDto(cp)
            return contactDto;
        });
        const reportDto = {
            id: report.id || null,
            name: report.name || "",
            logo: report.logo || null,
            logoSize: report.logoSize || 15,
            introPage: report.introPage === true ? true : false,
            introPageContent: report.introPageContent,
            profilePerPage: report.profilePerPage,
            photoMargin: report.photoMargin,
            photoSize: report.photoSize,
            proportionFace: report.proportionFace,
            pageMargin: report.pageMargin,
            orientation: report.orientation,
            type: report.type,

            contactPerspectives: contactsDtos,
            contactProfileDefinition: report.contactProfileDefinition || [],
            users: report.users || [],
            created: report.created,
        };
        return reportDto;
    }

    reportDtoToReport(reportDto: ReportDTO): Report {
        return new Report({
            id: reportDto.id,
            name: reportDto.name || "Report",
            logo: reportDto.logo || null,
            logoSize: reportDto.logoSize,
            introPage: reportDto.introPage,
            introPageContent: reportDto.introPageContent,
            profilePerPage: reportDto.profilePerPage,
            photoSize: reportDto.photoSize,
            proportionFace: reportDto.proportionFace,
            photoMargin: reportDto.photoMargin,
            pageMargin: reportDto.pageMargin,
            orientation: reportDto.orientation,
            type: reportDto.type as ReportType,
            contactPerspectives:
                reportDto.contactPerspectives.map((contactPerspective) =>
                    this.contactPerspectiveMapper.contactPerspectiveDtoToContactPerspective(contactPerspective)
                ) || [],
            contactProfileDefinition: Array.isArray(reportDto.contactProfileDefinition) ? reportDto.contactProfileDefinition : [],
            users: reportDto.users || [],
            created: (reportDto.created as firebase.firestore.Timestamp)?.toDate(),
        });
    }
}

export interface ReportDTO {
    id: string;
    name: string;
    logo: string;
    logoSize: number;
    introPage: boolean;
    introPageContent: string;
    profilePerPage: number;
    photoMargin: number;
    photoSize: number;
    proportionFace: number;
    pageMargin: number;
    orientation: Orientation;
    type: string;
    contactPerspectives: ContactPerspectiveDTO[];
    contactProfileDefinition: string[];
    users: DocumentReference[];
    created: firebase.firestore.Timestamp | Date;
}
