import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Notification } from 'src/modules/core/model/notification';

@Component({
    selector: "castlug-notification",
    templateUrl: "./notification.component.html",
    styleUrls: ["./notification.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
    @Input() notification: Notification;

    constructor(private chRef: ChangeDetectorRef) {}
}
