<input clrCheckbox type="checkbox" [(ngModel)]="isUnionChecked" (change)="onCheckboxChange($event.target.checked)" />
<input
    [(ngModel)]="textValue"
    class="text"
    [disabled]="!isUnionChecked"
    autocomplete="off"
    placeholder="valeur"
    name="value"
    type="text"
    (keyup)="onTextKeypress($event.target.value)"
/>
