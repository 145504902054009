<div [class.no-label]="!options.showLabel" *ngIf="formElement" class="fileupload-container clr-form-control">
    <label *ngIf="options.showLabel" class="clr-control-label"
        >{{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>
    <div class="clr-file-wrapper">
        <label class="clr-control-label">
            <span
                *ngIf="!fileUrl"
                class="btn btn-primary btn-sm"
                [class.disabled]="uploading || formElement.readonly"
                >{{ label("addFilesButtonText") }}</span
            >
            <span *ngIf="fileUrl" class="btn btn-primary btn-sm" [class.disabled]="uploading || formElement.readonly">{{
                label("modifyFilesButtonText")
            }}</span>
            <input
                [disabled]="uploading || formElement.readonly"
                [id]="formElement.id"
                (change)="onInputChange($event)"
                class="clr-file"
                type="file"
                [name]="formElement.id"
                [required]="formElement.required"
            />
        </label>
    </div>
    <div class="loading">
        <span [hidden]="!uploading" class="spinner spinner-sm"> {{ label("uploadingText") }} </span>
        <span class="text" [hidden]="!uploading">{{ label("uploadingText") }}</span>
    </div>
    <a *ngIf="fileUrl && !uploading" target="_blank" [href]="fileUrl">{{ fileUrl }}</a>
</div>
