import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, map, Observable, of, switchMap, tap } from 'rxjs';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { Form } from 'src/modules/diversite/model/form/form';
import { Project } from 'src/modules/diversite/model/project';
import { ContextNodeService } from 'src/modules/diversite/services/context-node.service';
import { TranslatableLabel } from 'src/modules/diversite/services/data-catalog.service';
import { FormService } from 'src/modules/diversite/services/form.service';
import { ProjectService } from 'src/modules/diversite/services/projects.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';

@Component({
    selector: 'diversite-copy-form-action',
    templateUrl: './copy-form-action.component.html',
    styleUrl: './copy-form-action.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyFormActionComponent {
    @Input() formId: string = "";
    @Input() projectId: string = "";

    @Output() close = new EventEmitter<void>();
    projects$: Observable<Project[]>;
    readonly projectChange$ = new BehaviorSubject<string>(undefined)
    readonly forms$: Observable<Form[]> = this.projectChange$.pipe(
        switchMap(pId => pId && pId !== "" ? this.formService.formForProject(pId) : of([])),
        tap(fs => {
            this._forms = fs;
            if (this.formId && this.formId !== "") {
                this.onFormChange();
            }
        })
    )

    name: TranslatableLabel = {
        fr: "",
        en: ""
    };

    submitted = false;

    destinationProjectId = "";
    private _forms: Form[] = []

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.projectId) {
            if (changes.projectId.currentValue && changes.projectId.currentValue !== "") {
                this.onProjectChange();
            } else {
                this.projectId = "";
                this.onProjectChange();
            }
        }
    }

    constructor(
        private projectService: ProjectService,
        private formService: FormService,
        private contextNodeService: ContextNodeService,
        private translateService: TranslateService,
        private notificationService: NotificationService) {
        this.projects$ = this.projectService.activeProjects();
    }

    trackById(_: number, entity: any): string {
        return entity.id;
    }

    onProjectChange(): void {
        this.projectChange$.next(this.projectId);
    }

    onFormChange(): void {
        const form = this._forms.find(f => f.id === this.formId);
        if (form) {
            this.name = { ...form.name };
        } else {
            this.name = null;
        }
    }

    disabled(): boolean {
        return this.submitted || this.destinationProjectId === '' || ((this.name.fr === '' || !this.name.fr) && (this.name.en === '' || !this.name.en));
    }

    get lang(): string {
        return this.translateService.lang;
    }

    copyForm(): void {
        this.submitted = true;
        this.formService.form(this.formId, { listen: false }).pipe(
            switchMap(form => {
                return this.formService.saveACopyInProject(form, this.destinationProjectId, this.name).pipe(
                    switchMap(f => this.contextNodeService.assignFormsToProjectNode(this.destinationProjectId, [f.id]))
                )
            })
        ).subscribe(_ => {
            this.notificationService.show("Votre formulaire à été copié avec succès.", "success");
            this.close.emit();
        })
    }

    onCancel(): void {
        this.close.emit();
    }
}
