import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

import { Video } from '../../services/contact-videos.service';

declare var $: any;

@Component({
    selector: "diversite-video-profile",
    templateUrl: "./video-profile.component.html",
    styleUrls: ["./video-profile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoProfileComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() videos: Video[];
    @Output() addVideos = new EventEmitter<File[]>();
    @Output() removeVideo = new EventEmitter<Video>();
    videoId: string;
    showVideo = true;
    private zone: any;
    loading = false;

    videoDeletion: Video;

    constructor(private authService: AuthService, private host: ElementRef, private chRef: ChangeDetectorRef) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.loading) {
            this.loading = false;
        }
        if (this.zone) {
            this.zone.removeClass("dragfile");
        }
        if (!this.videoId && changes.videos && changes.videos.currentValue && changes.videos.currentValue.length > 0) {
            this.videoId = changes.videos.currentValue[0].id;
        }
    }

    ngAfterViewInit(): void {
        if (this.authenticated) {
            this.setDroppableZone();
        }
    }

    get authenticated(): boolean {
        return this.authService.isLoggedIn;
    }

    previous(): void {
        const videoIndex: number = this.videos.findIndex((v, i) => v.id === this.videoId);
        const newIndex = this.previousNewIndex(videoIndex);
        this.videoId = this.videos[newIndex]?.id;
        this.chRef.markForCheck();
    }
    next(): void {
        const videoIndex: number = this.videos.findIndex((v, i) => v.id === this.videoId);
        const newIndex = this.nextNewIndex(videoIndex);
        this.videoId = this.videos[newIndex]?.id;
        this.chRef.markForCheck();
    }

    nextNewIndex(currentIndex: number): number {
        if (this.videos.length === 0) {
            this.videoId = undefined;
        }
        if (currentIndex || currentIndex === 0) {
            if (currentIndex + 1 > this.videos.length - 1) {
                return 0;
            }
            return currentIndex + 1;
        }
        return 0;
    }

    removePicture(video: Video): void {
        this.removeVideo.emit(video);
    }

    removePhotoPrompt(video: Video): void {
        this.videoDeletion = video;
    }

    onOpenVideoDeletion(isOpen: boolean): void {
        this.videoDeletion = undefined;
    }

    confirmImageRemoval(): void {
        if (this.videos.length > 0) {
            this.videoId = this.videos[0].id;
        } else {
            this.videoId = undefined;
        }

        this.removeVideo.emit(this.videoDeletion);
        this.videoDeletion = undefined;
    }

    previousNewIndex(currentIndex: number): number {
        if (this.videos.length === 0) {
            this.videoId = undefined;
        }
        if (currentIndex || currentIndex === 0) {
            if (currentIndex === 0 && this.videos.length > 1) {
                return this.videos.length - 1;
            }
            if (currentIndex - 1 <= 0 && this.videos.length > 1) {
                return 0;
            }
            return currentIndex - 1;
        }
        return 0;
    }

    trackById(_: number, video: Video): string {
        return video.id;
    }

    private setDroppableZone(): void {
        this.zone = $(".videos-container", this.host.nativeElement);

        this.zone.on("dragover", (e) => {
            if (!this.zone.hasClass("dragfile")) {
                this.zone.addClass("dragfile");
            }
            e.preventDefault();
            e.stopPropagation();
        });
        this.zone.on("dragenter", (e) => {
            this.zone.addClass("dragfile");
            e.preventDefault();
            e.stopPropagation();
        });
        this.zone.on("dragleave", (e) => {
            this.zone.removeClass("dragfile");
        });
        this.zone.on("drop", (e) => {
            if (e.originalEvent.dataTransfer && e.originalEvent.dataTransfer.files.length) {
                e.preventDefault();
                e.stopPropagation();
                const SUPPORTED_FORMATS = ["mov", "mp4", "hevc"];
                /*UPLOAD FILES HERE*/
                const validVideos = Array.from(e.originalEvent.dataTransfer.files as FileList).filter((videoFile) => {
                    const ext = videoFile.name.split(".").reverse()[0]?.toLowerCase();
                    if (ext) {
                        return SUPPORTED_FORMATS.includes(ext);
                    } else {
                        return false;
                    }
                });
                this.loading = true;
                this.addVideos.emit(validVideos);
            }
        });
    }
}
