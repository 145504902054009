import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';
import { isString } from 'src/app/core/functions';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormCheckbox } from 'src/modules/diversite/model/form/form-element/form-checkbox';
import { FormOption } from 'src/modules/diversite/model/form/form-element/form-option';

import { DEFAULT_OPTIONS, FormElementGenericOptions } from '../../form-element.component';

@Component({
    selector: "fillout-checkbox",
    templateUrl: "./checkbox.component.html",
    styleUrls: ["./checkbox.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements OnInit, OnChanges {
    @Input() lang: string;
    @Input() formElement: FormCheckbox;
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Input() value: ResponseCheckbox[] = [];

    @Output() responseChange = new EventEmitter<ResponseCheckbox[]>();

    delayedChanges$ = new BehaviorSubject<void>(undefined);

    private _disposeBag = new DisposeBag();

    constructor() {}

    ngOnInit(): void {
        this.delayedChanges$
            .pipe(skip(1))
            .subscribe((v) => {
                this.responseChange.emit(this.value);
            })
            .disposedBy(this._disposeBag);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && !Array.isArray(changes.value.currentValue)) {
            this.value = [];
        }

        this.backwardCompatibility(changes);
    }

    checkStateChange(event: any, option: FormOption): void {
        const target = event.target as HTMLInputElement;

        this.value = this.formElement.options.map((opt) => {
            const findOptionInValue = this.value.find((o) => o.id === opt.id);
            if (findOptionInValue) {
                return {
                    id: findOptionInValue.id,
                    value: option.id === findOptionInValue.id ? !findOptionInValue.value : findOptionInValue.value,
                };
            } else if (opt.id === option.id) {
                return { id: opt.id, value: target.checked };
            } else {
                return { id: opt.id, value: false };
            }
        });

        this.delayedChanges$.next();
    }

    isOptionChecked(option: FormOption): boolean {
        const founded = this.value?.find((opt) => opt.id === option.id);
        if (founded) {
            return founded.value === true;
        }
        return false;
    }

    /**
     * Convert old response (string[]) if they exist into array of object (ResponseCheckbox[])
     * @param changes
     */
    private backwardCompatibility(changes: SimpleChanges): void {
        // backward compatibility
        if (changes.value) {
            if (
                changes.value.currentValue &&
                Array.isArray(changes.value.currentValue) &&
                changes.value.currentValue.every((v) => isString(v))
            ) {
                this.value = this.formElement.options.map((formOption) => {
                    const existAlready = changes.value.currentValue.find(
                        (idFormOption) => idFormOption === formOption.id
                    );
                    if (existAlready) {
                        return { id: formOption.id, value: true };
                    }
                    return { id: formOption.id, value: false };
                });
                this.delayedChanges$.next();
            }
        }
    }
}

export interface ResponseCheckbox {
    id: string;
    value: boolean;
}
