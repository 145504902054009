<ul *ngIf="setArrayForPages() as pagesArray">
    <li class="per-page">
        par page
        <input min="5" max="1000" (change)="onLimitChange($event.target.value)" type="number" [value]="limit" />
    </li>
    <li (click)="onChangePage(1)" class="nav-change">
        <cds-icon direction="left" shape="angle-double"></cds-icon>
    </li>
    <li (click)="onChangePage(page - 1)" class="nav-change">
        <cds-icon direction="left" shape="angle"></cds-icon>
    </li>
    <li
        class="nav-change"
        (click)="onChangePage(p)"
        [class.current]="page === p"
        *ngFor="let p of pagesArray; trackBy: trackByPage"
    >
        {{ p }}
    </li>
    <li (click)="onChangePage(page + 1)" class="nav-change">
        <cds-icon direction="right" shape="angle"></cds-icon>
    </li>
    <li (click)="onChangePage(totalPages)" class="nav-change">
        <cds-icon direction="right" shape="angle-double"></cds-icon>
    </li>
    <li class="per-page" *ngIf="totalPages > pagesArray.length + 2" class="per-page">
        <input type="number" (change)="onChangePage($event.target.value)" [value]="page" /> de {{ totalPages }} pages
    </li>
</ul>
