import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, delay, filter, finalize, skip, take, tap } from 'rxjs/operators';
import { validateVimeoURL, validateYouTubeUrl } from 'src/app/core/functions';
import { Translatable } from 'src/modules/core/components/translatable';
import { FileUploadVideoService } from 'src/modules/core/services/file-upload-video.service';
import { UploadFilesResponse } from 'src/modules/core/services/file-upload.service';
import { StaticLabels, StaticLabelsService } from 'src/modules/core/services/static-labels.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormVideoupload } from 'src/modules/diversite/model/form/form-element/form-videoupload';
import { VideoSourceType } from 'src/modules/diversite/services/contact-videos.service';

import { DEFAULT_OPTIONS, FormElementGenericOptions } from '../../form-element.component';

@Component({
    selector: "fillout-videoupload",
    templateUrl: "./videoupload.component.html",
    styleUrls: ["./videoupload.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideouploadComponent implements OnInit, OnDestroy, OnChanges, Translatable {
    private translationId = "LCgYQovUxTVcpyhi379G";
    @Input() lang: string;
    @Input() formElement: FormVideoupload;
    @Input() value: string[];
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Output() responseChange = new EventEmitter<string[]>();

    @ViewChild("videoUrlElement") videoUrlElement: ElementRef<HTMLInputElement>;
    videoSourceType: VideoSourceType = "file";

    private translationLabels: StaticLabels = {};

    videoUrls: string[] = [];
    files: File[];
    uploading = false;
    percentage = 0;

    delayedChanges$ = new BehaviorSubject<string[]>(undefined);

    private disposeBag = new DisposeBag();

    constructor(
        private chRef: ChangeDetectorRef,
        private fileUploadService: FileUploadVideoService,
        private sanitizer: DomSanitizer,
        private staticLabelsService: StaticLabelsService
    ) { }

    ngOnInit(): void {
        this.staticLabelsService
            .labelsForComponent(this.translationId)
            .pipe(take(1))
            .subscribe((labels) => {
                this.translationLabels = labels;
                this.chRef.markForCheck();
            })
            .disposedBy(this.disposeBag);
        this.delayedChanges$
            .pipe(debounceTime(250), skip(1))
            .subscribe((v) => {
                const finalValue = Array.isArray(v) ? v : [];
                this.responseChange.emit(finalValue);
            })
            .disposedBy(this.disposeBag);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && changes.value.currentValue && Array.isArray(changes.value.currentValue)) {
            this.videoUrls = changes.value.currentValue;
        }
    }

    label(labelId: string): string {
        if (this.translationLabels && this.translationLabels[labelId] && this.translationLabels[labelId][this.lang]) {
            return this.translationLabels[labelId][this.lang];
        }
        return "";
    }

    onInputChange(event: any): void {
        // this.delayedChanges$.next((event.target as HTMLInputElement).value);
        if (!this.formElement.readonly) {
            if (event.target.files.length > 0) {
                this.percentage = 0;
                this.files = event.target.files;
                this.uploading = true;
                this.fileUploadService
                    .uploadFiles(Array.from(this.files))
                    .pipe(
                        tap((uploadVideoResponse: UploadFilesResponse) => {
                            this.percentage = uploadVideoResponse.percentage;
                            this.chRef.detectChanges();
                        }),
                        filter((uploadVideoResponse: UploadFilesResponse) => {
                            return uploadVideoResponse.percentage === 100;
                        }),
                        take(1),
                        delay(500),
                        finalize(() => { })
                    )
                    .subscribe((uploadVideoResponse) => {
                        this.videoUrls = [...this.videoUrls, ...uploadVideoResponse.urls];
                        this.responseChange.emit(this.videoUrls);
                        this.uploading = false;
                        this.chRef.detectChanges();
                    })
                    .disposedBy(this.disposeBag);
            } else {
                this.files = [];
                this.responseChange.emit([]);
            }
        }
    }

    addVideoSite(url: string): void {
        if (validateYouTubeUrl(url)) {
            this.videoUrls = [...this.videoUrls, validateYouTubeUrl(url) as string];
            this.responseChange.emit(this.videoUrls);
        } else if (validateVimeoURL(url)) {
            this.videoUrls = [...this.videoUrls, validateVimeoURL(url) as string];
            this.responseChange.emit(this.videoUrls);
        }

        this.videoUrlElement.nativeElement.value = "";
    }

    deleteImage(i: number): void {
        this.videoUrls = this.videoUrls.filter((_, index) => index !== i);
        this.responseChange.emit(this.videoUrls);
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
