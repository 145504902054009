<div *ngIf="formElement" class="form-element-edition">
    <span
        #labelElement
        class="edit-label"
        contenteditable
        (keyup)="onLabelChange()"
        [innerHTML]="tempFormElement.label[lang]"
    ></span>
    <ng-content select="[tooltip]"></ng-content>

    <div class="options">
        <div
            [attr.data-index]="index"
            class="option"
            *ngFor="let option of tempFormElement.options; let index = index; trackBy: trackByFn"
        >
            <cds-icon class="drag-field" shape="drag-handle"></cds-icon>
            <input class="radio" disabled type="radio" />
            <input class="option-edition" [(ngModel)]="option.label[lang]" (keyup)="onChange($event)" />
            <small *ngIf="!formElement.nameDisabled" (click)="deleteOption(option)"
                ><clr-icon shape="times"></clr-icon
            ></small>
        </div>
    </div>
    <small *ngIf="!formElement.nameDisabled" (click)="addOptionToField()">Ajouter une option</small>
</div>
