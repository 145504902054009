<div *ngIf="id">
    <div class="clr-file-wrapper">
        <label [for]="id" class="clr-control-label"
            ><span *ngIf="!imageUrl" class="btn btn-primary btn-sm" [class.disabled]="uploading || readonly"
                >Ajouter la photo...</span
            >
            <span *ngIf="imageUrl" class="btn btn-primary btn-sm" [class.disabled]="uploading || readonly"
                >Modifier la photo...</span
            >
            <input
                [disabled]="uploading || readonly"
                [id]="id"
                (change)="onInputChange($event)"
                class="clr-file"
                type="file"
                [name]="id"
        /></label>
        <small>Taille maximale de 10 Mo.</small>
        <div class="loading">
            <span [hidden]="!uploading" class="spinner spinner-sm"> Téléchargement en cours... </span>
            <span class="text" [hidden]="!uploading">Téléchargement en cours...</span>
        </div>
    </div>
    <img *ngIf="imageUrl && !uploading" style="max-width: 100px" [src]="imageUrl" />
</div>
