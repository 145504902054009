import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';

import { Contact } from '../model/contact';
import { ContactPerspective, ImageDispositionSpecification } from '../model/contactPerspective';
import { FacePositionService, MultiCustomLayout } from '../services/face-position.service';

@Injectable({
    providedIn: "root",
})
export class ContactPerspectiveMapperService {
    constructor(private db: AngularFirestore) { }

    contactPerspectiveToContactPerspectiveDto(contactPerspective: ContactPerspective): ContactPerspectiveDTO {
        return {
            id: contactPerspective.id,
            contact: this.db.collection("contacts").doc(contactPerspective.id).ref,
            defaultImage: contactPerspective.defaultImageIndex ? contactPerspective.defaultImageIndex : 0,
            layout: contactPerspective.layout ? contactPerspective.layout : null,
            visibleImages: contactPerspective.visibleImages,
            comments: contactPerspective.comments ? contactPerspective.comments : null,
            imageDispositionSpecs: contactPerspective.imageDispositionSpecs
                ? contactPerspective.imageDispositionSpecs
                : [],
            imageDispositionProfile: contactPerspective.imageDispositionProfile,
            createdAt: contactPerspective.createdAt
                ? new firebase.firestore.Timestamp(contactPerspective.createdAt / 1000, 0)
                : null,
            lastUpdate: contactPerspective.lastUpdate
                ? new firebase.firestore.Timestamp(contactPerspective.lastUpdate / 1000, 0)
                : null,
        };
    }

    contactPerspectiveDtoToContactPerspective(
        contactPerspectiveDto: ContactPerspectiveDTO,
        contact?: Contact
    ): ContactPerspective {
        let defaultImages;
        let defaultLayout;
        if (!contactPerspectiveDto.layout && contact) {
            defaultImages = contact.images
                .filter((_, index) => index < 3)
                .map((image) => {
                    return image.id;
                });
            defaultLayout = FacePositionService.profileLayoutVariantsForNumberOfPictures(defaultImages.length)[0];
        }
        return new ContactPerspective({
            id: contactPerspectiveDto.id,
            contact: contact ? contact : null,
            defaultImageIndex: contactPerspectiveDto.defaultImage || 0,
            layout: contactPerspectiveDto.layout ? contactPerspectiveDto.layout : defaultLayout,
            visibleImages:
                contactPerspectiveDto.visibleImages && contactPerspectiveDto.visibleImages.length !== 0
                    ? contactPerspectiveDto.visibleImages
                    : defaultImages,
            comments: contactPerspectiveDto.comments || "",
            imageDispositionSpecs: contactPerspectiveDto.imageDispositionSpecs || [],
            imageDispositionProfile: contactPerspectiveDto.imageDispositionProfile || [],
            createdAt: contactPerspectiveDto.createdAt ? contactPerspectiveDto.createdAt.seconds * 1000 : null,
            lastUpdate: contactPerspectiveDto.lastUpdate ? contactPerspectiveDto.lastUpdate.seconds * 1000 : null,
        });
    }
}

export interface ContactPerspectiveDTO {
    id: string;
    contact: DocumentReference;
    defaultImage?: number;
    layout?: MultiCustomLayout;
    visibleImages?: string[];
    imageDispositionSpecs?: ImageDispositionSpecification[];
    imageDispositionProfile?: ImageDispositionSpecification[];
    comments?: string;
    createdAt?: firebase.firestore.Timestamp;
    lastUpdate?: firebase.firestore.Timestamp;
}
