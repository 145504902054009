<diversite-search-pane-content
    #searchPaneContent
    [contextNodeId]="pane.contextNodeId"
    [projectId]="pane.projectId"
    [loading]="loading"
    [areaSizes]="pane.contextData?.areaSizes"
    [searchPagination]="searchPagination$ | async"
    [searchDefinition]="search$ | async"
    [tabOpen]="pane.contextData?.tabOpen ? pane.contextData?.tabOpen : 'search'"
    [favoriteSearchNodes]="favoriteSearchNodes$ | async"
    [contactWidth]="pane.contextData?.contactCardWidth"
    [contactResults]="contactResults$ | async"
    [searchMeta]="searchMeta$ | async"
    (search)="onSearch($event)"
    (changeTab)="onSearchParametersTab($event)"
    (selectAll)="onSelectAll($event)"
    (searchPaginationChange)="onSearchPaginationChange($event)"
    (contactDefaultImage)="onContactDefaultImageChange($event)"
    (searchDefinitionChange)="onSearchDefinitionChange($event)"
    (areaSizesChange)="onAreaSizesChange($event)"
    (massEditResults)="onMassEditResults($event)"
    (changeContactWidth)="onContactWidthChange($event)"
/>
