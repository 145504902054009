import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PublicContext } from 'src/modules/core/model/context';
import { PublicContextService } from 'src/modules/core/services/public-context.service';

@Injectable({
    providedIn: "root",
})
export class ContextResolver {
    constructor(private publicContextService: PublicContextService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<PublicContext> {
        return this.publicContextService.context(route.params.contextId);
    }
}
