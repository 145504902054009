import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { ImageDispositionOption, ImageLayout } from '../../services/face-position.service';

declare let $: any;
const DEFAULT_FACE_DISPOSITION: ImageDispositionOption = {
    containerWidth: 100,
    containerHeight: 100,
    finalFaceHeight: 70,
};
@Component({
    selector: "diversite-slideshow",
    templateUrl: "./slideshow.component.html",
    styleUrls: ["./slideshow.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideshowComponent implements OnInit, OnChanges {
    @Input() images: ImageLayout[] = [];
    @Input() cardFaceDispositionOptions: ImageDispositionOption = DEFAULT_FACE_DISPOSITION;
    @Input() currentImageIndex = 0;
    @Input() editable = true;
    @Input() showSquareFace = true;
    @Output() defaultImageChange = new EventEmitter<number>();

    constructor(private chRef: ChangeDetectorRef, private sanitizer: DomSanitizer) { }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void { }

    safeUrl(image: ImageLayout): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(image.url);
    }

    previousPhoto(event: MouseEvent): void {
        if (this.currentImageIndex === 0) {
            this.currentImageIndex = this.images.length - 1;
        } else {
            this.currentImageIndex = this.currentImageIndex - 1;
        }
        this.defaultImageChange.emit(this.currentImageIndex);
        event.preventDefault();
        event.stopPropagation();
    }

    nextPhoto(event: MouseEvent): void {
        if (this.currentImageIndex === this.images.length - 1) {
            this.currentImageIndex = 0;
        } else {
            this.currentImageIndex = this.currentImageIndex + 1;
        }
        this.defaultImageChange.emit(this.currentImageIndex);
        event.preventDefault();
        event.stopPropagation();
    }
}

export interface SlideshowOption {
    zoomable: boolean;
}
