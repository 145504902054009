<div
    class="container"
    [class.compact]="compact"
    [class.loading]="loading"
    *ngIf="contactPerspective && contactPerspective.contact"
>
    <h1 *ngIf="!compact">
        <cds-icon class="clickable" (click)="copyLink()" shape="link" size="lg"></cds-icon>&nbsp;<small>{{
            contactPerspective.contact.idfig
        }}</small>
        {{ contactPerspective.contact.fullname }} <small>,{{ contactPerspective.contact.age }}</small>
    </h1>
    <div *ngIf="contactPerspective.contact.suppressed" class="alert alert-danger" role="alert">
        <div class="alert-items">
            <div class="alert-item static">
                <div class="alert-icon-wrapper">
                    <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
                </div>
                <span class="alert-text"> Ce profil n'est pas disponible. </span>
            </div>
        </div>
    </div>

    <div class="clr-row no-margin">
        <div class="clr-col-12">
            <div class="contact">
                <clr-tabs>
                    <clr-tab>
                        <button class="button-tab" clrTabLink>
                            Photos
                            <cds-badge [color]="'red'">{{ contactPerspective.contact.images.length }}</cds-badge
                            ><cds-icon
                                *hasAccess="{ type: 'segment', segment: 'contactOperation', permissions: ['update'] }"
                                (click)="addPhotosPrompt()"
                                size="24"
                                shape="plus-circle"
                            ></cds-icon>
                        </button>
                        <clr-tab-content *clrIfActive>
                            <diversite-photo-profile
                                *ngIf="contactPerspective"
                                [contactPerspective]="contactPerspective"
                                (layoutChange)="onLayoutChange($event)"
                                (rotatePicture)="onRotatePicture($event)"
                                (addPhotos)="onAddPhotos($event)"
                                (contactPerspectiveChange)="onContactPerspectiveChange($event)"
                                (removeImage)="onRemovePhoto($event)"
                            ></diversite-photo-profile>
                        </clr-tab-content>
                    </clr-tab>
                    <clr-tab *ngIf="videos$ | async; let videos">
                        <button class="button-tab" clrTabLink>
                            Videos <cds-badge [color]="'red'">{{ videos.length }}</cds-badge
                            ><cds-icon
                                *hasAccess="{ type: 'segment', segment: 'contactOperation', permissions: ['update'] }"
                                (click)="addVideosPrompt()"
                                size="24"
                                shape="plus-circle"
                            ></cds-icon>
                        </button>
                        <clr-tab-content *clrIfActive>
                            <diversite-video-profile
                                (addVideos)="onAddVideos($event)"
                                (removeVideo)="onRemoveVideo($event)"
                                [videos]="videos"
                            >
                                <div *ngIf="percentageUploadVideo || percentageUploadVideo === 0" class="progress top">
                                    <progress [value]="percentageUploadVideo" max="100"></progress>
                                </div>
                            </diversite-video-profile>
                        </clr-tab-content>
                    </clr-tab>
                </clr-tabs>

                <div
                    *hasAccess="{ type: 'segment', segment: 'contactOperation', permissions: ['update'] }"
                    class="head-buttons"
                >
                    <button
                        class="btn btn-primary btn-sm"
                        [disabled]="isDownloading"
                        (click)="downloadPhoto()"
                        *ngIf="contactPerspective.contact.images.length > 0"
                    >
                        Télécharger photo(s)
                    </button>
                    <button class="btn btn-primary btn-sm" *ngIf="compact" (click)="goToProfile()">
                        <cds-icon shape="user"></cds-icon> Page du profil
                    </button>

                    <button class="btn btn-primary btn-sm" (click)="copy()">Copier l'information</button>

                    <!-- <button class="btn btn-primary btn-sm" *ngIf="!editMode" (click)="activateEditMode()">
                        Éditer le profil
                    </button>

                    <button class="btn btn-primary btn-sm" [disabled]="!isFormValid" *ngIf="editMode" (click)="save()">
                        Sauvegarder le profil
                    </button>

                    <button class="btn btn-primary btn-sm" *ngIf="editMode" (click)="deactivateEditMode()">
                        Cancel
                    </button> -->

                    <a
                        class="action-link"
                        (click)="openSuppressContactPrompt()"
                        *ngIf="!contactPerspective.contact.suppressed"
                    >
                        Désactiver le profil
                    </a>
                    <a class="action-link" *ngIf="contactPerspective.contact.suppressed" (click)="reactivateContact()">
                        Réactiver le profil
                    </a>
                </div>

                <table
                    *hasAccess="{ type: 'segment', segment: 'contactOperation', permissions: ['update'] }"
                    class="hidden"
                    id="table-for-copy"
                >
                    <tr>
                        <td># {{ contactPerspective.contact.idfig }} -</td>
                        <td>{{ contactPerspective.contact.fullname }}</td>
                        <td>
                            UDA:
                            <diversite-attribute-value
                                [attributeName]="'affiliation_uda'"
                                [value]="tempAttributes['affiliation_uda']"
                            ></diversite-attribute-value>
                            | ACTRA:
                            <diversite-attribute-value
                                [attributeName]="'affiliation_actra'"
                                [value]="tempAttributes['affiliation_actra']"
                            ></diversite-attribute-value>
                        </td>
                        <td>
                            {{ contactPerspective.contact.mainPhoneLiteral }}
                            <span *ngIf="tempAttributes['contactInformation_agentName']">/</span
                            ><diversite-attribute-value
                                [attributeName]="'contactInformation_agentPhone'"
                                [value]="tempAttributes['contactInformation_agentPhone']"
                            ></diversite-attribute-value
                            >&nbsp;<diversite-attribute-value
                                [attributeName]="'contactInformation_agentName'"
                                [value]="tempAttributes['contactInformation_agentName']"
                            ></diversite-attribute-value>
                        </td>
                        <td>
                            <diversite-attribute-value
                                [attributeName]="'contactInformation_email'"
                                [value]="tempAttributes['contactInformation_email']"
                            ></diversite-attribute-value>
                            <span *ngIf="tempAttributes['contactInformation_agentEmail']">/</span>
                            <diversite-attribute-value
                                [attributeName]="'contactInformation_agentEmail'"
                                [value]="tempAttributes['contactInformation_agentEmail']"
                            ></diversite-attribute-value>
                        </td>
                        <td>{{ tempAttributes["voiture"] && tempAttributes["voiture"] !== "" ? "A" : "" }}</td>
                    </tr>
                </table>

                <clr-tabs>
                    <clr-tab>
                        <button clrTabLink>Description</button>
                        <clr-tab-content *clrIfActive>
                            <diversite-attribute-nodes
                                [context]="context"
                                [contact]="contactPerspective.contact"
                            ></diversite-attribute-nodes>
                        </clr-tab-content>
                    </clr-tab>

                    <clr-tab *hasAccess="{ type: 'segment', segment: 'contactOperation', permissions: ['update'] }">
                        <button clrTabLink>Disponibilités</button>
                        <clr-tab-content *clrIfActive>
                            <diversite-calendar-availabilities
                                [availabilities]="availabilities$ | async"
                            ></diversite-calendar-availabilities>
                        </clr-tab-content>
                    </clr-tab>
                    <clr-tab *hasAccess="{ type: 'segment', segment: 'contactOperation', permissions: ['update'] }">
                        <button clrTabLink>
                            Historique
                            <small>({{ contactPerspective.contact.lastUpdate | date : "dd-MM-yyyy" }})</small>
                        </button>
                        <clr-tab-content *clrIfActive="contactPerspective.contact.suppressed">
                            <diversite-contact-change-history
                                [contact]="contactPerspective.contact"
                            ></diversite-contact-change-history>
                        </clr-tab-content>
                    </clr-tab>
                </clr-tabs>
            </div>
        </div>
    </div>
</div>

<diversite-suppress-contact-prompt
    [contact]="suppressContactPrompt"
    (contactSuppress)="onContactSuppress($event)"
></diversite-suppress-contact-prompt>

<clr-modal
    *ngIf="openAddVideosPrompt"
    [(clrModalOpen)]="openAddVideosPrompt"
    (clrModalOpenChange)="onOpenAddVideoModalChange($event)"
>
    <h3 class="modal-title">Ajouter video(s)</h3>
    <div class="modal-body" [class.loading]="loading">
        <clr-radio-container clrInline>
            <clr-radio-wrapper>
                <input
                    [disabled]="submittingVideos || uploading"
                    type="radio"
                    clrRadio
                    name="optionSourceType"
                    required
                    value="file"
                    [(ngModel)]="videoSourceType"
                />
                <label>Fichier(s)</label>
            </clr-radio-wrapper>
            <clr-radio-wrapper>
                <input
                    [disabled]="submittingVideos || uploading"
                    type="radio"
                    clrRadio
                    name="optionSourceType"
                    required
                    value="siteUrl"
                    [(ngModel)]="videoSourceType"
                />
                <label>Url <small>(youtube et vimeo)</small></label>
            </clr-radio-wrapper>
        </clr-radio-container>

        <div *ngIf="videoSourceType === 'siteUrl'">
            <clr-input-container style="margin-top: 5px">
                <input class="input-url" #videoUrlElement type="text" clrInput placeholder="Site youtube ou vimeo" />
            </clr-input-container>
            <button (click)="addVideoSite(videoUrlElement.value)" class="btn btn-sm">Ajouter</button>
        </div>

        <div *ngIf="videoSourceType === 'file'" class="clr-file-wrapper">
            <label class="clr-control-label">
                <span class="btn btn-primary btn-sm" [class.disabled]="uploading">Ajouter le/les vidéo(s)...</span>
                <input
                    [disabled]="uploading"
                    (change)="onInputChange('video', $event)"
                    accept=".mp4,.hevc,.mov"
                    class="clr-file"
                    type="file"
                    multiple
                />
            </label>
            <small>Format mp4, mov ou hevc.</small>
        </div>

        <div [hidden]="!uploading" class="loading">
            <small class="text"
                >Téléchargement en cours...<span class="spinner spinner-sm"> Téléchargement en cours... </span></small
            >
        </div>

        <div class="videos">
            <div *ngFor="let video of this.videoUrls; let i = index" class="video">
                <video-player [url]="video"></video-player>
                <cds-icon (click)="deleteVideo(i)" shape="times"></cds-icon>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <span [hidden]="!submittingVideos" class="spinner spinner-sm"> Téléchargement en cours... </span>
        <button (click)="onOpenAddVideoModalChange()" type="button" class="btn">Cancel</button>
        <button
            [disabled]="uploading || submittingVideos"
            (click)="onConfirmAddVideos()"
            type="button"
            class="btn btn-primary"
        >
            Ajouter
        </button>
    </div>
</clr-modal>

<clr-modal
    *ngIf="openAddPhotosPrompt"
    [(clrModalOpen)]="openAddPhotosPrompt"
    (clrModalOpenChange)="onOpenAddPhotoModalChange($event)"
>
    <h3 class="modal-title">Ajouter photo(s)</h3>
    <div class="modal-body" [class.loading]="loading">
        <div class="clr-file-wrapper">
            <label class="clr-control-label">
                <span class="btn btn-primary btn-sm" [class.disabled]="uploading">Téléverser le/les image(s)...</span>
                <input
                    [disabled]="uploading"
                    (change)="onInputChange('image', $event)"
                    accept=".jpg,.jpeg,.gif,.png"
                    class="clr-file"
                    type="file"
                    multiple
                />
            </label>
        </div>

        <div class="photos">
            <div *ngFor="let photo of this.photoUrls; let i = index" class="photo">
                <div class="image-thumb" [style.background-image]="safeImage(photo)"></div>
                <cds-icon (click)="deleteImage(i)" shape="times"></cds-icon>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <span [hidden]="!submittingPhotos" class="spinner spinner-sm"> Téléchargement en cours... </span>
        <button (click)="onOpenAddVideoModalChange()" type="button" class="btn">Cancel</button>
        <button
            [disabled]="uploading || submittingPhotos"
            (click)="onConfirmAddPhotos()"
            type="button"
            class="btn btn-primary"
        >
            Ajouter
        </button>
    </div>
</clr-modal>
