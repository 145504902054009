import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Project } from 'src/modules/diversite/model/project';
import { ContactContextNodeAssignService } from 'src/modules/diversite/services/contact-context-node-assign.service';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { ContextNodeService } from 'src/modules/diversite/services/context-node.service';
import { ProjectService } from 'src/modules/diversite/services/projects.service';

@Component({
    selector: 'diversite-add-idfig-list-action',
    templateUrl: './add-idfig-list-action.component.html',
    styleUrl: './add-idfig-list-action.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddIdfigListActionComponent implements OnDestroy {
    @Input() projectId: string;
    @Input() idfigs: string[];
    @Output() close = new EventEmitter<void>();

    parsedIdfigs$ = new BehaviorSubject<string[]>([]);
    folderName: string = "";
    idfigsRaw: string = "";
    private disposeBag = new DisposeBag();

    projects$: Observable<Project[]>;
    isCreating = false;
    constructor(
        private contextNodeService: ContextNodeService,
        private assignService: ContactContextNodeAssignService,
        private contactService: ContactService,
        private projectService: ProjectService,
        private notificationService: NotificationService) {

    }

    ngOnInit(): void {
        this.projects$ = this.projectService.activeProjects();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.idfigs?.currentValue) {
            this.idfigsRaw = changes.idfigs?.currentValue.join("\n");
            this.onIdfigKeytype(this.idfigsRaw);
        }
    }


    onIdfigKeytype(text: any): void {
        this.parsedIdfigs$.next(this.parseString(text));
    }

    onCancel(): void {
        this.close.emit();
    }

    valid(): boolean {
        return this.folderName !== "" && this.parsedIdfigs$.value.length > 0 && this.projectId && this.projectId !== "";
    }

    private parseString(text: string): string[] {
        const commaOrSpaces = /\s*[\s,]\s*/;
        return text.split(commaOrSpaces).map(raw => this.parseIdfig(raw)).filter(idfig => idfig.length >= 2);
    }


    private parseIdfig(idfigRaw: string): string {
        const idfig = idfigRaw.trim();
        return this.trimLeadingZeros(this.removeAfterNonDigit(idfig));
    }

    private trimLeadingZeros(str: string): string {
        return str.replace(/^0+/, '');
    }

    private removeAfterNonDigit(text: string): string {
        for (let i = 0; i < text.length; i++) {
            if (!/\d/.test(text[i])) {
                return text.slice(0, i);
            }
        }
        return text;
    }

    addFolderAndContacts(): void {
        this.isCreating = true;
        const contactIds = this.parsedIdfigs$.value.map(idfig => {
            return this.contactService.idfigToContactId(idfig);
        });

        this.contextNodeService.addFolderToProjectRoot(this.projectId, this.folderName).pipe(
            switchMap((data: any) => {
                return this.assignService.assignContactsToNode(contactIds, data.child)
            })
        ).subscribe(_ => {
            this.notificationService.show("Votre dossier à été ajouté avec succès.", "success");
            this.onCancel();
        }).disposedBy(this.disposeBag)
    }


    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
