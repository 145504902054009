import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { FormMapperService } from '../mappers/form-mapper.service';
import { Form } from '../model/form/form';

@Injectable({
    providedIn: "root",
})
export class FormBackupService {
    constructor(private db: AngularFirestore, private formMapper: FormMapperService) {}

    backupForm(form: Form): Observable<void> {
        return this.backups(form.id).pipe(
            switchMap((backups: FormBackup[]) => {
                const oneHour: number = 60 * 60 * 1000;
                const now = new Date();
                if (backups.length === 0 || now.getTime() - backups[0].backupDate.getTime() > oneHour) {
                    return from(
                        this.db
                            .collection("forms")
                            .doc(form.id)
                            .collection("backups")
                            .add({
                                backupDate: new Date(),
                                form: this.formMapper.formToFormDto(form),
                            })
                    ).pipe(map(() => undefined));
                } else {
                    return of(undefined);
                }
            })
        );
    }

    backups(formId: string): Observable<FormBackup[]> {
        return this.db
            .collection("forms")
            .doc(formId)
            .collection("backups", (ref) => ref.orderBy("backupDate", "desc"))
            .get()
            .pipe(
                map((docsSnapshot) => {
                    return docsSnapshot.docs.map((doc) => {
                        const docData = doc.data();
                        return {
                            id: doc.id,
                            backupDate: docData.backupDate.toDate(),
                            form: this.formMapper.formDtoToForm(docData.form),
                        };
                    });
                })
            );
    }
}

export interface FormBackup {
    id: string;
    backupDate: Date;
    form: Form;
}
