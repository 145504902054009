import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { User } from 'src/app/model/user';
import { Department, DepartmentService } from 'src/app/services/department.service';
import { UserService } from 'src/app/services/user.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Project } from 'src/modules/diversite/model/project';
import {
    AccessPermission,
    AccessRoleService,
    ApplicationSegment,
    ApplicationSegmentAccess,
    DepartmentAccess,
    ProjectAccess,
} from 'src/modules/diversite/services/access-role.service';
import { ProjectService } from 'src/modules/diversite/services/projects.service';

@Component({
    selector: 'app-access-roles',
    templateUrl: './access-roles.component.html',
    styleUrl: './access-roles.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessRolesComponent implements OnDestroy {


    constructor(private userService: UserService, private accessRoleSevice: AccessRoleService, private projectService: ProjectService, private departmentService: DepartmentService) { }
    users$: Observable<User[]>;
    applicationSegments: ApplicationSegment[];
    departments$: Observable<Department[]>;
    projects$: Observable<Project[]>;

    private disposeBag = new DisposeBag();
    rights: any = {};

    ngOnInit(): void {
        this.applicationSegments = this.accessRoleSevice.applicationSegments();
        this.users$ = this.userService.users({ listen: true }).pipe(tap(users => {
            users.forEach(u => {
                this.rights[u.id] = {
                    segmentMainSelection: u.access.applicationSegments === "all" ? "all" : "custom",
                    projectMainSelection: u.access.projects === "all" ? "all" : "custom",
                    departmentMainSelection: u.access.departments === "all" ? "all" : "custom",
                    selectedProjectId: "",
                    selectedDepartmentId: "",
                    segmentPermissions: {},
                    projectPermissions: {},
                    departmentPermissions: {},
                    selectedDepartmentApplication: u.selectedDepartmentApplication,
                }

                if (u.access.applicationSegments !== "all") {
                    this.applicationSegments.forEach(s => {
                        this.rights[u.id].segmentPermissions[s] = {
                            read: (u.access.applicationSegments as ApplicationSegmentAccess[]).find(us => us.segment === s)?.permissions.includes("read") ? true : false,
                            write: (u.access.applicationSegments as ApplicationSegmentAccess[]).find(us => us.segment === s)?.permissions.includes("write") ? true : false,
                            update: (u.access.applicationSegments as ApplicationSegmentAccess[]).find(us => us.segment === s)?.permissions.includes("update") ? true : false,
                            delete: (u.access.applicationSegments as ApplicationSegmentAccess[]).find(us => us.segment === s)?.permissions.includes("delete") ? true : false
                        }
                    })
                }

                if (u.access.projects !== "all") {
                    (u.access.projects as ProjectAccess[]).forEach(p => {
                        this.rights[u.id].projectPermissions[p.projectId] = {
                            read: p.permissions.includes("read"),
                            write: p.permissions.includes("write"),
                            update: p.permissions.includes("update"),
                            delete: p.permissions.includes("delete")
                        }
                    })
                }

                if (u.access.departments !== "all") {
                    (u.access.departments as DepartmentAccess[]).forEach(p => {
                        this.rights[u.id].departmentPermissions[p.departmentId] = {
                            read: p.permissions.includes("read"),
                            write: p.permissions.includes("write"),
                            update: p.permissions.includes("update"),
                            delete: p.permissions.includes("delete")
                        }
                    })
                }

            });
        }));

        this.projects$ = this.projectService.activeProjects();
        this.departments$ = this.departmentService.departments();

    }


    trackById(_: number, entity: any): string {
        return entity.id;
    }

    onProjectMainChange(value: "all" | "custom", user: User): void {
        this.userService.updateUser(
            user.change({
                access: { ...user.access, projects: value === "all" ? "all" : [] }
            })
        ).subscribe().disposedBy(this.disposeBag)
    }

    onSegmentMainChange(value: "all" | "custom", user: User): void {
        this.userService.updateUser(
            user.change({
                access: { ...user.access, applicationSegments: value === "all" ? "all" : this.accessRoleSevice.defaultApplicationSegmentsValue() }
            })
        ).subscribe().disposedBy(this.disposeBag)
    }

    onDepartmentMainChange(value: "all" | "custom", user: User): void {
        this.userService.updateUser(
            user.change({
                access: { ...user.access, departments: value === "all" ? "all" : [] }
            })
        ).subscribe().disposedBy(this.disposeBag)
    }

    onDepartmentApplicationChange(selectedDepartmentApplication: string, user: User): void {
        this.userService.updateUser(
            user.change({
                selectedDepartmentApplication: selectedDepartmentApplication
            })
        ).subscribe().disposedBy(this.disposeBag)
    }

    onPermissionChange(permission: AccessPermission, value: boolean, segment: ApplicationSegment, user: User): void {

        const applicationAccessSegment: ApplicationSegmentAccess = (user.access.applicationSegments as ApplicationSegmentAccess[]).find(s => s.segment === segment)
        if (applicationAccessSegment) {

            let newpermissions = [...applicationAccessSegment.permissions];
            if (value) {
                newpermissions.push(permission);
            } else if (!value) {
                newpermissions = newpermissions.filter(p => p !== permission)
            }
            this.userService.updateUser(user.change({
                access: {
                    ...user.access, applicationSegments: (user.access.applicationSegments as ApplicationSegmentAccess[]).map(sa => {
                        if (sa.segment === segment) {
                            return { ...applicationAccessSegment, permissions: newpermissions }
                        } else {
                            return sa
                        }
                    })
                }
            }))
        }

    }

    onProjectPermisionChange(persmission: AccessPermission, projectId: string, value: boolean, user: User): void {
        const projectPermissions = this.rights[user.id].projectPermissions[projectId];
        if (projectPermissions) {
            this.rights[user.id].projectPermissions[projectId][persmission] = value;
        } else {
            this.rights[user.id].projectPermissions[projectId] = {}
            this.rights[user.id].projectPermissions[projectId][persmission] = value;
        }
    }

    onDepartmentPermisionChange(persmission: AccessPermission, departmentId: string, value: boolean, user: User): void {
        const departmentPermissions = this.rights[user.id].departmentPermissions[departmentId];
        if (departmentPermissions) {
            this.rights[user.id].departmentPermissions[departmentId][persmission] = value;
        } else {
            this.rights[user.id].departmentPermissions[departmentId] = {}
            this.rights[user.id].departmentPermissions[departmentId][persmission] = value;
        }
    }

    onAddProjectPermission(user: User, projectId: string): void {
        const permissions: any[] = Object.keys(this.rights[user.id].projectPermissions[projectId]).filter(permission => this.rights[user.id].projectPermissions[projectId][permission] === true)
        const projectAccess: ProjectAccess = {
            projectId,
            permissions
        }

        if ((user.access.projects as ProjectAccess[]).find(pa => pa.projectId === projectId)) {

            this.userService.updateUser(user.change({
                access: { ...user.access, projects: (user.access.projects as ProjectAccess[]).map(pa => pa.projectId === projectId ? projectAccess : pa) }
            })).subscribe().disposedBy(this.disposeBag)
        } else {
            this.userService.updateUser(user.change({
                access: { ...user.access, projects: [...user.access.projects as ProjectAccess[], projectAccess] }
            })).subscribe().disposedBy(this.disposeBag)
        }
    }

    onAddDepartmentPermission(user: User, departmentId: string): void {
        const permissions: any[] = Object.keys(this.rights[user.id].departmentPermissions[departmentId]).filter(permission => this.rights[user.id].departmentPermissions[departmentId][permission] === true)
        const departmentAccess: DepartmentAccess = {
            departmentId,
            permissions
        }

        if ((user.access.departments as DepartmentAccess[]).find(pa => pa.departmentId === departmentId)) {

            this.userService.updateUser(user.change({
                access: { ...user.access, departments: (user.access.departments as DepartmentAccess[]).map(pa => pa.departmentId === departmentId ? departmentAccess : pa) }
            })).subscribe().disposedBy(this.disposeBag)
        } else {
            this.userService.updateUser(user.change({
                access: { ...user.access, departments: [...user.access.departments as DepartmentAccess[], departmentAccess] }
            })).subscribe().disposedBy(this.disposeBag)
        }
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
