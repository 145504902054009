<div *ngIf="isFullNodeDisplay" class="node-content node-fixed">
    <div class="node-title" (contextmenu)="onRightClickReport($event, node)">
        <cds-icon [shape]="'scroll'"></cds-icon>
        <input class="node-name-input" disabled [value]="'Rapport(s)'" />
        <span class="actions">
            <strong>&nbsp;&nbsp;{{ node.contextData.reportIds?.length }}&nbsp;&nbsp;</strong>
        </span>
    </div>

    <div class="reports" *ngIf="isOpen">
        <ng-container [ngTemplateOutlet]="reportsContent"> </ng-container>
    </div>
</div>
<div *ngIf="!isFullNodeDisplay" class="node-content">
    <div class="reports">
        <ng-container [ngTemplateOutlet]="reportsContent"> </ng-container>
    </div>
</div>

<ng-template #reportsContent>
    <table *ngIf="reports" class="table table-compact table-noborder">
        <tbody>
            <tr *ngFor="let report of reports; trackBy: trackById">
                <td class="left">{{ report.name }}</td>
                <td class="right">
                    <cds-icon (click)="onEditReportClick(report.id, node)" shape="pencil"></cds-icon>
                </td>
            </tr>
        </tbody>
    </table>
</ng-template>
