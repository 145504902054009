<div *ngIf="formElement" class="form-element-edition">
    <span
        #labelElement
        class="edit-label"
        contenteditable
        (keyup)="onLabelChange()"
        [innerHTML]="tempFormElement.label[lang]"
    ></span>
    <ng-content select="[tooltip]"></ng-content>
    <div>
        <button disabled class="btn btn-primary btn-sm">Tenir enfoncé pour enregistrer</button>
    </div>
</div>
