import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
    selector: "diversite-mass-edit-action",
    templateUrl: "./mass-edit-action.component.html",
    styleUrls: ["./mass-edit-action.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MassEditActionComponent implements OnInit {
    @Input() contactIds: string[];
    @Input() datasource: string;
    @Input() searchDefinitionId: string;
    @Output() close = new EventEmitter<void>();
    constructor() {}

    ngOnInit(): void {}

    onClose(): void {
        this.close.emit();
    }
}
