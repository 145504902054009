import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { forkJoin, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { ProcessVideoUploadRequest } from 'src/app/api-clients/system-request-api-client.service';
import { validateVimeoURL, validateYouTubeUrl } from 'src/app/core/functions';
import { SystemRequestService } from 'src/app/services/system-request.service';
import { FileUploadVideoService } from 'src/modules/core/services/file-upload-video.service';
import { UploadFilesResponse } from 'src/modules/core/services/file-upload.service';

import { Contact } from '../model/contact';

@Injectable({
    providedIn: "root",
})
export class ContactVideosService {
    constructor(
        private db: AngularFirestore,
        private systemRequestService: SystemRequestService,
        private fileUploadVideoService: FileUploadVideoService
    ) { }

    videosForContact(contactId: string): Observable<Video[]> {
        return this.db
            .collection("contacts")
            .doc(contactId)
            .collection("videos")
            .snapshotChanges()
            .pipe(
                map((docs: DocumentChangeAction<firebase.firestore.DocumentData>[]) => {
                    return docs
                        .map((doc) => {
                            const data = doc.payload.doc.data();
                            const video: Video = {
                                id: doc.payload.doc.id,
                                uploadedAt: data.uploadedAt,
                                status: data.status ? data.status : "success",
                                videoUrl: data.videoUrl,
                                deleted: data.deleted,
                                type:
                                    validateYouTubeUrl(data.videoUrl) || validateVimeoURL(data.videoUrl)
                                        ? "siteUrl"
                                        : "file",
                            };

                            return video;
                        })
                        .filter((v) => v.videoUrl && v.videoUrl !== "")
                        .filter((v) => !v.deleted);
                }),
                distinctUntilChanged()
            );
    }

    addVideosForContact(contact: Contact, files: File[]): Observable<UploadFilesResponse> {
        let response: UploadFilesResponse;
        return new Observable<UploadFilesResponse>((subscriber) => {
            const upload$ = this.fileUploadVideoService.uploadFiles(files).subscribe(
                (uploadFilesResponse) => {
                    response = uploadFilesResponse;
                    subscriber.next(uploadFilesResponse);
                },
                () => { },
                () => {
                    this.sendProcessVideoRequests(contact, response.urls)
                        .pipe(take(1))
                        .subscribe(
                            () => { },
                            () => { },
                            () => {
                                upload$.unsubscribe();
                                subscriber.complete();
                            }
                        );
                }
            );
        });
    }

    sendProcessVideoRequests(contact: Contact, urls: string[]): Observable<void> {
        return forkJoin(
            urls.map((url) => {
                const request: ProcessVideoUploadRequest = {
                    requestType: "processVideoUpload",
                    targetContainer: `contacts/${contact.id}`,
                    idfig: contact.idfig,
                    videoUrl: url,
                };
                return this.systemRequestService.add(request).pipe(
                    tap((systemRequest) => console.log(systemRequest.id)),
                    switchMap((systemRequest) =>
                        this.systemRequestService
                            .get(systemRequest.id)
                            .pipe(map((sR) => sR as ProcessVideoUploadRequest))
                    ),
                    filter((systemRequest) => systemRequest.status === "success"),
                    take(1)
                );
            })
        ).pipe(map((_) => undefined));
    }
}

export interface Video {
    id: string;
    uploadedAt: Date;
    status?: VideoStatus;
    videoUrl: string;
    deleted?: boolean;
    type: VideoSourceType;
}

export type VideoSourceType = "file" | "siteUrl";

export type VideoStatus = "processing" | "success";
