<div *ngIf="id">
    <div class="clr-file-wrapper">
        <label class="clr-control-label"
            ><span *ngIf="!fileUrl" class="btn btn-primary btn-sm" [class.disabled]="uploading || readonly"
                >Ajouter le fichier...</span
            >
            <span *ngIf="fileUrl" class="btn btn-primary btn-sm" [class.disabled]="uploading || readonly"
                >Modifier le fichier...</span
            >
            <input
                [disabled]="uploading || readonly"
                [id]="id"
                (change)="onInputChange($event)"
                class="clr-file"
                type="file"
                [name]="id"
        /></label>
        <div class="loading">
            <span [hidden]="!uploading" class="spinner spinner-sm"> Téléchargement en cours... </span>
            <span class="text" [hidden]="!uploading">Téléchargement en cours...</span>
        </div>
    </div>
    <a target="_blank" *ngIf="fileUrl && !uploading" [href]="fileUrl">Lien du fichier</a>
</div>
