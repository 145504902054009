<div class="activity" *ngIf="form$ | async as form">
    <ng-container *ngIf="contact$ | async as contact">
        <div class="top">
            <p>
                <a (click)="onViewSubmission(contact.id, form.id)"
                    >Une nouvelle soumission à&nbsp;<strong>{{
                        form.name["fr"] ? form.name["fr"] : form.name["en"]
                    }}</strong
                    >&nbsp;est disponible.</a
                >
            </p>
            <div class="sr-id">{{ activity.id }}</div>
        </div>
        <div class="activity-content">
            <diversite-contact-search-profile
                [width]="80"
                [height]="120"
                [infoSize]="0"
                [contact]="contact"
                (viewProfile)="onViewProfile($event)"
            ></diversite-contact-search-profile>
        </div>
        <div class="bottom">
            <div class="date">
                <small>{{ activity.created | date : "dd-MM-yyyy HH:mm" }}</small>
            </div>
        </div>
    </ng-container>
</div>
