import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Activity } from 'src/modules/diversite/services/activity.service';

@Component({
    selector: 'diversite-export-contacts-activity',
    templateUrl: './export-contacts-activity.component.html',
    styleUrl: './export-contacts-activity.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportContactsActivityComponent {
    @Input() activity: ExportContactsActivity;

    progress: number = 0;

    ngOnChanges(): void {
        if (this.activity?.progress?.exportedCount && this.activity?.progress?.totalToExport) {
            this.progress = Math.round(this.activity?.progress?.exportedCount / this.activity.progress.totalToExport) * 100
        } else {
            this.progress = 0;
        }
    }
}

export interface ExportContactsActivity extends Activity {
    id: string;
    status: "success" | "error";
    result?: {
        excelFileUrl?: string;
        imagePackageUrl?: string;
    },
    options?: {
        excelOptions: {
            includeFile: boolean;
            fields: string[];
        },
        imagesOptions: {
            includeImages: boolean;
            images: any;
            quality: null;
        }
    },
    progress?: {
        exportedCount: number;
        totalToExport: number;
    },
    created: Date;
}