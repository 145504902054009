<h3>
    <input
        (change)="onGroupDisabledChange($event.target.checked)"
        [checked]="!searchGroup.disabled"
        clrCheckbox
        type="checkbox"
    />
    <span *ngIf="index === 0">Selection</span><span *ngIf="index !== 0">ou</span>
    <div class="actions">
        <cds-icon *ngIf="index !== 0" (click)="onRemoveGroup()" size="20" shape="times"></cds-icon>
        <cds-icon (click)="onAddOrSearchGroup()" size="20" shape="plus-circle"></cds-icon>
    </div>
</h3>
<small (click)="onViewGroupResults(searchGroup.id)" class="action-link no-margin results" *ngIf="metaForGroup"
    >{{ metaForGroup }} résultats</small
>

<div [class.disabled]="searchGroup.disabled" class="tags">
    <diversite-search-definition-tag
        *ngIf="searchGroup.hasFullTextSearch()"
        [isFullTextSearch]="true"
        [maxCharacters]="50"
        [removable]="true"
        [editable]="false"
        [searchOperation]="searchGroup.fullTextSearch"
        (searchOperationChange)="onSearchElementChange($event)"
        (removeSearchOperation)="onRemoveSearchOperation($event)"
    />
    <ng-container *ngFor="let sop of searchGroup.searchOperations; trackBy: trackById">
        <diversite-search-definition-tag
            *ngIf="filterFormParameter(sop)"
            [maxCharacters]="50"
            [removable]="true"
            [editable]="sop.source ? true : false"
            [searchOperation]="sop"
            (searchOperationChange)="onSearchElementChange($event)"
            (removeSearchOperation)="onRemoveSearchOperation($event)"
        />
    </ng-container>
</div>
<div [class.disabled]="searchGroup.disabled" class="add-field">
    <!-- <diversite-main-search-input
        *ngIf="searchGroup.fullTextSearch"
        [searchOperation]="searchGroup.fullTextSearch"
        (searchOperationChange)="onFullTextSearchChange($event)"
        (search)="onFullTextSearch($event)"
    /> -->
    <input
        *hasAccess="{ type: 'segment', segment: 'globalSearch', permissions: ['read'] }"
        (change)="onIdfigChange($event.target.value)"
        class="static-field"
        type="text"
        placeholder="idfig"
        [(ngModel)]="idfig"
    />
    <input
        *hasAccess="{ type: 'segment', segment: 'globalSearch', permissions: ['read'] }"
        (change)="onFullnameChange($event.target.value)"
        class="static-field"
        type="text"
        placeholder="Nom et/ou prénom"
        [(ngModel)]="fullname"
    />
    <input
        *hasAccess="{ type: 'segment', segment: 'globalSearch', permissions: ['read'] }"
        (keydown.enter)="onFullTextSearch($event.target.value)"
        (keyup)="onFullTextSearchKeyup($event.target.value)"
        class="static-field"
        type="text"
        placeholder="Tous les champs"
        [(ngModel)]="fulltextsearch"
    />

    <diversite-search-form-element
        [lang]="lang"
        [display]="'vertical'"
        [formElement]="defaultDateFormElement"
        [searchOperation]="sopDateCreated"
        (valueChange)="onDateCreatedValueChange($event)"
    ></diversite-search-form-element>
    <!-- <select>
        <option value="lowerThan">Avant le</option>
        <option value="range">Avant le</option>
        <option value="greaterThan">Apres le</option>
    </select>
    <clr-date-container>
        <label></label>
        <input (clrDateChange)="onChangeCreatedDate($event)" placeholder="Date de création" clrDate />
    </clr-date-container> -->

    <div class="accordion-container">
        <clr-accordion [clrAccordionMultiPanel]="true">
            <clr-accordion-panel>
                <clr-accordion-title>Favoris</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                    <diversite-search-favorites
                        [favoriteNodes]="favoriteNodes"
                        [searchGroup]="searchGroup"
                        [contextNodeId]="contextNodeId"
                        (search)="onFavoritesSearch($event)"
                    />
                </clr-accordion-content>
            </clr-accordion-panel>

            <clr-accordion-panel [(clrAccordionPanelOpen)]="projectFormSearchOpen">
                <clr-accordion-title>Projet/Formulaire</clr-accordion-title>
                <clr-accordion-content *clrIfExpanded>
                    <div class="search-project">
                        <clr-select-container>
                            <label>Projet</label>
                            <select
                                (change)="onProjectChange($event.target.value)"
                                clrSelect
                                name="selectedProjectId"
                                [(ngModel)]="selectedProjectId"
                            >
                                <option value="">-</option>
                                <option
                                    [value]="project.id"
                                    *ngFor="let project of projects$ | async; trackBy: trackById"
                                >
                                    {{ project.name }}
                                </option>
                            </select>
                        </clr-select-container>
                        <clr-select-container>
                            <label>Formulaire</label>
                            <select
                                (change)="onSearchFormChange($event.target.value)"
                                clrSelect
                                name="selectedFormId"
                                [(ngModel)]="selectedFormId"
                            >
                                <option value="">-</option>
                                <option [value]="form.id" *ngFor="let form of formsForProject$ | async">
                                    {{ form.name[lang] }}
                                </option>
                            </select>
                        </clr-select-container>

                        <a
                            (click)="openSearchInForm()"
                            class="action-link no-margin"
                            [class.disabled]="isOpenSearchInFormDisabled || projectFormSearchOpen"
                        >
                            Chercher dans le formulaire
                        </a>

                        <clr-date-container>
                            <label>Les soumissions après</label>
                            <input
                                [disabled]="selectedFormId === '' || selectedProjectId === ''"
                                (clrDateChange)="onSinceDateChange($event)"
                                type="date"
                                autocomplete="off"
                                clrDate
                                [clrDate]="sinceDate"
                            />
                        </clr-date-container>
                        <diversite-time-picker
                            [label]="'HH:mm'"
                            [disabled]="!sinceDate"
                            [value]="sinceDateTime"
                            (valueChange)="onTimeChange($event)"
                        ></diversite-time-picker>
                        <br />
                        <small (click)="onEraseSinceDate()" class="action-link no-margin">Effacer</small>
                        <!-- <br /> -->

                        <!-- <button
                            [disabled]="selectedFormId === '' || selectedProjectId === ''"
                            (click)="onAddFormSearchOperation()"
                            class="btn btn-sm btn-primary"
                        >
                            Ajouter
                        </button> -->
                        <br />
                        <br />

                        <diversite-context-node-search-operations
                            [projectId]="selectedProjectId"
                            (searchOperationsForAllFolder)="onSelectAllFolder($event)"
                            (changeSearchSpecification)="onContextNodeOperationAddReplace($event)"
                        />
                        <br />
                        <div class="search-in-form" *ngIf="searchInForm">
                            <clr-spinner clrSmall *ngIf="loadingSearchForm"></clr-spinner>
                            <diversite-fillout-search
                                *ngIf="selectedForm$ | async as form"
                                [form]="form"
                                (searchOperationsChange)="onSearchElementChange($event)"
                                [searchOperations]="searchGroup.searchOperations"
                            ></diversite-fillout-search>
                        </div>
                    </div>
                </clr-accordion-content>
            </clr-accordion-panel>

            <ng-container *ngIf="showAutoComplete">
                <clr-accordion-panel
                    *ngIf="labelResults$ | async as labelsResults"
                    [clrAccordionPanelDisabled]="labelsResults.length === 0"
                    [(clrAccordionPanelOpen)]="labelsResultsOpen"
                >
                    <clr-accordion-title
                        >Attributs/Questions
                        <small *ngIf="labelsResults.length > 0"
                            >({{ labelsResults.length }})</small
                        ></clr-accordion-title
                    >
                    <clr-accordion-content *clrIfExpanded>
                        <div class="result-group form-element-results">
                            <div
                                *ngFor="let labelResult of labelsResults; trackBy: trackByAttributeName"
                                class="result"
                            >
                                <div class="result-title">
                                    <small *ngIf="labelResult.children[0].source === 'config'" class="action-link">
                                        Attribut de base</small
                                    >
                                    <small
                                        *ngIf="labelResult.children[0].source === 'form'"
                                        class="action-link"
                                        (click)="openFormPane(labelResult.children[0].formId)"
                                    >
                                        <diversite-attribute-value
                                            [attributeName]="'recruitment_' + labelResult.children[0].formId"
                                            [value]="'recruitment_' + labelResult.children[0].formId"
                                        ></diversite-attribute-value
                                    ></small>
                                </div>

                                <div class="form-element-content">
                                    <diversite-search-form-element-loader
                                        [source]="labelResult.children[0].source"
                                        [formId]="labelResult.children[0].formId"
                                        [formElementName]="labelResult.children[0].formElementName"
                                        (searchOperationAdd)="onSearchOperationAdd($event)"
                                        (addToFavorite)="onAddToFavorite($event)"
                                    ></diversite-search-form-element-loader>
                                </div>
                            </div>
                        </div>
                    </clr-accordion-content>
                </clr-accordion-panel>
            </ng-container>

            <ng-container *ngIf="showAutoComplete">
                <clr-accordion-panel
                    *ngIf="attributeValueGroupedSearchResults$ | async as attributeResults"
                    [clrAccordionPanelDisabled]="attributeResults.length === 0"
                    [(clrAccordionPanelOpen)]="attributeResultsOpen"
                >
                    <clr-accordion-title
                        >Valeur(s)
                        <small *ngIf="attributeResults.length > 0"
                            >({{ attributeResults.length }})</small
                        ></clr-accordion-title
                    >
                    <clr-accordion-content *clrIfExpanded>
                        <div class="result-group attribute-results">
                            <div
                                class="result"
                                *ngFor="let attributeResult of attributeResults; trackBy: trackByAttributeId"
                            >
                                <div>
                                    <cds-icon
                                        class="add-icon"
                                        (click)="addSearchOperationForIndex(attributeResult.attributeId)"
                                        shape="plus"
                                    ></cds-icon>
                                </div>
                                <div>
                                    <strong
                                        ><diversite-attribute-label
                                            [id]="attributeResult.attributeId"
                                        ></diversite-attribute-label
                                    ></strong>
                                    :
                                    <small
                                        (click)="addSearchOperationForIndex(attributeResult.attributeId)"
                                        class="action-link"
                                        ><strong>{{ attributeResult.count }}</strong> résultats</small
                                    >
                                </div>
                            </div>
                        </div>
                    </clr-accordion-content>
                </clr-accordion-panel>
            </ng-container>
        </clr-accordion>
    </div>
</div>
