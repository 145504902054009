<div class="activities">
    <diversite-activity *ngFor="let activity of activities$ | async; trackBy: trackById">
        <diversite-upload-photo-activity
            *ngIf="activity.type === 'upload-photo'"
            [activity]="activity"
            (openProfile)="onOpenProfile($event)"
        ></diversite-upload-photo-activity>

        <diversite-submit-form-activity
            *ngIf="activity.type === 'submit-form'"
            [activity]="activity"
            (openProfile)="onOpenProfile($event)"
        ></diversite-submit-form-activity>

        <diversite-contact-signup-activity
            *ngIf="activity.type === 'contact-signup'"
            [activity]="activity"
            (openProfile)="onOpenProfile($event)"
        ></diversite-contact-signup-activity>

        <diversite-contact-update-activity
            *ngIf="activity.type === 'contact-update'"
            [activity]="activity"
            (openProfile)="onOpenProfile($event)"
        ></diversite-contact-update-activity>

        <diversite-contact-support-activity
            *ngIf="activity.type === 'contact-support'"
            [activity]="activity"
        ></diversite-contact-support-activity>

        <diversite-merge-profiles-activity
            *ngIf="activity.type === 'merge-profiles'"
            [activity]="activity"
        ></diversite-merge-profiles-activity>

        <diversite-export-contacts-activity
            *ngIf="activity.type === 'export-contacts'"
            [activity]="activity"
        ></diversite-export-contacts-activity>

        <!-- <div
            *ngIf="!isProd()"
            style="background: rgb(255, 183, 183)"
            class="activity"
            [hidden]="
                activity.type === 'upload-photo' ||
                activity.type === 'submit-form' ||
                activity.type === 'contact-signup' ||
                activity.type === 'contact-update' ||
                activity.type === 'contact-support' ||
                activity.type === 'merge-profiles' ||
                activity.type === 'export-contacts'
            "
        >
            <div class="top">
                {{ activity.requestType }}
                <div class="sr-id">{{ activity.id }}</div>
            </div>
            <pre style="font-size: 0.45rem; line-height: 0.5rem">{{ activity | json }}</pre>
            <div class="bottom">
                <div class="date">
                    <small>{{ activity.created | date : "dd-MM-yyyy HH:mm" }}</small>
                </div>
            </div>
        </div> -->
    </diversite-activity>
    <div #loadingContainer class="loading">
        <clr-spinner clrMedium></clr-spinner>
    </div>
</div>
