<div class="report-container" *ngIf="report">
    <as-split direction="horizontal">
        <as-split-area [order]="0" *ngIf="!print" [minSize]="20" [size]="20">
            <div class="actions hide-print">
                <button (click)="doPrint()" class="btn btn-primary btn-sm">Imprimer le rapport</button>
                <button *ngIf="!print" (click)="exportToExcel()" class="btn btn-primary btn-sm">
                    Exporter fichier excel
                </button>
            </div>
            <div class="options hide-print">
                <div class="all-options">
                    <div class="print-options">
                        <small>Type de rapport</small>
                        <clr-radio-wrapper (click)="changeType('picture-grid')">
                            <input
                                type="radio"
                                clrRadio
                                value="picture-grid"
                                [checked]="isPictureGrid()"
                                name="reportType"
                            />
                            <label>Grille photo</label>
                        </clr-radio-wrapper>

                        <clr-radio-wrapper (click)="changeType('picture-info')">
                            <input
                                type="radio"
                                clrRadio
                                value="picture-info"
                                [checked]="isPictureInfo()"
                                name="reportType"
                            />
                            <label>Information/photo</label>
                        </clr-radio-wrapper>
                    </div>
                    <div class="print-options">
                        <small>Options générale</small>
                        <clr-radio-wrapper (click)="changeOrientation('landscape')">
                            <input
                                type="radio"
                                clrRadio
                                value="picture-grid"
                                name="orientation"
                                [checked]="isLandscape()"
                            />
                            <label>Paysage</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper (click)="changeOrientation('portrait')">
                            <input type="radio" clrRadio value="list" name="orientation" [checked]="isPortrait()" />
                            <label>Portrait</label>
                        </clr-radio-wrapper>

                        <clr-checkbox-container>
                            <clr-checkbox-wrapper>
                                <input
                                    type="checkbox"
                                    clrCheckbox
                                    (change)="onIntroPageChange($event)"
                                    [checked]="report.introPage"
                                    name="introPage"
                                />
                                <label>Page d'introduction</label>
                            </clr-checkbox-wrapper>
                        </clr-checkbox-container>

                        <div class="clr-form-control">
                            <label class="clr-control-label">Logo</label>
                            <div *ngIf="!fileLogoData" class="clr-control-container">
                                <div class="clr-file-wrapper">
                                    <label for="basic" class="clr-control-label"
                                        ><span class="btn btn-sm">Image...</span></label
                                    >
                                    <input
                                        #fileLogo
                                        type="file"
                                        id="basic"
                                        placeholder="Enter value here"
                                        class="clr-file"
                                        (change)="fileLogoChange()"
                                    />
                                </div>
                            </div>
                        </div>
                        <img class="logo" [attr.src]="fileLogoData" />
                        <clr-range-container *ngIf="fileLogoData" class="no-margin">
                            <label>Grandeur logo</label>
                            <input
                                type="range"
                                clrRange
                                [(ngModel)]="logoSize"
                                min="1"
                                max="100"
                                step="2"
                                name="logoSize"
                                class="slider"
                            />
                            <clr-control-helper>{{ logoSize }} %</clr-control-helper>
                        </clr-range-container>
                        <small class="clear-logo" *ngIf="fileLogoData" (click)="clearLogo()">Effacer</small>
                    </div>

                    <div *ngIf="isPictureGrid()" class="print-options">
                        <small>Options de grille</small>

                        <clr-range-container class="no-margin">
                            <label>Grandeur de photo</label>
                            <input
                                type="range"
                                clrRange
                                (change)="thumbnailSizeChange(true)"
                                [(ngModel)]="sizeThumbnail"
                                min="0.1"
                                max="7.5"
                                step="0.1"
                                class="slider"
                            />
                            <clr-control-helper>{{ sizeThumbnail }} in.</clr-control-helper>
                        </clr-range-container>
                        <!-- <clr-checkbox-container>
                        <clr-checkbox-wrapper>
                            <input type="checkbox" clrCheckbox [checked]="faceCentered" (change)="changeFaceDisposition()" />
                            <label>Keep face centered</label>
                        </clr-checkbox-wrapper>
                    </clr-checkbox-container> -->

                        <clr-range-container>
                            <label>Proportion face/photo</label>
                            <input
                                type="range"
                                clrRange
                                (change)="proportionFaceChange()"
                                [(ngModel)]="proportionFace"
                                min="0.1"
                                max="1"
                                step="0.1"
                                class="slider"
                            />
                            <clr-control-helper>{{ proportionFace * 100 }} % du carré</clr-control-helper>
                        </clr-range-container>

                        <clr-range-container>
                            <label>Marge des photos</label>
                            <input
                                type="range"
                                clrRange
                                (change)="thumbnailMarginChange()"
                                [(ngModel)]="contactsMargin"
                                min="0"
                                max="0.3"
                                step="0.01"
                                class="slider"
                            />
                            <clr-control-helper>{{ contactsMargin }} in.</clr-control-helper>
                        </clr-range-container>
                        <clr-range-container>
                            <label>Marge des pages</label>
                            <input
                                type="range"
                                clrRange
                                (change)="pagesPaddingChange()"
                                [(ngModel)]="pagesPadding"
                                min="0"
                                max="1"
                                step="0.01"
                                class="slider"
                            />
                            <clr-control-helper>{{ pagesPadding }} in.</clr-control-helper>
                        </clr-range-container>
                    </div>

                    <div *ngIf="isPictureInfo()" class="print-options">
                        <small>Options d'info/photo</small>
                        <clr-select-container class="no-margin">
                            <label>Profils/page</label>
                            <select
                                (change)="onProfilePerPageChange($event)"
                                clrSelect
                                [value]="report.profilePerPage"
                                name="options"
                                required
                            >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="4">4</option>
                            </select>
                        </clr-select-container>
                        <br />
                    </div>
                </div>
            </div>
        </as-split-area>
        <as-split-area [order]="1" [size]="print ? 100 : 80">
            <div class="right-section">
                <div
                    *ngIf="report.introPage"
                    class="page-intro"
                    [style.width.in]="pagesWidth"
                    [style.height.in]="pagesHeight"
                    [style.padding.in]="pagesPadding"
                >
                    <diversite-text-html-editor
                        [contentHtml]="introPageContent"
                        (contentChange)="onIntroPageContentChange($event)"
                    ></diversite-text-html-editor>
                </div>

                <div
                    *ngIf="isPictureGrid()"
                    class="pages-section"
                    [style.width.in]="pagesWidth"
                    [style.height.in]="!print ? totalPageHeight() : null"
                >
                    <div class="pages-container picture-grid">
                        <div
                            *ngFor="let page of pagesPictureGrid; let i = index"
                            [style.width.in]="pagesWidth"
                            [style.height.in]="pagesHeight"
                            [style.padding.in]="pagesPadding"
                            [style.backgroundImage]="logoBackgroundImage()"
                            [style.backgroundSize.%]="logoSize"
                            class="page"
                        >
                            <button
                                *ngIf="!print"
                                (click)="addTextToPictureGrid(i)"
                                class="add-text-icon tooltip tooltip-xs"
                                role="tooltip"
                                aria-haspopup="true"
                            >
                                <cds-icon shape="plus-circle" size="lg"></cds-icon>
                                <span class="tooltip-content">Ajouter du texte</span>
                            </button>

                            <input
                                class="page-title"
                                (change)="onReportNameChange($event.target.value)"
                                [value]="pagesPictureGrid[i].title"
                            />
                            <div class="contacts">
                                <div
                                    class="contact"
                                    [style.width.in]="sizeThumbnail"
                                    [style.height.in]="sizeThumbnail"
                                    [style.margin.in]="contactsMargin"
                                    *ngFor="let contactPerspective of page.contactPerspectives"
                                >
                                    <diversite-contact-card
                                        [showSquareFace]="false"
                                        [cardFaceDispositionOptions]="cardFaceDispositionOptions"
                                        (contactPerspectiveChange)="onContactPerspectiveChange($event)"
                                        [lazyload]="false"
                                        [options]="contactCardOptions"
                                        [height]="inchToPx"
                                        [contactPerspective]="contactPerspective"
                                        (rotatePicture)="onRotatePicture($event)"
                                    >
                                    </diversite-contact-card>
                                </div>
                            </div>

                            <diversite-editable-text
                                class="movable-text"
                                [style.left.px]="text.left"
                                [style.top.px]="text.top"
                                (loaded)="onEditableTextLoaded($event)"
                                (remove)="onTextRemovePictureGrid(i, iText)"
                                [text]="text"
                                *ngFor="let text of page.texts; let iText = index"
                            >
                            </diversite-editable-text>

                            <div class="page-number">
                                Propulsé par Castlug &copy;<br />{{ i + 1 }}/{{ pagesPictureGrid.length }}
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="isPictureInfo()" class="pages-section" [style.width.in]="pagesWidth">
                    <div class="pages-container picture-info">
                        <div class="actions hide-print">
                            <clr-combobox-container>
                                <label>Champs à afficher</label>
                                <clr-combobox
                                    #fieldSelection
                                    [ngModel]="selectionFields"
                                    name="multiSelect"
                                    clrMulti="true"
                                    required
                                    (clrSelectionChange)="onFieldVisibilityChange($event)"
                                >
                                    <ng-container *clrOptionSelected="let selected">
                                        {{ selected?.label }}
                                    </ng-container>
                                    <!-- <clr-options class="hide-print">
                                        <clr-option
                                            *clrOptionItems="
                                                let attribute of toComboboxData(
                                                    report.contactProfileDefinition.attributes
                                                );
                                                field: 'label'
                                            "
                                            [clrValue]="attribute"
                                            >{{ attribute.label }}</clr-option
                                        >
                                    </clr-options> -->
                                </clr-combobox>
                            </clr-combobox-container>
                        </div>
                        <div
                            *ngFor="let page of pagesPictureInfo; let i = index; trackBy: trackByIndex"
                            [style.width.in]="pagesWidth"
                            [style.height.in]="pagesHeight"
                            [style.backgroundImage]="logoBackgroundImage()"
                            [style.backgroundSize.%]="logoSize"
                            class="page"
                        >
                            <button
                                *ngIf="!print"
                                (click)="addTextToPictureInfo(i)"
                                class="add-text-icon tooltip tooltip-xs"
                                role="tooltip"
                                aria-haspopup="true"
                            >
                                <cds-icon shape="plus-circle" size="lg"></cds-icon>
                                <span class="tooltip-content">Ajouter du texte</span>
                            </button>

                            <input
                                class="page-title"
                                (change)="onReportNameChange($event.target.value)"
                                [value]="pagesPictureInfo[i].title"
                            />
                            <div class="contacts layout{{ report.profilePerPage }} {{ report.orientation }}">
                                <div
                                    class="contact"
                                    *ngFor="let contactPerspective of page.contactPerspectives; trackBy: trackByCPFn"
                                >
                                    <diversite-photo-profile
                                        (contactPerspectiveChange)="onContactPerspectiveChange($event)"
                                        [contactPerspective]="contactPerspective"
                                        (rotatePicture)="onRotatePicture($event)"
                                    ></diversite-photo-profile>
                                    <div class="info-contact">
                                        <ul class="list-unstyled">
                                            <ng-container
                                                *ngFor="let attributeIndex of selectionFields; trackBy: trackAtributes"
                                            >
                                                <li>
                                                    <strong
                                                        ><diversite-attribute-label
                                                            [id]="attributeIndex.index"
                                                        ></diversite-attribute-label
                                                    ></strong>
                                                    :
                                                    <diversite-attribute-value
                                                        [attributeName]="attributeIndex.index"
                                                        [value]="
                                                            contactPerspective.contact.getAttributeValue(
                                                                attributeIndex.index
                                                            )
                                                        "
                                                    ></diversite-attribute-value>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <diversite-editable-text
                                class="movable-text"
                                [style.left.px]="text.left"
                                [style.top.px]="text.top"
                                (loaded)="onEditableTextLoaded($event)"
                                (remove)="onTextRemovePictureInfo(i, iText)"
                                [text]="text"
                                *ngFor="let text of page.texts; let iText = index"
                            >
                            </diversite-editable-text>

                            <div class="page-number">
                                Propulsé par youlug &copy;<br />{{ i + 1 }}/{{ pagesPictureInfo.length }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </as-split-area>
    </as-split>
</div>
<div *ngIf="exportExcel" #epltable>
    <table>
        <thead>
            <tr>
                <ng-container *ngFor="let field of fields">
                    <th *ngIf="field.selected" class="left">{{ field.label }}</th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let cp of report.contactPerspectives">
                <ng-container *ngFor="let field of fields">
                    <td *ngIf="field.selected" class="left">{{ getValueOfField(cp, field) }}</td>
                </ng-container>
            </tr>
        </tbody>
    </table>
</div>
