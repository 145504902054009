import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, map, Observable } from 'rxjs';

import { TranslatableLabel } from './data-catalog.service';

@Injectable({
    providedIn: 'root'
})
export class SharedPackageService {

    constructor(private db: AngularFirestore) { }


    package(packageId: string, options = { listen: false }): Observable<Package> {
        const ref = this.db.collection("sharedPackages").doc(packageId);
        if (options.listen) {
            return ref.snapshotChanges().pipe(map(doc => {
                if (doc.payload.exists) {
                    const data: any = doc.payload.data();
                    let name: TranslatableLabel;
                    if (typeof data.name === "string") {
                        name = {
                            fr: data.name,
                            en: ""
                        }
                    } else {
                        name = data.name;
                    }
                    return this.packageDtoToPackage({
                        ...data,
                        name,
                        id: doc.payload.id
                    });
                } else {
                    return undefined;
                }
            }))
        } else {
            return ref.get().pipe(map(doc => {
                if (doc.exists) {
                    const data: any = doc.data();
                    let name: TranslatableLabel;
                    if (typeof data.name === "string") {
                        name = {
                            fr: data.name,
                            en: ""
                        }
                    } else {
                        name = data.name;
                    }
                    return this.packageDtoToPackage({
                        ...data,
                        name,
                        id: doc.id
                    });
                } else {
                    return undefined
                }
            }))
        }
    }

    editPackage(pckage: Package): Observable<Package> {
        return from(this.db.collection("sharedPackages").doc(pckage.id).set(this.packageToPackageDto(pckage))).pipe(map(_ => {
            return pckage;
        }))
    }

    deletePackage(pckage: Package): Observable<void> {
        return from(this.db.collection("sharedPackages").doc(pckage.id).delete());
    }

    createPackage(projectId: string): Observable<Package> {
   const docRef = this.db.collection("sharedPackages").doc();
   const pckage: Package = new Package({
     id: docRef.ref.id, 
     name: { fr: "Paquet", en: "Package" },
     description: { fr: "", en: "" },
     projectId,
     files: []
   });
   

   // Add the package data to Firestore, including the id
   return from(docRef.set(this.packageToPackageDto(pckage)))
     .pipe(
       map(() => pckage) // Return the package with the id set
     );
 }

    packageToPackageDto(pckage: Package): any {
        return {
            id: pckage.id || null,
            name: pckage.name,
            description: pckage.description,
            projectId: pckage.projectId || null,
            files: pckage.files || []
        }
    }
    packageDtoToPackage(pckage: any): Package {
        return new Package({
            id: pckage.id || null,
            name: pckage.name,
            description: pckage.description,
            projectId: pckage.projectId || null,
            files: pckage.files?.map(f => {
                return {
                    ...f,
                    description: f.description ? f.description : {
                        fr: "", en: ""
                    }
                }
            }) || []
        });
    }
}

interface PackageBuilder {
    id?: string;
    projectId?: string;
    name?: TranslatableLabel;
    description?: TranslatableLabel;
    files?: SharedFile[];
}
export class Package {

    private _id: string;
    private _projectId: string;
    private _name: TranslatableLabel;
    private _description: TranslatableLabel;
    private _files: SharedFile[];

    constructor(builder: PackageBuilder) {
        this._id = builder.id;
        this._projectId = builder.projectId || null;
        this._name = builder.name || { fr: "", en: "" };
        this._description = builder.description || { fr: "", en: "" };
        this._files = builder.files || [];
    }

    get id(): string { return this._id; }
    get projectId(): string { return this._projectId; }
    get name(): TranslatableLabel { return this._name; }
    get description(): TranslatableLabel { return this._description; }
    get files(): SharedFile[] { return this._files; }

    change(builder: PackageBuilder): Package {
        return new Package({
            id: this.id,
            projectId: this.projectId,
            name: this.name,
            description: this.description,
            files: this.files,
            ...builder
        });
    }


}

export interface SharedFile {
    id?: string;
    url?: string;
    name?: string;
    description?: TranslatableLabel
}

