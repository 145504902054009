import { ChangeDetectionStrategy, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ContextNodeService } from '../../services/context-node.service';
import { AttributeLabel, DataCatalogService } from '../../services/data-catalog.service';
import { TranslateService } from '../../services/translate.service';

@Component({
    selector: "diversite-attribute-label",
    templateUrl: "./attribute-label.component.html",
    styleUrls: ["./attribute-label.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeLabelComponent implements OnInit {
    @Input() attributeLabel: AttributeLabel;
    @Input() id: string;
    @Input() maxCharacters: number;

    label$: Observable<string>;
    constructor(private contextNodeService: ContextNodeService, private dataCatalogService: DataCatalogService, private translateService: TranslateService) { }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.attributeLabel && !this.id) {
            this.label$ = of(this.attributeLabel.label(this.translateService.lang));
        }
        if (changes.id && changes.id.currentValue) {
            const id = changes.id.currentValue;


            if (id === "availability") {
                this.label$ = of("Disponibilité");
            } else if (id.startsWith("contextNode_")) {
                this.label$ = this.contextNodeService.node(id.replace("contextNode_", "")).pipe(map(n => n.name), startWith(""));
            } else {
                this.label$ = this.dataCatalogService.attributeLabel(id).pipe(
                    map((attrLabel) => {
                        return attrLabel.label(this.translateService.lang);
                    }),
                );
            }
        }
    }

    truncate(input: string): string {
        const buffer = this.maxCharacters / 2 - 5;
        return this.isTruncated(input) ? `${input.substring(0, buffer)}...${input.slice(-buffer)}` : input;
    }

    isTruncated(input: string): boolean {
        if (!this.maxCharacters) {
            return false;
        } else {
            return input.length > this.maxCharacters;
        }
    }
}
