import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DepartmentService {

    constructor(private db: AngularFirestore) { }

    departments(): Observable<Department[]> {
        return this.db.collection("departments").get().pipe(map(docs => {
            return docs.docs.map(d => {
                return { ...d.data() as any, id: d.id };
            })
        }))
    }

}


export interface Department {
    id: string;
    name: string;
}
