<clr-range-container>
    <label>min.</label>
    <input
        name="minimumHeight"
        type="range"
        [(ngModel)]="searchValue.valueMin"
        clrRange
        (ngModelChange)="onHeightChange()"
        min="100"
        max="250"
    />
    <clr-control-helper
        >{{ height("min") }}
        <span class="erase-value" [hidden]="!searchValue.valueMin" (click)="erase('min')">effacer</span>
    </clr-control-helper>
</clr-range-container>

<clr-range-container>
    <label>max.</label>
    <input
        name="maximumHeight"
        type="range"
        [(ngModel)]="searchValue.valueMax"
        clrRange
        (ngModelChange)="onHeightChange()"
        min="100"
        max="250"
    />
    <clr-control-helper
        >{{ height("max") }}
        <span class="erase-value" [hidden]="!searchValue.valueMax" (click)="erase('max')">effacer</span>
    </clr-control-helper>
</clr-range-container>
