import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchDefinitionMapperService } from 'src/modules/diversite/mappers/search-definition-mapper.service';
import { Pane } from 'src/modules/diversite/model/pane';
import { ContactsDatabase, ContactsDatabasesService } from 'src/modules/diversite/services/contacts-databases.service';

@Component({
    selector: "diversite-actions-pane-content-container",
    templateUrl: "./actions-pane-content-container.component.html",
    styleUrls: ["./actions-pane-content-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsPaneContentContainerComponent implements OnInit {
    @Input() pane: Pane;
    @Output() close = new EventEmitter<void>();

    databases$: Observable<ContactsDatabase[]>;
    contactIdsToExport$: Observable<string[]>;

    constructor(private contactsDatabaseService: ContactsDatabasesService, private searchDefinitionMapper: SearchDefinitionMapperService) { }

    ngOnInit(): void {
        if (this.pane?.contextData?.actionType === "add-contact") {
            this.databases$ = this.contactsDatabaseService.databases();
        }

    }

    onClose(): void {
        this.close.emit();
    }
}
