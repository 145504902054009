<div *ngIf="formElement" class="clr-form-control">
    <label class="clr-control-label"
        >{{ formElement.label }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>
    <div class="table-container">
        <table class="table table-compact">
            <thead>
                <tr>
                    <th class="left" *ngIf="!formElement.readonly"></th>
                    <th class="left" *ngFor="let field of formElement.fields">
                        <div>
                            {{ field.label }}
                            <span
                                *ngIf="tooltipShow(field)"
                                tooltip-content
                                role="tooltip"
                                aria-haspopup="true"
                                class="tooltip tooltip-right"
                            >
                                <clr-icon shape="info-circle" size="20"></clr-icon>
                                <span class="tooltip-content">{{ field.description }}</span>
                            </span>
                            <span class="required-star" *ngIf="field.required">*</span>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let entry of value; let indexEntry = index">
                    <td class="left input actions" *ngIf="!formElement.readonly">
                        <button (click)="removeEntry(indexEntry)" class="btn btn-primary btn-sm remove">
                            <clr-icon shape="times" size="20"></clr-icon>
                        </button>
                    </td>
                    <td class="left input" *ngFor="let field of formElement.fields">
                        <input
                            *ngIf="field.type === 'textbox'"
                            [disabled]="formElement.readonly"
                            class="input-textbox"
                            (change)="onInputChange()"
                            [(ngModel)]="entry[field.name]"
                            type="text"
                        />
                        <textarea
                            *ngIf="field.type === 'textarea'"
                            [disabled]="formElement.readonly"
                            class="input-textarea"
                            (change)="onInputChange()"
                            [(ngModel)]="entry[field.name]"
                            type="text"
                        ></textarea>
                        <input
                            *ngIf="field.type === 'number'"
                            [disabled]="formElement.readonly"
                            class="input-number"
                            (change)="onInputChange()"
                            [(ngModel)]="entry[field.name]"
                            type="number"
                        />

                        <select
                            *ngIf="field.type === 'dropdown'"
                            [disabled]="formElement.readonly"
                            class="input-dropdown"
                            (change)="onInputChange()"
                            [(ngModel)]="entry[field.name]"
                        >
                            <option value="">-</option>
                            <option [value]="opt.name" *ngFor="let opt of field.options">{{ opt.label }}</option>
                            <optgroup label=""></optgroup>
                        </select>

                        <fillout-table-imageupload-input
                            [readonly]="formElement.readonly"
                            *ngIf="field.type === 'imageupload'"
                            [imageUrl]="entry[field.name]"
                            (imageChange)="onExistingFileChange(field.name, indexEntry, $event)"
                        ></fillout-table-imageupload-input>

                        <fillout-table-fileupload-input
                            [readonly]="formElement.readonly"
                            *ngIf="field.type === 'fileupload'"
                            [fileUrl]="entry[field.name]"
                            (fileChange)="onExistingFileChange(field.name, indexEntry, $event)"
                        ></fillout-table-fileupload-input>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <button *ngIf="!formElement.readonly" (click)="addEntry()" class="btn btn-primary btn-sm add-row">+</button>
</div>
