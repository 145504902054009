import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
} from '@angular/core';

@Component({
    selector: 'diversite-search-pagination',
    templateUrl: './search-pagination.component.html',
    styleUrl: './search-pagination.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchPaginationComponent {
    @Input() page: number = 1;
    @Input() limit: number = 50;
    @Input() total: number;

    @Output() changePage = new EventEmitter<number>();
    @Output() changeLimit = new EventEmitter<number>();

    totalPages: number;

    constructor(private chRef: ChangeDetectorRef) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.limit?.currentValue && changes.total?.currentValue) {
            this.totalPages = this.calculateTotalPages(changes.total?.currentValue, changes.limit?.currentValue);
        } else if (changes.limit?.currentValue) {
            this.totalPages = this.calculateTotalPages(this.total, changes.limit?.currentValue);
        } else if (changes.total?.currentValue) {
            this.totalPages = this.calculateTotalPages(changes.total?.currentValue, this.limit);
        }
    }



    private calculateTotalPages(total: number, limit: number): number {
        if (total && limit) {
            return Math.ceil(total / limit);
        }

    }

    onChangePage(page: any): void {
        if (parseInt(page) >= 1) {
            this.page = parseInt(page);
            this.changePage.emit(this.page);
            this.chRef.detectChanges();
        }
    }

    onLimitChange(limit: any): void {
        limit = parseInt(limit);
        if (limit < 5) {
            limit = 5;
        }
        if (limit > 1000) {
            limit = 1000;
        }

        this.limit = limit;
        this.changeLimit.emit(this.limit);
        this.chRef.detectChanges();
    }

    trackByPage(_index: number, page: number): string {
        return `${page}`;
    }

    setArrayForPages(): number[] {

        if (this.totalPages) {
            const array = [...new Array(this.totalPages)].map((_, i) => {
                const min = i - 2;
                const max = i + 3;
                if (this.page > min && this.page <= max) {
                    return i + 1;
                }
                return 0;
            }).filter(v => v !== 0);
            return array;
        }
    }
}
