import { AccessRole } from 'src/modules/diversite/services/access-role.service';

export const DEFAULT_ACCESS: AccessRole = {
    applicationSegments: "all",
    projects: "all",
    departments: "all"
}
export const DEFAULT_DEPARTMENT = "fjb";

export interface Builder {
    id?: string;
    email?: string;
    lastLogin?: number;
    access?: AccessRole;
    selectedDepartmentApplication?: string;
}

export class User {
    private _id: string;
    private _email: string;
    private _lastLogin: number;
    private _access: AccessRole;
    private _selectedDepartmentApplication: string;

    constructor(builder: Builder) {
        this._id = builder.id || null;
        this._email = builder.email || null;
        this._lastLogin = builder.lastLogin || null;
        this._access = builder.access || DEFAULT_ACCESS;
        this._selectedDepartmentApplication = builder.selectedDepartmentApplication;
    }

    get id(): string {
        return this._id;
    }
    get email(): string {
        return this._email;
    }
    get lastLogin(): number {
        return this._lastLogin;
    }
    get access(): AccessRole {
        return this._access;
    }
    get selectedDepartmentApplication(): string {
        return this._selectedDepartmentApplication;
    }

    change(builder: Builder): User {
        return new User({
            id: this.id,
            email: this.email,
            lastLogin: this.lastLogin,
            access: this._access,
            selectedDepartmentApplication: this._selectedDepartmentApplication,
            ...builder,
        });
    }
}

