import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Form } from 'src/modules/diversite/model/form/form';

import { PublicContext } from '../model/context';

@Injectable({
    providedIn: "root",
})
export class PublicContextService {
    constructor(private db: AngularFirestore) { }

    context(slug: string): Observable<PublicContext> {
        return this.db
            .collection("publicContexts")
            .doc(slug)
            .get()
            .pipe(
                map((doc) => {
                    if (doc.exists) {
                        return this.mapPublicContextDtoToPublicContext(doc.data() as any);
                    }
                    return undefined;
                })
            );
    }


    disableContext(context: PublicContext): Observable<PublicContext> {
        return from(this.db
            .collection("publicContexts")
            .doc(context.slug).update({ disabled: true })).pipe(map(_ => {
                return { ...context, disabled: true }
            }));
    }

    enableContext(context: PublicContext): Observable<PublicContext> {
        return from(this.db
            .collection("publicContexts")
            .doc(context.slug).update({ disabled: false })).pipe(map(_ => {
                return { ...context, disabled: false }
            }));
    }

    removeContext(context: PublicContext): Observable<void> {
        return from(this.db
            .collection("publicContexts")
            .doc(context.slug).delete());
    }

    contextsForForm(formId: string, options?: { listen: boolean }): Observable<PublicContext[]> {
        if (options && options.listen) {
            return this.db
                .collection("publicContexts", (ref) => {
                    return ref.where("form", "==", this.db.collection("forms").doc(formId).ref);
                })
                .snapshotChanges()
                .pipe(
                    map((data) => {
                        return data.map((d) => {
                            return this.mapPublicContextDtoToPublicContext(d.payload.doc.data() as any);
                        });
                    })
                );
        } else {
            return this.db
                .collection("publicContexts", (ref) => {
                    return ref.where("form", "==", this.db.collection("forms").doc(formId).ref);
                })
                .get()
                .pipe(
                    map((data) => {
                        return data.docs ? data.docs.map((doc) => this.mapPublicContextDtoToPublicContext(doc.data() as any)) : [];
                    })
                );
        }
    }

    contextByFormId(formId: string): Observable<PublicContext> {
        return this.db
            .collection("publicContexts", (ref) => {
                return ref.where("form", "==", this.db.collection("forms").doc(formId).ref);
            })
            .get()
            .pipe(
                map((data) => {
                    if (data.docs[0]) {
                        return this.mapPublicContextDtoToPublicContext(data.docs[0].data());
                    }
                    return undefined;
                })
            );
    }

    private mapPublicContextDtoToPublicContext(dto: any): PublicContext {
        return {
            form: dto.form ? dto.form : null,
            projectId: dto.projectId ? dto.projectId : null,
            disabled: dto.disabled === true ? true : false,
            slug: dto.slug,
            type: dto.type ? dto.type : "recruitment"
        }
    }

    registerNewContextForForm(slug: string, form: Form, projectId: string): Observable<PublicContext> {
        return this.context(slug).pipe(
            switchMap((context) => {
                if (!context) {
                    const context: PublicContext = {
                        projectId,
                        slug,
                        disabled: false,
                        form: this.db.collection("forms").doc(form.id).ref,
                        type: "recruitment"
                    };
                    console.log(slug);
                    return from(this.db.collection("publicContexts").doc(slug).set(context)).pipe(map((_) => context));
                } else {
                    return of(undefined);
                }
            })
        );
    }
}
