import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, skip } from 'rxjs';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';

declare var $: any;

@Component({
    selector: 'diversite-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrl: './time-picker.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePickerComponent {

    @Input() label = "";
    @Input() disabled: boolean = false;
    @Input() value: Time;


    @Output() valueChange = new EventEmitter<Time>()

    private valueChange$ = new BehaviorSubject<Time>(undefined);
    private disposeBag = new DisposeBag();


    private jqElement;

    constructor(private host: ElementRef) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value?.currentValue && this.jqElement) {
            this.jqElement.timepicker("setTime", new Date(0, 0, 0, changes.value?.currentValue.hour, changes.value?.currentValue.minute));
        }
    }

    ngAfterViewInit(): void {
        this.jqElement = $('input.timepicker', this.host.nativeElement);
        this.jqElement.timepicker({
            timeFormat: 'HH:mm',
            dynamic: true,
            startTime: new Date(0, 0, 0, this.value?.hour ? this.value.hour : 0, this.value?.minute ? this.value.minute : 0),
            dropdown: false,
            change: (date: Date | false) => {
                if (date) {
                    const d = date as Date;
                    const time: Time = {
                        hour: d.getHours(),
                        minute: d.getMinutes()
                    };
                    if (time.hour !== this.value.hour || time.minute !== this.value.minute) {
                        this.valueChange$.next(time)
                    }
                }
            }
        });

        this.valueChange$.pipe(skip(1)).subscribe(time => {
            if (time.hour !== this.value.hour || time.minute !== this.value.minute) {
                this.valueChange.emit(time);
            }
        }).disposedBy(this.disposeBag)
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}

export interface Time {
    hour: number;
    minute: number;
}