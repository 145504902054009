import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { Notification } from '../model/notification';
import { NotificationType } from '../model/notification-type';

@Injectable({
    providedIn: "root",
})
export class NotificationService {
    private readonly trigger$ = new BehaviorSubject<Notification>(undefined);

    readonly alert$ = this.trigger$.pipe(
        map((alert) => {
            return alert;
        })
    );

    constructor() {}

    show(message: string, type: NotificationType, options = { autoClose: true }): void {
        this.trigger$.next({
            message,
            type,
            options,
        });
        if (options.autoClose) {
            setTimeout(() => this.hide(), 5000);
        }
    }

    hide(): void {
        this.trigger$.next(undefined);
    }
}
