import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

import { AccessRequest, AccessRoleService } from '../diversite/services/access-role.service';
import { NotificationService } from './services/notification.service';

@Injectable({
    providedIn: "root",
})
export class AuthGuard {
    constructor(
        private authService: AuthService,
        private router: Router,
        private notificationService: NotificationService,
        private accessRoleService: AccessRoleService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const accessRequest: AccessRequest = route.data?.access;

        return this.authService.loaded$.pipe(
            filter((loaded) => loaded),
            take(1),
            switchMap((_) => {
                if (this.authService.isLoggedIn) {
                    if (accessRequest) {
                        return of(this.accessRoleService.hasAccess(accessRequest))
                    } else {
                        return of(true);
                    }

                } else {
                    this.notificationService.show("Vous avez été déconnecté. Veuillez vous relogger.", "warning");
                    this.router.navigate(["/"]);
                    return of(false);
                }
            })
        );
    }
}
