import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { BehaviorSubject, debounceTime, filter } from 'rxjs';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { Orientation, Report, ReportType } from "src/modules/diversite/model/report";
import { ImageDispositionOption } from 'src/modules/diversite/services/face-position.service';

const DEFAULT_SHOWED_ATTRIBUTES = [
    "identity_firstname",
    "identity_lastname"
];

@Component({
    selector: 'diversite-report-edit',
    templateUrl: './report-edit.component.html',
    styleUrl: './report-edit.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportEditComponent {
    @Input() report: Report;
    @Input() print = false;
    @Output() editReport = new EventEmitter<Report>();

    @ViewChild("fileLogo") fileLogo: ElementRef;

    constructor(
        private sanitizer: DomSanitizer,
        private chRef: ChangeDetectorRef
    ) { }

    optionsOpen = false;
    introPageContent = "";

    faceDispositionChange$ = new BehaviorSubject<ImageDispositionOption>(null);

    reportChange$ = new BehaviorSubject<Report>(null);
    pagesPictureGrid: PageContent[] = [];
    pagesPictureInfo: PageContent[] = [];

    private disposeBag = new DisposeBag();

    ngOnInit(): void {

        this.introPageContent = this.report.introPageContent;


        this.faceDispositionChange$.next(this.setCardDispositionOptions())
        this.pagesPictureGrid = this.calculatePagePictureGrid();
        this.pagesPictureInfo = this.setPagesForPictureInfo();



        this.reportChange$.pipe(
            filter(r => r ? true : false),
            debounceTime(1000),
        ).subscribe(r => {
            this.editReport.emit(r);
        }).disposedBy(this.disposeBag)
    }

    toggleOptions(): void {
        this.optionsOpen = !this.optionsOpen;
    }

    doPrint(): void {
        if (this.print) {
            window.print();
        } else {
            const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=800,top=10`;
            window.open(
                `${window.location.origin}/#/report/${this.report.id}`,
                `${window.location.origin}/#/report/${this.report.id}`,
                params
            );
        }
    }

    changeReportAndSave(report: Report): void {
        this.report = report;
        this.reportChange$.next(this.report)
    }

    onIntroPageContentChange(html: string): void {
        this.introPageContent = html;
        this.changeReportAndSave(this.report.change({ introPageContent: this.introPageContent }));
    }

    changeType(reportType: ReportType): void {
        this.changeReportAndSave(this.report.change({ type: reportType }));
    }

    inchToPx(inch: number): number {
        return inch * 96;
    }

    private setCardDispositionOptions(): ImageDispositionOption {
        return {
            containerWidth: this.inchToPx(this.report.photoSize),
            containerHeight: this.inchToPx(this.report.photoSize),
            finalFaceHeight: Math.round(this.inchToPx(this.report.photoSize) * this.report.proportionFace),
        }
    }

    private calculatePagePictureGrid(): PageContent[] {
        if (this.report) {
            const column = this.columnCount();
            const row = this.rowCount();
            const breakPageOn = column * row;

            const pagesPictureGrid = [];
            let page = 0;
            this.report.contactPerspectives.forEach((value, index) => {
                if (!pagesPictureGrid[page]) {
                    pagesPictureGrid[page] = {
                        title: this.report.name,
                        contactPerspectives: [],
                    };
                }
                pagesPictureGrid[page].contactPerspectives.push(value);
                if (index % breakPageOn === breakPageOn - 1) {
                    page = page + 1;
                }
            });
            return pagesPictureGrid;
        }
    }


    private columnCount(): number {
        const pageWidthForThumbnail = this.pagesWidth - 2 * this.report.pageMargin;
        const columnCount = pageWidthForThumbnail / (this.report.photoSize + 2 * this.report.photoMargin);
        return Math.floor(columnCount - 0.0000001);
    }

    private rowCount(): number {
        const pageHeightForThumbnail = this.pagesHeight - 2 * this.report.pageMargin;
        const rowCount = pageHeightForThumbnail / (this.report.photoSize + 2 * this.report.photoMargin);
        return Math.floor(rowCount + 0.0000001) ? Math.floor(rowCount + 0.0000001) : 1;
    }

    get pagesWidth(): number {
        return this.report && this.report.orientation === "landscape" ? 11 : 8.5;
    }

    get pagesHeight(): number {
        return this.report && this.report.orientation === "landscape" ? 8.5 : 11;
    }

    logoBackgroundImage(): SafeStyle {
        const image = this.report.logo;
        if (image) {
            return this.sanitizer.bypassSecurityTrustStyle(`url(${image})`);
        }
    }
    logoSizeChange(size: string): void {

        this.changeReportAndSave(this.report.change({
            logoSize: parseFloat(size)
        }));
    }

    onReportNameChange(name: string): void {
        this.changeReportAndSave(this.report.change({ name }));
    }

    isPictureGrid(): boolean {
        return this.report && this.report.type === "picture-grid";
    }

    isPictureInfo(): boolean {
        return this.report && this.report.type === "picture-info";
    }

    isLandscape(): boolean {
        return this.report && this.report.orientation === "landscape";
    }

    isPortrait(): boolean {
        return this.report && this.report.orientation === "portrait";
    }

    onIntroPageChange(event: Event): void {
        const checkbox = event.target as HTMLInputElement;
        this.changeReportAndSave(this.report.change({
            introPage: checkbox.checked,
        }));
    }

    onFieldSelectionChange(fields: string[]): void {
        this.changeReportAndSave(this.report.change({
            contactProfileDefinition: [...fields]
        }));
    }

    changeOrientation(orientation: Orientation): void {
        this.changeReportAndSave(this.report.change({ orientation }));
    }

    fileLogoChange(): void {
        const input = this.fileLogo.nativeElement as HTMLInputElement;
        if (input.files && input.files[0]) {
            const reader = new FileReader();

            reader.onload = (e) => {
                // $('#blah').attr('src', e.target.result);
                this.changeReportAndSave(this.report.change({
                    logo: e.target.result as string
                }));
                this.chRef.detectChanges();
            };

            reader.readAsDataURL(input.files[0]);
        }
    }

    clearLogo(): void {
        this.changeReportAndSave(this.report.change({
            logo: null
        }));
    }

    thumbnailSizeChange(size: string): void {
        this.changeReportAndSave(this.report.change({
            photoSize: parseFloat(size)
        }));
        this.pagesPictureGrid = this.calculatePagePictureGrid();
        this.faceDispositionChange$.next(this.setCardDispositionOptions());
    }

    proportionFaceChange(proportion: string): void {
        this.changeReportAndSave(this.report.change({
            proportionFace: parseFloat(proportion)
        }));
        this.faceDispositionChange$.next(this.setCardDispositionOptions());
    }


    thumbnailMarginChange(margin: string): void {
        this.changeReportAndSave(this.report.change({
            photoMargin: parseFloat(margin)
        }));
        this.pagesPictureGrid = this.calculatePagePictureGrid();
    }



    pagesMarginChange(margin: string): void {
        this.changeReportAndSave(this.report.change({
            pageMargin: parseFloat(margin)
        }));
        this.pagesPictureGrid = this.calculatePagePictureGrid();
    }

    onProfilePerPageChange(event: Event): void {
        const select = event.target as HTMLSelectElement;
        this.changeReportAndSave(this.report.change({
            profilePerPage: parseInt(select.value, 10),
        }));
        this.pagesPictureInfo = this.setPagesForPictureInfo();
        this.chRef.detectChanges();
    }

    onContactPerspectiveChange(cp: ContactPerspective): void {
        this.changeReportAndSave(this.report.change({
            contactPerspectives: this.report.contactPerspectives.map(contactPerspective => cp.id === contactPerspective.id ? cp : contactPerspective)
        }))
    }

    private setPagesForPictureInfo(): PageContent[] {
        const pages: PageContent[] = [];
        let pageNum = 0;
        pages.push({
            contactPerspectives: [],
        });
        this.report.contactPerspectives.forEach((element, i) => {
            pages[pageNum].contactPerspectives.push(element);
            if ((i + 1) % this.report.profilePerPage === 0) {
                pages.push({
                    contactPerspectives: [],
                });
                pageNum = pageNum + 1;
            }
        });

        if (pages[pages.length - 1].contactPerspectives.length === 0) {
            pages.splice(-1, 1);
        }

        return pages;
    }

    trackById(index: number, item: any): string {
        return item.id;
    }

    trackByIndex(index: number): string {
        return `${index}`;
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}


export interface PageContent {
    contactPerspectives: ContactPerspective[];
}