import { AfterViewInit, Directive, ElementRef, EventEmitter, NgZone, OnDestroy, Output } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[draggable]'
})
export class DraggableDirective implements AfterViewInit, OnDestroy {
    @Output() dragged = new EventEmitter<DraggableEvent>();
    @Output() draggedEnd = new EventEmitter<DraggableEvent>();

    private handle: HTMLElement;
    private delta: DragDelta = { x: 0, y: 0 };
    private offset = { x: 0, y: 0 };

    private destroy$ = new Subject<void>();

    constructor(private elementRef: ElementRef, private zone: NgZone) {
    }

    public ngAfterViewInit(): void {
        this.handle = this.elementRef.nativeElement;
        this.setupEvents();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }

    private setupEvents(): void {
        const mousedown$ = fromEvent(this.handle, 'mousedown');
        const mousemove$ = fromEvent(document, 'mousemove');
        const mouseup$ = fromEvent(document, 'mouseup');

        const mousedrag$ = mousedown$.pipe(
            switchMap((mouseDownEvent: any) => {
                const startX = mouseDownEvent.clientX;
                const startY = mouseDownEvent.clientY;
                return mousemove$.pipe(
                    map((mouseMoveEvent: any) => {
                        this.delta = {
                            x: mouseMoveEvent.clientX - startX,
                            y: mouseMoveEvent.clientY - startY
                        };
                    }),
                    debounceTime(0),
                    takeUntil(mouseup$)
                );
            }),
            takeUntil(this.destroy$)
        );

        mousedrag$.subscribe(() => {
            if (this.delta.x === 0 && this.delta.y === 0) {
                return;
            }
            this.drag();
        });

        mouseup$.pipe(
            tap(_ => {
                // if delta has changed on mouseup then emit drag end
                if (!(this.delta.y === 0 && this.delta.x === 0)) {
                    this.draggedEnd.emit({
                        delta: this.delta,
                        offset: this.offset
                    });
                }
            }),
            takeUntil(this.destroy$)
        ).subscribe(e => {
            this.offset.x += this.delta.x;
            this.offset.y += this.delta.y;

            this.delta = { x: 0, y: 0 };
        });
    }

    private drag(): void {
        this.dragged.emit({
            delta: this.delta,
            offset: this.offset
        });
    }
}

export interface DragDelta {
    x: number;
    y: number;
}

export interface DragOffset {
    x: number;
    y: number;
}

export interface DraggableEvent {
    delta: DragDelta;
    offset: DragOffset;
}
