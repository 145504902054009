<div class="export-container">
    <div class="top">
        <div *ngIf="contacts$ | async as contacts" class="contacts">
            <div class="contact" *ngFor="let contact of contacts; trackBy: trackById">
                <diversite-photo-cover
                    [mode]="'cover'"
                    [url]="contact.images[0]?.url"
                    [rotate]="contact.images[0]?.rotate"
                >
                </diversite-photo-cover>
            </div>
            <div class="more" *ngIf="metaMore$ | async as meta">
                ... plus <strong>{{ meta }}</strong>
            </div>
        </div>
    </div>

    <div class="middle">
        <div *ngIf="includeImagesOptions$ | async as includeImagesOptions">
            <clr-checkbox-container clrInline>
                <clr-checkbox-wrapper>
                    <input
                        type="checkbox"
                        [checked]="includeImagesOptions.includeImages"
                        (change)="onIncludeImagesCheck($event.target.checked)"
                        name="includedImages"
                        clrCheckbox
                        required
                    />
                    <label class="clr-control-label">Inclure les images</label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <div class="sub-options" [class.disabled]="!includeImagesOptions.includeImages">
                <clr-radio-container class="image-options">
                    <clr-radio-wrapper>
                        <input
                            type="radio"
                            [checked]="includeImagesOptions.quality === 'compressed'"
                            (change)="onChangeImageQuality('compressed')"
                            clrRadio
                            name="includeImagesQuality"
                        />
                        <label>Images optimisées</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input
                            type="radio"
                            [checked]="includeImagesOptions.quality === 'original'"
                            (change)="onChangeImageQuality('original')"
                            clrRadio
                            name="includeImagesQuality"
                        />
                        <label>Images originales</label>
                    </clr-radio-wrapper>
                </clr-radio-container>

                <br />
                <clr-radio-container class="image-options">
                    <clr-radio-wrapper>
                        <input
                            type="radio"
                            clrRadio
                            name="imagesCollection"
                            [checked]="includeImagesOptions.images?.type === 'all'"
                            (change)="onChangeImageCollectionType('all')"
                        />
                        <label>Inclure toute les images</label>
                    </clr-radio-wrapper>
                    <clr-radio-wrapper>
                        <input
                            type="radio"
                            clrRadio
                            name="imagesCollection"
                            (change)="onChangeImageCollectionType('dateRange')"
                            [checked]="includeImagesOptions.images?.type === 'dateRange'"
                        />
                        <label class="date-selection">
                            <span>Inclure les images entre ces dates</span>
                            <clr-date-container>
                                <input
                                    type="date"
                                    [disabled]="includeImagesOptions.images?.type !== 'dateRange'"
                                    autocomplete="off"
                                    clrDate
                                    (clrDateChange)="onChangeDateImage('from', $event)"
                                    [clrDate]="includeImagesOptions.images?.date?.from"
                                    name="dateFrom"
                                />
                            </clr-date-container>
                            <clr-date-container>
                                <input
                                    [disabled]="includeImagesOptions.images?.type !== 'dateRange'"
                                    type="date"
                                    autocomplete="off"
                                    clrDate
                                    (clrDateChange)="onChangeDateImage('to', $event)"
                                    [clrDate]="includeImagesOptions.images?.date?.to"
                                    name="dateTo"
                                />
                            </clr-date-container>
                        </label>
                    </clr-radio-wrapper>
                </clr-radio-container>
            </div>
        </div>

        <div *ngIf="includeExcelFileOptions$ | async as includeExcelFileOptions">
            <clr-checkbox-container clrInline>
                <clr-checkbox-wrapper>
                    <input
                        [checked]="includeExcelFileOptions.includeFile"
                        (change)="onExcelFileChange($event.target.checked)"
                        type="checkbox"
                        clrCheckbox
                        name="includeExcel"
                        required
                    />
                    <label class="clr-control-label">Inclure un fichier excel</label>
                </clr-checkbox-wrapper>
            </clr-checkbox-container>

            <div class="sub-options" [class.disabled]="!includeExcelFileOptions.includeFile">
                <div class="add-from-project-fields">
                    <clr-select-container>
                        <label>Ajouter tout les champs d'un formulaire de projet.</label>
                        <select
                            (change)="onProjectSelectionChange()"
                            clrSelect
                            name="project-selection"
                            [(ngModel)]="projectSelection"
                        >
                            <option value="">-</option>
                            <option [value]="project.id" *ngFor="let project of projects$ | async">
                                {{ project.name }}
                            </option>
                        </select>
                    </clr-select-container>
                    <clr-select-container>
                        <label></label>
                        <select clrSelect name="form-selection" [(ngModel)]="formSelection">
                            <option value="">-</option>
                            <option [value]="form.id" *ngFor="let form of forms$ | async">{{ form.name[lang] }}</option>
                        </select>
                    </clr-select-container>
                    <button
                        (click)="addFormFields()"
                        [disabled]="!formSelection || formSelection === ''"
                        class="btn btn-sm btn-primary"
                    >
                        Ajouter
                    </button>
                </div>
                <div class="fields-to-export">
                    <diversite-attribute-multiselect
                        id="included-fields"
                        [label]="'Choisir les champs à inclure'"
                        [selection]="includeExcelFileOptions.fields"
                        (selectionChange)="onSelectedFieldsChange($event)"
                    ></diversite-attribute-multiselect>
                    <small (click)="clearFields()" class="action-link">Vider</small>
                </div>
            </div>
        </div>

        <div [hidden]="!generatingBundle" class="log-progress">
            <div class="progress">
                <progress [value]="progress" max="100"></progress>
            </div>

            <span *ngIf="progress !== 0">{{ progress }}/100</span>
            <clr-spinner *ngIf="progress < 100" [clrInline]="true"></clr-spinner>
        </div>
    </div>

    <div class="bottom">
        <button class="btn btn-sm" (click)="onCancel()">Annuler</button>
        <button [disabled]="generatingBundle" type="button" class="btn btn-primary btn-sm" (click)="download()">
            Télécharger
        </button>
    </div>
</div>
