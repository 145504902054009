import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SearchProfileVisibleAttributesService {

    private _attributes = new BehaviorSubject<AttributeShown[]>([]);

    constructor() { }

    attributes(): Observable<AttributeShown[]> {
        return this._attributes.asObservable();
    }

    setAttributes(attribute: AttributeShown[]): void {
        this._attributes.next(attribute)
    }

    setAttribute(attrShown: AttributeShown): void {
        const existAttr = this._attributes.value.find(attr => attr.attributeId === attrShown.attributeId) ? true : false;
        if (!existAttr) {
            this._attributes.next([attrShown, ...this._attributes.value]);
        }
    }

    removeAttribute(index: string): void {
        const existAttr = this._attributes.value.find(attr => attr.attributeId === index ? true : false);
        if (existAttr) {
            this._attributes.next(this._attributes.value.filter(attr => attr.attributeId !== index))
        }
    }

    clear(): void {
        this._attributes.next([]);
    }

    removeFormAttribute(): void {
        const existAttr = this._attributes.value.find(attr => attr.attributeId && attr.attributeId.startsWith("recruitment_") ? true : false);
        if (existAttr) {
            this._attributes.next(this._attributes.value.filter(attr => !attr.attributeId || !attr.attributeId.startsWith("recruitment_")))
        }
    }

    toggleAttribute(attributeId: string): void {
        const attr = this._attributes.value.find(attr => attr.attributeId === attributeId);
        if (attr) {
            this._attributes.next(this._attributes.value.filter(attr => attr.attributeId !== attributeId));
        } else {
            this._attributes.next([...this._attributes.value, { attributeId }])
        }
    }

    isViewedAttr(attributeId: string): boolean {
        return this._attributes.value.find(attr => attr.attributeId === attributeId) ? true : false;
    }
}

export interface AttributeShown {
    attributeId: string;
    highlight?: string;
}