import { FormElement } from './form/form-element/form-element';

export interface SearchOperationBuilder {
    id?: string;
    type?: SearchOperationType;
    operand?: string;
    value?: any;
    operator?: Operator;
    occurance?: Occurance;
    field?: ToSearchField;
    boost?: number;
    disabled?: boolean;
    source?: FormElement;
}

export class SearchOperation {
    private _id: string;
    private _type: SearchOperationType;
    private _operand?: string;
    private _value?: any;
    private _operator?: Operator;
    private _occurance?: Occurance;
    private _field?: ToSearchField;
    private _boost?: number;
    private _disabled?: boolean;
    private _source?: FormElement;

    constructor(builder: SearchOperationBuilder) {
        if (!builder.id) {
            throw new Error("SearchOperation must have an Id");
        }
        this._id = builder.id;
        this._type = builder.type || "default";
        this._operand = builder.operand || null;
        this._value = builder.value || builder.value === 0 ? builder.value : null;
        this._operator = builder.operator || null;
        this._occurance = builder.occurance || "must";
        this._field = builder.field || null;
        this._boost = builder.boost || 1;
        this._disabled = builder.disabled === true;
        this._source = builder.source || null;
    }

    get id(): string {
        return this._id;
    }
    get type(): SearchOperationType {
        return this._type;
    }
    get operand(): string {
        return this._operand;
    }
    get value(): any {
        return this._value;
    }
    get operator(): Operator {
        return this._operator;
    }
    get occurance(): Occurance {
        return this._occurance;
    }
    get field(): ToSearchField {
        return this._field;
    }
    get boost(): number {
        return this._boost;
    }
    get disabled(): boolean {
        return this._disabled;
    }
    get source(): FormElement {
        return this._source;
    }

    hasValue(): boolean {
        if (Array.isArray(this.value)) {
            return this.value.length > 0;
        }
        if (this.operator === "exists") {
            return true;
        }
        return this.value ? true : false;
    }


    setState(disabled: boolean): SearchOperation {
        return this.change({ disabled });
    }

    toggleDisabled(): SearchOperation {
        return this.change({
            disabled: !this._disabled,
        });
    }

    change(builder: SearchOperationBuilder): SearchOperation {
        return new SearchOperation({
            type: this._type,
            operand: this._operand,
            value: this._value,
            operator: this._operator,
            occurance: this._occurance,
            field: this._field,
            boost: this._boost,
            disabled: this._disabled,
            source: this._source,
            ...builder,
            id: this._id,
        });
    }
}

export type SearchOperationType = "default" | "availability";
export type Operator =
    | "greaterThan"
    | "lowerThan"
    | "range"
    | "or"
    | "or-single-source"
    | "query"
    | "exact"
    | "boolean"
    | "exists";
export type ToSearchField = "valueString" | "valueDouble" | "valueInt" | "valueBoolean" | "valueDateTime";

export type Occurance = "must" | "must-not";
