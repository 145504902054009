<h3>Administration</h3>

<ul id="demoTabs" class="nav" role="tablist">
    <li role="presentation" class="nav-item">
        <button
            id="tab1"
            class="btn btn-link nav-link"
            (click)="goTo('errors')"
            [class.active]="isActive('errors')"
            type="button"
        >
            Errors
        </button>
    </li>
    <li role="presentation" class="nav-item">
        <button
            id="tab2"
            class="btn btn-link nav-link"
            (click)="goTo('modules')"
            [class.active]="isActive('modules')"
            type="button"
        >
            Modules
        </button>
    </li>
    <li role="presentation" class="nav-item">
        <button
            id="tab3"
            class="btn btn-link nav-link"
            (click)="goTo('access-role')"
            [class.active]="isActive('access-role')"
            type="button"
        >
            Access Roles
        </button>
    </li>
</ul>

<router-outlet></router-outlet>
