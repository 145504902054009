<label class="clr-control-label">Classification(s)</label>
<div class="classifications">
    <cds-icon (click)="changeSearchOperationContextNode()" [class.disabled]="disabled" shape="plus"></cds-icon>

    <select [(ngModel)]="occurance">
        <option value="must">fait partie de</option>
        <option value="must-not">pas partie de</option>
    </select>

    <select [(ngModel)]="selectedNodeId" [disabled]="!projectId || projectId === ''">
        <option value="">-</option>
        <ng-container *ngIf="contextNodes$ | async as contextNodes">
            <option *ngFor="let contextNode of contextNodes; trackBy: trackById" [value]="contextNode.id">
                {{ contextNode.name }}
            </option>
            <option value="all">(tous les dossiers)</option>
        </ng-container>
    </select>
</div>
