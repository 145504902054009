import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { DisposeBag } from "src/modules/core/utilities/dispose-bag";
import { Report } from "src/modules/diversite/model/report";
import { ReportService } from "src/modules/diversite/services/report.service";
import { RotateContactImageService } from "src/modules/diversite/services/rotate-contact-image.service";

import { RotateImageEvent } from "../../photo-profile/photo-profile.component";

@Component({
    selector: "diversite-report-container",
    templateUrl: "./report-container.component.html",
    styleUrls: ["./report-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportContainerComponent implements OnInit, OnDestroy {
    report$: Observable<Report>;

    private _dispose = new DisposeBag();

    constructor(
        private reportService: ReportService,
        private route: ActivatedRoute,
        private rotateContactImageService: RotateContactImageService
    ) {}

    ngOnInit(): void {
        this.report$ = this.reportService.reportById(this.route.snapshot.params.id, { listen: true });
    }

    onReportChange(report: Report): void {
        this.reportService.editReport(report).subscribe().disposedBy(this._dispose);
    }

    onRotatePicture(event: RotateImageEvent): void {
        this.rotateContactImageService.saveRotation(event.contactId, event.image);
    }

    ngOnDestroy(): void {
        this._dispose.dispose();
    }
}
