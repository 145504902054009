import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormWeight } from 'src/modules/diversite/model/form/form-element/form-weight';

import { DEFAULT_OPTIONS, FormElementGenericOptions } from '../../form-element.component';

@Component({
    selector: "fillout-weight",
    templateUrl: "./weight.component.html",
    styleUrls: ["./weight.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeightComponent implements OnInit {
    @Input() lang: string;
    @Input() formElement: FormWeight;
    @Input() value: any;
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Output() responseChange = new EventEmitter<string>();
    delayedChanges$ = new BehaviorSubject<string>(undefined);

    private _disposeBag = new DisposeBag();

    constructor(private chRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.delayedChanges$
            .pipe(debounceTime(250), skip(1))
            .subscribe((v) => {
                this.responseChange.emit(v);
            })
            .disposedBy(this._disposeBag);
    }

    onInputChange(event: KeyboardEvent): void {
        this.value = (event.target as HTMLInputElement).value;
        this.delayedChanges$.next(this.value);
        this.chRef.detectChanges();
    }

    pounds(kg: number): number {
        if (kg) {
            return Math.round((kg || 0) * 2.20462);
        }
        return null;
    }

    poundsChange(pounds: number): void {
        if (pounds) {
            // 2 decimals max
            this.value = Math.round((pounds / 2.2046) * 100) / 100;
            this.delayedChanges$.next(this.value);
            this.chRef.detectChanges();
        } else {
            this.value = null;
            this.delayedChanges$.next(this.value);
            this.chRef.detectChanges();
        }
    }

    minus(): void {
        if (!this.value) {
            this.value = 54.5;
        }
        this.value = parseFloat(this.value) - 0.5;
        this.delayedChanges$.next(this.value);
        this.chRef.detectChanges();
    }

    plus(): void {
        if (!this.value) {
            this.value = 54.5;
        }
        this.value = parseFloat(this.value) + 0.5
        this.delayedChanges$.next(this.value);
        this.chRef.detectChanges();
    }

    kg(kg: number): number {
        return kg || null;
    }
}
