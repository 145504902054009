import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Activity } from 'src/modules/diversite/services/activity.service';

@Component({
    selector: 'diversite-contact-support-activity',
    templateUrl: './contact-support-activity.component.html',
    styleUrl: './contact-support-activity.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSupportActivityComponent {
    @Input() activity: ContactSupportActivity
}


export interface ContactSupportActivity {
    id: string,
    created: Date,
    message: {
        name: string,
        email: string,
        subject: string,
        message: string
    },

}