import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip, take } from 'rxjs/operators';
import { Translatable } from 'src/modules/core/components/translatable';
import { FileUploadGenericService } from 'src/modules/core/services/file-upload.service';
import { StaticLabels, StaticLabelsService } from 'src/modules/core/services/static-labels.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormFileupload } from 'src/modules/diversite/model/form/form-element/form-fileupload';

import { DEFAULT_OPTIONS, FormElementGenericOptions } from '../../form-element.component';

@Component({
    selector: "fillout-fileupload",
    templateUrl: "./fileupload.component.html",
    styleUrls: ["./fileupload.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileuploadComponent implements OnInit, OnDestroy, Translatable {
    private translationId = "MIOHs8lxnzIoae93Z5BN";
    @Input() lang: string;
    @Input() formElement: FormFileupload;
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Output() responseChange = new EventEmitter<string>();

    private translationLabels: StaticLabels = {};
    fileUrl: string;
    file: File;
    uploading = false;

    delayedChanges$ = new BehaviorSubject<string>(undefined);

    private disposeBag = new DisposeBag();

    constructor(
        private chRef: ChangeDetectorRef,
        private fileUploadService: FileUploadGenericService,
        private staticLabelsService: StaticLabelsService
    ) { }

    ngOnInit(): void {
        this.staticLabelsService
            .labelsForComponent(this.translationId)
            .pipe(take(1))
            .subscribe((labels) => {
                this.translationLabels = labels;
                this.chRef.markForCheck();
            })
            .disposedBy(this.disposeBag);

        this.delayedChanges$
            .pipe(debounceTime(250), skip(1))
            .subscribe((v) => {
                this.responseChange.emit(v);
            })
            .disposedBy(this.disposeBag);
    }

    label(labelId: string): string {
        if (this.translationLabels && this.translationLabels[labelId] && this.translationLabels[labelId][this.lang]) {
            return this.translationLabels[labelId][this.lang];
        }
        return "";
    }

    onInputChange(event: any): void {
        if (!this.formElement.readonly) {
            if (event.target.files.length > 0) {
                this.file = event.target.files[0];
                this.uploading = true;
                this.fileUploadService
                    .upload(this.file)
                    .subscribe((response) => {
                        this.fileUrl = response.asset.finalUrl;
                        this.responseChange.emit(this.fileUrl);
                        this.uploading = false;
                        this.chRef.detectChanges();
                    })
                    .disposedBy(this.disposeBag);
            } else {
                this.file = undefined;
                this.responseChange.emit(undefined);
            }
        }
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
