import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map, Observable, of, tap } from 'rxjs';
import { Pane } from 'src/modules/diversite/model/pane';
import { Project } from 'src/modules/diversite/model/project';
import { ProjectService } from 'src/modules/diversite/services/projects.service';

import { ContextNodeTitle, PaneTitleService } from '../services/pane-title.service';
import { ContextNodeService } from 'src/modules/diversite/services/context-node.service';
import { SearchContextService } from 'src/modules/diversite/services/search-context.service';
import { SearchDefinition } from 'src/modules/diversite/model/search-definition';
import { SearchOperation } from 'src/modules/diversite/model/search-operation';

@Component({
    selector: "diversite-pane",
    templateUrl: "./pane.component.html",
    styleUrls: ["./pane.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaneComponent implements OnInit {
    @Input() pane: Pane;
    @Input() paneSize: PaneSizeSpec;
    @Input() disabled: boolean;
    @Input() collapsed = false;

    @Output() closePane = new EventEmitter<string>();
    @Output() toggleCollapse = new EventEmitter<PaneCollapseEvent>();
    @Output() changeLock = new EventEmitter<PaneLockEvent>();
    @Output() maximizePane = new EventEmitter<Pane>();
    @Output() paneSizeChange = new EventEmitter<number>();

    panePath$: Observable<ContextNodeTitle[]>;

    project$: Observable<Project>;

    searchOperations$: Observable<SearchOperation[]> = of([]);

    constructor(
        private projectService: ProjectService,
        private contextNodeService: ContextNodeService,
        private searchContextService: SearchContextService,
        private paneTitleService: PaneTitleService
    ) { }

    ngOnInit(): void {
        if (this.pane.projectId) {
            this.project$ = this.projectService.project(this.pane.projectId);
        }
        this.panePath$ = this.paneTitleService.contextNodePathForPane(this.pane);

        if (this.pane.type === "search") {
            this.searchOperations$ = this.searchContextService.searchDefinition(this.pane.contextData.searchContextId, { listen: true }).pipe(
                map(s => s.searchOperations)
            )
        }
    }


    headerName(pane: Pane): string {
        return this.paneTitleService.actionPaneTitle(pane);
    }

    isClosable(pane: Pane): boolean {
        return pane.isClosable();
    }

    isPinable(pane: Pane): boolean {
        return pane.isPinable();
    }

    onToggleCollapsePane(): void {
        this.toggleCollapse.emit({ pane: this.pane, collapsed: !this.collapsed });
    }

    onToggleLockPane(pane: Pane): void {
        this.changeLock.emit({ paneId: this.pane.id, locked: !pane.locked });
    }

    onClosePane(): void {
        this.closePane.emit(this.pane.id);
    }

    onMaximize(pane: Pane): void {
        this.maximizePane.emit(pane);
    }

    onPaneSizeChange(width: number): void {
        this.paneSizeChange.emit(width);
    }
}

export interface PaneCollapseEvent {
    pane: Pane;
    collapsed: boolean;
}

export interface PaneLockEvent {
    paneId: string;
    locked: boolean;
}

export interface PaneSizeSpec {
    size: number;
    max: number;
    min: number;
}
