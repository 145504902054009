import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import {
    SearchValueType,
} from 'src/modules/diversite/components/search-form-elements/search-form-element/search-value-type.service';
import { Operator } from 'src/modules/diversite/model/search-operation';

import { DisposeBag } from '../../utilities/dispose-bag';

const DEFAULT_OPTIONS: SearchValueSelectorOption = {};
@Component({
    selector: "search-value-selector",
    templateUrl: "./search-value-selector.component.html",
    styleUrls: ["./search-value-selector.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchValueSelectorComponent implements OnInit, OnChanges {
    @Input() lang: string;
    @Input() type: SearchValueType;
    @Input() display: "vertical" | "horizontal" = "vertical";
    @Input() options: SearchValueSelectorOption = DEFAULT_OPTIONS;
    @Output() valueChange = new EventEmitter<SearchValue>();
    @Output() keyPressed = new EventEmitter<string>();

    private delayedChange$ = new BehaviorSubject<void>(undefined);
    private disposeBag = new DisposeBag();

    @Input() searchValue: SearchValue;

    constructor(private chRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.delayedChange$
            .pipe(skip(1), debounceTime(100))
            .subscribe((_) => {
                this.valueChange.emit(this.searchValue);
            })
            .disposedBy(this.disposeBag);
    }

    ngOnChanges(changes: SimpleChanges): void { }

    reset(): void {
        this.chRef.markForCheck();
    }

    onKeypress(event: KeyboardEvent): void {
        this.keyPressed.emit(event.key);
    }

    onChange(searchValue?: SearchValue): void {
        if (searchValue) {
            this.searchValue = searchValue;
        }
        if (this.type === "age") {
            this.searchValue.operator =
                this.searchValue.valueMin && this.searchValue.valueMax
                    ? "range"
                    : this.searchValue.valueMin
                        ? "greaterThan"
                        : "lowerThan";
        }

        this.delayedChange$.next();
    }
}

export interface SearchValueSelectorOption {
    availableChoices?: SearchValueOption[];
}

export interface SearchValueOption {
    id: string;
    label?: string;
    type?: string;
    value?: any;
}

export interface SearchValue {
    operator?: Operator;
    value?: any;
    valueMin?: any;
    valueMax?: any;
}
