export const environment = {
    production: true,
    firebaseConfig: {
        apiKey: "AIzaSyCK0A6d_a-Xu_Org6r_-87VzKjbkX-vrAQ",
        authDomain: "castlugalgolia.firebaseapp.com",
        databaseURL: "https://castlugalgolia.firebaseio.com",
        projectId: "castlugalgolia",
        storageBucket: "castlugalgolia.appspot.com",
        messagingSenderId: "699295200478",
        appId: "1:699295200478:web:058883c52485644eb0fe4f",
        measurementId: "G-8DRTYPG9VZ"
    }
};
