import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';

import { AttributeLabel, DataCatalogService } from '../../services/data-catalog.service';

const HIGHLIGHT_TAG = {
    start: "<span style='background:yellow;'>",
    end: "</span>",
};
@Component({
    selector: "diversite-attribute-value",
    templateUrl: "./attribute-value.component.html",
    styleUrls: ["./attribute-value.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeValueComponent implements OnInit, OnDestroy, OnChanges {
    @Input() isSearched: boolean = false;
    @Input() attributeName: string;
    @Input() attributeLabel: AttributeLabel;
    @Input() maxCharacters: number;
    @Input() value: any;

    value$: Observable<string> = of("");
    disposeBag = new DisposeBag();

    toHighlight: string[] = [];

    constructor(private dataCatalogService: DataCatalogService) { }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {


            if (this.attributeName) {
                this.value$ = this.dataCatalogService
                    .attributeValue(this.attributeName, this.value, this.maxCharacters)
                    .pipe(
                        map((value) => {
                            if (this.isSearched && !this.alreadyHightlighted(value)) {
                                return `${HIGHLIGHT_TAG.start}${value}${HIGHLIGHT_TAG.end}`;
                            }
                            return value;
                        })
                    );
            }

            if (this.attributeLabel && !this.attributeName) {
                this.value$ = of(this.dataCatalogService.litteralValueForAttributeLabel(this.attributeLabel, this.value)).pipe(
                    map((value) => {
                        if (this.isSearched && !this.alreadyHightlighted(value)) {
                            return `${HIGHLIGHT_TAG.start}${value}${HIGHLIGHT_TAG.end}`;
                        }
                        return value;
                    })
                );
            }

            if (!this.attributeLabel && !this.attributeName) {
                this.value$ = of(this.value).pipe(
                    map((value) => {
                        if (this.isSearched && !this.alreadyHightlighted(value)) {
                            return `${HIGHLIGHT_TAG.start}${value}${HIGHLIGHT_TAG.end}`;
                        }
                        return value;
                    })
                );
            }

        }

    }

    private alreadyHightlighted(value: string): boolean {
        return (
            value &&
            typeof value === "string" &&
            value.indexOf("background") !== -1 &&
            value.indexOf("yellow") !== -1 &&
            value.indexOf("</span>") !== -1
        );
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
