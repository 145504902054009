import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Project } from 'src/modules/diversite/model/project';
import { ProjectService } from 'src/modules/diversite/services/projects.service';

@Component({
    selector: 'diversite-share-project-action',
    templateUrl: './share-project-action.component.html',
    styleUrl: './share-project-action.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareProjectActionComponent {
    @Input() projectId: string = "";
    @Output() close = new EventEmitter<void>();

    projects$: Observable<Project[]>;
    users$: Observable<User[]>;
    selectedUsers: any = {};

    private disposeBag = new DisposeBag();

    constructor(
        private projectService: ProjectService,
        private userService: UserService,
        private notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        this.projects$ = this.projectService.activeProjects();
        this.users$ = this.userService.users();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.projectId) {
            if (!changes.projectId.currentValue) {
                this.projectId = "";
            } else {
                this.projectId = changes.projectId.currentValue;
            }
        }
    }

    get selectedUsersArray(): string[] {
        return Object.keys(this.selectedUsers).filter(key => this.selectedUsers[key] === true);
    }

    usersLength(): number {
        return this.selectedUsersArray.length;
    }

    shareProject(): void {

        this.projectService.shareProject(this.projectId, this.selectedUsersArray).subscribe(_ => {
            this.notificationService.show("Le project à été partagé avec succès.", "success");
            this.close.emit();
        }).disposedBy(this.disposeBag)
    }

    cancel(): void {
        this.close.emit();
    }
}

