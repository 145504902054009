<clr-range-container [class.no-label]="!options.showLabel" *ngIf="formElement">
    <label *ngIf="options.showLabel"
        >{{ formElement.label[lang] }}&nbsp;<span *ngIf="value" class="value"
            >{{ kg(value) }}kg. / {{ pounds(value) }}lbs.</span
        ><span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>
    <label *ngIf="!options.showLabel">{{ kg(value) }}kg. / {{ pounds(value) }}lbs.</label>
    <input
        class="slider"
        [value]="value"
        (input)="onInputChange($event)"
        [disabled]="formElement.readonly"
        type="range"
        [name]="formElement.name"
        clrRange
        step="0.5"
        [required]="formElement.required"
        min="1"
        max="109"
    />
</clr-range-container>
<cds-icon role="button" shape="minus" (click)="minus()"></cds-icon>
<cds-icon role="button" shape="plus" (click)="plus()"></cds-icon>
<!-- <div class="value">
    <span *ngIf="value" class="kg-content"
        ><input min="1" max="250" [(ngModel)]="value" class="small" type="number" />kg.</span
    >
    <span *ngIf="value" class="pounds-content"
        ><input
            class="small"
            (change)="poundsChange($event.target.value)"
            type="number"
            [value]="pounds(value)"
        />lbs.</span
    >
</div> -->
