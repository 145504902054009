import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { User } from 'src/app/model/user';
import { AuthService } from 'src/app/services/auth.service';

import { ContactContextNodeAssignService } from '../services/contact-context-node-assign.service';
import { ContextMenu, ContextMenuService } from '../services/context-menu.service';

@Component({
    selector: "diversite-container",
    templateUrl: "./diversite-container.component.html",
    styleUrls: ["./diversite-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiversiteContainerComponent implements OnInit {
    constructor(private contextMenuService: ContextMenuService) { }


    contextMenu$: Observable<ContextMenu>;

    ngOnInit(): void {
        this.contextMenu$ = this.contextMenuService.contextMenu();
    }
}
