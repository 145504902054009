import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/*
 * Hightlight text
 * Takes a terms argument that defaults to empty array.
 * Usage:
 *   value | hightlight:terms
 * Example:
 *   {{ 'Hello World!' | hightlight:['H','world'] }}
 */
@Pipe({ name: "hightlight" })
export class HighlightPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}
    transform(value: string, terms: string[] = []): SafeHtml {
        const highlightTag = [`<span style="background:yellow;">`, `</span>`];
        if (typeof value === "string") {
            terms.forEach((term) => {
                let index = value.toLowerCase().indexOf(term.toLowerCase());
                if (index !== -1) {
                    value = this.insert(value, index, highlightTag[0]);
                    value = this.insert(value, index + term.length + highlightTag[0].length, highlightTag[1]);
                }
            });
        }

        return this.sanitized.bypassSecurityTrustHtml(`${value}`);
    }

    private insert(originString: string, index: number, toAddString: string): string {
        var ind = index < 0 ? originString.length + index : index;
        return originString.substring(0, ind) + toAddString + originString.substr(ind);
    }
}
