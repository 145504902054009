import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { guid } from 'src/app/core/functions';
import {
    ContextNodePaneActionsService,
} from 'src/modules/diversite/components/context-node/context-node-pane-actions.service';
import { Time } from 'src/modules/diversite/components/time-picker/time-picker.component';
import { Form } from 'src/modules/diversite/model/form/form';
import { FormDatetime } from 'src/modules/diversite/model/form/form-element/form-datetime';
import { FormElement } from 'src/modules/diversite/model/form/form-element/form-element';
import { FormTextbox } from 'src/modules/diversite/model/form/form-element/form-textbox';
import { Project } from 'src/modules/diversite/model/project';
import { SearchDefinition } from 'src/modules/diversite/model/search-definition';
import { SearchGroup } from 'src/modules/diversite/model/search-group';
import { SearchOperation } from 'src/modules/diversite/model/search-operation';
import { AttributeMeta, ElasticSearchService, LabelMeta } from 'src/modules/diversite/services/elasticsearch.service';
import { FormService } from 'src/modules/diversite/services/form.service';
import { ModuleService } from 'src/modules/diversite/services/module.service';
import { ProjectService } from 'src/modules/diversite/services/projects.service';
import { SearchFavoriteNode } from 'src/modules/diversite/services/search-favorite-node.service';
import {
    SearchProfileVisibleAttributesService,
} from 'src/modules/diversite/services/search-profile-visible-attributes.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';

const SEARCH_LENGTH_THRESHOLD = 2;
@Component({
    selector: "diversite-search-group",
    templateUrl: "./search-group.component.html",
    styleUrls: ["./search-group.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchGroupComponent {
    @Input() searchDefinition: SearchDefinition;
    @Input() projectId: string;
    @Input() searchGroup: SearchGroup;
    @Input() favoriteNodes: SearchFavoriteNode[];
    @Input() metaForGroup: number;
    @Input() index: number = 0;
    @Input() contextNodeId: string;

    @Output() removeGroup = new EventEmitter<void>();
    @Output() addOrSearchGroup = new EventEmitter<SearchGroup>();
    @Output() addToFavorite = new EventEmitter<FormElement>();
    @Output() editGroup = new EventEmitter<SearchGroup>();
    @Output() viewGroupResults = new EventEmitter<string>();
    @Output() projectChange = new EventEmitter<string>();


    constructor(
        private formService: FormService,
        private projectService: ProjectService,
        private actionPaneService: ContextNodePaneActionsService,
        private translateService: TranslateService,
        private elasticSearchService: ElasticSearchService,
        private moduleService: ModuleService,
        private visibleAttributes: SearchProfileVisibleAttributesService,
        private chRef: ChangeDetectorRef,
        private db: AngularFirestore
    ) { }

    private searchFieldChange$ = new BehaviorSubject<{ value: string; reset?: boolean }>(undefined);

    results$: Observable<{ value: string; reset?: boolean }> = this.searchFieldChange$.pipe(
        distinctUntilChanged(),
        debounceTime(750)
    );

    showAutoComplete = false;
    loadingSearchForm = false;
    projects$: Observable<Project[]>;
    formsForProject$: Observable<Form[]>;
    projectChange$ = new BehaviorSubject<string>("");

    selectedFormId$ = new BehaviorSubject<string>("");
    selectedForm$ = this.selectedFormId$.pipe(switchMap(formId => {
        this.loadingSearchForm = false;
        this.searchInForm = false;

        if (formId && formId !== "") {
            this.searchInForm = true;
            return this.formService.form(formId, { listen: false });
        }

        return of(undefined);
    }));

    attributeValueGroupedSearchResults$: Observable<AttributeMeta[]> = this.results$.pipe(
        tap((_) => (this.genericLoading = false)),
        switchMap((d) => {
            if (d?.reset) {
                return of([]);
            } else if (d && d.value && d.value.length >= SEARCH_LENGTH_THRESHOLD) {
                return this.elasticSearchService.findAttributes(d.value).pipe(
                    tap((r) => {
                        if (r.length > 0) {
                            this.attributeResultsOpen = true;
                        } else {
                            this.attributeResultsOpen = false;
                        }
                    })
                );
            } else {
                return of([]);
            }
        })
    );

    labelResults$: Observable<LabelMeta[]> = this.results$.pipe(
        tap((_) => (this.genericLoading = false)),
        switchMap((d) => {
            if (d?.reset) {
                return of([]);
            } else if (d && d.value && d.value.length >= 3) {
                return this.elasticSearchService.findLabels(d.value).pipe(
                    tap((r) => {
                        if (r.length > 0) {
                            this.labelsResultsOpen = true;
                        } else {
                            this.labelsResultsOpen = false;
                        }
                    })
                );
            } else {
                return of([]);
            }
        })
    );

    labelsResultsOpen = false;
    attributeResultsOpen = false;

    genericLoading = false;

    selectedProjectId = "";
    selectedFormId = "";
    searchInForm = false;

    projectFormSearchOpen = false;

    sinceDate: Date;
    sinceDateTime: Time;

    idfig: string = "";
    fullname: string = "";
    fulltextsearch: string = "";

    private readonly _defaultDateFormElement = new FormDatetime({
        id: guid(),
        name: "createdAt",
        label: {
            fr: "Date de création",
            en: "Creation Date"
        },
    })

    private readonly _sopDateCreated = new SearchOperation({
        id: guid(),
        operand: "createdAt",
        operator: "greaterThan",
        source: this.defaultDateFormElement,
        value: null
    });

    ngOnInit(): void {
        this.projects$ = this.projectService.activeProjects();
        this.formsForProject$ = this.projectChange$.pipe(
            switchMap((projectId) => {
                if (projectId !== "") {
                    return this.formService.formForProject(projectId);
                } else {
                    return of([]);
                }
            })
        );
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.projectId) {
            this.projectFormSearchOpen = true;
            this.selectedProjectId = changes.projectId?.currentValue && changes.projectId?.currentValue !== "" ? changes.projectId?.currentValue : "";
            this.onProjectChange(this.selectedProjectId);
        }

        if (changes.searchGroup?.currentValue) {
            const searchGroup: SearchGroup = changes.searchGroup?.currentValue;
            if (searchGroup && searchGroup.hasFormSearchOperation()) {
                const sopForm = searchGroup.searchOperations.find(sop => sop.operand.startsWith("recruitment_"))
                const formId = sopForm.operand.replace("recruitment_", "");
                if (sopForm && formId !== this.selectedFormId) {
                    setTimeout(() => {
                        this.selectedFormId = formId;
                        this.selectedFormId$.next(formId);
                        if (sopForm.value && sopForm.operator === "greaterThan") {
                            this.sinceDate = sopForm.value;
                            this.sinceDateTime = this.time(this.sinceDate);
                        }
                        this.chRef.detectChanges();
                    }, 500);
                }
            }
        }
    }

    onChangeCreatedDate(createdDate: Date): void {
        // this.searchGroup = this.searchGroup.replaceAddSearchOperations([new SearchOperation({
        //     id: guid(),
        //     operand: "createdAt",
        //     operator: "greaterThan",
        //     value: createdDate
        // })]);
        // this.editGroup.emit(this.searchGroup);


    }

    onTimeChange(time: Time): void {
        this.sinceDate = new Date(this.sinceDate.getFullYear(), this.sinceDate.getMonth(), this.sinceDate.getDate(), time.hour, time.minute);
        this.sinceDateTime = this.time(this.sinceDate);
        this.searchGroup = this.searchGroup.addFormSearchOperation(this.selectedFormId, { sinceDateSubmision: this.sinceDate });
        this.editGroup.emit(this.searchGroup);
    }

    get sopDateCreated(): SearchOperation {
        return this._sopDateCreated;
    }

    time(sinceDate: Date): Time {
        if (sinceDate) {
            return {
                hour: sinceDate.getHours(),
                minute: sinceDate.getMinutes()
            }
        }
        return null;
    }

    filterFormParameter(sop: SearchOperation): boolean {
        return !sop.operand?.startsWith("recruitment_");
    }

    get defaultDateFormElement(): FormElement {
        return this._defaultDateFormElement;
    }

    onDateCreatedValueChange(sop: SearchOperation): void {
        const hasValue = (sop.operator === "range" && sop.value[0] && sop.value[1]) || (sop.value && !Array.isArray(sop.value) && sop.value !== "");
        if (hasValue) {
            this.searchGroup = this.searchGroup.replaceAddSearchOperations([sop]);
            this.editGroup.emit(this.searchGroup);
        } else if (this.searchGroup.hasOperationOperand("createdAt")) {
            this.searchGroup = this.searchGroup.removeSearchOperationOperand("createdAt");
            this.editGroup.emit(this.searchGroup);
        }
    }

    onIdfigChange(value: string): void {
        this.searchGroup = this.searchGroup.clearSearchOperations();
        this.searchGroup = this.searchGroup.replaceAddSearchOperations([new SearchOperation({
            id: guid(),
            operand: "idfig",
            operator: "exact",
            source: new FormTextbox({
                id: guid(),
                name: "idfig",
                label: {
                    fr: "idfig",
                    en: "idfig"
                },
                type: "textbox"
            }),
            value
        })]);
        this.editGroup.emit(this.searchGroup);
        this.idfig = "";
    }

    onFullnameChange(value: string): void {
        this.searchGroup = this.searchGroup.replaceAddSearchOperations([new SearchOperation({
            id: guid(),
            operand: "fullname",
            operator: "query",
            source: new FormTextbox({
                id: guid(),
                name: "fullname",
                label: {
                    fr: "Nom et/ou prénom",
                    en: "Fullname"
                },
                type: "textbox"
            }),
            value
        })]);
        this.editGroup.emit(this.searchGroup);
        this.fullname = "";
    }

    onFullTextSearch(value: string): void {
        this.searchGroup = this.searchGroup.change({
            fullTextSearch: new SearchOperation({
                id: guid(),
                operand: null,
                operator: "query",
                value
            })
        });
        this.editGroup.emit(this.searchGroup);
        this.fulltextsearch = "";
    }

    onFullTextSearchKeyup(value: string): void {
        if (value?.length > SEARCH_LENGTH_THRESHOLD) {
            this.genericLoading = true;
            this.showAutoComplete = true;
            this.searchFieldChange$.next({ value });
        } else {
            this.showAutoComplete = false;
        }

    }

    onRemoveGroup(): void {
        this.removeGroup.emit();
    }

    onAddOrSearchGroup(): void {
        this.addOrSearchGroup.emit(this.searchGroup);
    }

    onProjectChange(projectId: string): void {
        if (projectId !== this.projectId) {
            this.projectChange.emit(projectId);
        }
        this.sinceDate = null;
        this.sinceDateTime = null;
        this.projectChange$.next(projectId);
    }

    openSearchInForm(): void {
        if (!this.isOpenSearchInFormDisabled) {
            this.searchInForm = true;
            this.chRef.detectChanges();
        }
    }

    onFavoritesSearch(group: SearchGroup): void {
        this.searchGroup = group;
        this.editGroup.emit(this.searchGroup);
    }

    onSearchOperationAdd(sop: SearchOperation): void {
        this.searchGroup = this.searchGroup.replaceAddSearchOperations([sop]);
        this.editGroup.emit(this.searchGroup);
    }

    onViewGroupResults(groupId: string): void {
        this.viewGroupResults.emit(groupId);
    }

    // onSearchField(event: KeyboardEvent): void {
    // const value = (event.target as HTMLInputElement).value;

    // if (event.key === "Enter") {
    //     this.searchGroup = this.searchGroup.changeFullTextValue(value);
    //     this.editGroup.emit(this.searchGroup);
    //     this.searchFieldValue = "";
    //     this.searchFieldChange$.next({ value, reset: true });
    // } else if (value !== this.searchFieldChange$.value?.value) {
    //     if (value.length > SEARCH_LENGTH_THRESHOLD) this.genericLoading = true;
    //     this.searchFieldChange$.next({ value });
    // }
    // }

    onSearchFormChange(formId: string): void {
        this.loadingSearchForm = true;
        this.selectedFormId$.next(formId);
        this.visibleAttributes.clear();
        this.sinceDate = null;
        if (formId === "") {
            this.searchGroup = this.searchGroup.removeFormSearchOperations();
        } else {
            this.searchGroup = this.searchGroup.addFormSearchOperation(this.selectedFormId, { sinceDateSubmision: this.sinceDate });
        }
        this.editGroup.emit(this.searchGroup);
    }

    onGroupDisabledChange(enabled: boolean): void {
        if (this.searchGroup.disabled === enabled) {
            if (enabled) {
                this.searchGroup = this.searchGroup.enable();
            } else {
                this.searchGroup = this.searchGroup.disable();
            }

            this.editGroup.emit(this.searchGroup);
        }
    }

    onAddToFavorite(formElement: FormElement): void {
        this.addToFavorite.emit(formElement);
    }

    onSearchKeyup(event: KeyboardEvent): void {
        const value = (event.target as HTMLInputElement).value;
        if (event.key === "Enter") {
            this.searchGroup = this.searchGroup.changeFullTextValue(value);
            this.editGroup.emit(this.searchGroup);
        }
    }

    openFormPane(formId: string): void {
        this.actionPaneService.openFormPane(formId, this.contextNodeId);
    }

    // onAddFormSearchOperation(): void {
    //     this.searchGroup = this.searchGroup.addFormSearchOperation(this.selectedFormId, { sinceDateSubmision: this.sinceDate });
    //     this.editGroup.emit(this.searchGroup);
    // }

    onSinceDateChange(event: Date): void {
        this.sinceDate = event;
        this.sinceDateTime = this.time(this.sinceDate);
        this.searchGroup = this.searchGroup.addFormSearchOperation(this.selectedFormId, { sinceDateSubmision: this.sinceDate });
        this.editGroup.emit(this.searchGroup);
    }

    onEraseSinceDate(): void {
        this.sinceDate = null;
        this.sinceDateTime = this.time(this.sinceDate);
        this.searchGroup = this.searchGroup.addFormSearchOperation(this.selectedFormId, { sinceDateSubmision: null });
        this.editGroup.emit(this.searchGroup);
    }

    get isOpenSearchInFormDisabled(): boolean {
        return this.selectedFormId === '' || this.selectedProjectId === ''
    }

    addSearchOperationForIndex(index: string): void {
        const fe: FormElement = this.moduleService.formElementForIndex(index);

        const sop = new SearchOperation({
            id: this.db.createId(),
            operand: index,
            operator: "query",
            source: fe || new FormTextbox({
                id: guid(),
                name: index,
                label: {
                    fr: index,
                    en: index
                },
                type: "textbox"
            }),
            value: this.searchFieldChange$.value?.value && this.searchFieldChange$.value?.value !== "" ? this.searchFieldChange$.value?.value : null,
        });

        this.searchGroup = this.searchGroup.replaceAddSearchOperations([sop]);
        this.editGroup.emit(this.searchGroup);
    }

    get lang(): string {
        return this.translateService.lang;
    }

    onContextNodeOperationAddReplace(searchOperation: SearchOperation): void {
        this.searchGroup = this.searchGroup.replaceAddSearchOperations([searchOperation]);
        this.editGroup.emit(this.searchGroup);
    }

    trackById(_: number, item: any): string {
        return item.id;
    }

    trackByAttributeId(any: number, entity: AttributeMeta): string {
        return entity.attributeId;
    }

    trackByAttributeName(any: number, entity: LabelMeta): string {
        return entity.attributeName;
    }

    onSelectAllFolder(sops: SearchOperation[]): void {
        this.searchGroup = this.searchGroup.replaceAddSearchOperations(sops);
        this.editGroup.emit(this.searchGroup);
    }

    onSearchElementChange(sop: SearchOperation): void {
        this.searchGroup = this.searchGroup.replaceAddSearchOperations([sop]);
        this.editGroup.emit(this.searchGroup);
    }

    onRemoveSearchOperation(sop: SearchOperation): void {

        this.visibleAttributes.removeAttribute(sop.operand);
        this.searchGroup = this.searchGroup.removeSearchOperation(sop.id);
        this.editGroup.emit(this.searchGroup);
    }
}
