<audio #audioElement [src]="audioUrl" (playing)="onPlay()" (pause)="onPause()" (ended)="onEnd()"
    (loadedmetadata)="onLoaded()"></audio>
<div class="audio-wrapper">
    <div class="play" (click)="onClick()">
        <clr-icon shape="play" *ngIf="(isPlaying$ | async)===false"></clr-icon>
        <clr-icon shape="pause" *ngIf="isPlaying$ | async"></clr-icon>
    </div>
    <div class="time" *ngIf="isPlaying$ | async">
        {{ currentTime$ | async }} / {{ duration }}
    </div>
</div>