import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contact } from 'src/modules/diversite/model/contact';

const DEFAULT_CONTEXT: ContactUpdateContext = {
    type: "deactivation",
    reason: "",
    reasonOther: "",
    additionalInfo: "",
};

@Component({
    selector: "diversite-suppress-contact-prompt",
    templateUrl: "./suppress-contact-prompt.component.html",
    styleUrls: ["./suppress-contact-prompt.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuppressContactPromptComponent implements OnInit {
    effacer = "";
    @Input() contact: Contact;
    @Output() contactSuppress = new EventEmitter<SuppressContactEvent>();

    context: ContactUpdateContext = DEFAULT_CONTEXT;

    constructor(private chRef: ChangeDetectorRef) {}

    ngOnInit(): void {}

    confirmSuppress(): void {
        this.contactSuppress.emit({ contact: this.contact, context: this.context });
        this.context = DEFAULT_CONTEXT;
    }

    onReasonChange(event: Event): void {
        if (this.context.reason !== "other") {
            this.context.reasonOther = "";
        }
        setTimeout(() => {
            this.chRef.detectChanges();
        });
    }
}

export interface SuppressContactEvent {
    contact: Contact;
    context?: ContactUpdateContext;
}

export interface ContactUpdateContext {
    type: "deactivation" | "edit" | "reactivation";
    reason?: string;
    reasonOther?: string;
    additionalInfo?: string;
}
