import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { FileUploadConnector } from './file-upload-connector';
import { UploadFileResponse, UploadFilesResponse } from './file-upload.service';

@Injectable({
    providedIn: "root",
})
export class FileUploadVideoService {
    constructor() { }

    uploadFiles(files: File[]): Observable<UploadFilesResponse> {
        const obs$ = new Observable<UploadFilesResponse>((subscriber) => {
            const uploadSubs$ = files.map((file, i: number) => {
                const fileUploadConnector = new FileUploadConnector("input-videos");
                return fileUploadConnector.uploadFile(file);
            });

            const totalSize: number = files.map((file) => file.size).reduce((accumulator, curr) => accumulator + curr);
            return combineLatest(uploadSubs$)
                .pipe(
                    map((responses: UploadFileResponse[]) => {
                        const uploadBytes = responses
                            .map((response) =>
                                response.loadedBytes || response.loadedBytes === 0
                                    ? response.loadedBytes
                                    : response.asset.file.size
                            )
                            .reduce((accumulator, curr) => accumulator + curr);
                        return {
                            percentage: Math.round((uploadBytes / totalSize) * 100),
                            urls: responses.map((response) => {
                                return response.asset.finalUrl;
                            }),
                        };
                    }),
                    tap((response) => {
                        subscriber.next(response);
                        if (response.percentage === 100) {
                            subscriber.complete();
                        }
                    })
                )
                .subscribe();
        });

        return obs$;
    }
}
