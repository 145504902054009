import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormOption } from 'src/modules/diversite/model/form/form-element/form-option';

import { SearchValue, SearchValueOption, SearchValueSelectorOption } from '../../search-value-selector.component';

@Component({
    selector: "search-value-mulitple-selection",
    templateUrl: "./search-value-mulitple-selection.component.html",
    styleUrls: ["./search-value-mulitple-selection.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchValueMulitpleSelectionComponent implements OnInit {
    @Input() searchValue: SearchValue;
    @Input() lang: string;
    @Input() options: SearchValueSelectorOption;
    @Output() searchValueChange = new EventEmitter<SearchValue>();

    constructor(private chRef: ChangeDetectorRef) { }

    ngOnInit(): void {

    }

    trackById(_: any, item: FormOption): string {
        return `${item.id}${item.value ? item.value : ""}`;
    }

    isChecked(opt: SearchValueOption): boolean {
        if (this.searchValue && Array.isArray(this.searchValue.value)) {
            return this.searchValue.value.find((v) => v === opt.value);
        } else {
            return false;
        }
    }

    onChange(opt: SearchValueOption): void {

        let currentValue = this.searchValue.value;

        if (currentValue && Array.isArray(currentValue)) {
            if (currentValue.find((v) => v === opt.value)) {
                currentValue = currentValue.filter((v) => v !== opt.value);
            } else {
                currentValue = [...currentValue, opt.value];
            }
        } else {
            currentValue = [opt.value];
        }

        if (currentValue.length === 0) {
            currentValue = null;
        }

        this.searchValueChange.emit({ ...this.searchValue, value: currentValue });
    }
}
