<div class="contact-content" [class.selected]="selected$ | async">
    <diversite-photo-cover [url]="imageUrl" />
    <div class="info">
        <div class="idfig-age">
            <small>{{ contact.idfig }}</small>
            <small class="age">{{ contact.ageLiteral }}</small>
        </div>
        {{ contact.fullname }}
    </div>
</div>
