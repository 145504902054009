<div [class.no-label]="!options.showLabel" *ngIf="formElement" class="imageupload-container clr-form-control">
    <label *ngIf="options.showLabel" class="clr-control-label"
        >{{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>
    <clr-radio-container clrInline>
        <clr-radio-wrapper>
            <input type="radio" clrRadio name="optionSourceType" required value="file" [(ngModel)]="videoSourceType" />
            <label>{{ label("fileRadioOptionText") }}</label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
            <input
                type="radio"
                clrRadio
                name="optionSourceType"
                required
                value="siteUrl"
                [(ngModel)]="videoSourceType"
            />
            <label>{{ label("urlRadioOptionText") }}</label>
        </clr-radio-wrapper>
    </clr-radio-container>

    <div *ngIf="videoSourceType === 'siteUrl'">
        <clr-input-container>
            <input class="input-url" #videoUrlElement type="text" clrInput placeholder="Site youtube ou vimeo" />
        </clr-input-container>
        <button (click)="addVideoSite(videoUrlElement.value)" class="btn btn-sm btn-primary">
            {{ label("addVideoLinkButton") }}
        </button>
    </div>

    <div *ngIf="videoSourceType === 'file'" class="clr-file-wrapper">
        <label class="clr-control-label">
            <span class="btn btn-primary btn-sm" [class.disabled]="uploading || formElement.readonly">{{
                label("addVideosButtonText")
            }}</span>
            <input
                [disabled]="uploading || formElement.readonly"
                [id]="formElement.id"
                (change)="onInputChange($event)"
                accept=".mp4,.hevc,.mov"
                class="clr-file"
                type="file"
                multiple
                [name]="formElement.name"
                [required]="formElement.required"
            />
        </label>
        <small>{{ label("formatExplanationText") }}</small>
    </div>

    <div [hidden]="!uploading" class="loading">
        <small class="text"
            >{{ label("uploadingText") }}<span class="spinner spinner-sm"> {{ label("uploadingText") }} </span></small
        >

        <div class="progress-static labeled">
            <div class="progress-meter" [attr.data-value]="percentage" [attr.data-displayval]="percentage"></div>
            <span>{{ percentage }}%</span>
        </div>
    </div>

    <div class="videos">
        <div *ngFor="let video of this.videoUrls; let i = index" class="video">
            <video-player [url]="video"></video-player>
            <cds-icon *ngIf="!formElement.readonly" (click)="deleteImage(i)" shape="times"></cds-icon>
        </div>
    </div>
</div>
