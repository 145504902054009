<clr-textarea-container [class.no-label]="!options.showLabel" *ngIf="formElement">
    <label *ngIf="options.showLabel">
        {{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span>
        <ng-content select="[tooltip-content]"></ng-content>
    </label>
    <textarea
        [value]="value"
        (keyup)="onInputChange($event)"
        clrTextarea
        [name]="formElement.name"
        [readonly]="formElement.readonly"
        [required]="formElement.required"
    ></textarea>
</clr-textarea-container>
