import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { Report } from "src/modules/diversite/model/report";
import { ReportService } from "src/modules/diversite/services/report.service";

@Component({
    selector: "app-report-print-container",
    templateUrl: "./report-print-container.component.html",
    styleUrls: ["./report-print-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportPrintContainerComponent implements OnInit {
    constructor(private reportService: ReportService, private route: ActivatedRoute) {}

    report$: Observable<Report>;

    ngOnInit(): void {
        this.report$ = this.reportService.reportById(this.route.snapshot.params.report, { listen: false });
    }
}
