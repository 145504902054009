<div *ngIf="!collapsed" class="header-container" [class.locked]="locked" [class.action]="paneType === 'action'">
    <div class="actions left">
        <cds-icon *ngIf="closable" size="sm" shape="times" (click)="deletePane()"></cds-icon>
        <cds-icon *ngIf="pinable" size="sm" [shape]="locked ? 'lock' : 'unlock'" (click)="lockPane()"></cds-icon>
    </div>
    <div class="text-title">
        <span *ngIf="forceHeaderName">{{ forceHeaderName }}</span>

        <ng-container *ngIf="!forceHeaderName">
            <span *ngFor="let title of panePath; last as isLast">
                <ng-container *ngIf="!title.searchOperation">
                    {{ title.name }}<span *ngIf="!isLast">&nbsp;/&nbsp;</span>
                </ng-container>
                <ng-container *ngIf="title.searchOperation">
                    <diversite-search-definition-tag
                        [removable]="false"
                        [disabled]="false"
                        [editable]="false"
                        [searchOperation]="title.searchOperation"
                    ></diversite-search-definition-tag>
                </ng-container>
            </span>
        </ng-container>
    </div>
    <div class="actions right">
        <!-- <input *ngIf="paneType !== 'treeview'" type="range" (change)="onPaneSizeChange()" [(ngModel)]="paneSize.size" /> -->
        <cds-icon *ngIf="paneType !== 'treeview'" (click)="maximize()" size="sm" shape="landscape"></cds-icon>
        <cds-icon
            *ngIf="paneType !== 'action'"
            [class.disabled]="collapseDisabled"
            (click)="toggleCollapsePane()"
            size="sm"
            shape="angle"
            [direction]="'left'"
        ></cds-icon>
    </div>
</div>

<div
    *ngIf="collapsed"
    class="header-container-collapsed vertical"
    [class.locked]="locked"
    [class.action]="paneType === 'action'"
    (click)="toggleCollapsePane()"
>
    <div class="title">
        <cds-icon *ngIf="closable" size="sm" shape="times" (click)="deletePane()"></cds-icon>
        <span *ngFor="let title of panePath; last as isLast" class="text-title">
            <ng-container *ngIf="!title.searchOperation">
                {{ title.name }}<span *ngIf="!isLast">&nbsp;/&nbsp;</span>
            </ng-container>
            <ng-container *ngIf="title.searchOperation">
                <diversite-search-definition-tag
                    [removable]="false"
                    [disabled]="false"
                    [editable]="false"
                    [searchOperation]="title.searchOperation"
                ></diversite-search-definition-tag>
            </ng-container>
        </span>
    </div>
</div>
