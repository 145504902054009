import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormDropdown } from 'src/modules/diversite/model/form/form-element/form-dropdown';

import { DEFAULT_OPTIONS, FormElementGenericOptions } from '../../form-element.component';

@Component({
    selector: "fillout-dropdown",
    templateUrl: "./dropdown.component.html",
    styleUrls: ["./dropdown.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnInit, OnChanges {
    @Input() lang: string;
    @Input() formElement: FormDropdown;
    @Input() value = "";
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Output() responseChange = new EventEmitter<string>();
    delayedChanges$ = new BehaviorSubject<string>(undefined);

    private _disposeBag = new DisposeBag();

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && !changes.value.currentValue) {
            this.value = "";
        }
    }

    ngOnInit(): void {
        this.delayedChanges$
            .pipe(debounceTime(250), skip(1))
            .subscribe((v) => {
                this.responseChange.emit(v);
            })
            .disposedBy(this._disposeBag);
    }

    onInputChange(event: KeyboardEvent): void {
        this.delayedChanges$.next(this.value);
    }
}
