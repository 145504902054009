import { Injectable } from '@angular/core';
import { guid, isString } from 'src/app/core/functions';
import {
    SearchValueType,
} from 'src/modules/diversite/components/search-form-elements/search-form-element/search-value-type.service';
import { Operator, SearchOperation } from 'src/modules/diversite/model/search-operation';

import { SearchValue, SearchValueOption } from './search-value-selector.component';

@Injectable({
    providedIn: "root",
})
export class SearchOperationStrategyService {
    private strategyValueForType = new Map<SearchValueType, (s: SearchValue, options?: SearchValueOption[]) => any>([
        ["age", (searchValue) => this.ageValueStrategy(searchValue)],
        ["phone", (searchValue) => this.phoneValueStrategy(searchValue)],
        ["union", (searchValue) => this.unionValueStrategy(searchValue)],
        ["weight", (searchValue) => this.rangeValueStrategy(searchValue)],
        ["height", (searchValue) => this.rangeValueStrategy(searchValue)],
        ["date", (searchValue) => this.dateValueStrategy(searchValue)],
        ["availability", (searchValue, options) => this.availabilityValueStrategy(searchValue, options)],
        ["multiple-selection", (searchValue) => this.multipleSelectionValueStrategy(searchValue)],
        ["single-selection", (searchValue) => this.multipleSelectionFromSingleChoiceSourceValueStrategy(searchValue)],
    ]);

    private defaultOperatorForType = new Map<SearchValueType, Operator>([
        ["age", "range"],
        ["number", "greaterThan"],
        ["phone", "exists"],
        ["union", "boolean"],
        ["weight", "range"],
        ["height", "range"],
        ["availability", "query"],
        ["multiple-selection", "or"],
        ["single-selection", "or-single-source"],
    ]);

    private specificOperandsForName = new Map<string, string[]>([
        ["affiliation_uda", ["isUda"]],
        ["affiliation_actra", ["isActra"]],
    ]);

    constructor() { }

    defaultSearchValueForType(type: SearchValueType): SearchValue {
        return this.searchValueForSearchOperation(null, type);
    }

    searchValueForSearchOperation(searchOperation: SearchOperation, type: SearchValueType): SearchValue {
        if (searchOperation) {
            if (type === "age") {
                return this.searchValueForAgeSearchOperation(searchOperation);
            }

            if (type === "height" || type === "weight") {
                return this.searchValueForHeightWeightSearchOperation(searchOperation);
            }

            return this.searchValueDefaultForSearchOperation(searchOperation);
        } else {
            // return default
            const def: SearchValue = {
                operator: this.defaultOperatorForType.get(type) ? this.defaultOperatorForType.get(type) : "query",
                value: null,
                valueMin: null,
                valueMax: null,
            };
            return def;
        }
    }

    private searchValueDefaultForSearchOperation(searchOperation: SearchOperation): SearchValue {
        const value = searchOperation.value;
        const valueMin = this.valueForSearchOperations(searchOperation, "min");
        const valueMax = this.valueForSearchOperations(searchOperation, "max");

        const searchValue = {
            value,
            valueMin,
            valueMax,
            operator: searchOperation.operator,
        };
        return searchValue;
    }

    private searchValueForHeightWeightSearchOperation(searchOperation: SearchOperation): SearchValue {
        const value = this.valueForSearchOperationsHeightWeight(searchOperation);
        const valueMin = this.valueForSearchOperationsHeightWeight(searchOperation, "min");
        const valueMax = this.valueForSearchOperationsHeightWeight(searchOperation, "max");
        const operator: Operator = valueMin && valueMax ? "range" : valueMax ? "lowerThan" : "greaterThan";
        const searchValue = {
            value,
            valueMin,
            valueMax,
            operator,
        };
        return searchValue;
    }

    private searchValueForAgeSearchOperation(searchOperation: SearchOperation): SearchValue {
        const valueMin = this.valueForSearchOperations(searchOperation, "min");
        const valueMax = this.valueForSearchOperations(searchOperation, "max");
        const operator: Operator = valueMin && valueMax ? "range" : valueMax ? "greaterThan" : "lowerThan";

        let searchValue = {
            value: null,
            valueMax: valueMin ? new Date().getFullYear() - valueMin.getFullYear() - 1 : null,
            valueMin: valueMax ? new Date().getFullYear() - valueMax.getFullYear() : null,
            operator,
        };
        return searchValue;
    }

    private dateValueStrategy(searchValue: SearchValue): any {
        if (searchValue?.operator === "range") {
            return [searchValue.valueMin, searchValue.valueMax];
        }
        return searchValue.value;
    }

    assignSearchValueToSearchOperations(
        searchOperation: SearchOperation,
        type: SearchValueType,
        searchValue: SearchValue,
        options?: SearchValueOption[]
    ): SearchOperation {
        const valueTypeStrategy = this.strategyValueForType.get(type);
        let finalValue = valueTypeStrategy ? valueTypeStrategy(searchValue, options) : searchValue.value;

        if (searchOperation) {
            let operator: Operator = searchOperation.operator;

            if (type === "age") {
                operator =
                    searchValue.valueMin && searchValue.valueMax
                        ? "range"
                        : searchValue.valueMin
                            ? "lowerThan"
                            : "greaterThan";

                if (operator === "range") {
                    finalValue = finalValue.reverse();
                }
            }

            if (type === "date") {
                operator = searchValue.operator === "range" ? "range" : searchValue.operator;
            }

            if (type === "weight" || type === "height") {
                operator =
                    searchValue.valueMin && searchValue.valueMax
                        ? "range"
                        : searchValue.valueMax
                            ? "lowerThan"
                            : "greaterThan";
            }

            if (type === "union") {
                operator = isString(finalValue) ? "query" : "boolean";
            }

            return searchOperation.change({
                value: finalValue,
                operator,
            });
        } else {
            const finalSop = this.defaultSearchOperation(type, searchValue).change({ value: finalValue });
            return finalSop;
        }
    }

    private defaultSearchOperation(type: SearchValueType, searchValue: SearchValue): SearchOperation {
        let operator = searchValue.operator ? searchValue.operator : this.defaultOperatorForType.get(type);

        // add this in the defaultOperatorForType map
        if (type === "age") {
            operator =
                searchValue.valueMin && searchValue.valueMax
                    ? "range"
                    : searchValue.valueMin
                        ? "lowerThan"
                        : "greaterThan";
        }
        // end of comment
        if (type === "weight" || type === "height") {
            operator =
                searchValue.valueMax && searchValue.valueMin
                    ? "range"
                    : searchValue.valueMax
                        ? "lowerThan"
                        : "greaterThan";
        }

        return new SearchOperation({ id: guid(), operator: operator ? operator : "query", value: null });
    }

    searchOperandsForFormElementName(formElementName: string): string[] {
        const specificOperands = this.specificOperandsForName.get(formElementName);
        if (specificOperands) {
            return specificOperands;
        }
        return [formElementName];
    }

    valueForSearchOperations(searchOperation: SearchOperation, minMax?: "min" | "max") {
        if (searchOperation) {
            // if (searchOperation.operator !== "range") {
            //     return searchOperation.value;
            // }
            if (minMax === "min") {
                if (searchOperation.operator === "range") {
                    return searchOperation.value[0];
                }
                if (searchOperation.operator === "greaterThan") {
                    return searchOperation.value;
                }
            }
            if (minMax === "max") {
                if (searchOperation.operator === "range") {
                    return searchOperation.value[1];
                }
                if (searchOperation.operator === "lowerThan") {
                    return searchOperation.value;
                }
            }
        }
        return null;
    }
    valueForSearchOperationsHeightWeight(searchOperation: SearchOperation, minMax?: "min" | "max") {
        if (searchOperation) {
            if (minMax === "min") {
                if (searchOperation.operator === "range") {
                    return searchOperation.value[0];
                }
                if (searchOperation.operator === "greaterThan") {
                    return searchOperation.value;
                }
            }
            if (minMax === "max") {
                if (searchOperation.operator === "range") {
                    return searchOperation.value[1];
                }
                if (searchOperation.operator === "lowerThan") {
                    return searchOperation.value;
                }
            }
        }
        return null;
    }

    phoneValueStrategy(searchValue: SearchValue): any {
        return searchValue.value;
    }

    private ageValueStrategy(searchValue: SearchValue): any {
        const data: any = {};
        if ((searchValue.valueMin || searchValue.valueMin === 0) && searchValue.valueMin !== "") {
            data.min = searchValue.valueMin;
        }
        if ((searchValue.valueMax || searchValue.valueMax === 0) && searchValue.valueMax !== "") {
            data.max = searchValue.valueMax;
        }

        const now = new Date();
        const min = data.max ? new Date(now.getFullYear() - data.max - 1, now.getMonth(), now.getDate()) : null;
        const max = data.min ? new Date(now.getFullYear() - data.min, now.getMonth(), now.getDate()) : null;

        if (min && max) {
            return [max, min];
        } else if (min) {
            return min;
        } else if (max) {
            return max;
        } else {
            return null;
        }
    }

    unionValueStrategy(searchValue: SearchValue): any {
        if (searchValue) {
            return searchValue.value;
        } else {
            return null;
        }
    }

    rangeValueStrategy(searchValue: SearchValue): any {
        if (searchValue.valueMin && searchValue.valueMax) {
            return [searchValue.valueMin, searchValue.valueMax];
        } else if (searchValue.valueMin) {
            return searchValue.valueMin;
        } else if (searchValue.valueMax) {
            return searchValue.valueMax;
        } else {
            return null;
        }
    }

    multipleSelectionValueStrategy(searchValue: SearchValue): SearchOperation[] {
        return searchValue.value;
    }

    multipleSelectionFromSingleChoiceSourceValueStrategy(searchValue: SearchValue): SearchOperation[] {
        return searchValue.value;
    }

    private availabilityValueStrategy(searchValue: SearchValue, options: SearchValueOption[]): any {
        console.log("TODO");
        // return defaultSearchOperation.value.map((dateRange) => {
        //     return new SearchOperation({
        //         id: defaultSearchOperation.id || guid(),
        //         type: "availability",
        //         value: dateRange,
        //         occurance: defaultSearchOperation.occurance,
        //     });
        // });
    }
}
