import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { ModuleMapperService } from '../mappers/module-mapper.service';
import { AttributeIndex } from '../model/contact-profile-definition';
import { FormElement, FormElementInput } from '../model/form/form-element/form-element';
import { FormOption } from '../model/form/form-element/form-option';

@Injectable({
    providedIn: "root",
})
export class AttributeIndexFormElementMapperService {
    constructor(private db: AngularFirestore, private moduleMapper: ModuleMapperService) {}

    attributeIndexToFormElement(attr: AttributeIndex): FormElement {
        if (attr) {
            return this.moduleMapper.formElementDtoToFormElement({
                id: this.db.createId(),
                name: attr.index,
                nameDisabled: true,
                nameDescriptor: attr.label,
                type: attr.formField?.type || "textbox",
                label: attr.label,
                options: this.options(attr.formField?.options),
                required: attr.formField?.required || false,
            });
        }
        return undefined;
    }

    formElementToAttributeIndex(formElement: FormElementInput, moduleId: string = null): AttributeIndex {
        const formElementDto = formElement.toDto();

        return {
            index: formElement.name,
            label: formElement.label,
            defaultDescription: null,
            order: Infinity,
            module: moduleId,
            formField: {
                type: formElement.type,
                required: false,
                options: formElementDto.options && Array.isArray(formElement.options) ? formElementDto.options : null,
            },
        };
    }

    private options(options: FormOption[]): FormOption[] {
        if (options) {
            return options;
        }
    }
}
