import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Contact } from 'src/modules/diversite/model/contact';

@Component({
    selector: "castlug-faces",
    templateUrl: "./faces.component.html",
    styleUrls: ["./faces.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacesComponent implements OnInit {
    @Input() images: string[] = [];

    layoutOptions = [
        "empty",
        "empty",
        "empty",
        "empty",
        "empty",
        "empty",
        "empty",
        "empty",
        "empty",
        "empty",
        "empty",
        "empty",

        "x11",
        "x11",
        "x11",
        "x11",
        "x11",
        "x11",
        "x11",
        "x11",
        "x11",
        "x11",
        "x11",
        "x11",
        "x11",
        "x11",
        "x11",
        "x11",

        "x22",
        "x22",
        "x22",
        "x22",
        "x22",
        "x22",
        "x22",
        "x22",
        "x22",
        "x22",
        "x22",
        "x22",

        "x33",
        "x33",
        "x33",
        "x33",
        "x33",
    ];

    constructor(private sanitizer: DomSanitizer, private chRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.layoutOptions.sort((_) => Math.random() - 0.5);
    }

    getBackgroundImage(imageUrl): SafeStyle {
        if (imageUrl) {
            return this.sanitizer.bypassSecurityTrustStyle(`url($ {})`);
        }
    }

    trackByFn(index: number, item: Contact): string {
        return item.id;
    }
}
