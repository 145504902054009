import { Injectable } from '@angular/core';
import { FormElement } from 'src/modules/diversite/model/form/form-element/form-element';

@Injectable({
    providedIn: "root",
})
export class SearchValueTypeService {
    private specificFieldType = new Map<string, SearchValueType>([
        ["identity_dateOfBirth", "age"],
        ["measurements_weight_metric", "weight"],
        ["measurements_height_metric", "height"],
    ]);

    constructor() {}

    valueType(formElement: FormElement): SearchValueType {
        const specificFieldType = this.specificFieldType.get(formElement.name);
        if (specificFieldType) {
            return specificFieldType;
        }

        return this.defaultValueType(formElement);
    }

    private defaultValueType(formElement: FormElement): SearchValueType {
        if (formElement.type === "textbox") {
            return "text";
        } else if (formElement.type === "textarea") {
            return "textarea";
        } else if (formElement.type === "number" || formElement.type === "height" || formElement.type === "weight") {
            return "number";
        } else if (formElement.type === "datetime") {
            return "date";
        } else if (formElement.type === "dropdown" || formElement.type === "radio") {
            return "single-selection";
        } else if (formElement.type === "checkbox") {
            return "multiple-selection";
        } else if (formElement.type === "union") {
            return "union";
        } else if (formElement.type === "phone") {
            return "phone";
        } else if (formElement.type === "availability") {
            return "availability";
        }
    }
}

export type SearchValueType =
    | "text"
    | "textarea"
    | "number"
    | "date"
    | "single-selection"
    | "multiple-selection"
    | "age"
    | "union"
    | "weight"
    | "height"
    | "phone"
    | "boolean"
    | "availability";
