import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundComponent implements OnInit {
    // file: File;
    // imageUrl: string;

    constructor(private chRef: ChangeDetectorRef) { }

    ngOnInit(): void {
    }


    // handleFileInput(event: any): void {
    //     if (event.target.files.length > 0) {
    //         this.file = event.target.files[0];
    //     } else {
    //         this.file = undefined;
    //     }
    // }

    // test(): void {
    //     const fileUploadService = new FileUploadService();
    //     fileUploadService.uploadFile(this.file).subscribe(imageUrl => {
    //         console.log(imageUrl);
    //         this.imageUrl = imageUrl;
    //         this.chRef.detectChanges();
    //     });
    // }
}
