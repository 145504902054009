import { Injectable } from "@angular/core";
import { AngularFirestore, DocumentReference } from "@angular/fire/compat/firestore";
import { from, Observable, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { getMinutesBetweenDates, guid } from "src/app/core/functions";
import { AuthService } from "src/app/services/auth.service";

// 2 hours
const LOCK_EXPIRATION_TIME_MINUTES = 15;

@Injectable({
    providedIn: "root",
})
export class FormLockService {
    constructor(private authService: AuthService, private db: AngularFirestore) {}

    lockForm(formId: string): Observable<LockResponse> {
        return this.db
            .collection("formLocks")
            .doc(formId)
            .get()
            .pipe(
                switchMap((doc) => {
                    const formLockDate = (doc.data() as any)?.date?.toDate();
                    const now = new Date();
                    const minutesBetween = getMinutesBetweenDates(formLockDate, now);
                    const lockByUserId = (doc.data() as any)?.user?.id;

                    if (
                        doc.exists &&
                        formLockDate &&
                        minutesBetween < LOCK_EXPIRATION_TIME_MINUTES &&
                        lockByUserId !== this.authService.loggedInUser.id
                    ) {
                        return of({ success: false, formLock: undefined, message: "Form already locked" });
                    } else {
                        const formLock: FormLock = {
                            formId,
                            hash: guid(),
                            date: new Date(),
                            user: this.db.collection("users").doc(this.authService.loggedInUser.id).ref,
                        };
                        return from(this.db.collection("formLocks").doc(formId).set(formLock)).pipe(
                            map((_) => {
                                return {
                                    success: true,
                                    formLock,
                                } as LockResponse;
                            })
                        );
                    }
                })
            );
    }

    validateLock(formId: string, formLockHash: string): Observable<ValidateLockResponse> {
        return this.db
            .collection("formLocks")
            .doc(formId)
            .get()
            .pipe(
                take(1),
                switchMap((doc) => {
                    if (doc.exists) {
                        const formLock: FormLock = {
                            ...(doc.data() as any),
                            date: (doc.data() as any)?.date?.toDate(),
                            user: (doc.data() as any)?.user,
                        };
                        const now = new Date();
                        const minutesBetween = getMinutesBetweenDates(formLock.date, now);

                        if (
                            formLock.hash === formLockHash &&
                            minutesBetween < LOCK_EXPIRATION_TIME_MINUTES &&
                            formLock.user.id === this.authService.loggedInUser.id
                        ) {
                            return from(this.db.collection("formLocks").doc(formId).update({ date: new Date() })).pipe(
                                map((_) => {
                                    return { success: true };
                                })
                            );
                        } else {
                            return of({ success: false, message: "Form lock invalid." });
                        }
                    } else {
                        return of({ success: false, message: "Form lock doesn't exist." });
                    }
                })
            );
    }

    unlockForm(formId: string): Promise<void> {
        return this.db.collection("formLocks").doc(formId).delete();
    }
}

export interface ValidateLockResponse {
    success: boolean;
    message?: string;
}

export interface FormLock {
    formId: string;
    hash: string;
    date: Date;
    user: DocumentReference;
}

export interface LockResponse {
    success: boolean;
    message?: string;
    formLock: FormLock;
}
