import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { debounceTime, skip } from "rxjs/operators";
import { guid } from "src/app/core/functions";
import { DisposeBag } from "src/modules/core/utilities/dispose-bag";
import { AvailabilityType, FormAvailability } from "src/modules/diversite/model/form/form-element/form-availability";
import { FormElement } from "src/modules/diversite/model/form/form-element/form-element";
import { FormOption } from "src/modules/diversite/model/form/form-element/form-option";
import { TranslatableLabel } from "src/modules/diversite/services/data-catalog.service";

declare var $: any;

@Component({
    selector: "diversite-form-availability-edition",
    templateUrl: "./form-availability-edition.component.html",
    styleUrls: ["./form-availability-edition.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormAvailabilityEditionComponent implements OnInit, AfterViewInit {
    @Input() lang: string;
    @Input() formElement: FormAvailability;
    @Input() readonly readonlyElement = false;
    @Output() formElementChange = new EventEmitter<FormElement>();
    @ViewChild("labelElement") labelElement: ElementRef;

    delayedChanges$ = new BehaviorSubject<void>(undefined);

    tempFormElement: {
        label: TranslatableLabel;
        readonly: boolean;
        required: boolean;
        availabilityType: AvailabilityType;
        options: FormOption[];
    } = {
        label: {},
        readonly: false,
        required: false,
        availabilityType: "predetermined",
        options: [],
    };

    private _disposeBag = new DisposeBag();
    constructor(private chRef: ChangeDetectorRef, private hostElement: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.lang && changes.lang.currentValue) {
            this.tempFormElement.label = {};
            this.tempFormElement.options = [];
            this.chRef.detectChanges();
            this.tempFormElement.label = { ...this.initLabel() };
            this.tempFormElement.options = this.formElement.options;
            this.chRef.detectChanges();
        }
    }

    ngAfterViewInit(): void {
        $(".options", this.hostElement.nativeElement).sortable({
            handle: ".drag-field",
            update: (event, ui) => {
                const indexes = $("[data-index]", event.target)
                    .toArray()
                    .map((e) => Number(e.getAttribute("data-index")));

                this.tempFormElement.options = indexes.map((index) => this.tempFormElement.options[index]);
                this.delayedChanges$.next();
                $(".options", this.hostElement.nativeElement).sortable("disable");
            },
        });
    }

    private initLabel(): TranslatableLabel {
        const initLabel: TranslatableLabel = { ...this.formElement.label };
        initLabel[this.lang] =
            this.formElement.label && this.formElement.label[this.lang] ? this.formElement.label[this.lang] : "";
        return initLabel;
    }

    ngOnInit(): void {
        this.tempFormElement = {
            label: this.initLabel(),
            readonly: this.formElement ? this.formElement.readonly : false,
            required: this.formElement ? this.formElement.required : false,
            availabilityType: this.formElement ? this.formElement.availabilityType : "predetermined",
            options: this.formElement ? this.formElement.options : [],
        };
        this.chRef.detectChanges();
        this.delayedChanges$
            .pipe(debounceTime(500), skip(1))
            .subscribe((_) => {
                const label = { ...this.formElement.label };
                label[this.lang] = this.labelElement.nativeElement.textContent;

                this.formElement = this.formElement.change({
                    label,
                    readonly: this.tempFormElement.readonly,
                    required: this.tempFormElement.required,
                    availabilityType: this.tempFormElement.availabilityType,
                    options: this.tempFormElement.options.map((opt) => {
                        return {
                            ...opt,
                            value: { ...opt.value, from: opt.value?.from || null, to: opt.value?.to || null },
                        };
                    }),
                });
                this.formElementChange.emit(this.formElement);
            })
            .disposedBy(this._disposeBag);
    }

    onRadioClick(type: AvailabilityType): void {
        this.tempFormElement = { ...this.tempFormElement, availabilityType: type };
        console.log(this.tempFormElement);
        this.delayedChanges$.next();
    }

    ngOnDestroy(): void {
        this._disposeBag.dispose();
    }

    onChange(): void {
        this.delayedChanges$.next();
    }

    addOptionToField(): void {
        this.tempFormElement.options.push({ id: guid(), value: { from: null, to: null } });
        this.onChange();
    }

    deleteOption(option: FormOption): void {
        this.tempFormElement.options = this.tempFormElement.options.filter((opt) => option.id !== opt.id);
        this.onChange();
    }

    onFromChange(optionId: string, event: any): void {
        const option = this.tempFormElement.options.find((opt) => opt.id === optionId);
        if (option) {
            if (!option.value.to) {
                option.value.to = event;
            } else if (option.value.to < event) {
                option.value.to = undefined;
            }
            this.tempFormElement.options = this.tempFormElement.options.map((opt) =>
                opt.id === option.id ? option : opt
            );
        }
        this.onChange();
    }

    onToChange(optionId: string, event: any): void {
        const option = this.tempFormElement.options.find((opt) => opt.id === optionId);
        if (option) {
            if (!option.value.from) {
                option.value.from = event;
            } else if (option.value.from > event) {
                option.value.from = undefined;
            }
            this.tempFormElement.options = this.tempFormElement.options.map((opt) =>
                opt.id === option.id ? option : opt
            );
        }
        this.onChange();
    }
}
