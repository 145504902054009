import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { PublicContext } from 'src/modules/core/model/context';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { PublicContextService } from 'src/modules/core/services/public-context.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Form } from 'src/modules/diversite/model/form/form';
import { FormModule } from 'src/modules/diversite/model/form/form-module';
import { FormService } from 'src/modules/diversite/services/form.service';
import { ModuleService } from 'src/modules/diversite/services/module.service';

import { AddFormLinkEvent, SaveFormEvent } from '../form-edit/form-edit.component';

@Component({
    selector: "diversite-form-edit-container",
    templateUrl: "./form-edit-container.component.html",
    styleUrls: ["./form-edit-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormEditContainerComponent implements OnInit, OnDestroy {
    constructor(
        private formService: FormService,
        private moduleService: ModuleService,
        private publicContextService: PublicContextService,
        private route: ActivatedRoute,
        private notificationService: NotificationService
    ) { }

    @Input() formId: string;
    @Input() contextNodeId: string;
    @Input() paneId: string;
    @Input() projectId: string;
    form$: BehaviorSubject<Form> = new BehaviorSubject<Form>(undefined);

    private _disposeBag = new DisposeBag();

    templateModules$: Observable<FormModule[]>;
    questionLibraryModules$: Observable<FormModule[]>;
    publicContexts$: Observable<PublicContext[]>;

    ngOnInit(): void {
        this.templateModules$ = this.moduleService.templateModules();
        this.questionLibraryModules$ = this.moduleService.questionLibraryModules();

        if (this.formId) {
            this.formService.form(this.formId, { listen: false }).subscribe((f) => {
                this.publicContexts$ = this.publicContextService.contextsForForm(f.id, { listen: true });
                this.form$.next(f);
            });
        } else {
            this.route.paramMap
                .pipe(
                    switchMap((paramMap) => {
                        const id = paramMap.get("formId");
                        return this.formService.form(id);
                    })
                )
                .subscribe((form) => {
                    this.form$.next(form);
                })
                .disposedBy(this._disposeBag);
        }
    }

    saveForm(event: SaveFormEvent): void {
        this.formService
            .saveForm(event.form, event.formLockHash)
            .subscribe((response) => {
                if (!response.success) {
                    if (response.message === "Form Lock invalid or expired") {
                        this.notificationService.show("Ce formulaire est vérouillé.", "danger");
                    }
                }
            })
            .disposedBy(this._disposeBag);
    }

    onAddFormLink(event: AddFormLinkEvent): void {
        this.formService
            .addFormLink(event.form, this.projectId, event.urlSlug)
            .subscribe((_) => {
                this.notificationService.show(
                    "Un nouveau lien à été ajouté avec succès pour ce formulaire.",
                    "success"
                );
            })
            .disposedBy(this._disposeBag);
    }

    onAddQuestionLibModule(module: FormModule): void {
        this.moduleService.addQuestionLibraryModule(module).subscribe().disposedBy(this._disposeBag);
    }
    onEditQuestionLibModule(module: FormModule): void {
        this.moduleService.editQuestionLibraryModule(module).subscribe().disposedBy(this._disposeBag);
    }
    onEditQuestionLibModules(modules: FormModule[]): void {
        this.moduleService.editQuestionLibraryModules(modules).subscribe().disposedBy(this._disposeBag);
    }

    onDeleteQuestionLibModule(moduleId: string): void {
        this.moduleService.deleteQuestionLibraryModule(moduleId).subscribe().disposedBy(this._disposeBag);
    }

    onDisablePublicContext(context: PublicContext): void {
        this.publicContextService.disableContext(context).subscribe().disposedBy(this._disposeBag);
    }

    onEnablePublicContext(context: PublicContext): void {
        this.publicContextService.enableContext(context).subscribe().disposedBy(this._disposeBag);
    }

    onRemovePublicContext(context: PublicContext): void {
        this.publicContextService.removeContext(context).subscribe().disposedBy(this._disposeBag);
    }

    ngOnDestroy(): void {
        this._disposeBag.dispose();
    }
}
