import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { Package } from 'src/modules/diversite/services/shared-folder.service';

@Component({
    selector: 'fillout-shared-package-public',
    templateUrl: './shared-package-public.component.html',
    styleUrl: './shared-package-public.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedPackagePublicComponent {
    @Input() package: Package;


    languages = ["fr", "en"];
    selectedLang$ = new BehaviorSubject<string>(this.languages[0]);

    constructor(private sanitizer: DomSanitizer) { }

    trackById(_: number, entity: any): string {
        return entity.id;
    }

    safe(value: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }

    logo(): string {
        return `assets/fjblogo.jpg`;
    }

    selectLang(lang: string): void {
        this.selectedLang$.next(lang);
    }

    get selectedLang(): string {
        return this.selectedLang$.value;
    }
}
