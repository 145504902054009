import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';

import { ContextMenuElement, ContextMenuService } from '../../services/context-menu.service';

@Component({
    selector: "diversite-context-menu",
    templateUrl: "./context-menu.component.html",
    styleUrls: ["./context-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuComponent implements OnInit {
    private wasInside = false;
    @Input() contextMenuElements: ContextMenuElement[];

    @HostListener("click") clickInside(event) {
        this.wasInside = true;
    }

    @HostListener("document:click") clickOutside() {
        if (!this.wasInside) {
            this.contextMenuService.closeContextMenu();
        }
        this.wasInside = false;
    }

    constructor(private contextMenuService: ContextMenuService) {}

    ngOnInit(): void {}

    onElementClick(element: ContextMenuElement): void {
        element.action();
    }
}
