import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Project } from '../../model/project';

@Component({
    selector: "diversite-delete-project-modal",
    templateUrl: "./delete-project-modal.component.html",
    styleUrls: ["./delete-project-modal.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteProjectModalComponent implements OnInit {
    @Input() project: Project;
    @Output() confirmDelete = new EventEmitter<Project>();
    @Output() closeModal = new EventEmitter<void>();

    effacer = "";

    constructor() {}

    ngOnInit(): void {}

    confirmDeletion(): void {
        this.confirmDelete.emit(this.project);

        this.effacer = "";
        this.closeModal.emit();
    }

    get disabled(): boolean {
        return this.effacer !== "EFFACER";
    }

    onOpenChange(isOpen: boolean): void {
        if (!isOpen) {
            this.closeModal.emit();
        }
    }
}
