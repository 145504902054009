import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { BehaviorSubject, debounceTime, Observable } from 'rxjs';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';

@Component({
    selector: 'diversite-activity',
    templateUrl: './activity.component.html',
    styleUrl: './activity.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityComponent {
}
