<div *ngIf="formElement" class="form-element-edition">
    <span
        #labelElement
        class="edit-label"
        contenteditable
        (keyup)="onLabelChange()"
        [innerHTML]="tempFormElement.label[lang]"
    ></span>
    <ng-content select="[tooltip]"></ng-content>
    <div>
        <input readonly size="3" class="fake-input prefix" type="number" />-
        <input readonly class="fake-input id" type="number" />
    </div>
</div>
