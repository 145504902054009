import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, map, skip, switchMap, take, tap } from 'rxjs/operators';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Pane } from 'src/modules/diversite/model/pane';
import { SearchDefinition, SearchPagination } from 'src/modules/diversite/model/search-definition';
import { SearchMeta } from 'src/modules/diversite/model/search-meta';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { ContactResult } from 'src/modules/diversite/services/elasticsearch.service';
import { AttributeModule } from 'src/modules/diversite/services/modules-attributes.service';
import { SearchContextService } from 'src/modules/diversite/services/search-context.service';
import { SearchFavoriteNode, SearchFavoriteNodeService } from 'src/modules/diversite/services/search-favorite-node.service';
import {
    SearchProfileVisibleAttributesService,
} from 'src/modules/diversite/services/search-profile-visible-attributes.service';

import { ChangeContactDefaultImageEvent } from '../../../contacts/contact-search-profile/contact-search-profile.component';
import { ContextNodePaneActionsService } from '../../../context-node/context-node-pane-actions.service';
import { PaneReferenceService } from '../../services/pane-reference.service';
import { PaneService } from '../../services/pane.service';
import { SearchState, SearchStateService } from '../../services/search-state.service';
import { SearchParametersTab } from '../search-parameters/search-parameters.component';
import { SearchPaneContentComponent } from './search-pane-content/search-pane-content.component';

@Component({
    selector: "diversite-search-pane-content-container",
    templateUrl: "./search-pane-content-container.component.html",
    styleUrls: ["./search-pane-content-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPaneContentContainerComponent implements OnInit, OnDestroy {
    constructor(
        private searchContextService: SearchContextService,
        private searchStateService: SearchStateService,
        private contactService: ContactService,
        private paneService: PaneService,
        private notificationService: NotificationService,
        private searchFavoriteNodeService: SearchFavoriteNodeService,
        private actionPaneService: ContextNodePaneActionsService,
        private paneRefService: PaneReferenceService,
        private visibleAttrShownService: SearchProfileVisibleAttributesService,
        private chRef: ChangeDetectorRef
    ) { }

    @Input() pane: Pane;

    loading = true;

    search$ = new BehaviorSubject<SearchDefinition>(undefined);
    searchPagination$ = new BehaviorSubject<SearchPagination>(null);
    searchMeta$ = new BehaviorSubject<SearchMeta>(undefined);

    attributeModules$: Observable<AttributeModule[]>;
    contactResults$ = new BehaviorSubject<ContactResult[]>([]);

    selectedContactLoading = false;
    private searchState$ = new BehaviorSubject<SearchState>(null);
    private disposeBag = new DisposeBag();

    private scrollContacts$ = new BehaviorSubject<number>(undefined);

    favoriteSearchNodes$: Observable<SearchFavoriteNode[]>;
    @ViewChild("searchPaneContent", { read: SearchPaneContentComponent }) searchPaneContent: SearchPaneContentComponent;


    ngOnInit(): void {
        this.loading = true;
        this.setSearchSub();
        this.initSearchState();
        this.favoriteSearchNodes$ = this.searchFavoriteNodeService.searchFavorites({ listen: true });
    }

    onMassEditResults(searchDef: SearchDefinition): void {
        this.actionPaneService
            .openActionPaneAtIndex("mass-edit", this.paneRefService.currentLayout.length + 1, {
                searchContextId: searchDef.id,
            })
            .subscribe()
            .disposedBy(this.disposeBag);
    }

    private initSearchState(): void {
        this.searchContextService
            .searchDefinition(this.pane.contextData.searchContextId, { listen: false })
            .pipe(
                switchMap((searchContext) => {
                    return this.searchStateService.state(this.pane.contextData.searchStateId, { listen: false }).pipe(
                        map((state) => {
                            if (state) {
                                if (state.total) {
                                    this.searchMeta$.next({ ...this.searchMeta$.value, total: state.total });
                                }

                                this.searchPagination$.next(
                                    new SearchPagination({
                                        limit: state.limit,
                                        page: state.page,
                                        sort: state.sort,
                                    })
                                );
                                this.searchState$.next(state);
                                this.executeSearch(searchContext);
                            } else {
                                throw new Error("Search should always have a state.");
                            }
                        })
                    );
                })
            )

            .subscribe()
            .disposedBy(this.disposeBag);
    }

    private setSearchSub(): void {
        this.search$.pipe(
            skip(1),
            filter((search) => search ? true : false),
            switchMap((search) => this.contactService.searchContacts(search, this.searchPagination$.value)),
            map((search) => {
                // console.log(search);
                this.searchMeta$.next({ total: search.total, searchGroupTotals: search.searchGroupTotals });
                return search.contactResults;
            }),
            map((contactResults: ContactResult[]) => {
                this.contactResults$.next(contactResults);
                return this.contactResults$.value;
            }),
            tap((cps) => {
                this.searchState$.next({
                    id: this.pane.contextData.searchStateId,
                    limit: this.searchPagination$.value.limit,
                    page: this.searchPagination$.value.page,
                    total: this.searchMeta$.value.total,
                    sort: this.searchPagination$.value.sort,
                });
                this.searchStateService.saveState(this.searchState$.value);
            }),
            catchError((error, ref) => {
                let message;
                if (error?.error?.error?.root_cause[0]?.reason) {
                    message = error.error.error.root_cause[0].reason;
                } else {
                    message = error.message;
                }
                console.error(error);
                this.notificationService.show(message, "danger");
                this.loading = false;
                this.chRef.detectChanges();
                return ref;
            })
        ).subscribe(() => {
            this.loading = false;
            this.chRef.detectChanges();


        }).disposedBy(this.disposeBag);
    }

    private setVisibleAttributes(searchDefinition: SearchDefinition): void {

        if (searchDefinition) {
            if (searchDefinition.hasFormSearchOperation()) {
                this.visibleAttrShownService.setAttribute({
                    attributeId: searchDefinition.formSearchOperation().operand
                })
            } else {
                this.visibleAttrShownService.removeFormAttribute();
            }

            const notFormOperations = this.search$.value.searchOperations.filter(s => !s.operand || !s.operand.startsWith("recruitment_"));
            if (notFormOperations.length > 0) {
                notFormOperations.forEach((sop) => {
                    this.visibleAttrShownService.setAttribute({ attributeId: sop.operand });
                })
            }
        }
    }

    onSearchParametersTab(tab: SearchParametersTab): void {
        this.paneService.editPane(this.pane.change({
            contextData: { ...this.pane.contextData, tabOpen: tab }
        })).subscribe().disposedBy(this.disposeBag)
    }

    onSelectAll(event: any): void {
        console.log(event);
    }

    onAreaSizesChange(areaSizes: number[]): void {
        this.pane = this.pane.change({
            contextData: this.pane.contextData
                ? { ...this.pane.contextData, areaSizes: areaSizes }
                : { areaSizes: areaSizes },
        });
        this.paneService.editPane(this.pane).subscribe().disposedBy(this.disposeBag);
    }

    onSearch(searchDefinition: SearchDefinition): void {
        this.loading = true;
        this.searchPagination$.next(this.searchPagination$.value.change({ page: 1 }));

        searchDefinition = this.idfigProcessing(this.search$.value, searchDefinition);
        this.setVisibleAttributes(searchDefinition);
        this.search$.next(searchDefinition);
        this.chRef.detectChanges();
    }


    private idfigProcessing(oldSearch: SearchDefinition, newSearch: SearchDefinition): SearchDefinition {
        if (
            newSearch.searchOperations.length > 1 &&
            newSearch.hasSearchOperationOperand("idfig")
        ) {
            if (oldSearch.hasSearchOperationOperand("idfig")) {

                newSearch = newSearch.removeSearchOperationOperand("idfig");
            } else {
                const sop = newSearch.searchOperationByName("idfig");
                newSearch = newSearch.eraseReset().replaceAddSearchOperations([sop]);
            }
        }
        return newSearch;
    }

    onSearchPaginationChange(searchPagination: SearchPagination): void {
        this.loading = true;
        this.searchPagination$.next(searchPagination);
        this.chRef.detectChanges();
        this.executeSearch(this.search$.value);
    }

    onContactWidthChange(width: number): void {
        this.paneService.editPane(this.pane.change({ contextData: { ...this.pane.contextData, contactCardWidth: width } })).subscribe().disposedBy(this.disposeBag);
    }

    private executeSearch(searchDefinition: SearchDefinition): void {
        this.search$.next(searchDefinition.change({}));
    }


    onSearchDefinitionChange(searchDefinition: SearchDefinition): void {
        this.searchContextService.saveSearchDefinition(searchDefinition).subscribe().disposedBy(this.disposeBag);
        this.onSearch(searchDefinition);
    }


    onContactDefaultImageChange(event: ChangeContactDefaultImageEvent): void {
        this.contactService
            .changeContactDefaultImageIndex(event.contactId, event.index)
            .pipe(take(1))
            .subscribe()
            .disposedBy(this.disposeBag);
    }


    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}

export interface SearchPaneContextData {
    searchContextId: string;
}
