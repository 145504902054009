import { Injectable } from '@angular/core';
import { BehaviorSubject, filter } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApplicationDepartmentService {

    private currentDepartment$ = new BehaviorSubject<string>(undefined);
    readonly departmentChange$ = this.currentDepartment$.asObservable().pipe(filter(d => d ? true : false));

    constructor() { }

    changeDepartment(departmentId?: string): void {
        this.currentDepartment$.next(departmentId ? departmentId : null);
    }

    currentDepartmentId(): string {
        return this.currentDepartment$.value;
    }
}
