<div *ngIf="formElementInput"
     class="quick-edit">
    <clr-input-container *ngIf="!baseField">
        <input [value]="formElementInput.name"
               [disabled]="true"
               clrInput
               name="name" />
    </clr-input-container>

    <div *ngIf="baseField">
        <span class="label">{{baseField.parentInfo.name[lang]}} -> {{baseField.id}}</span>
    </div>

    <clr-checkbox-container>
        <clr-checkbox-wrapper>
            <input [disabled]="formElementInput.readonly"
                   type="checkbox"
                   [checked]="formElementInput.required"
                   clrCheckbox
                   (change)="onRequiredChanged($event)" />
            <label>Champ requis</label>
        </clr-checkbox-wrapper>
    </clr-checkbox-container>
    <clr-checkbox-container>
        <clr-checkbox-wrapper>
            <input type="checkbox"
                   [checked]="formElementInput.readonly"
                   clrCheckbox
                   (change)="onReadonlyChanged($event)" />
            <label>Lecture seule</label>
        </clr-checkbox-wrapper>
    </clr-checkbox-container>
    <!-- <clr-range-container>
        <input
            type="range"
            (change)="onScoreChange($event)"
            [value]="formElementInput.score"
            clrRange
            min="0"
            max="100"
        />
        <clr-control-helper>{{ formElementInput.score }}%</clr-control-helper>
    </clr-range-container> -->
</div>