import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DisposeBag } from "src/modules/core/utilities/dispose-bag";
import { FormElement, FormElementInput } from "src/modules/diversite/model/form/form-element/form-element";
import { TranslatableLabel } from "src/modules/diversite/services/data-catalog.service";

@Component({
    selector: "diversite-form-element-edit",
    templateUrl: "./form-element-edit.component.html",
    styleUrls: ["./form-element-edit.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormElementEditComponent implements OnInit, OnChanges {
    @Input() lang: string;
    @Input() formElement: FormElement;
    @Output() formElementChanged = new EventEmitter<FormElement>();
    @Input() readonlyElement = false;

    constructor(private chRef: ChangeDetectorRef) {}

    description: TranslatableLabel = {};
    langChanges$ = new BehaviorSubject<string>(undefined);

    private disposeBag = new DisposeBag();

    ngOnInit(): void {
        this.description = this.formElement.description;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.lang) {
            this.langChanges$.next(changes.lang.currentValue);
        }
    }

    onFormElementChange(formElement: FormElement): void {
        this.formElementChanged.emit(formElement);
    }

    onTooltipChanged(tooltip: TranslatableLabel, formElement: FormElement): void {
        this.formElementChanged.emit(
            formElement.change({
                description: tooltip,
            })
        );
    }

    onQuickEditChange(formElement: FormElementInput): void {
        this.formElementChanged.emit(formElement as FormElement);
    }
}
