import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CdsModule } from '@cds/angular';

import { CoreModule } from '../core/core.module';
import { AudioPlayerComponent } from './components/form-element/audio-recording/audio-player.component';
import { AudioRecordingComponent } from './components/form-element/audio-recording/audio-recording.component';
import { AvailabilityComponent } from './components/form-element/element/availability/availability.component';
import { CheckboxComponent } from './components/form-element/element/checkbox/checkbox.component';
import { DatetimeComponent } from './components/form-element/element/datetime/datetime.component';
import { DropdownComponent } from './components/form-element/element/dropdown/dropdown.component';
import { FileuploadComponent } from './components/form-element/element/fileupload/fileupload.component';
import { HeightComponent } from './components/form-element/element/height/height.component';
import { ImageuploadComponent } from './components/form-element/element/imageupload/imageupload.component';
import { NumberComponent } from './components/form-element/element/number/number.component';
import { PhoneComponent } from './components/form-element/element/phone/phone.component';
import { RadioComponent } from './components/form-element/element/radio/radio.component';
import {
    TableFileuploadInputComponent,
} from './components/form-element/element/table/table-inputs/table-fileupload-input/table-fileupload-input.component';
import {
    TableImageuploadInputComponent,
} from './components/form-element/element/table/table-inputs/table-imageupload-input/table-imageupload-input.component';
import { TableComponent } from './components/form-element/element/table/table.component';
import { TextComponent } from './components/form-element/element/text/text.component';
import { TextareaComponent } from './components/form-element/element/textarea/textarea.component';
import { TextboxComponent } from './components/form-element/element/textbox/textbox.component';
import { UnionComponent } from './components/form-element/element/union/union.component';
import { VideouploadComponent } from './components/form-element/element/videoupload/videoupload.component';
import { VoiceuploadComponent } from './components/form-element/element/voiceupload/voiceupload.component';
import { WeightComponent } from './components/form-element/element/weight/weight.component';
import { FormElementComponent } from './components/form-element/form-element.component';
import { FormComponent } from './components/form/form.component';
import { SharedPackagePublicComponent } from './components/shared-package-public/shared-package-public.component';
import { UploadImagesComponent } from './components/upload-images/upload-images.component';

@NgModule({
    declarations: [
        FormElementComponent,
        TextComponent,
        TextareaComponent,
        TextboxComponent,
        NumberComponent,
        FormComponent,
        ImageuploadComponent,
        VideouploadComponent,
        FileuploadComponent,
        DropdownComponent,
        DatetimeComponent,
        RadioComponent,
        CheckboxComponent,
        AudioRecordingComponent,
        AudioPlayerComponent,
        VoiceuploadComponent,
        TableComponent,
        TableImageuploadInputComponent,
        TableFileuploadInputComponent,
        HeightComponent,
        WeightComponent,
        PhoneComponent,
        UnionComponent,
        AvailabilityComponent,
        SharedPackagePublicComponent,
        UploadImagesComponent,
    ],
    imports: [CoreModule, CommonModule, CdsModule],
    exports: [FormElementComponent, FormComponent, SharedPackagePublicComponent, UploadImagesComponent],
})
export class FilloutModule { }
