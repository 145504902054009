import { Injectable } from "@angular/core";
import { AngularFirestore, DocumentReference } from "@angular/fire/compat/firestore";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SystemRequest } from "./system-request-api-client.service";

@Injectable({
    providedIn: "root",
})
export class BatchSystemRequestService {
    constructor(private db: AngularFirestore) {}

    add(systemRequest: SystemRequest): Observable<SystemRequest> {
        return from(this.db.collection("batchSystemRequests").add(systemRequest)).pipe(
            map((docRef: DocumentReference) => {
                const id = docRef.id;
                return { id, requestType: systemRequest.requestType };
            })
        );
    }
}
