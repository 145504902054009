import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Contact } from 'src/modules/diversite/model/contact';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { Activity } from 'src/modules/diversite/services/activity.service';
import { ContactService } from 'src/modules/diversite/services/contact.service';

@Component({
    selector: 'diversite-contact-update-activity',
    templateUrl: './contact-update-activity.component.html',
    styleUrl: './contact-update-activity.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactUpdateActivityComponent {
    @Input() activity: ContactUpdateActivity;
    @Output() openProfile = new EventEmitter<string>();

    contact$: Observable<Contact>;

    constructor(private contactService: ContactService) { }

    ngOnInit(): void {
        this.contact$ = this.contactService.contactByIdfig(this.activity.idfig);
    }

    onViewProfile(contactId: string): void {
        this.openProfile.emit(contactId);
    }
}

export interface ContactUpdateActivity extends Activity {
    id: string,
    idfig: string;
    contact: any;
    created: Date;
}