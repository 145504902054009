<div [class.no-label]="!options.showLabel" *ngIf="formElement" class="voiceupload-container clr-form-control">
    <label *ngIf="options.showLabel" class="clr-control-label"
        >{{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>

    <audio-player *ngIf="audioUrl" [audioUrl]="audioUrl"></audio-player>
    <div *ngIf="uploading" class="loading">
        <span [hidden]="!uploading" class="spinner spinner-sm"> Téléchargement en cours... </span>
        <span class="text" [hidden]="!uploading">Téléchargement en cours...</span>
    </div>
    <audio-recording
        [lang]="lang"
        [disabled]="formElement.readonly"
        *ngIf="!uploading"
        (audioChange)="onAudioChange($event)"
    ></audio-recording>
</div>
