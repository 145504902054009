import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DEFAULT_ACCESS, User } from 'src/app/model/user';
import { UserService } from 'src/app/services/user.service';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { ContactService } from 'src/modules/diversite/services/contact.service';

@Component({
    selector: "castlug-login-container",
    templateUrl: "./login-container.component.html",
    styleUrls: ["./login-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginContainerComponent implements OnInit {
    private readonly _disposeBag = new DisposeBag();

    constructor(
        private contactService: ContactService,
        private userService: UserService,
        private notificationService: NotificationService
    ) { }

    ngOnInit(): void {
        const urlParts = window.location.href.split("?");
        if (urlParts.length > 1) {
            const params = urlParts[1];
            const arrayValues: string[][] = params.split("&").map((attrValueString) => attrValueString.split("="));
            const user: { id?: string; email?: string } = {};

            arrayValues.forEach((value) => {
                if (value[0] === "uid") {
                    user.id = value[1];
                }
                if (value[0] === "email") {
                    user.email = value[1];
                }
            });

            this.userService
                .addUser(
                    new User({
                        id: user.id,
                        email: user.email,
                        access: DEFAULT_ACCESS
                    })
                )
                .subscribe((_) => {
                    this.notificationService.show(
                        "Votre compte à été activé avec succès, veuillez vous connecter.",
                        "success"
                    );
                    window.location.href = urlParts[0];
                })
                .disposedBy(this._disposeBag);
        }
    }
}
