import { Injectable } from "@angular/core";
import { AngularFirestore, DocumentData } from "@angular/fire/compat/firestore";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";

import { FormDTO } from "../mappers/form-mapper.service";
import { FormQueryOptions } from "../services/form.service";

@Injectable({
    providedIn: "root",
})
export class FormApiClientService {
    constructor(private db: AngularFirestore, private authService: AuthService) { }

    saveForm(formDto: FormDTO): Observable<FormDTO> {
        return from(this.db.collection("forms").doc(formDto.id).set(formDto)).pipe(map((_) => formDto));
    }

    forms(opts?: FormQueryOptions): Observable<FormDTO[]> {
        return this.db
            .collection("forms", (ref) => {
                let queryRef = ref as any;
                if (opts && (opts.archived === true || opts.archived === false)) {
                    queryRef = queryRef.where("archived", "==", opts.archived);
                }
                if (opts && (opts.deleted === true || opts.deleted === false)) {
                    queryRef = queryRef.where("deleted", "==", opts.archived);
                }
                if (opts && (opts.templates === true || opts.templates === false)) {
                    queryRef = queryRef.where("isTemplate", "==", opts.templates);
                }
                if (opts && opts.projectId) {
                    queryRef = queryRef.where("projectId", "==", opts.projectId);
                }

                queryRef.orderBy("lastUpdated", "desc");

                return queryRef;
            })
            .snapshotChanges()
            .pipe(
                map((formsDto) => {
                    if (formsDto) {
                        return formsDto.map((a) => {
                            const data: DocumentData = a.payload.doc.data();
                            const id = a.payload.doc.id;
                            return { ...data, id } as FormDTO;
                        });
                    }
                })
            );
    }

    formById(id: string, options = { listen: true }): Observable<FormDTO> {
        if (options.listen) {
            return this.db
                .collection("forms")
                .doc(id)
                .snapshotChanges()
                .pipe(
                    map((snap) => {
                        if (snap.payload.exists) {
                            const data = snap.payload.data() as any;
                            return { id, ...data };
                        } else {
                            return undefined;
                        }
                    })
                );
        } else {
            return this.db
                .collection("forms")
                .doc(id)
                .get()
                .pipe(
                    map((docRef: any) => {
                        if (docRef && docRef.exists) {
                            return { id: docRef.id, ...docRef.data() };
                        } else {
                            return undefined;
                        }
                    })
                );
        }
    }
}
