import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { filter, map, switchMap, take, tap } from "rxjs/operators";
import {
    LoadContactRequest,
    RequestCodeRequest,
    RequestContactInfoRequest,
    VerifyCodeRequest,
} from "src/app/api-clients/system-request-api-client.service";
import { SystemRequestService } from "src/app/services/system-request.service";

import { ContactPublicMapperService } from "../mapper/contact-public-mapper.service";
import { ContactPublic } from "./recruit.service";

@Injectable({
    providedIn: "root",
})
export class AccessCodeService {
    constructor(
        private systemRequestService: SystemRequestService,
        private contactPublicMapper: ContactPublicMapperService
    ) { }

    getCommunicationOptionsForAccessCode(idfig: string): Observable<StrategyOptionRequest> {
        if (!idfig || idfig.trim().length === 0) {
            return of(undefined);
        }
        const systemRequest: RequestContactInfoRequest = {
            idfig,
            requestType: "requestContactInfo",
        };
        return this.systemRequestService.add(systemRequest).pipe(
            switchMap((sr) => {
                return this.systemRequestService.get(sr.id).pipe(
                    filter((sysR) => {
                        return sysR.status === "success" || sysR.status === "error";
                    }),
                    take(1)
                );
            }),
            map((sr) => {
                const finalSystemRequest: RequestContactInfoRequest = sr as RequestContactInfoRequest;
                const strategies: IdfigRequestStrategy[] = finalSystemRequest.response
                    ? (Object.keys(finalSystemRequest.response) as IdfigRequestStrategy[])
                    : [];
                return {
                    status: sr.status,
                    options: strategies?.map((strat) => {
                        return {
                            strategy: strat,
                            value: finalSystemRequest.response[strat],
                        };
                    }),
                };
            }),
            tap((_) => console.log(_)),
            take(1)
        );
    }

    getCode(contactInfo: AccessCodeContactInfo, lang: string): Observable<string> {
        const systemRequest: RequestCodeRequest = {
            lang,
            contactInfo,
            requestType: "requestCode",
        };
        return this.systemRequestService.add(systemRequest).pipe(
            switchMap((sr) => {
                console.log(sr.id);
                return this.systemRequestService.get(sr.id).pipe(
                    filter((sysR) => {
                        return sysR.status === "success";
                    }),
                    tap(sr => console.log("get code", sr))
                );
            }),
            map((sr) => sr.id)
        );
    }

    authenticatedProfilesForAccessCode(
        accessCode: string,
        contextId: string
    ): Observable<AuthenticatedProfilesResponse> {
        const systemRequest: VerifyCodeRequest = {
            contextId,
            verifyCode: accessCode,
            requestType: "verifyCode",
        };
        return this.systemRequestService.add(systemRequest).pipe(
            switchMap((sr) => {
                console.log("verify code", sr)
                console.log(sr.id);
                return this.systemRequestService.get(sr.id).pipe(
                    filter((sysR) => {
                        return sysR.status === "success" || sysR.status === "error";
                    })
                );
            }),
            map((sr) => {
                if (sr.status === "success") {
                    const finalSystemRequest = sr as VerifyCodeRequest;
                    return { status: "success", authenticatedProfiles: finalSystemRequest.authenticatedProfiles };
                } else if (sr.status === "error") {
                    return { status: "error" };
                }
            })
        );
    }

    loadContact(idfig: string, contextId: string): Observable<ContactPublic> {
        const systemRequest: LoadContactRequest = {
            contextId,
            idfig,
            requestType: "loadContact",
        };

        return this.systemRequestService.add(systemRequest).pipe(
            switchMap((sr) => {
                console.log(sr.id);
                return this.systemRequestService.get(sr.id).pipe(
                    filter((sysR) => {
                        return sysR.status === "success" || sysR.status === "error";
                    })
                );
            }),
            map((sr) => {
                if (sr.status === "success") {
                    const finalSystemRequest: LoadContactRequest = sr as LoadContactRequest;
                    const contact: ContactPublic = this.contactPublicMapper.mapDataToContactPublic(
                        finalSystemRequest.contact
                    );
                    return contact;
                } else if (sr.status === "error") {
                    return undefined;
                }
            })
        );
    }
}

export interface AccessCodeContactInfo {
    idfig?: string;
    email?: string;
    type: "email" | "sms";
}

export interface AuthenticatedProfile {
    firstName: string;
    idfig: string;
    lastName: string;
}

export type CodeRequestStrategy = "email" | "idfig" | "new";
export type IdfigRequestStrategy = "sms" | "email";

export interface StrategyOptionRequest {
    status: "success" | "error";
    options: IdfigRequestStrategyOption[];
}

export interface IdfigRequestStrategyOption {
    strategy: IdfigRequestStrategy;
    value: string;
}

export interface AuthenticatedProfilesResponse {
    status: string;
    authenticatedProfiles?: AuthenticatedProfile[];
}
