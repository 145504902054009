import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap, tap } from 'rxjs';
import { guid } from 'src/app/core/functions';

import { ContextNode } from '../../model/context-node';
import { Occurance, SearchOperation } from '../../model/search-operation';
import { ContactContextNodeAssignService } from '../../services/contact-context-node-assign.service';
import { ContextNodeService } from '../../services/context-node.service';

@Component({
    selector: 'diversite-context-node-search-operations',
    templateUrl: './context-node-search-operations.component.html',
    styleUrl: './context-node-search-operations.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextNodeSearchOperationsComponent implements OnInit {

    @Input() projectId: string;
    @Output() changeSearchSpecification = new EventEmitter<SearchOperation>();
    @Output() searchOperationsForAllFolder = new EventEmitter<SearchOperation[]>();
    private projectId$ = new BehaviorSubject<string>("")

    contextNodes$: Observable<ContextNode[]>;
    selectedNodeId: string = "";
    occurance: Occurance = "must";

    private _nodes: ContextNode[] = [];

    constructor(private contextNodeService: ContextNodeService, private assignNodeService: ContactContextNodeAssignService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.projectId) {
            if (changes.projectId.currentValue && changes.projectId.currentValue !== "") {
                this.projectId$.next(changes.projectId.currentValue)
            } else {
                this.projectId$.next(undefined);
            }
        }
    }

    ngOnInit(): void {
        this.contextNodes$ = this.projectId$.pipe(
            switchMap(projectId => {
                if (projectId) {
                    return this.contextNodeService.projectNodes(projectId, { listen: false }).pipe(switchMap(contextNodes => {
                        return this.contextNodeService.nodes(contextNodes[0].children, { listen: false })
                    }))
                }
                return of([]);
            }),
            tap(nodes => this._nodes = nodes)
        );
    }

    get disabled(): boolean {
        return !this.projectId || this.projectId === '' || !this.selectedNodeId || this.selectedNodeId === '' || !this.occurance;
    }

    changeSearchOperationContextNode(): void {
        if (!this.disabled) {
            if (this.selectedNodeId === "all") {
                this.searchOperationsForAllFolder.emit(this._nodes.map(n => {
                    return this.sopForNodeId(n.id);
                }))
            } else {
                this.changeSearchSpecification.emit(this.sopForNodeId(this.selectedNodeId));
            }
        }
    }


    private sopForNodeId(nodeId: string): SearchOperation {
        return new SearchOperation({
            id: guid(),
            operand: `contextNode_${nodeId}`,
            operator: "boolean",
            value: true,
            occurance: this.occurance
        })
    }

    trackById(_: number, item: any): string {
        return item.id;
    }

}
