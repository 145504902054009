import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Form } from 'src/modules/diversite/model/form/form';
import { FormBackup, FormBackupService } from 'src/modules/diversite/services/form-backup.service';

@Component({
    selector: "diversite-form-backups",
    templateUrl: "./form-backups.component.html",
    styleUrls: ["./form-backups.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormBackupsComponent implements OnInit {
    @Input() formId: string;
    @Output() restore = new EventEmitter<Form>();

    previewRestore: FormBackup;

    backups$: Observable<FormBackup[]>;

    constructor(private formBackup: FormBackupService) {}

    ngOnInit(): void {
        this.backups$ = this.formBackup.backups(this.formId);
    }

    previewRestoreBackup(backup: FormBackup): void {
        this.previewRestore = backup;
    }

    removePreview(): void {
        this.previewRestore = undefined;
    }

    restoreBackup(form: Form): void {
        this.restore.emit(form);
    }
}
