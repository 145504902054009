import { DatePipe } from "@angular/common";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { debounceTime, skip } from "rxjs/operators";
import { DisposeBag } from "src/modules/core/utilities/dispose-bag";
import { FormAvailability } from "src/modules/diversite/model/form/form-element/form-availability";
import { FormOption } from "src/modules/diversite/model/form/form-element/form-option";

import { DEFAULT_OPTIONS, FormElementGenericOptions } from "../../form-element.component";

declare var $: any;

@Component({
    selector: "fillout-availability",
    templateUrl: "./availability.component.html",
    styleUrls: ["./availability.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvailabilityComponent implements OnInit, AfterViewInit {
    @Input() lang: string;
    @Input() formElement: FormAvailability;
    @Input() value: DateRangeResponse[] = [];
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Output() responseChange = new EventEmitter<DateRangeResponse[]>();
    delayedChanges$ = new BehaviorSubject<void>(undefined);

    private _disposeBag = new DisposeBag();

    // tempFrom: Date;
    // tempTo: Date;

    private datePickerFrom: any;
    private datePickerTo: any;

    constructor(private datePipe: DatePipe, private host: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            this.initValues();
        }
    }

    ngOnInit(): void {
        this.delayedChanges$
            .pipe(debounceTime(250), skip(1))
            .subscribe((v) => {
                this.responseChange.emit(this.value);
            })
            .disposedBy(this._disposeBag);
    }

    ngAfterViewInit(): void {
        const datePicketOptions: any = {
            nextText: ">",
            prevText: "<",
            showMonthAfterYear: true,
            dateFormat: "dd-mm-yy",
            constrainInput: true,
            changeMonth: true,
            changeYear: true,
            monthNames: [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Août",
                "Septembre",
                "Octobre",
                "Novembre",
                "Décembre",
            ],
            dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
        };

        this.datePickerFrom = $(".datep-from", this.host.nativeElement);
        this.datePickerTo = $(".datep-to", this.host.nativeElement);
        this.datePickerFrom.datepicker({
            ...datePicketOptions,
            onSelect: (dateText) => {
                this.datePickerFrom.datepicker("setDate", dateText);
                this.onFromChange(this.datePickerFrom.datepicker("getDate"));
            },
        });
        this.datePickerTo.datepicker({
            ...datePicketOptions,
            onSelect: (dateText) => {
                this.datePickerTo.datepicker("setDate", dateText);
                this.onToChange(this.datePickerTo.datepicker("getDate"));
            },
        });
    }

    private initValues(): void {
        if (!this.value && this.formElement && this.formElement.availabilityType === "predetermined") {
            this.value = this.formElement.options.map((opt: FormOption) => {
                return { ...opt.value, formOptionId: opt.id, responseStatus: "not-responded" };
            });
        } else if (!this.value && this.formElement && this.formElement.availabilityType === "custom") {
            this.value = [];
        } else if (
            // if elements has been added since last answer
            this.value &&
            this.formElement &&
            this.formElement.availabilityType === "predetermined"
        ) {
            this.value = this.updateValues();
        }
    }

    readableDate(date: Date): string {
        return this.datePipe.transform(date, "dd-MM-yyyy");
    }

    private updateValues(): DateRangeResponse[] {
        const filteredResponseThatDontExistInFormElement = this.value.filter((response) =>
            this.formElement.options.find((opt) => opt.id === response.formOptionId)
        );
        const filteredResponseThatExistInFormElementBuNotInExistingResponse = this.formElement.options
            .filter((opt) => !this.value.find((response) => response.formOptionId === opt.id))
            .map((opt) => {
                return {
                    ...opt.value,
                    formReponseId: opt.id,
                    responseStatus: opt.value.responseStatus ? opt.value.responseStatus : "not-responded",
                };
            });

        return [
            ...filteredResponseThatDontExistInFormElement,
            ...filteredResponseThatExistInFormElementBuNotInExistingResponse,
        ];
    }

    addCustomRange(): void {
        const from = this.datePickerFrom.datepicker("getDate");
        const to = this.datePickerTo.datepicker("getDate");
        if (from && to) {
            this.value.push({
                from,
                to,
                responseStatus: "available",
            });
            this.onInputChange();
        }
    }

    deleteRange(index: number): void {
        this.value = this.value.filter((value, i) => i !== index);
    }

    private onFromChange(event: Date): void {
        const to = this.datePickerTo.datepicker("getDate");
        if (!to) {
            this.datePickerTo.datepicker("setDate", event);
        } else if (to < event) {
            this.datePickerTo.datepicker("setDate", null);
        }
    }

    private onToChange(event: Date): void {
        const from = this.datePickerFrom.datepicker("getDate");
        if (!from) {
            this.datePickerFrom.datepicker("setDate", event);
        } else if (from > event) {
            this.datePickerFrom.datepicker("setDate", null);
        }
    }

    onInputChange(): void {
        this.delayedChanges$.next();
    }
}

export interface DateRange {
    from: Date;
    to: Date;
}

export interface DateRangeResponse extends DateRange {
    formOptionId?: string;
    responseStatus: ResponseStatus;
}

export type ResponseStatus = "available" | "not-available" | "not-reponded";
