<div class="attribute" *ngIf="attrLabel$ | async as attrLabel">
    <span *ngIf="attrLabel.attributeId.startsWith('recruitment_'); else notForm" class="label-attribute"
        >Formulaire :
        <span class="no-margin action-link" (click)="openForm(attrLabel.attributeId)"
            ><diversite-attribute-value
                [attributeName]="attrLabel.attributeId"
                [value]="attrLabel.attributeId"
            ></diversite-attribute-value></span
    ></span>

    <ng-template #notForm>
        <span class="label-attribute"
            ><diversite-attribute-label [attributeLabel]="attrLabel"></diversite-attribute-label>
            <!-- <cds-icon *ngIf="!editMode" (click)="edit()" class="edit" shape="note"></cds-icon
            ><cds-icon *ngIf="editMode" (click)="save()" class="edit" shape="check"></cds-icon>
            <cds-icon *ngIf="editMode" (click)="cancel()" class="edit" shape="times"></cds-icon> -->
        </span>
        <diversite-attribute-value
            *ngIf="!editMode"
            [isSearched]="isSearched"
            [attributeLabel]="attrLabel"
            [value]="attrLabel.hasOptionLabels() ? value : stringValue"
        ></diversite-attribute-value>

        <!-- <fillout-form-element
            *ngIf="editMode"
            [value]="tempAttributeValue"
            [lang]="lang"
            [options]="{ showLabels: false }"
            [formElement]="tempFormElement"
            (responseChange)="onResponseChange($event)"
        ></fillout-form-element> -->
    </ng-template>
</div>
