import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, filter, map, Observable, of, switchMap, take } from 'rxjs';
import { StaticLabels, StaticLabelsService } from 'src/modules/core/services/static-labels.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormElement, FormElementError, FormElementInput } from 'src/modules/diversite/model/form/form-element/form-element';
import { FormImageupload } from 'src/modules/diversite/model/form/form-element/form-imageupload';
import { FormTextbox } from 'src/modules/diversite/model/form/form-element/form-textbox';
import { ModuleService } from 'src/modules/diversite/services/module.service';

import { ResponseInput } from '../../model/response-input';
import { AttributeValue, ContactEmailViewState, RecruitService } from '../../services/recruit.service';
import { SubmissionReponses } from '../form/form.component';


const INDEX_ORDER = [
    "identity_firstname",
    "identity_lastname",
    "identity_gender",
    "identity_dateOfBirth",
    "contactInformation_mainPhone",
    "measurements_height_metric",
    "measurements_weight_metric",
    "affiliation_uda",
    "affiliation_actra",
    "voiture",
    "photo_photos_upload_form"
];

declare let $: any;
@Component({
    selector: 'fillout-upload-images',
    templateUrl: './upload-images.component.html',
    styleUrl: './upload-images.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadImagesComponent {
    private translationId = "mPPChPxCfo9iki7M9j7N";
    @Input() email: string;
    @Input() attributeValues: AttributeValue[];
    @Input() mode: "preview" | "fillout" = "fillout";
    @Input() isSubmitted = false;
    @Output() submitPhotoForm = new EventEmitter<SubmissionReponses>();

    readonly uploadFormElement = new FormImageupload({
        id: "JaYMXpzkUsoTGaU4c8ZS",
        label: {
            fr: "Formats valide : JPG, JPEG, PNG, HEIC, BMP",
            en: "Valid image : JPG, JPEG, PNG, HEIC, BMP",
        },
        name: "photo_photos_upload_form",
        required: true
    });

    readonly languages = ["fr", "en"];
    lang = "fr";
    isSubmitting = false;
    showErrorsBoard = false;
    disabledEmailFormElement: FormElement;
    private _errors: FormElementError[][] = [];
    private emailChanged$ = new BehaviorSubject<string>(undefined);
    viewStrategy$: Observable<ContactEmailViewState> = this.emailChanged$.pipe(filter(email => email && email !== ""), switchMap(email => this.recruitService.getEmailContactState(email)));
    attributesMissing$: Observable<FormElement[]>;

    private translationLabels: StaticLabels = {};
    private idfig: string;

    private formResponses = new Map<string, ResponseInput>();

    private disposeBag = new DisposeBag();
    constructor(
        private recruitService: RecruitService,
        private moduleService: ModuleService,
        private staticLabelsService: StaticLabelsService,
        private chRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.disabledEmailFormElement = (this.moduleService.formElementForIndex("contactInformation_email") as FormTextbox).change({ readonly: true, required: false });

        this.staticLabelsService
            .labelsForComponent(this.translationId)
            .pipe(take(1))
            .subscribe((labels) => {
                this.translationLabels = labels;
                this.chRef.markForCheck();
            })
            .disposedBy(this.disposeBag);

        if (this.mode === "preview") {
            this.attributesMissing$ = of(this.attributeValues.filter(attr => attr.index !== "contactInformation_email").map(attr => {
                if (attr.index === this.uploadFormElement.name) {
                    return this.uploadFormElement;
                } else {
                    return this.moduleService.formElementForIndex(attr.index);
                }
            })).pipe(map((formElements) => {
                return formElements.filter(fe => fe).sort((a, b) => {
                    return INDEX_ORDER.indexOf(a.name) - INDEX_ORDER.indexOf(b.name);
                })
            }));
        } else {
            this.attributesMissing$ = this.viewStrategy$.pipe(
                map((viewStrategy: ContactEmailViewState) => {
                    this.idfig = viewStrategy.idfig || null;
                    return viewStrategy.attributesMissing?.map(index => this.moduleService.formElementForIndex(index)) || [];
                }),
                map(formElements => {
                    return [...formElements.filter(fe => fe), this.uploadFormElement];
                })
            )
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.email?.currentValue && changes.email?.currentValue !== this.emailChanged$.value) {
            this.emailChanged$.next(changes.email?.currentValue);
        }
    }

    trackByError(index: number, errors: FormElementError[]): string {
        return `${index}`;
    }

    get selectedLang(): string {
        return this.lang;
    }

    valueForIndex(index: string): any {
        return this.attributeValues.find(a => a.index === index)?.value;
    }

    isValid(formElements: FormElementInput[]): boolean {
        this._errors = formElements
            .map((fi) => {
                const responseInput = this.formResponses.get(fi.id);
                const isValid = responseInput ? fi.validate(responseInput.value) : fi.validate(undefined);
                return isValid;
            })
            .filter((_) => _);

        return this._errors.length === 0;
    }

    get errors(): FormElementError[][] {
        return this._errors;
    }

    submit(formElements: FormElementInput[]): void {
        if (this.mode !== "preview") {
            if (this.isValid(formElements)) {
                const responses: ResponseInput[] = Array.from(this.formResponses).map((a) => a[1]);

                this.isSubmitting = true;


                responses.push({
                    value: this.email,
                    input: this.moduleService.formElementForIndex("contactInformation_email") as FormElementInput,
                });


                this.submitPhotoForm.emit({
                    idfig: this.idfig,
                    responseInputs: responses,
                });
                this.scrollToTop();
            }


            if (!this.isValid(formElements)) {
                this.chRef.markForCheck();

                this.showErrorsBoard = true;

            }
        }
    }

    private scrollToTop(): void {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    seeError(error: FormElementError): void {
        this.scrollToAndFlashElementId(error.formElement.id);
    }

    private scrollToAndFlashElementId(elementId: string): void {
        $("html, body").animate(
            {
                scrollTop: $(`#${elementId}`).offset().top - 30,
            },
            250,
            () => {
                $(`#${elementId}`)
                    .addClass("show")
                    .delay(3000)
                    .queue((next) => {
                        $(`#${elementId}`).removeClass("show");
                        next();
                    });
            }
        );
    }

    label(labelId: string): string {
        if (
            this.translationLabels &&
            this.translationLabels[labelId] &&
            this.translationLabels[labelId][this.selectedLang]
        ) {
            return this.translationLabels[labelId][this.selectedLang];
        }
        return labelId;
    }

    onResponseChange(response: ResponseInput): void {
        this.formResponses.set(response.input.id, response);
        this.chRef.detectChanges();
    }

    selectLang(lang: string): void {
        this.lang = lang;
    }

    logo(): string {
        return `assets/fjblogo.jpg`;
    }
    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}

