import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { SearchValue } from '../../search-value-selector.component';

@Component({
    selector: 'search-value-union',
    templateUrl: './search-value-union.component.html',
    styleUrls: ['./search-value-union.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchValueUnionComponent implements OnChanges {
    @Input() searchValue: SearchValue;
    @Output() searchValueChange = new EventEmitter<SearchValue>();


    isUnionChecked: boolean = false;
    textValue: string = "";

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.searchValue) {
            if (changes.searchValue.currentValue) {

                const currentValue: SearchValue = changes.searchValue.currentValue;
                if (Object.prototype.toString.call(currentValue.value) === "[object String]" && currentValue.value !== "") {
                    this.isUnionChecked = true;
                    this.textValue = currentValue.value;
                } else if (currentValue.value === true) {
                    this.isUnionChecked = true;
                    this.textValue = "";
                } else {
                    this.isUnionChecked = false;
                    this.textValue = "";
                }
            } else {
                this.isUnionChecked = false;
                this.textValue = "";
            }
        }
    }

    onCheckboxChange(value: boolean): void {
        // console.log("checkbox", value);
        this.isUnionChecked = value;
        if (!this.isUnionChecked) {
            this.textValue = "";
        }
        this.emitChange();
    }

    onTextKeypress(value: string): void {
        this.textValue = value;
        this.emitChange();
    }

    private emitChange(): void {
        if (this.isUnionChecked && this.textValue && this.textValue !== "") {
            this.searchValueChange.emit({ operator: "query", value: this.textValue });
        } else if (this.isUnionChecked) {
            this.searchValueChange.emit({
                operator: "boolean",
                value: true
            })
        } else {
            this.searchValueChange.emit({
                operator: "boolean",
                value: null
            })
        }
    }
}
