<h1>404 Not found !</h1>


<!-- <input type="file" id="file" (change)="handleFileInput($event)">

<button (click)="test()">test</button>


<div *ngIf="imageUrl">
    this image has been uploaded
    <img style="max-width: 500px;" [src]="this.imageUrl" />
</div> -->