import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, delay, filter, finalize } from 'rxjs/operators';
import { guid } from 'src/app/core/functions';
import { FileUploadGenericService } from 'src/modules/core/services/file-upload.service';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { ContextNode } from 'src/modules/diversite/model/context-node';
import { TranslatableLabel } from 'src/modules/diversite/services/data-catalog.service';
import { Package } from 'src/modules/diversite/services/shared-folder.service';
import { TranslateService } from 'src/modules/diversite/services/translate.service';

import { ContextNodePaneActionsService } from '../context-node-pane-actions.service';

@Component({
    selector: 'diversite-context-node-shared-packages',
    templateUrl: './context-node-shared-packages.component.html',
    styleUrl: './context-node-shared-packages.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextNodeSharedPackagesComponent {
    @Input() projectId: string;
    @Input() displayMode: "onlyContent" | "fullNode" = "fullNode";
    @Input() node: ContextNode;
    @Input() isOpen: boolean = false;
    @Input() packages: Package[];

    @Output() editPackage = new EventEmitter<Package>();
    @Output() deletePackage = new EventEmitter<Package>();

    private disposeBag = new DisposeBag();

    constructor(private translateService: TranslateService, private fileUploadService: FileUploadGenericService, private notificationService: NotificationService, private actionPaneService: ContextNodePaneActionsService) { }

    get isFullNodeDisplay(): boolean {
        return this.displayMode === "fullNode";
    }

    onPackageNameChange(pckage: Package, value: string): void {
        let name: TranslatableLabel = { ...pckage.name };
        name[this.translateService.lang] = value;
        this.editPackage.emit(pckage.change({
            name
        }));
    }

    get lang(): string {
        return this.translateService.lang
    }

    openEditPackagePane(packageId: string): void {
        this.actionPaneService.openActionPaneAtIndex("edit-package", 1, { packageId, contextNodeId: this.node.id, projectId: this.projectId }).subscribe().disposedBy(this.disposeBag);
    }

    removePackage(pckage: Package): void {
        this.deletePackage.emit(pckage);
    }

    copyLink(packageId: string): void {
        navigator.clipboard.writeText(
            `${window.location.origin}#/documents/${packageId}`
        );
        this.notificationService.show("Le liens du membre à été copiés dans le presse-papier.", "info");
    }
    onInputChange(pckage: Package, event: any, retry = false): void {
        if (event.target.files.length > 0) {

            const files = event.target.files;
            this.fileUploadService
                .uploadFiles(Array.from(files))
                .pipe(
                    delay(500),
                    filter(response => response.percentage === 100),
                    catchError((err) => {
                        if (!retry) {
                            this.onInputChange(pckage, event, true);
                            return null;
                        }
                        return throwError(err);
                    }),
                    finalize(() => { })
                )
                .subscribe((uploadVideoResponse) => {
                    this.editPackage.emit(pckage.change({
                        files: [
                            ...pckage.files,
                            ...uploadVideoResponse.urls.map(url => {
                                return {
                                    id: guid(),
                                    url,
                                    name: url,
                                }
                            })
                        ]
                    }));
                })
                .disposedBy(this.disposeBag);
        }
    }

    removeFile(fileId: string, pckage: Package): void {
        this.editPackage.emit(pckage.change({
            files: pckage.files.filter(f => f.id !== fileId)
        }))
    }

    trackById(_: number, entity: any): string {
        return entity?.id;
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }

}
