import { Injectable } from '@angular/core';
import { isEmpty } from 'src/app/core/functions';

import { ContactImage } from '../model/contact-image';
import { ImageDispositionSpecification } from '../model/contactPerspective';

@Injectable({
    providedIn: "root",
})
export class FacePositionService {
    constructor() { }

    static profileLayoutVariantsForNumberOfPictures(numOfPictures: number): MultiCustomLayout[] {
        switch (numOfPictures) {
            case 1:
                return [[1]];
            case 2:
                return [[1, 1], [2]];
            case 3:
                return [[2, 1], [1, 2], [3], [1, 1, 1]];
            case 4:
                return [
                    [2, 2],
                    [1, 3],
                    [3, 1],
                    [1, 2, 1],
                    [2, 1, 1],
                    [1, 1, 2],
                    [1, 1, 1, 1],
                ];
            case 5:
                return [
                    [1, 1, 3],
                    [3, 1, 1],
                    [1, 3, 1],
                    [2, 3],
                    [3, 2],
                    [1, 2, 2],
                    [2, 2, 1],
                    [2, 1, 2],
                    [1, 1, 1, 1, 1],
                ];
            case 6:
                return [
                    [2, 2, 2],
                    [1, 1, 1, 1, 1, 1],
                ];
            case 7:
                return [
                    [2, 2, 2, 1],
                    [1, 2, 2, 2],
                    [1, 1, 1, 1, 1, 1, 1],
                ];
            case 8:
                return [
                    [2, 2, 2, 2],
                    [1, 1, 1, 1, 1, 1, 1, 1],
                ];

            default:
                if (numOfPictures % 2 === 0) {
                    return [
                        Array.from({ length: numOfPictures / 2 }, () => 2),
                        Array.from({ length: numOfPictures }, () => 1),
                    ];
                } else {
                    return [
                        [1, ...Array.from({ length: (numOfPictures - 1) / 2 }, () => 2)],
                        [...Array.from({ length: (numOfPictures - 1) / 2 }, () => 2), 1],
                        Array.from({ length: numOfPictures }, () => 1),
                    ];
                }
        }
    }

    private getDefaultPositionForFace(image: ContactImage, options: ImageDispositionOption): PositionFocus {
        const faceRectangle = image.faceRectangle;

        if (faceRectangle && !isEmpty(faceRectangle)) {
            return this.positionForFaceRectangle(faceRectangle, image.width, image.height, image.rotate, options);
        } else {
            const zoom = (options.containerWidth * 100) / image.width;
            const positionFace = {
                zoom,
                backgroundx: 0,
                backgroundy: 0,
                rotate: image.rotate,
            };

            return positionFace;
        }
    }

    positionForFaceRectangle(
        faceRectangle: any,
        imageWidth: number,
        imageHeight: number,
        rotate: number,
        options: ImageDispositionOption
    ): PositionFocus {
        const zoom = Math.ceil((options.finalFaceHeight / faceRectangle.height) * 100);

        const centerImageX = options.containerWidth / 2 - (imageWidth * zoom) / 100 / 2;
        const centerImageY = options.containerHeight / 2 - (imageHeight * zoom) / 100 / 2;

        const positionOfFaceFromCenterX =
            -(((imageWidth / 2) * zoom) / 100) +
            (faceRectangle.left * zoom) / 100 +
            ((faceRectangle.width / 2) * zoom) / 100;
        const positionOfFaceFromCenterY =
            -(((imageHeight / 2) * zoom) / 100) +
            (faceRectangle.top * zoom) / 100 +
            ((faceRectangle.height / 2) * zoom) / 100;

        return {
            zoom,
            backgroundx: centerImageX - positionOfFaceFromCenterX,
            backgroundy: centerImageY - positionOfFaceFromCenterY,
            rotate,
        };
    }

    recenterFaceForImageLayout(image: ImageLayout, options: ImageDispositionOption): ImageLayout {
        if (image.faceRectangle && image.width && image.height) {
            const position = this.positionForFaceRectangle(
                image.faceRectangle,
                image.width,
                image.height,
                image.rotate,
                options
            );
            return {
                ...image,
                zoom: position.zoom,
                backgroundx: position.backgroundx,
                backgroundy: position.backgroundy,
            };
        }
        return image;
    }

    mapContactImagesToImageLayout(
        images: ContactImage[],
        options: ImageDispositionOption,
        imagesSpecs: ImageDispositionSpecification[]
    ): ImageLayout[] {
        return images
            .map((image) =>
                this.mapContactImageToImageLayout(
                    image,
                    options,
                    imagesSpecs.find((imgSpec) => imgSpec.imageId === image.id)
                )
            )
            .reduce((acc, val) => acc.concat(val), []);
    }

    mapContactImageToImageLayout(
        image: ContactImage,
        options: ImageDispositionOption,
        imageSpec?: ImageDispositionSpecification
    ): ImageLayout {
        if (imageSpec) {
            return {
                id: imageSpec.imageId,
                url: image.url,
                width: image.width,
                height: image.height,
                faceRectangle: image.faceRectangle,
                zoom: imageSpec.zoom,
                backgroundx: imageSpec.x || undefined,
                backgroundy: imageSpec.y || undefined,
                rotate: image.rotate || 0,
                cellPicture: image.cellPicture,
                uploadedAt: image?.uploadedAt,
                originalDate: image?.originalDate,
            };
        } else {
            const defaultSpecs = this.getDefaultPositionForFace(image, options);
            return {
                id: image.id,
                url: image.url,
                width: image.width,
                height: image.height,
                faceRectangle: image.faceRectangle,
                zoom: defaultSpecs ? defaultSpecs.zoom : undefined,
                backgroundx: defaultSpecs ? defaultSpecs.backgroundx : undefined,
                backgroundy: defaultSpecs ? defaultSpecs.backgroundy : undefined,
                rotate: defaultSpecs ? defaultSpecs.rotate : 0,
                cellPicture: image.cellPicture,
                uploadedAt: image?.uploadedAt,
                originalDate: image?.originalDate,
            };
        }
    }
}

export interface PositionFocus {
    zoom: number;
    backgroundx: number;
    backgroundy: number;
    rotate: number;
}

export interface ImageDispositionOption {
    containerWidth: number;
    containerHeight: number;
    finalFaceHeight: number;
}

export interface ImageLayout {
    id?: string;
    url: string;
    name?: string;
    zoom?: number;
    rotate?: number;
    backgroundx?: number;
    backgroundy?: number;
    width?: number;
    height?: number;
    faceRectangle?: {
        top: number;
        left: number;
        width: number;
        height: number;
    };
    cellPicture?: boolean;
    uploadedAt?: Date;
    originalDate?: Date;
}

export type MultiCustomLayout = number[];
