import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Contact } from 'src/modules/diversite/model/contact';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { Activity } from 'src/modules/diversite/services/activity.service';
import { ContactService } from 'src/modules/diversite/services/contact.service';

@Component({
    selector: 'diversite-contact-signup-activity',
    templateUrl: './contact-signup-activity.component.html',
    styleUrl: './contact-signup-activity.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSignupActivityComponent {
    @Input() activity: ContactSignupActivity;
    @Output() openProfile = new EventEmitter<string>();

    contact$: Observable<Contact>;

    constructor(private contactService: ContactService) { }

    ngOnInit(): void {
        this.contact$ = this.contactService.contactByIdfig(this.activity.idfig);
    }

    onViewProfile(contact: ContactPerspective): void {
        this.openProfile.emit(contact.id)
    }
}


export interface ContactSignupActivity {
    id: string,
    created: Date;
    idfig: string,
}