import { ChangeDetectionStrategy, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { FileUploadImageService } from 'src/modules/core/services/file-upload-image.service';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Contact } from 'src/modules/diversite/model/contact';
import { ContactPerspective } from 'src/modules/diversite/model/contactPerspective';
import { Form } from 'src/modules/diversite/model/form/form';
import { Pane } from 'src/modules/diversite/model/pane';
import {
    AttributeNodesContext,
    AttributesContextPerspectiveService,
} from 'src/modules/diversite/services/attributes-context-perspective.service';
import { ContactService } from 'src/modules/diversite/services/contact.service';
import { FormService } from 'src/modules/diversite/services/form.service';

import { AddPhotosForContactEvent } from '../../../contacts/contact-view/contact-view.component';

@Component({
    selector: "diversite-contact-pane-content-container",
    templateUrl: "./contact-pane-content-container.component.html",
    styleUrls: ["./contact-pane-content-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPaneContentContainerComponent implements OnInit {
    constructor(
        private contactService: ContactService,
        private formService: FormService,
        private notificationService: NotificationService,
        private fileUploadService: FileUploadImageService,
        private attrContextPerspectiveService: AttributesContextPerspectiveService
    ) { }

    @Input() pane: Pane;

    forms$: Observable<Form[]>;

    contactPerspective$: Observable<ContactPerspective>;

    private disposeBag = new DisposeBag();

    context$: Observable<AttributeNodesContext>;

    ngOnInit(): void {
        this.forms$ = this.formService.forms();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pane?.currentValue) {
            const contactId = changes.pane.currentValue.contextData?.contactId;
            if (contactId) {
                this.contactPerspective$ = this.contactService
                    .contactById(contactId)
                    .pipe(filter(c => c ? true : false), map((c) => c.defaultPerspective));
            }

            if (changes.pane.currentValue.contextData?.formId) {
                this.context$ = of({
                    projectId: this.pane?.projectId || null,
                    formId: this.pane?.contextData?.formId || null,
                });
            } else if (!this.context$) {
                this.context$ = this.attrContextPerspectiveService.lastSelectedContext();
            }
        }
    }

    onEditContact(contact: Contact): void {
        this.contactService
            .editContact(contact)
            .subscribe((_) => {
                this.notificationService.show("Le profil à été modifié avec succès !", "success");
            })
            .disposedBy(this.disposeBag);
    }

    onAddPhotos(event: AddPhotosForContactEvent): void {
        this.fileUploadService
            .uploadFiles(event.files)
            .pipe(
                switchMap((uploadEvent) => {
                    return forkJoin(
                        uploadEvent.urls.map((url) => this.contactService.addContactPhoto(event.contact.idfig, url))
                    ).pipe(switchMap(() => this.contactService.contactById(event.contact.id)));
                }),
                take(1)
            )
            .subscribe((c) => {
                this.notificationService.show(
                    "Vos images on bien été téléchargé. Un temps de traitement est requis.",
                    "info"
                );
            })
            .disposedBy(this.disposeBag);
    }
}
