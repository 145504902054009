<clr-input-container [class.no-label]="!options.showLabel" *ngIf="formElement">
    <label *ngIf="options.showLabel"
        >{{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>
    <input
        [disabled]="formElement.readonly"
        [value]="value"
        (keyup)="onInputChange($event)"
        (change)="onInputChange($event)"
        type="number"
        clrInput
        [name]="formElement.name"
        [required]="formElement.required"
    />
</clr-input-container>
