import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Form } from '../../model/form/form';
import { FormElementInput } from '../../model/form/form-element/form-element';
import { SearchOperation } from '../../model/search-operation';
import { TranslateService } from '../../services/translate.service';

@Component({
    selector: "diversite-fillout-search",
    templateUrl: "./fillout-search.component.html",
    styleUrls: ["./fillout-search.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilloutSearchComponent implements OnInit {

    @Input() searchOperations: SearchOperation[] = [];
    @Input() form: Form;
    @Output() searchOperationsChange = new EventEmitter<SearchOperation>();

    constructor(private translateService: TranslateService) { }

    ngOnInit(): void { }

    searchValues(): SearchOperation[] {
        return [];
    }

    get lang(): string {
        return this.translateService.lang;
    }

    onSearchOperationChange(sop: SearchOperation): void {
        this.searchOperationsChange.emit(sop);
    }

    searchButton(): void { }

    get inputFormElements(): FormElementInput[] {
        if (this.form) {
            return this.form.activeFormElements().filter((fe) => fe.isInput()) as FormElementInput[];
        }
        return [];
    }
}
