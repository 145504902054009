import { FormElementDTO } from "src/modules/diversite/mappers/module-mapper.service";
import { TranslatableLabel } from "src/modules/diversite/services/data-catalog.service";

import { FormElement, FormElementType } from "./form-element";

export class FormText implements FormElement {
    private _id: string;
    private _nameDescriptor: TranslatableLabel;
    private _description: TranslatableLabel;
    private _name: string;
    private _type: FormElementType = "text";
    private _text: TranslatableLabel;
    private _deleted: boolean;

    static defaultElement(): FormText {
        return new FormText({
            nameDescriptor: { fr: "Texte statique" },
            text: "",
        });
    }

    constructor(builder: FormElementDTO) {
        this._id = builder.id;
        this._nameDescriptor = builder.nameDescriptor || {};
        this._description = builder.description || {};
        this._name = builder.name || "";
        this._text = builder.text;
        this._deleted = builder.deleted === true ? true : false;
    }

    get id(): string {
        return this._id;
    }
    get nameDescriptor(): TranslatableLabel {
        return this._nameDescriptor;
    }
    get description(): TranslatableLabel {
        return this._description;
    }

    get name(): string {
        return this._name;
    }
    get type(): FormElementType {
        return this._type;
    }

    get text(): TranslatableLabel {
        return this._text;
    }
    get deleted(): boolean {
        return this._deleted;
    }

    change(builder: any): FormText {
        return new FormText({
            id: this.id,
            nameDescriptor: this._nameDescriptor,
            description: this.description,
            name: this.name,
            text: this.text,
            deleted: this.deleted,
            ...builder,
        });
    }

    isInput(): boolean {
        return false;
    }

    toDto(): FormElementDTO {
        return {
            id: this.id,
            nameDescriptor: this._nameDescriptor || null,
            description: this.description || null,
            name: this.name || null,
            type: this.type,
            text: this.text || null,
            deleted: this.deleted,
        };
    }
}
