<div
    *ngIf="url && !hideImage"
    class="image-holder"
    [style.background-image]="safeBackgroundImage(url)"
    [style.transform]="rotation(rotate || 0)"
    [style.background-size]="mode"
></div>

<div *ngIf="!url || hideImage" class="image-holder">
    <p><cds-icon shape="picture" badge="warning-triangle" size="xl"></cds-icon></p>
</div>
<ng-content></ng-content>
