import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, skip } from 'rxjs/operators';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { FormUnion } from 'src/modules/diversite/model/form/form-element/form-union';
import { Union } from 'src/modules/diversite/model/union';

import { DEFAULT_OPTIONS, FormElementGenericOptions } from '../../form-element.component';

const UNDEFINED_UNION: Union = {
    id: null,
    prefix: null,
};

@Component({
    selector: "fillout-union",
    templateUrl: "./union.component.html",
    styleUrls: ["./union.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnionComponent implements OnInit, OnChanges {
    @Input() lang: string;
    @Input() formElement: FormUnion;
    @Input() value: Union = UNDEFINED_UNION;
    @Input() options: FormElementGenericOptions = { ...DEFAULT_OPTIONS };
    @Output() responseChange = new EventEmitter<Union>();
    delayedChanges$ = new BehaviorSubject<void>(undefined);

    id: string;
    prefix: string;

    private _disposeBag = new DisposeBag();

    constructor() {}

    ngOnInit(): void {
        this.delayedChanges$
            .pipe(debounceTime(250), skip(1))
            .subscribe(() => {
                this.responseChange.emit({
                    id: this.id || null,
                    prefix: typeof this.prefix != "undefined" && this.prefix ? this.prefix : " ",
                });
            })
            .disposedBy(this._disposeBag);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && !changes.value.currentValue) {
            this.value = UNDEFINED_UNION;
        }
        if (changes.value && changes.value.currentValue) {
            this.id = changes.value.currentValue.id;
            this.prefix = changes.value.currentValue.prefix;
        }
    }

    name(idprefix: string): string {
        return `${this.formElement.name}-${idprefix}`;
    }

    onInputChange(): void {
        this.delayedChanges$.next();
    }
}
