<div class="add-section">
    <cds-icon
        role="button"
        class="add-icon"
        [class.disabled]="disabled"
        (click)="addParameter()"
        shape="plus"
    ></cds-icon>
    <cds-icon
        class="view-icon"
        [class.viewed]="isViewed(formElement.name)"
        role="button"
        (click)="onToggleViewField(formElement.name)"
        shape="eye"
    ></cds-icon>
</div>
<diversite-search-form-element
    [lang]="lang"
    [display]="display"
    [formElement]="formElement"
    [searchOperation]="searchOperation"
    (enterPressed)="onEnterPressed()"
    (valueChange)="onSearchOperationValueChange($event)"
/>
