import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { map, Observable } from 'rxjs';

import { Bookmark, BookmarkService } from '../../services/bookmark.service';

@Component({
    selector: 'diversite-bookmark-selector',
    templateUrl: './bookmark-selector.component.html',
    styleUrl: './bookmark-selector.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookmarkSelectorComponent {
    @Input() searchDefinitionId: string;
    @Input() selectedBookmark: Bookmark;
    @Input() viewNew = false;
    @Output() selectBookmark = new EventEmitter<Bookmark>();
    @Output() changeNew = new EventEmitter<boolean>();


    bookmarks$: Observable<Bookmark[]>;


    constructor(private bookmarkService: BookmarkService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.searchDefinitionId?.currentValue && !this.bookmarks$) {
            this.bookmarks$ = this.bookmarkService.bookmarksForSearch(changes.searchDefinitionId?.currentValue).pipe(map(bookmarks => {
                return bookmarks.filter((element, index) => index < 4);
            }));
        }
    }

    select(bookmark: Bookmark): void {
        this.selectedBookmark = bookmark;
        this.selectBookmark.emit(bookmark);
    }

    trackById(_: number, item: any): string {
        return item.id;
    }

    changeNewState(isChecked: boolean): void {
        this.changeNew.emit(isChecked);
    }

}

