import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';

import { Form, Variable } from '../model/form/form';
import { TranslatableLabel } from '../services/data-catalog.service';
import { FilterDTO, ModuleDTO, ModuleMapperService } from './module-mapper.service';

@Injectable({
    providedIn: "root",
})
export class FormMapperService {
    constructor(private moduleMapper: ModuleMapperService, private db: AngularFirestore) { }

    formToFormDto(form: Form): FormDTO {
        return {
            id: form.id,
            name: form.name,
            projectId: form.projectId || null,
            logo: form.logo || null,
            endText: form.endText,
            languages: form.languages || [],
            modules: form.modules.map((m) => this.moduleMapper.moduleToModuleDto(m)),
            isTemplate: form.isTemplate,
            variables: form.variables.map((v) => {
                return { id: v.id, name: v.name };
            }),
            isPublished: form.isPublished,
            users: form.users,
            archived: form.archived,
            deleted: form.deleted,
            lastUpdated: form.lastUpdated,
        };
    }

    formDtoToForm(formDto: FormDTO): Form {
        if (formDto) {
            return new Form({
                id: formDto.id,
                name: this.stringToTranslatableLabel(formDto.name || ""),
                projectId: formDto.projectId || null,
                logo: formDto.logo || "",
                endText: this.stringToTranslatableLabel(formDto.endText || ""),
                modules: formDto.modules
                    ? formDto.modules.map((module) => this.moduleMapper.moduleDtoToModule(module))
                    : [],
                isPublished: formDto.isPublished === true ? true : false,
                isTemplate: formDto.isTemplate === true ? true : false,
                variables: formDto.variables ? formDto.variables.map((vDto) => new Variable(vDto)) : [],
                languages: formDto.languages || [],
                users: formDto.users,
                archived: formDto.archived === true ? true : false,
                deleted: formDto.deleted === true ? true : false,
                lastUpdated: formDto.lastUpdated ? formDto.lastUpdated * 1000 : null,
            });
        }
        return undefined;
    }

    private stringToTranslatableLabel(value: string | TranslatableLabel): TranslatableLabel {
        if (typeof value === "string") {
            return {
                fr: value,
            };
        }
        return value;
    }
}

export interface FormDTO {
    id?: string;
    name?: any;
    projectId?: string;
    logo?: string;
    endText?: any;
    filters?: FilterDTO[];
    modules?: ModuleDTO[];
    isPublished?: boolean;
    recruitment?: DocumentReference;
    languages?: string[];
    isTemplate?: boolean;
    variables?: VariableDTO[];
    users?: DocumentReference[];
    archived?: boolean;
    deleted?: boolean;
    lastUpdated?: number;
}

export interface VariableDTO {
    id: string;
    name: string;
}
