<clr-range-container [class.no-label]="!options.showLabel" *ngIf="formElement">
    <label *ngIf="options.showLabel"
        >{{ formElement.label[lang] }}&nbsp;<span *ngIf="value" class="value"
            >{{ meters(value) }}m. / {{ feetInch(value) }}</span
        ><span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>
    <label *ngIf="!options.showLabel">{{ meters(value) }}m. / {{ feetInch(value) }}</label>
    <input
        class="slider"
        [value]="value"
        (input)="onInputChange($event)"
        [disabled]="formElement.readonly"
        type="range"
        [name]="formElement.name"
        clrRange
        [required]="formElement.required"
        min="1"
        max="308"
    />
</clr-range-container>
<cds-icon role="button" shape="minus" (click)="minus()"></cds-icon>
<cds-icon role="button" shape="plus" (click)="plus()"></cds-icon>
<!-- <div class="value">
    <span *ngIf="value" class="kg-content"
        ><input
            (change)="metersChange($event.target.value)"
            [value]="meters(value)"
            class="small"
            type="number"
        />m.</span
    >
    <span *ngIf="value" class="pounds-content"
        ><input
            class="small"
            #feetInput
            (change)="feetChange($event.target.value, inchInput)"
            type="number"
            [value]="feet(value)"
        />'<input
            #inchInput
            class="small"
            (change)="inchChange(feetInput, $event.target.value)"
            type="number"
            [value]="inch(value)"
        />"</span
    >
</div> -->
