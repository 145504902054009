import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { catchError, delay, filter, finalize, tap, throwError } from 'rxjs';
import { guid } from 'src/app/core/functions';
import { FileUploadGenericService } from 'src/modules/core/services/file-upload.service';
import { NotificationService } from 'src/modules/core/services/notification.service';
import { DisposeBag } from 'src/modules/core/utilities/dispose-bag';
import { Package, SharedPackageService } from 'src/modules/diversite/services/shared-folder.service';

@Component({
    selector: 'diversite-edit-package-action',
    templateUrl: './edit-package-action.component.html',
    styleUrl: './edit-package-action.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditPackageActionComponent {
    @Input() projectId: string;
    @Input() packageId: string;
    @Output() close = new EventEmitter<void>();

    package: Package;


    private disposeBag = new DisposeBag();

    constructor(private sharedPackagesService: SharedPackageService, private fileUploadService: FileUploadGenericService, private notificationService: NotificationService, private chRef: ChangeDetectorRef) { }

    ngOnInit() {
        this.sharedPackagesService.package(this.packageId, { listen: false }).subscribe(p => {
            this.package = p;
            this.chRef.detectChanges();
        }).disposedBy(this.disposeBag);
    }

    onDescriptionChanged(desc: string, lang: string): void {
        let description = { ...this.package.description };
        description[lang] = desc;
        this.package = this.package.change({
            description
        });
    }
    onNameChanged(name: string, lang: string): void {
        let n = { ...this.package.name };
        n[lang] = name;

        this.package = this.package.change({
            name: n
        })
    }

    removeFile(fileId: string, pckage: Package): void {
        this.package = pckage.change({
            files: pckage.files.filter(f => f.id !== fileId)
        });
    }

    onInputChange(pckage: Package, event, retry = false): void {
        if (event.target.files.length > 0) {

            const files = event.target.files;
            this.fileUploadService
                .uploadFiles(Array.from(files))
                .pipe(
                    delay(500),
                    filter(response => response.percentage === 100),
                    tap(uploadVideoResponse => {
                        this.package = this.package.change({
                            files: [
                                ...pckage.files,
                                ...uploadVideoResponse.urls.map(url => {
                                    return {
                                        id: guid(),
                                        url,
                                        name: url,
                                    }
                                })
                            ]
                        })
                    }),
                    catchError((err) => {
                        if (!retry) {
                            this.onInputChange(pckage, event, true);
                            return null;
                        }
                        return throwError(err);
                    }),
                    finalize(() => { })
                )
                .subscribe((uploadVideoResponse) => {
                })
                .disposedBy(this.disposeBag);
        }

    }

    onFileDescirptionChange(value: string, fileId: string, lang: string): void {
        this.package = this.package.change({
            files: this.package.files.map(file => {
                if (file.id === fileId) {
                    let desc = { ...file.description };
                    desc[lang] = value;
                    return { ...file, description: desc };
                }
                return file;
            })
        })
    }

    onCancel(): void {
        this.close.emit();
    }

    editPackage(): void {
        this.sharedPackagesService.editPackage(this.package).subscribe(_ => {
            this.notificationService.show("Le dossier de partage à été modifié avec succès.", "success");
            this.close.emit();
        });
    }

    ngOnDestroy(): void {
        this.disposeBag.dispose();
    }
}
