<!-- <diversite-contacts-report
    *ngIf="report$ | async as report"
    [report]="report"
    (editReport)="onEditReport($event)"
></diversite-contacts-report>


 -->
<diversite-report-edit
    *ngIf="report$ | async as report"
    [report]="report"
    (editReport)="onEditReport($event)"
></diversite-report-edit>
