<div [class.no-label]="!options.showLabel" *ngIf="formElement" class="clr-form-control">
    <label *ngIf="options.showLabel" class="clr-control-label"
        >{{ formElement.label[lang] }}&nbsp;<span [hidden]="!formElement.required" class="required-star">*</span
        ><ng-content select="[tooltip-content]"></ng-content
    ></label>
    <div class="clr-control-container">
        <div class="clr-select-wrapper">
            <select
                [disabled]="formElement.readonly"
                id="select-basic"
                class="clr-select"
                [(ngModel)]="value"
                (change)="onInputChange($event)"
            >
                <option value="">-</option>
                <option *ngFor="let option of formElement.options" [value]="option.id">
                    {{ option.label[lang] }}
                </option>
            </select>
        </div>
    </div>
</div>
