import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Pane } from 'src/modules/diversite/model/pane';
import { Project } from 'src/modules/diversite/model/project';
import { ProjectService } from 'src/modules/diversite/services/projects.service';

@Component({
    selector: "diversite-projects-pane-content-container",
    templateUrl: "./projects-pane-content-container.component.html",
    styleUrls: ["./projects-pane-content-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsPaneContentContainerComponent implements OnInit {
    @Input() pane: Pane;


    constructor(private projectService: ProjectService) { }
    projects$: Observable<Project[]>;

    ngOnInit(): void {
        this.projects$ = this.projectService.projects({ listen: true });
    }
}
