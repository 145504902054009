<div class="favorites">
    <span class="actions">
        <clr-toggle-wrapper class="favorite-options">
            <input type="checkbox" clrToggle name="edit-perspective-mode" [(ngModel)]="editFavoriteMode" />
            <label>Édition</label>
        </clr-toggle-wrapper>
    </span>

    <div class="search-favorite-nodes">
        <diversite-search-favorite-node
            *ngFor="let node of favoriteNodes; trackBy: trackById"
            [attr.data-nodeid]="node.id"
            [editFavoriteMode]="editFavoriteMode"
            [searchGroup]="searchGroup"
            [node]="node"
            (nodeChange)="onNodeChange($event)"
            (removeNode)="onRemoveFavoriteNode($event)"
            (sortTree)="onSortTree()"
            (searchOperationChange)="onSearchElementChange($event)"
        />
    </div>
    <small (click)="addFavoriteGroup()" class="action-link" *ngIf="editFavoriteMode">Ajouter un groupe</small>
</div>
