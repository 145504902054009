import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, combineLatest, filter, map, Observable, of, startWith, switchMap, tap } from 'rxjs';
import { guid } from 'src/app/core/functions';

import { Attribute, Contact } from '../model/contact';
import { SearchDefinition, SearchPagination } from '../model/search-definition';
import { ContactResult, ElasticSearchService, SearchResultContacts } from './elasticsearch.service';
import { ContextNode } from '../model/context-node';
import { ContactService } from './contact.service';
import { ContextNodeService } from './context-node.service';
import { SystemRequestService } from 'src/app/services/system-request.service';

@Injectable({
    providedIn: 'root'
})
export class ContactContextNodeAssignService {


    private contactsAssigned$ = new BehaviorSubject<ContactAssignedData>(null);
    private nodeContactsChanged$ = this.contactsAssigned$.pipe(
        switchMap(data => {
            if (data) {
                return this.systemRequestService.get(data.jobId).pipe(
                    filter(sr => sr.status === "success" || sr.status === "error"),
                    map(_ => data.nodeId)
                )
            } else {
                return of(null);
            }
        })
    );


    constructor(private contactService: ContactService, private contextNodeService: ContextNodeService, private systemRequestService: SystemRequestService) { }

    contactsForNodeId(nodeId: string, options = { listen: true }): Observable<Contact[]> {
        if (options?.listen === true) {
            return this.nodeContactsChanged$.pipe(
                filter(changedNodeId => !changedNodeId || changedNodeId === nodeId),
                switchMap(_ => {
                    return this.searchForNodeContacts(nodeId);
                })
            )
        } else {
            return this.searchForNodeContacts(nodeId);
        }
    }

    private searchForNodeContacts(nodeId: string): Observable<Contact[]> {
        return this.contactService.searchContacts(
            SearchDefinition.DEFAULT_SEARCH_DEFINITION_NODE(nodeId),
            new SearchPagination({ limit: 9999 }),
            { simpleProfile: true }
        ).pipe(
            map(contactResults => {
                return contactResults.contactResults.map(cr => cr.contact)
            })
        );
    }

    assignSearchResultsToNode(searchDef: SearchDefinition, node: ContextNode, options = { wait: false }): Observable<ContextNode> {
        return this.contactService.massEditSearchDefinitionResults(
            searchDef,
            [{ attribute: `contextNode_${node.id}`, type: 'set', value: true }],
            options
        ).pipe(
            map(job => {
                return {
                    jobId: job.jobId,
                    contextNode: node
                }
            }),
            switchMap(data => {
                return this.contextNodeService.editNode(data.contextNode.change({
                    contextData: { ...data.contextNode.contextData, jobId: data.jobId }
                })).pipe(tap(n => {
                    this.contactsAssigned$.next({
                        nodeId: n.id,
                        jobId: n.contextData.jobId
                    })
                }))
            })
        );
    }

    assignContactsToNode(contactIds: string[], node: ContextNode, options = { wait: false }): Observable<ContextNode> {
        return this.contactService.massEditContactIds(
            contactIds,
            [{ attribute: `contextNode_${node.id}`, type: 'set', value: true }],
            options
        ).pipe(
            map(job => {
                return {
                    jobId: job.jobId,
                    contextNode: node
                }
            }),
            switchMap(data => {
                return this.contextNodeService.editNode(data.contextNode.change({
                    contextData: { ...data.contextNode.contextData, jobId: data.jobId }
                })).pipe(tap(n => {
                    this.contactsAssigned$.next({
                        nodeId: n.id,
                        jobId: n.contextData.jobId
                    })
                }))
            })
        );
    }


    unassignContactsFromNode(contactIds: string[], node: ContextNode, options = { wait: false }): Observable<ContextNode> {
        return this.contactService.massEditContactIds(
            contactIds,
            [{ attribute: `contextNode_${node.id}`, type: 'set', value: false }],
            options
        ).pipe(
            map(job => {
                return {
                    jobId: job.jobId,
                    contextNode: node
                }
            }),
            switchMap(data => {
                return this.contextNodeService.editNode(data.contextNode.change({
                    contextData: { ...data.contextNode.contextData, jobId: data.jobId }
                })).pipe(tap(n => {
                    this.contactsAssigned$.next({
                        nodeId: n.id,
                        jobId: n.contextData.jobId
                    })
                }))
            })
        );
    }


}

export interface ContactAssignedData {
    jobId: string;
    nodeId: string;
}