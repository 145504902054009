<div *ngIf="formElement"
     class="form-element-edition">
    <span #labelElement
          class="edit-label"
          contenteditable
          (keyup)="onLabelChange()"
          [innerHTML]="tempFormElement.label[lang]"></span>
    <ng-content select="[tooltip]"></ng-content>
    <div class="options">
        <div [attr.data-index]="index"
             class="option"
             *ngFor="let option of tempFormElement.options; let index = index">
            <cds-icon class="drag-field"
                      shape="drag-handle"></cds-icon>
            <input [disabled]="formElement.nameDisabled"
                   class="option-edition"
                   (change)="onLabelChange()"
                   [(ngModel)]="option.label[lang]" />
            <!-- <input
                class="value-edition"
                placeholder="Entrez le nom du champ ici"
                [disabled]="formElement.nameDisabled"
                (change)="onLabelChange()"
                [(ngModel)]="option.name"
            /> -->
            <small *ngIf="!formElement.nameDisabled"
                   (click)="deleteOption(option)">
                <clr-icon shape="times"></clr-icon>
            </small>
        </div>
    </div>
    <small *ngIf="!formElement.nameDisabled"
           (click)="addOptionToField()">Ajouter une option</small>
</div>