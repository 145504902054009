<diversite-form-edit
    *ngIf="form$ | async as form"
    [contextNodeId]="contextNodeId"
    [projectId]="projectId"
    [paneId]="paneId"
    [initForm]="form"
    [publicContexts]="publicContexts$ | async"
    [templateModules]="templateModules$ | async"
    [questionLibraryModules]="questionLibraryModules$ | async"
    (saveForm)="saveForm($event)"
    (addQuestionLibModule)="onAddQuestionLibModule($event)"
    (editQuestionLibModule)="onEditQuestionLibModule($event)"
    (editQuestionLibModules)="onEditQuestionLibModules($event)"
    (deleteQuestionLibModule)="onDeleteQuestionLibModule($event)"
    (addFormLink)="onAddFormLink($event)"
    (disablePublicContext)="onDisablePublicContext($event)"
    (enablePublicContext)="onEnablePublicContext($event)"
    (removePublicContext)="onRemovePublicContext($event)"
>
</diversite-form-edit>
