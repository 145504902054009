import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { PaneType } from "src/modules/diversite/model/pane";

import { ContextNodeTitle } from "../../services/pane-title.service";
import { PaneSizeSpec } from "../pane.component";

@Component({
    selector: "diversite-pane-header",
    templateUrl: "./pane-header.component.html",
    styleUrls: ["./pane-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaneHeaderComponent implements OnInit {
    @Input() panePath: ContextNodeTitle[] = [];
    @Input() forceHeaderName: string;
    @Input() collapsible: boolean = false;
    @Input() collapsed = false;
    @Input() closable = true;
    @Input() pinable = true;
    @Input() paneType: PaneType;
    @Input() paneSize: PaneSizeSpec;
    @Input() locked = false;

    @Output() remove = new EventEmitter<void>();
    @Output() maximizePane = new EventEmitter<void>();
    @Output() toggleCollapse = new EventEmitter<void>();
    @Output() toggleLock = new EventEmitter<void>();
    @Output() paneSizeChange = new EventEmitter<number | "*">();

    size: number | "*";

    constructor() { }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.paneSize?.currentValue) {
            this.size = this.paneSize.size;
        }
    }

    deletePane(): void {
        this.remove.emit();
    }

    lockPane(): void {
        this.locked = !this.locked;
        this.toggleLock.emit();
    }

    get collapseDisabled(): boolean {
        return !this.collapsed && !this.collapsible;
    }

    maximize() {
        this.maximizePane.emit();
    }

    onPaneSizeChange(): void {
        this.paneSizeChange.emit(this.paneSize.size);
    }

    toggleCollapsePane(): void {
        if (!this.collapseDisabled) {
            this.toggleCollapse.emit();
        }
    }
}
