<div class="search-value {{ formElement.type }}" [class.inline]="display === 'horizontal'" *ngIf="formElement">
    <div class="label-options">
        <ng-content></ng-content>
        <label class="label-search">{{ formElement.label[lang] }}</label>
    </div>
    <search-value-selector
        [searchValue]="searchValue"
        [display]="display"
        [type]="type"
        [options]="options"
        [lang]="lang"
        (keyPressed)="onKeypress($event)"
        (valueChange)="onSearchValueChange($event)"
    />
</div>
