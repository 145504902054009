import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { DisposeBag } from "src/modules/core/utilities/dispose-bag";

@Component({
    selector: "admin-container",
    templateUrl: "./admin-container.component.html",
    styleUrls: ["./admin-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminContainerComponent implements OnInit {
    currentUrl: string;

    private _disposeBag = new DisposeBag();

    constructor(private router: Router, private route: ActivatedRoute) {
        // this.router.routerState()
        // this.route.url.subscribe(_ => console.log("url", _));
    }

    ngOnInit(): void {
        this.currentUrl = this.router.routerState.snapshot.url.split("/").pop();
        this.router.events
            .subscribe((navEvent) => {
                if (navEvent instanceof NavigationStart) {
                    this.currentUrl = navEvent.url.split("/").pop();
                }
            })
            .disposedBy(this._disposeBag);
    }

    isActive(page: string): boolean {
        return page === this.currentUrl;
    }

    goTo(page: string): void {
        this.router.navigate([`./${page}`], { relativeTo: this.route });
    }
}
